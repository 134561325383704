<template>
  <div class="boutique-header">
    <h3 class="parameters-title">Mangopay</h3>
  </div>
  <CustomTabs :tabs="tabs"></CustomTabs>
</template>

<script>
import CustomTabs from "../../../components/CustomTabs.vue";

export default {
  components: {
    CustomTabs
  },
  name: "AdminTabs",
  data () {
    return {
      tabs: [
          {
            label: 'Hooks',
            to: { name: 'admin-mangopay-hooks'}
          },
          // {
          //   label: 'Modèle économique',
          //   to: { name: 'admin-economics'}
          // },

      ]
    }
  },
  computed: {
  }
}
</script>

<style scoped>

</style>
