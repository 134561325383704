<template>
  <el-card style="overflow: visible;">
    <div class="product-creation-form-header">
      <h3>Annonces inclues dans le pack</h3>
      <p>Proposer plusieurs annonces au travers de ce pack. Ajouter des annonces pour étoffer votre pack. <br>
      Des articles pourront ensuite être proposé sur la page paniers de vos clients.
      </p>
    </div>

    <ItemSelect :placeholder="'Ajouter votre annonce'" :newItem="false" :types="['annonce']" :ESOptions="{params: {p: 12, types: 'annonce', userIds: Auth.id, isNot: adIds}}" @update:modelValue="updateValue">
      <template  #item="{element}" >
        <a href="" class="clickable-item" v-html="'#'+ element.id + ' ' + element.user.id +' - '+ element.titre"></a>
      </template>
    </ItemSelect>
    <el-table  :data="bundleItems" style="width: 100%;" :table-layout="'auto'" stripe>
      <el-table-column prop="nomEquipement" label="Produit" min-width="400px" align="left">
        <template #default="scope">
          <span v-if="scope.row.annonces">{{scope.row.annonces.titre}}</span>
<!--          <el-input v-model="scope.row.annonces.titre"/>-->
        </template>
      </el-table-column>
      <el-table-column prop="quantity" label="Quantité" min-width="20px">
        <template #default="scope">
          <el-input-number v-model="scope.row.quantity"/>
        </template>
      </el-table-column>
      <el-table-column prop="quantity" label="" width="200" align="right" fixed="right">
        <template #default="scope">
          <el-button class="dropdown-edit-toogle" style="padding: 5px;">
            <span class="icon-kit" @click="goToAdEdit(scope.row.annonceId)">
              &#xe95c
            </span>
          </el-button>
          <el-button class="dropdown-edit-toogle" style="padding: 5px;" @click="removeBundleItem(scope.row)">
            <span class="icon-kit">
              &#xe9f1
            </span>
          </el-button>
  <!--        <el-button v-if="scope.row.isNew" @click="pushItem(scope.row)" >Ajouter</el-button>-->
  <!--        <el-button v-else >Migrez en produit</el-button>-->

        </template>
      </el-table-column>
    </el-table>
  </el-card>
<!--  <BundleItem v-for="item in items" :item="item" ></BundleItem>-->
</template>

<script>
  // import BundleItem from "../components/BundleItem.vue";
  import ItemSelect from "../components/ItemSelect.vue"
  import Draggable from 'vuedraggable'
  import formHandler from "../../../mixins/formHandler.js";
  import Bundle from "../services/Bundle.js";
  import BundleItem from "../services/BundleItem.js";
  import Annonce from "../services/Ads.js";
  export default {
    name: "AdBundleEdit",
    mixins: [formHandler],
    ApiUrl: 'bundle-edit',
    components: {
      Draggable,
      ItemSelect
    },
    props: {
      annonceId: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        items: []
      }
    },
    computed: {
      adIds () {
        return this.bundleItems.map(item => item.annonceId)
      },
      Annonce () {
        return Annonce.query().with('bundle.bundleItems').find(this.annonceId)
      },
      Bundle () {
        if (this.Annonce) {
          return Bundle.query().with('bundleItems.annonces').where('annonceId', this.Annonce.id).get().length ? Bundle.query().with('bundleItems.annonces').where('annonceId', this.Annonce.id).get()[0] : {bundleItems: []}
        }
      },
      Model () {
        return Bundle
      },
      bundleItems () {
        if (this.Bundle) {
          return this.Bundle.bundleItems
        }
        return []
      }
    },
    methods: {
      goToAdEdit (id) {
        console.log('go to ad edit')
        this.$router.push({name: 'ad-edit', params: {id: id}})
      },
      removeBundleItem (item) {
        console.log('Remove Bundle Item')
        console.log(item)
        BundleItem.api().delete(`/bundle-remove-item/${item.id}`, {
          delete: item.id
        })
      },
      preQuery (query) {
        query.with('annonces')
        query.with('bundleItems')
        query.where('annonceId', this.annonceId)
      },
      updateValue (payload) {
        console.log('Add Bundle Item')
        Bundle.api().get(`bundle-add-item/${this.edit.id}/${payload.id}`)
        // this.items.push(payload)
        console.log('Update value')
        console.log(payload)
      },
      addAnnonce () {
      }
    }
  }
</script>

<style scoped>

</style>