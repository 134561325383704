<script>
import CustomTabs from "../../../components/CustomTabs.vue";
import Ads from "~/modules/ad/services/Ads.js";
import LoadContent from "~/mixins/loadContent.js";
import Inventory from "~/modules/ad/services/Inventory.js";

export default {
  components: {
    CustomTabs
  },
  mixins: [LoadContent],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  data () {
    return {
      // tabs: [
      //   {
      //     label: 'Inventaires',
      //     to: { name: 'product-inventory'}
      //   },
      //   {
      //     label: 'Paramètres',
      //     to: { name: 'product-edition'}
      //   },
      //   {
      //     label: 'Historique',
      //     to: { name: 'product-history'}
      //   },
      // ]
    }
  },
  computed: {
    hasLink () {
      return this.$route.name === 'add-stock' || this.$route.params.stockId
    },
    tabTitle () {
      if (this.$route.name === 'add-stock') {
        return this.$t('shop.products.stock.add-stock.title')
      }
      return this.$t('shop.products.title')
    },
    hasTabs () {
      if ((this.$route.name === 'add-product')
          || (this.$route.name === 'add-bundle')
          || (this.$route.name === 'add-stock')
      ) {
        return false
      } else {
        return true
      }
    },
    tabs () {
      let generalTabs = [
        {
          label: 'Paramètres',
          to: { name: this.linkRouteEditionName}
        },
        {
          label: 'Indisponibilités',
          to: { name: 'product-unavailability'}
        },
        {
          label: 'Historique',
          to: { name: 'product-history'}
        }
      ]
      let additionalTabs = []
      if (this.ad && this.ad.stockType === 1) {
        additionalTabs = [
          {
            label: 'Inventaires',
            to: { name: 'product-inventory'}
          }
        ]
      }
      if (this.$route.params.stockId) {
        additionalTabs = []
        generalTabs = [
          {
            label: 'Paramètres',
            to: { name: 'edit-stock', params: {id: this.$route.params.id, stockId: this.$route.params.stockId}}
          },
          {
            label: 'Indisponibilités',
            to: { name: 'stock-unavailability', params: {id: this.$route.params.id, stockId: this.$route.params.stockId}}
          }
        ]
      }
      return [
        ...additionalTabs,
        ...generalTabs
      ]
    },
    Model () {
      return Ads
    },
    id () {
      return this.$route.params.id
    },
    stockId () {
      return this.$route.params.stockId
    },
    fetchUrl () {
      return 'user/annonce-edit/' + this.id
    },
    ad () {
      return Ads.query().with('stocks').where('id', parseInt(this.id)).first()
      // return Ads.find(this.id)//.query().whereId(this.id).first()
    },
    stock () {
      return this.$route.params.stockId ? Inventory.find(this.$route.params.stockId) : null
    },
    title () {
      if (this.ad && this.ad.productType === 1) {
        return this.ad && this.ad.titre ? this.ad.titre : ''
      }
      return this.ad ? this.ad.title : ''
    },
    stockTitle () {
      if (this.stock) {
        return this.stock.name
      } else {
        if (this.$route.name === 'add-stock' && this.ad) {
          return this.ad.sku + (this.ad.stocks.length + 1)
        }
        return ''
      }
    },
    productType () {
      if (!this.ad) return ''
      return this.ad && this.ad.productType === 1 ? 'Packs' : 'Produits'
    },
    iconType () {
      if (this.productType === 'Packs') {
        return '&#xe9ac'
      } else if (this.productType === 'Produits') {
        return '&#xe928'
      }
      return ''
    },
    linkRouteName () {
      if (this.ad && this.ad.productType === 1) {
        return 'pack'
      } else {
        return 'annonces'
      }
    },
    linkRouteEditionName () {
      if (this.ad && this.ad.productType === 1) {
        return 'bundle-edition'
      } else {
        return 'product-edition'
      }
    },
    allTabs () {
      let routesTab = []
      if (this.$route.name === 'ad-edit') {
        routesTab = [
          {
            label: 'Modifier',
            to: { name: 'ad-edit', params: {id: this.$route.params.id}}
          }
        ]
      }
      return [
        ...routesTab,
        ...this.tabs
      ]
    }
  },
  name: "ProductTabs"
}
</script>

<template>
  <div class="boutique-header">
    <h3 class="parameters-title">{{tabTitle}}</h3>
    <p class="breadcrumb-product">
      <router-link :to="{ name: linkRouteName}" class="c-black breadcrumb-item first">
        <span v-html="iconType" class="icomoon"></span>
      </router-link>
      <span v-if="!hasLink">
        <span class="icomoon breadcrumb-icon">&#xe930</span><span class="breadcrumb-item">{{title}}</span>
      </span>
      <span v-else >
        <span class="icomoon breadcrumb-icon">&#xe930</span>
        <router-link :to="{ name: 'product-inventory', params: {id: $route.params.id }}" class="breadcrumb-item">
          {{ title }}
        </router-link>
        <span class="icomoon breadcrumb-icon">&#xe930</span><span class="breadcrumb-item">{{ stockTitle }}</span>
      </span>
    </p>
  </div>
  <CustomTabs v-if="hasTabs" :tabs="allTabs"></CustomTabs>
</template>

<style scoped>

</style>