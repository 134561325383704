<script>
import Ads from "~/modules/ad/services/Ads.js";
import LoadContent from "~/mixins/loadContent.js";
import AnnonceCard from "~/components/AnnonceCard.vue";
import Cart from "~/modules/cart/services/Cart.js";

export default {
  name: "AdSuggestionCart",
  emits: ['add-cart-item'],
  components: {AnnonceCard},
  ApiUrl: 'user/panier-edit',
  mixins: [LoadContent],
  data () {
    return {
      loadingAddItem: {},
      loadingMore: false
    }
  },
  props: {
    cart: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    id: {
      immediate: true,
      async handler (val, oldVal) {
        if (val && val !== oldVal) {
          await this.beforeDataFetch()
        }
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    currentPage () {
      return this.response && this.response.metadatas && this.response.metadatas.page ? this.response.metadatas.page : 0
    },
    additionalParams () {
      let add = {
        pp: this.currentPage + 1 > 1 ? 8 : 8
      }
      if (this.currentPage + 1 > 1) {
        add.p = this.currentPage + 1
      }
      return add
    },
    lazyLoading () {
      return false
    },
    offset () {
      return 0
    },
    perPage () {
      return this.currentPage * 8
    },
    cartAdsIds () {
      return this.cart && this.cart.cartItems && Array.isArray(this.cart.cartItems) ? this.cart.cartItems.map((item) => {
        return item.annonces.id
      }) : []
    },
    Model () {
      return Ads
    },
    id () {
      return this.cart && this.cart.id ? this.cart.id : this.$router && this.$router.params && this.$router.params.id ? this.$router.params.id : false
    },
    fetchUrl () {
      let url = '/user/panier-annonces'
      if (this.id) {
        url += '/' + this.id
      }
      return url
    },
  },
  methods: {
    handleScroll() {
      let container = this.$refs.listContainer;
      if (container) {
        let containerBottomPosition = container.getBoundingClientRect().bottom;
        let windowBottomPosition = window.innerHeight;

        if (containerBottomPosition <= windowBottomPosition) {
          this.loadMore();
        }

      }
    },
    async loadMore () {
      if (!this.loadingMore) {
        this.loadingMore = true
        await this.fetchData()
        this.loadingMore = false
      }
      console.log('loadmore')
    },
    preQuery (query) {
      query.with('cover')
      query.where('publie', true)
      query.orderBy('position', 'asc')
      query.where((ad) => {
        return ad.id && !this.cartAdsIds.includes(ad.id)
      })
    },
    async onButtonClick (ad) {
      if (!this.loadingAddItem[ad.id]) {
        this.loadingAddItem[ad.id] = true
        try {
          let response = await Cart.api().get('user/panier-ajouter/' + this.id + '/' + ad.id)
          this.$emit('add-cart-item', response)
        } catch (e) {
          console.log(e)
        }
        this.loadingAddItem[ad.id] = false
      }
    }
  }
}
</script>

<template>
  <div class="annonces-section container" v-if="cart && cart.proprietaire_annonce" ref="listContainer">
    <h3>{{$t('valid-cart.ads-section.title-vue', {user: cart.proprietaire_annonce.fullName})}}</h3>
    <h6>{{$t('valid-cart.ads-section.subtitle')}}</h6>
    <div class="cart-ad-card-grid">
      <AnnonceCard v-for="ad in modelList" :ad="ad" :key="ad.id" :button="'Ajouter'" :loadingButton="this.loadingAddItem && this.loadingAddItem[ad.id]" @button-click="onButtonClick"></AnnonceCard>
    </div>
    <div v-if="loadingMore" class="cv-spinner" style="padding: 20px">
      <span class="spinner"></span>
    </div>
  </div>

</template>

<style scoped>

</style>