<template>
  <el-alert v-if="generalError" :title="generalError.title" :description="generalError.description" type="error" show-icon style="margin-bottom: 12px;" :closable="false"/>
  <el-alert v-if="error" :title="error.title" :description="error.description" type="error" show-icon style="margin-bottom: 12px;" :closable="false"/>
  <h2 class="signup-form-title">{{$t('signup.siret.title')}}</h2>
  <div class="form-group">
    <p class="signup-form-description">{{$t('signup.siret.description')}}</p>
  </div>
  <el-form-item :label="$t('signup.siret.label')">
    <div class="inline-input-btn">
      <el-input v-model="edit.siret" :placeholder="$t('signup.siret.label')" class="full-width-input" required />
      <el-button :loading="verifySiretLoading" @click="verifySiret" class="" type="info" >{{$t('signup.siret.button')}}</el-button>
    </div>
  </el-form-item>
  <div v-if="siret && siret.nom_entreprise && siret.siege && siret.siege.siret_formate && adresseSiege">
    <div class="siret-ticket" @click="continu">
      <p>
        <span class="company">{{siret.nom_entreprise}}</span>
        <span class="signup-grey"> · {{siret.siege.siret_formate}}<br>
        <OfficeBuilding class="text-icon" /> {{adresseSiege}}</span>
      </p>
      <ArrowRightBold class="arrow-icon"/>
    </div>
    <el-button class="manually-btn" plain type="info" @click="continuManualy">{{$t('signup.siret.manually')}}</el-button>
  </div>
  <div v-else-if="siret !== false" class="siret-not-found">
    <el-button class="manually-btn" plain type="info" @click="continuManualy">{{$t('signup.siret.manually')}}</el-button>
  </div>
  <div v-if="siret === false" class="siret-not-found">
    <img class="icon-image" :src="asset('img/icons/searchstatus.png')" alt="">
    <h3 class="title">{{$t('signup.siret.not-found-title')}}</h3>
    <p class="siret-description">{{$t('signup.siret.not-found-description')}}</p>
    <el-button class="manually-btn" plain type="info" @click="continuManualy">{{$t('signup.siret.manually')}}</el-button>
  </div>
</template>
<script>

import {loadPageContent} from "~/services/parameters.js";
import Signup from "../services/Signup.js";
import SignupHandler from "../mixins/signupHandler.js";

export default {
  name: "RegisterSiret",
  mixins: [SignupHandler],
  data () {
    return {
      numberOfSiretRequest: 0,
      siret: null,
      edit: {
        siret: '',
        company: {
          name: "",
          siret: "",
          taxId: "",
          addressName: "",
          address: {}
        },
        content: {
          company: {
            siret: ""
          },
          addressName: "",
          address: {}
        }
      }
    }
  },
  created() {
    // let user = JSON.parse(JSON.stringify(this.signupUser))
    // if (user.telephone) {
    //   this.edit.adr = user.telephone.telephone
    // }
  },
  computed: {
    adresseSiege () {
      return this.siret && this.siret.siege ? (
          this.siret.siege.adresse_ligne_1 +
          ", " +
          this.siret.siege.code_postal +
          " " +
          this.siret.siege.ville
      ) : ''
    },
    verifySiretLoading () {
      return this.numberOfSiretRequest > 0
    }
  },
  methods: {
    async continuManualy () {
      await Signup.insertOrUpdate({
        data: {
          id: this.signupUser.id,
          infoSociete: {
            manually: true,
          }
        }
      })
      let index = this.steps.indexOf(this.$route.name)
      let nextStepIndex = index + 1
      if (nextStepIndex < this.steps.length) {
        this.$router.push({ name: this.steps[nextStepIndex] })
      }
    },
    async beforeContinu () {
      // console.log('before continu register siret')
      // console.log(this.siret)
      if (this.siret) {
        let adresse = this.siret.siege && this.siret.siege.adresse_ligne_1 ? this.siret.siege.adresse_ligne_1 : ''
        let rue = this.siret.siege && this.siret.siege.type_voie && this.siret.siege.libelle_voie ? this.siret.siege.type_voie + ' ' + this.siret.siege.libelle_voie : ''
        let numero = this.siret.siege && this.siret.siege.numero_voie ? this.siret.siege.numero_voie : ''
        let codePostal = this.siret.siege && this.siret.siege.code_postal ? this.siret.siege.code_postal : ''
        let ville = this.siret.siege && this.siret.siege.ville ? this.siret.siege.ville : ''
        let raisonSociale = this.siret.nom_entreprise ? this.siret.nom_entreprise : ''
        let formeJuridique = this.siret.forme_juridique ? this.siret.forme_juridique : ''
        let numeroRcs = this.siret.numero_rcs ? this.siret.numero_rcs : ''
        let capital = this.siret.capital ? this.siret.capital : ''
        let siret = this.siret.siege && this.siret.siege.siret ? this.siret.siege.siret : ''
        let pays = this.siret.siege && this.siret.siege.pays ? this.siret.siege.pays : ''
        let vatNumber = this.siret.numero_tva_intracommunautaire ? this.siret.numero_tva_intracommunautaire : ''
        let latitude = this.siret.siege && this.siret.siege.latitude ? this.siret.siege.latitude : ''
        let longitude = this.siret.siege && this.siret.siege.longitude ? this.siret.siege.longitude : ''
        let location = this.siret.siege && this.siret.siege.longitude && this.siret.siege.latitude ? this.siret.siege.latitude + ',' + this.siret.siege.longitude : ''
        await Signup.insertOrUpdate({
          data: {
            id: this.signupUser.id,
            adresseSiege: {
              adresse: adresse,
              rue: rue,
              numero: numero,
              codePostal: codePostal,
              ville: ville,
              lat: latitude,
              lng: longitude,
              location: location,
              pays: pays
            },
            infoSociete: {
              manually: false,
              raisonSociale: raisonSociale,
              formeJuridique: formeJuridique,
              numeroRcs: numeroRcs,
              capital: capital,
              siret: siret,
              vatNumber: vatNumber,
              address: null
            }
          }
        })
      } else {
        await Signup.insertOrUpdate({
          data: {
            id: this.signupUser.id,
            infoSociete: {
              manually: true,
            }
          }
        })
      }
      return true
    },
    verifySiret () {
      console.log("fetchData");
      this.numberOfSiretRequest++
      loadPageContent("/api/verify-siret/" + this.edit.siret)
          .then(response => {
            this.siret = response.data.siret;
            let vat = response.data.vat;
            if (this.siret) {
              this.edit.company.name = this.siret.nom_entreprise ? this.siret.nom_entreprise : ''
              this.edit.company.taxId = this.siret.numero_tva_intracommunautaire ? this.siret.numero_tva_intracommunautaire : ''
              let adresse = this.siret.siege && this.siret.adresse_ligne_1 ? this.siret.adresse_ligne_1 : ''
              let codePostal = this.siret.siege && this.siret.code_postal ? this.siret.code_postal : ''
              let ville = this.siret.siege && this.siret.ville ? this.siret.ville : ''
              this.edit.company.addressName = (
                  adresse +
                  " " +
                  codePostal +
                  ", " +
                  ville
              )
            } else if (vat && vat.valid) {
              this.edit.company.name = vat.name + ''
              this.edit.company.addressName = vat.address + ''
            }
            // console.log(this.content)
            // this.content = response
          })
          .catch(e => {
            console.error(e);
          })
          .finally(() => {
            this.numberOfSiretRequest--
          })
      ;
    },
  }
}
</script>

<style scoped>

</style>