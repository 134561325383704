<template>
  <div class="custom-filter-table">
    <el-radio-group v-if="radioFiltersKeys.length" v-model="mode" size="large">
      <el-radio-button label="tous">Tous</el-radio-button>
      <el-radio-button v-for="(filter, key) in radioFilters" :label="key">{{ filter }}</el-radio-button>
    </el-radio-group>
    <el-button v-if="newButton" size="large" icon="plus" class="float-right" style="margin-left:12px" @click="handleNewClick">
      {{ newButton }}</el-button>
    <div v-if="hasSearch" class="float-right large" >
      <el-input
          v-model="search"
          size="large"
          :placeholder="placeholderSearch"
          prefix-icon="Search"
      >
      </el-input>
  <!--      <el-button size="large">-->
  <!--        <template #icon>-->
  <!--          <Filter />-->
  <!--        </template>-->
  <!--        Filtres-->
  <!--      </el-button>-->
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
import { Search } from '@element-plus/icons-vue'

export default {
  components: {
    Search
  },
  name: "CustomFilterTable",
  props: {
    placeholderSearch: {
      type: String,
      default: 'Rechercher un document'
    },
    newButton: {
      type: [Boolean, String],
      default: false
    },
    hasSearch: {
      type: Boolean,
      default: true
    },
    radioFilters: {
      type: Object,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      search: ''
    }
  },
  watch: {
    search: {
      handler () {
        this.addQuery({
          q: this.search + ''
        })
      }
    }
  },
  computed: {
    radioFiltersKeys () {
      return Object.keys(this.radioFilters)
    },
    // search: {
      // set (val) {
      //   this.addQuery({
      //     q: val
      //   })
      // },
      // get () {
      //   return this.$route.query.q ? this.$route.query.q : ''
      // }
    // },
    mode: {
      set (val) {
        if (val === 'tous') {
          val = false
        }
        this.addQuery({f: val})
      },
      get () {
        return this.$route.query.f ? this.$route.query.f : 'tous'
      }
    }
  },
  methods: {
    handleNewClick () {
      this.$emit('newClick')
    },
    addQuery (q) {
      const query = {
        ...this.$route.query,
        ...q
      }
      if (q.q === '') {
        delete query.q
      }
      if (q.s === false) {
        delete query.s
      }
      if (q.f === false) {
        delete query.f
      }
      this.$router.push({
        query
      })
    }
  }
}
</script>

<style scoped>

</style>