<template>
  <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 checkout-cart-left">
    <div v-if="isTenant && stepVirement" class="alert alert-info"
         style="font-size: 14px;line-height: 19px;font-weight: 700;" role="alert">
      <i class="fas fa-exclamation-circle"></i> {{ $t('infos.bankWire.alert') }}
    </div>
    <LocationInfosUpdate v-if="isTenant && stepOneIsLocationUpdating && !stepCanceled" :DL="DL" :auth="auth"/>
    <LocationInfosDates :auth="auth" :DL="DL"/>
    <LocationInfosAvis v-if="termined === true && !avisDonne.length && !stepChoicePenaltyOrRefund && !stepCanceled" :auth="auth" :DL="DL"/>

    <div class="mobile-only">
      <LocationBox :auth="auth" :DL="DL" :screen="screen"/>
    </div>

    <div v-if="isOwner">
      <LocationInfosPenalty v-if="stepChoicePenaltyOrRefund" :auth="auth" :DL="DL"/>
      <LocationInfosAction @showModalCancel="showModalCancel" @update="updateAction" @showModalEquipment="showModalEquipment" @overlay="overlay" @dlUpdated="updatedPrices" :auth="auth" :DL="DL" :termined="termined" :showCancelBtn="showCancelBtn"/>
      <div v-if="stepOne">
        <LocationInfosProjet v-if="DL.id > minIdFacturation" :auth="auth" :DL="DL" :termined="termined"/>
        <br>
        <LocationInfosMaterial @showModalCancel="showModalCancel" @showModalEquipment="showModalEquipment"
                               @overlay="overlay" @dlUpdated="updatedPrices" :auth="auth" :DL="DL"
                               :termined="termined"/>
        <div class="mobile-only">
          <LocationBoxOptions :DL="DL" :auth="auth" :termined="termined"/>
          <br>
          <LocationBoxButtons @showModalCancel="showModalCancel" @go-rental-payment="goRentalPayment" :DL="DL" :auth="auth" :termined="termined"/>
        </div>
      </div>
      <div v-if="!stepOne">
        <div class="mobile-only">
          <LocationInfosProjet :auth="auth" :DL="DL" :termined="termined"/>
          <br>
          <LocationInfosMaterial @showModalCancel="showModalCancel" @showModalEquipment="showModalEquipment"
                                 @overlay="overlay" @dlUpdated="updatedPrices" :auth="auth" :DL="DL"
                                 :termined="termined"/>
          <br>
          <LocationBoxOptions :DL="DL" :auth="auth" :termined="termined"/>
          <br>
          <LocationBoxButtons @showModalCancel="showModalCancel" @go-rental-payment="goRentalPayment" :DL="DL" :auth="auth" :termined="termined"/>
        </div>
        <div class="desktop-only">
          <LocationInfosMaterial @showModalCancel="showModalCancel" @showModalEquipment="showModalEquipment"
                                 @overlay="overlay" @dlUpdated="updatedPrices" :auth="auth" :DL="DL"
                                 :termined="termined"/>
          <br>
          <LocationInfosProjet :auth="auth" :DL="DL" :termined="termined"/>
        </div>
      </div>
    </div>
    <div v-if="isTenant">
      <div v-if="changeBillingAddress" class="contrat-de-location">
        <ChangeRecipientAccount :showSaveButton="true" :rental-id="DL.id" @addressChanged="addressChanged"/>
      </div>
      <LocationInfosAction
          @showModalCancel="showModalCancel"
          @showModalEquipment="showModalEquipment"
          @overlay="overlay"
          @dlUpdated="updatedPrices"
          @toggleChangeBillingAddress="toggleChangeBillingAddress"
          v-if="!stepTermined && !stepCanceled"
          :changeBillingAddress="changeBillingAddress"
          :auth="auth"
          :DL="DL"
          :showCancelBtn="showCancelBtn"
      />
      <LocationInfosAddress v-if="stepThree" :DL="DL" :proprietaire="proprietaire"/>
      <LocationInfosMaterial @showModalCancel="showModalCancel" @showModalEquipment="showModalEquipment"
                             @overlay="overlay" @dlUpdated="updatedPrices" :auth="auth" :DL="DL"
                             :termined="termined"/>
      <LocationInfosProjet :auth="auth" :DL="DL" :termined="termined"/>
      <LocationInfosCancel v-if="!termined && !stepCanceled" :DL="DL"/>
      <div class="mobile-only">
        <LocationBoxOptions :DL="DL" :auth="auth" :termined="termined"/>
        <br>
        <LocationBoxButtons @showModalCancel="showModalCancel" @go-rental-payment="goRentalPayment" :DL="DL" :auth="auth" :termined="termined"/>
      </div>
    </div>
  </div>
</template>

<script>
import ChangeRecipientAccount from "~/modules/billing/components/ChangeRecipientAccount.vue";
import LocationInfosDates from "./LocationInfosDates.vue";
import LocationInfosAvis from "./LocationInfosAvis.vue";
import LocationBox from "./LocationBox.vue";
import LocationInfosPenalty from "./LocationInfosPenalty.vue";
import LocationInfosAction from "./LocationInfosAction.vue";
import LocationInfosProjet from "./LocationInfosProjet.vue";
import LocationInfosMaterial from "./LocationInfosMaterial.vue";
import LocationInfosAddress from "./LocationInfosAddress.vue";
import LocationInfosCancel from "./LocationInfosCancel.vue";
import LocationBoxOptions from "./LocationBoxOptions.vue";
import LocationBoxButtons from "./LocationBoxButtons.vue";
import LocationInfosUpdate from "./LocationInfosUpdate.vue";

export default {
  name: 'LocationInfos',
  emits: ["dlUpdated", "showModalCancel", "showModalEquipment", "overlay", "update", "goRentalPayment"],
  data () {
    return {
      changeBillingAddress: false
    }
  },
  components: {
    ChangeRecipientAccount,
    LocationInfosDates,
    LocationInfosAvis,
    LocationBox,
    LocationInfosPenalty,
    LocationInfosAction,
    LocationInfosProjet,
    LocationInfosMaterial,
    LocationInfosAddress,
    LocationInfosCancel,
    LocationBoxOptions,
    LocationBoxButtons,
    LocationInfosUpdate
  },
  props: {
    auth: {
      type: Object
    },
    DL: {
      type: Object
    },
    termined: {
      type: Boolean
    },
    screen: {
      type: String
    },
    showCancelBtn: {
      type: Boolean
    }
  },
  computed: {
    locataire() {
      return this.DL.locataire
    },
    proprietaire() {
      return this.DL.proprietaire
    },
    isTenant() {
      return this.auth.id == this.locataire.id ? true : false
    },
    isOwner() {
      return this.auth.id == this.proprietaire.id ? true : false
    },
    stepOne() {
      return this.DL.AcceptedAt === null && this.DL.PaidAt === null && this.termined === false ? true : false
    },
    startDateLimit() {
      return this.$moment(this.DL.dateDebut).format('dddd d MMMM')
    },
    stepTwo() {
      return this.DL.AcceptedAt !== null && this.DL.PaidAt === null && this.termined == false ? true : false
    },
    stepThree() {
      return this.DL.AcceptedAt !== null && this.DL.PaidAt !== null && this.termined == false && this.DL.CanceledAt === null ? true : false
    },
    stepOneIsLocationUpdating() {
      return this.DL.updateStatusLocation == 1 ? true : false
    },
    stepTermined() {
      return this.DL.AcceptedAt !== null && this.DL.PaidAt !== null && this.termined == true ? true : false
    },
    stepCanceled() {
      return this.DL.CanceledAt !== null ? true : false
    },
    stepChoicePenaltyOrRefund() {
      return this.DL && this.DL.remboursement && this.DL.remboursement.montantRemboursementProprietaire !== 0 && this.DL.remboursement.choixProprietaire == 'WAITING' ? true : false
    },
    stepChoiceAction() {
      return this.isOwner && this.DL.AcceptedAt !== null && termined == false && this.DL.CanceledAt !== null || this.isTenant && termined == false && this.DL.CanceledAt === null
    },
    avisDonne() {
      if (this.$symfony.avisDonne) {
        return this.$symfony.avisDonne
      } else {
        return this.DL.avisDone
      }
    },
    minIdFacturation() {
      return this.$symfony.globals.min_id_for_facturation
    },
    stepVirement() {
      return this.DL.bank_wire && this.DL.bank_wire.createdAt && this.DL.bank_wire.createdAt !== null && this.DL.PaidAt === null ? true : false
    }
  },
  methods: {
    addressChanged () {
      this.changeBillingAddress = false
    },
    toggleChangeBillingAddress () {
      console.log('changeBillingAddress')
      this.changeBillingAddress = !this.changeBillingAddress
    },
    goRentalPayment ($event) {
      this.$emit('goRentalPayment', $event);
    },
    updateAction(updateAction) {
      this.$emit('update', {
        type: 'updateAction',
        value: updateAction
      })
    },
    updatedPrices(val) {
      console.log('updatedPrices')
      this.$emit('dlUpdated', val);
    },
    showModalCancel(val) {
      this.$emit('showModalCancel', val);
    },
    showModalEquipment(val) {
      this.$emit('showModalEquipment', val);
    },
    overlay(val) {
      this.$emit('overlay', val);
    }
  }
}
</script>
