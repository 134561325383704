import { Model } from "@vuex-orm/core"
import Ads from "./Ads.js";
import Bundle from "./Bundle.js";

export default class BundleItem extends Model {
  static entity = 'bundleItem'

  static apiConfig = {
    headers: {'X-Requested-With': 'XMLHttpRequest'},
    baseURL: '/api',
    dataKey: 'bundleItem',
    actions: {
      fetch: {
        method: 'get',
        url: 'bundle-item'
      }
    }
  }
  // }
  static fields () {
    return {
      id: this.attr(null),
      bundleId: this.attr(null),
      quantity: this.attr(0),
      position: this.attr(0),
      // bundle: this.hasOne(Bundle, 'bundleId'),
      annonceId: this.attr(null),
      annonces: this.belongsTo(Ads, 'annonceId', 'id'),
      adTitle: this.attr(null),
      adCover: this.attr(null),
    }
  }
}
