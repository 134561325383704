<script>
import AdTicket from "~/modules/ad/components/AdTicket.vue";
import AdUnavailability from "~/modules/ad/services/AdUnavalability.js";
import Ads from "~/modules/ad/services/Ads.js";

export default {
  name: "UnavailabilityTable",
  components: {AdTicket},
  props: {
    defaultOrder: {
      type: Object,
      default () { return {}}
    },
    unavailability: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    ad () {
      return Ads.find(this.id)
    },
  },
  methods: {
    handleCurrentChange (val) {
      // console.log('handleCurrentChange')
      // console.log(val)
      // console.log('handleCurrentChange')
      // console.log(val)
      if (val && val.bundleAdUnavailableRangeId) {
        let adUnavailability = AdUnavailability.find(val.bundleAdUnavailableRangeId)
        console.log(adUnavailability);
        this.$router.push({
          name: 'product-unavailability',
          params: {
            id: adUnavailability.ad_id
          }
        })
      } else {
        this.selectedRow = val
        this.$emit('handleCurrentChange', val)
      }
    },
    console(data) {
      console.log(data)
    },
    removeUnavailability (row) {
      this.$emit('remove', row.id)
    },
    onCellClick (row, column) {
      // if (column.property !== 'action') {
      //   let routeName = 'bundle-edition'
      //   this.$router.push({
      //     name: routeName,
      //     params: {
      //       id: row.id
      //     }
      //   })
      // }
    },
    formatDate (date) {
      return new Date(date).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    }
  }
}
</script>

<template>
  <el-table @cell-click="onCellClick" class="pointer-row" :data="unavailability" style="width: 100%;" :table-layout="'auto'" stripe :default-sort="defaultOrder" @current-change="handleCurrentChange" highlight-current-row>
    <template #empty>
      <slot name="empty"></slot>
    </template>
    <el-table-column type="expand" v-if="ad && ad.stockType === 1">
      <template #default="{row}">
        <div m="4">
          <ul>
            <li v-for="stock in row.stockUnavailableRanges">{{ stock.stock.name }}</li>
          </ul>
        </div>
      </template>
    </el-table-column>
    <!--    <el-table-column fixed prop="id" label="#" width="60" />-->
<!--    <el-table-column prop="titre" :label="$t('owner-listing.tab-head.title')" sortable="custom" min-width="100">-->
<!--      <template #default="{row}">-->
<!--        <AdTicket :Ad="row"/>-->
<!--      </template>-->
<!--    </el-table-column>-->
    <el-table-column label="Début">
      <template #default="{row}">
        <span v-if="row.unavailableRange">{{formatDate(row.unavailableRange
            .startAt)}}</span>
      </template>
    </el-table-column>
    <el-table-column label="Fin">
      <template #default="{row}">
        <span v-if="row.unavailableRange">{{formatDate(row.unavailableRange
            .endAt)}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="quantity" label="Quantité" sortable="custom" min-width="200" >
    </el-table-column>
    <el-table-column fixed="right" width="50px">
      <template #default="scope">
          <button v-if="scope.row && !scope.row.bundleAdUnavailableRangeId" class="dropdown-edit-toogle" style="padding: 5px;" @click="removeUnavailability(scope.row)">
            <span class="icon-kit">
              &#xe9f1
            </span>
          </button>
<!--        <div class="display-flex">-->
<!--          <el-dropdown trigger="click">-->
<!--            <div class="dropdown">-->
<!--              <button class="dropdown-edit-toogle" style="padding: 5px;">-->
<!--                <span class="icon-kit">-->
<!--                  &#xe9a5-->
<!--                </span>-->
<!--              </button>-->
<!--            </div>-->
<!--            <template #dropdown>-->
<!--              <el-dropdown-menu>-->
<!--                <el-dropdown-item>-->
<!--                  <a class="dropdown-item" :href="path('lighty_share_user_detail_annonce', { id: scope.row.id})">-->
<!--                    {{ $t('owner-listing.dropdown.show') }}-->
<!--                  </a>-->
<!--                </el-dropdown-item>-->
<!--                <el-dropdown-item>-->
<!--                  <router-link class="dropdown-item" :to="{ name: 'bundle-edit', params: { id: scope.row.id }}">-->
<!--                    {{ $t('owner-listing.dropdown.update') }}-->
<!--                  </router-link>-->
<!--                </el-dropdown-item>-->
<!--                <el-dropdown-item>-->
<!--                  <a class="dropdown-item" :href="path('lighty_share_user_listing_duplicate', { id: scope.row.id})">-->
<!--                    {{ $t('owner-listing.dropdown.duplicate') }}-->
<!--                  </a>-->
<!--                </el-dropdown-item>-->
<!--                <el-dropdown-item>-->
<!--                  <a class="dropdown-item" :href="'/user/telechargement-facture-mensuelle/' + scope.row.id">-->
<!--                    {{ $t('owner-listing.dropdown.spotlight') }}-->
<!--                  </a>-->
<!--                </el-dropdown-item>-->
<!--                <el-dropdown-item>-->
<!--                  <a class="dropdown-item" href="#">-->
<!--                    {{ $t('owner-listing.dropdown.delete') }}-->
<!--                  </a>-->
<!--                </el-dropdown-item>-->
<!--              </el-dropdown-menu>-->
<!--            </template>-->
<!--          </el-dropdown>-->

<!--        </div>-->
      </template>
    </el-table-column>
  </el-table>
</template>

<style scoped>

</style>