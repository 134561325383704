<script>
import MenuBarItem from "~/modules/ad/components/MenuBarItem.vue";

export default {
  components: {
    MenuBarItem
  },
  name: "DescriptionMenuBar",
  props: {
    editor: {
      type: Object,
      default () {
        return null
      }
    }
  },
  computed: {
    menuItems () {
      return [
        {
          name: 'bold',
          icon: '&#xe921',
          class: 'icomoon',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
        },
        {
          name: 'italic',
          icon: '&#xe985',
          class: 'icomoon',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
        },
        {
          name: 'link',
          icon: '&#xe98b',
          class: 'icomoon',
          title: 'link',
          action: () => {
            const previousUrl = this.editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
              return
            }

            // empty
            if (url === '') {
              this.editor
                  .chain()
                  .focus()
                  .extendMarkRange('link')
                  .unsetLink()
                  .run()
              return
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run()
          },
          isActive: () => this.editor.isActive('link'),
        },
        {
          name: 'list',
          icon: '&#xe98d',
          class: 'icomoon',
          title: 'List',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
        },
      ]
    }
  }
}
</script>

<template>
  <div class="wysiwyg-tools">
    <template v-for="(item, index) in menuItems">
      <div class="divider" v-if="item.type === 'divider'" :key="`divider${index}`" />
      <MenuBarItem v-else :key="index" v-bind="item" />
    </template>
  </div>
</template>

<style scoped>

</style>