import {
  transformDataToFormContent,
  loadPageContent,
  sendFormContent,
  replaceSubObjectWithId
} from "../services/parameters";
import loadContent from './loadContent.js'
import { ElNotification } from 'element-plus'
function getNonMatchingKeys(x, y) {
  'use strict';

  let nonMatchingKeys = [];

  // Function to compare values
  function compareValues(a, b) {
    if (a instanceof Date && b instanceof Date) {
      return a.getTime() === b.getTime();
    } else if (a instanceof Function && b instanceof Function) {
      return a === b;
    } else if (a instanceof RegExp && b instanceof RegExp) {
      return a.toString() === b.toString();
    } else if (Array.isArray(a) && Array.isArray(b)) {
      if (a.length !== b.length) return false;
      for (let i = 0; i < a.length; i++) {
        if (!compareValues(a[i], b[i])) return false;
      }
      return true;
    } else if (a instanceof Object && b instanceof Object) {
      let keysA = Object.keys(a);
      let keysB = Object.keys(b);
      if (keysA.length !== keysB.length) return false;
      for (let key of keysA) {
        if (!compareValues(a[key], b[key])) return false;
      }
      return true;
    } else {
      return a === b;
    }
  }

  // Check for non-matching keys
  let keysX = Object.keys(x);
  let keysY = Object.keys(y);

  for (let key of keysX) {
    if (!compareValues(x[key], y[key])) {
      nonMatchingKeys.push(key);
    }
  }

  for (let key of keysY) {
    if (!compareValues(x[key], y[key]) && keysX.indexOf(key) === -1) {
      nonMatchingKeys.push(key);
    }
  }

  return nonMatchingKeys;
}
function objectEquals(x, y, ignoreKeys) {
  'use strict';

  if (x === null || x === undefined || y === null || y === undefined) { return x === y; }
  if (x.constructor !== y.constructor) { return false; }
  if (x instanceof Function) { return x === y; }
  if (x instanceof RegExp) { return x === y; }
  if (x === y || x.valueOf() === y.valueOf()) { return true; }
  if (Array.isArray(x) && x.length !== y.length) { return false; }
  if (x instanceof Date) { return false; }
  if (!(x instanceof Object)) { return false; }
  if (!(y instanceof Object)) { return false; }

  let p = Object.keys(x);
  let q = Object.keys(y);

  // Remove ignored keys
  p = p.filter(key => !ignoreKeys.includes(key));
  q = q.filter(key => !ignoreKeys.includes(key));

  return q.every(function (i) { return p.indexOf(i) !== -1; }) &&
    p.every(function (i) { return objectEquals(x[i], y[i], ignoreKeys); });
}

export default {
  mixins: [loadContent],
  props: {
    newEntity: {
      type: Boolean,
      default: false
    },
    entityId: {
      type: [Boolean, Number],
      default: false
    },
    prefillData: {
      type: Function,
      default: (edit) => {
        return edit
      }
    }
  },
  data () {
    return {
      formLoading: false,
      contentLoaded: false,
      isLoading: false,
      formErrors: [],
      edit: {
      },
      content: {
      }
    }
  },
  watch: {
    // edit: {
    //   immediate: true,
    // },
    content: {
      immediate: true,
      deep:true,
      handler (val) {
        // this.edit = val
        // console.log()
        if (val && !this.edit) {
          this.edit = this.prefillData(JSON.parse(JSON.stringify(val)))
        }
        if (val && this.edit) {
          let data = {
            ...this.edit,
            ...JSON.parse(JSON.stringify(val))
          }
          this.edit = this.prefillData(data, val)
        }
      }
    },
    // $route: {
    //   immediate: true,
    //   handler (val) {
    //     // if ()
    //     this.loadPage().catch(this.handleError)
    //   }
    // }
  },
  computed: {
    allowNull () {
      return false
    },
    formSave () {
      return true
    },
    formSaveId () {
      return this.id
    },
    save () {
      return true
    },
    nonMatchingKeys () {
      return getNonMatchingKeys(this.content, this.edit)
    },
    idInUrl () {
      return true
    },
    displayedErrorNotification () {
      return true
    },
    apiUrl () {
      return this.$options.ApiUrl
    },
    isNew () {
      return this.newEntity ? this.newEntity : !this.id
    },
    formKey () {
      return 'Annonce'
    },
		id () {
			return this.newEntity ? false : this.entityId ? this.entityId : this.$route.params.id ? this.$route.params.id : false;
		},
    HeaderErrors () {
      return Object.keys(this.formErrors).filter((errorKey) => {
        return !Array.isArray(this.formErrors[errorKey])
      }).map((errorKey) => {
        return this.formErrors[errorKey]
      })
    },
    FieldsErrors () {
      const fieldsErrors = {}
      const that = this
      Object.keys(this.edit).forEach((v) => {
        if (Object.keys(this.formErrors).includes(v)) {
          fieldsErrors[v] = this.formErrors[v].join(',')
        } else if (this.edit && this.edit[v] && typeof this.edit[v] === 'object') {
          fieldsErrors[v] = that.objectFieldsErrors(that.edit[v])
        } else {
          fieldsErrors[v] = ''
        }
      })
      Object.keys(this.formErrors).filter((errorKey) => {
        return Object.keys(this.edit).includes(errorKey)
      })
      return fieldsErrors
    },
    ModelEntity () {
			const id = this.id
			if (id) {
      	return this.fullQuery.find(id)
			} else {
				return new this.Model()
			}
    },
    buttonClass () {
      return this.hasUpdate ? '' : 'disabled'
    },
    updatedFields () {
      return Object.keys(this.edit).filter((key) => {
        return objectEquals(this.edit[key], this.content[key])
      })
    },
    ignoreKeysUpdate () {
      return []
    },
    hasUpdate () {
      return !objectEquals(this.content, this.edit, this.ignoreKeysUpdate)
    },
  },
  methods: {
    objectFieldsErrors (edit) {
      const fieldsErrors = {}
      const that = this
      Object.keys(edit).forEach((v) => {
        if (Object.keys(this.formErrors).includes(v)) {
          fieldsErrors[v] = that.formErrors[v].join(',')
        } else if (edit && edit[v] && typeof edit[v] === 'object') {
          fieldsErrors[v] = that.objectFieldsErrors(edit[v])
        } else {
          fieldsErrors[v] = ''
        }
      })
      return fieldsErrors

    },
    transformHook (edit) {
      return edit
    },
    async sendForm (force = false) {
      let content = {}
      let response = null
      let url = this.apiUrl
      let edit = this.transformHook(this.edit)
      if (this.hasUpdate || force) {
        if (this.Model) {
          if (this.id && this.idInUrl) {
            url += '/' + this.id
          }
          // let edit = {...this.edit}
          let dataFormContent = {}
          dataFormContent[this.formKey] = {...edit}
          let data = transformDataToFormContent(dataFormContent, [], [], this.allowNull)
          this.formLoading = true
          try {
            response = await this.Model.api().post(url, data, {
              headers: {
                'content-type': 'application/x-www-form-urlencoded'
              },
              save: this.formSave
            })
          } catch (e) {
            if (typeof this.onSubmitFormError === 'function') {
              response = this.onSubmitFormError(e)
            }
          }
          this.formLoading = false
          if (response) {

          }
          if (response.response.data[response.config.dataKey]) {

            if (response.response.data.form_errors) {
              this.formErrors = response.response.data.form_errors
            }
            content = response.response.data[response.config.dataKey]
            if (response.response.data.token_csrf) {
              content['_token'] = response.response.data.token_csrf
            }
            let hasError = false
            if (typeof response.response.data.form_errors !== 'undefined') {
              if (Object.keys(response.response.data.form_errors).length || Object.keys(response.response.data.form_errors).length) {
               hasError = true
              }
            }
            if (hasError) {
              if (this.displayedErrorNotification) {
                if (this.displayedErrorNotification === true) {
                  ElNotification({
                    title: 'Une erreur est survenu',
                    type: 'error'
                  })
                } else {
                  ElNotification({
                    title: this.displayedErrorNotification,
                    type: 'error'
                  })
                }
              }
            } else {
              if (typeof this.afterFormSend === 'function') {
                this.afterFormSend(response)
              }

              this.content = {
                ...content
              }
            }
          } else {
            if (response.response.data && response.response.data.form_errors) {
              this.formErrors = response.response.data.form_errors
            }
            if (response.response.data.form_errors && (Object.keys(response.response.data.form_errors).length || Object.keys(response.response.data.form_errors).length)) {
              ElNotification({
                title: 'Une erreur est survenu',
                type: 'error'
              })
            }
          }
        } else {
          this.formLoading = true
          try {
            content = await sendFormContent(edit)
            if (typeof this.afterFormSend === 'function') {
              this.afterFormSend(content)
            }
          } catch (e) {

          }
          this.formLoading = false
        }
        if (content && content.data && typeof content.data !== 'string') {
          this.content = {
            ...content.data
          }
        }
        return response
      }
    },
    handleError (error) {
      console.error(error)
    },
    updateEditContent (response, dataKey = null) {
      if (dataKey === null) {
        dataKey = response.config.dataKey
      }
      if (response && response.response && response.response.data && response.response.data[dataKey]) {
        let content = response.response.data[dataKey]
        if (response.response.data.token_csrf) {
          content['_token'] = response.response.data.token_csrf
        }
        this.content = {
          ...content
        }
        this.edit = {
          ...content
        }
      }
    },
    async fetchData () {
      let handleResponse = null
      let content = {}
      let options = {}
      if (this.Model) {
        let url = this.apiUrl
        if (this.formSaveId) {
          url += '/' + this.formSaveId
        } else {
          options['save'] = this.save
        }
        try {
          this.content = {...this.ModelEntity}
          this.isLoading = true
          const response = await this.Model.api().get(url, options)
          this.isLoading = false
          if (response.response.data[response.config.dataKey]) {

            content = response.response.data[response.config.dataKey]
            if (response.response.data.token_csrf) {
              content['_token'] = response.response.data.token_csrf
            }
          }
          handleResponse = this.handleResponse(response)
          if (typeof this.afterFetchSuccess === 'function') {
            try {
              await this.afterFetchSuccess(handleResponse)
            } catch (error) {
              console.error(error)
            }
          }
        } catch (e) {

        }
      } else {
        content = await loadPageContent()
        handleResponse = content
        content = content.data
      }
      if (content) {
        this.content = {
          ...content
        }
        // if (this.edit && !this.edit.id) {
        //   const prefillData = JSON.parse(JSON.stringify(this.prefillData(this.edit, content)))
        //   this.edit = {
        //     ...this.edit,
        //     ...prefillData
        //   }
        // }
      }
      this.contentLoaded = true
      return handleResponse
    }
  }
}
