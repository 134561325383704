<template>
    <div v-if="(stepOne || stepTwo) && !stepIsLocationUpdating" class="alert alert-info" style="font-size: 14px;line-height: 23px;font-weight: 700;margin: 10px 0 45px 0;padding: 8px 24px;" role="alert">
        <i class="far fa-clock fa-lg"></i> {{$t('progress.step.label')}} {{locationStep}}
    </div>
    <div v-if="stepThree && !stepIsLocationUpdating" class="payment-valid-msg">
        <p><img :src="asset('img/svg/green-checkmark-big.svg')" alt=""> {{$t('progress.step.label')}} {{locationStep}} </p>
    </div>

    <div v-if="stepIsLocationUpdating && !stepCanceled" class="alert alert-info" style="font-size: 14px;line-height: 23px;font-weight: 700;margin: 10px 0 45px 0;padding: 8px 24px;" role="alert">
        <i class="fa fa-exclamation-circle fa-lg"></i> {{locationStep}}
    </div>

    <div v-if="stepCanceled" class="payment-invalid-msg">
        <p><img :src="asset('img/svg/exclamation-icon.svg')" alt=""> {{$t('progress.step.label')}} {{locationStep}} {{whoCanceled}} </p>
    </div>
</template>

<script>

export default {
    name: 'LocationProgress',
    props: {
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
        termined : {
            type: Boolean
        },
    },
    computed: {
        locataire () {
            return this.DL.locataire
        },
        proprietaire () {
            return this.DL.proprietaire
        },
        isTenant() {
            return this.auth.id == this.locataire.id ? true : false
        },
        isOwner() {
            return this.auth.id == this.proprietaire.id ? true : false
        },
        stepOne () {
            return this.DL.AcceptedAt === null && this.DL.PaidAt === null && this.termined === false && this.DL.CanceledAt === null ? true : false
        },
        startDateLimit () {
            return this.$moment(this.DL.dateDebut).format('dddd DD MMMM')
        },
        stepTwo () {
            return this.DL.AcceptedAt !== null && this.DL.PaidAt === null && this.termined == false && this.DL.CanceledAt === null ? true : false
        },
        stepThree () {
            return this.DL.AcceptedAt !== null && this.DL.PaidAt !== null && this.termined == false && this.DL.CanceledAt === null ? true : false
        },
        stepIsLocationUpdating () {
            return this.DL.updateStatusLocation == 1 || this.DL.updateStatusLocation == 2 ? true : false 
        },
        stepTermined () {
            return this.DL.AcceptedAt !== null && this.DL.PaidAt !== null && this.termined == true ? true : false
        },
        stepCanceled () {
            return this.DL.CanceledAt !== null ? true : false
        },
        locationStep() {
            if (this.stepOne && !this.stepIsLocationUpdating) {
                if(this.isOwner) {
                    return this.$t('progress.step.1.p', {startDate: this.startDateLimit})
                } else if(this.isTenant) {
                    return this.$t('progress.step.1.l')
                }
            } else if (this.stepTwo && !this.stepIsLocationUpdating) {
                if(this.isOwner) {
                    return this.$t('progress.step.2.p') 
                } else if(this.isTenant) {
                    return this.$t('progress.step.2.l', {startDate: this.startDateLimit})
                }
            } else if (this.stepThree && !this.stepIsLocationUpdating) {
                if(this.isOwner) {
                    return this.$t('progress.step.3.p') 
                } else if(this.isTenant) {
                    return this.$t('progress.step.3.l')
                }
            } else if (this.stepIsLocationUpdating && !this.stepCanceled) {
                if(this.isOwner) {
                    return this.DL.UpdateStatusLocation == 1 ? "Modification de la demande : En attente du choix du locataire" : "Modification de la demande : En attente du paiement du locataire"
                } else if(this.isTenant) {
                    return this.DL.UpdateStatusLocation == 1 ? "Modification de la demande : Action nécessaire de votre part" : "Modification de la demande : En attente du paiement"
                }
            } else if (this.stepCanceled) {
                return this.$t('progress.step.cancel')
            }
        },
        whoCanceled() {
            return this.DL.locationProblem && this.DL.locationProblem.by.id === this.proprietaire.id ? "propriétaire" : "locataire"
        }
    }
}

</script>