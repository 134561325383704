<template>
  <div class="boutique-header">
    <h3 class="parameters-title">Utilisateurs</h3>
  </div>
  <CustomTabs :tabs="tabs"></CustomTabs>
</template>

<script>
import CustomTabs from "../../../components/CustomTabs.vue";

export default {
  components: {
    CustomTabs
  },
  name: "AdminUsersTabs",
  computed: {
  },
  data () {
    return {
      tabs: [
        {
          label: 'Non certifiés',
          to: { name: 'admin-users', params: { type: 'unverified'}}
        },
        {
          label: 'Locataires',
              to: { name: 'admin-users', params: { type: 'tenant'}}
        },
        {
          label: 'Propriétaires',
              to: { name: 'admin-users', params: { type: 'owner'}}
        },
        {
            label: 'Actifs',
            to: { name: 'admin-users', params: { type: 'active'}}
        },
        {
          label: 'Anonymes',
              to: { name: "admin-users", params: { type: 'anonymous'}}
        }
    // {
    //   label: 'Modèle économique',
    //   to: { name: 'admin-economics'}
    // },

  ]
  }
  }
}
</script>

<style scoped>

</style>
