<script>
export default {
  name: "ResumeListItem",
  props: {
    listItems: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    threeFirstCartItems () {
      return this.listItems.slice(0, 3).map((item) => {
        if (!item.cover) {
          item.cover = '/display-media/img/sans-photo.jpg?w=32&h=32&type=fit'
        }
        if (item.cover && item.cover.includes('/display-media') === false) {
          item.cover = '/display-media' + item.cover + '?w=32&h=32&type=fit'
        } else {
          item.cover = item.cover + '?w=32&h=32&type=fit'
        }
        return item
      })
    },
    moreCartItems () {
      if (Array.isArray(this.threeFirstCartItems) && Array.isArray(this.listItems) && this.threeFirstCartItems.length < this.listItems.length) {
        return this.listItems.length - this.threeFirstCartItems.length
      } else {
        return false
      }
    }
  }
}
</script>

<template>
  <div class="item-resume">
    <img v-for="item in threeFirstCartItems" :src="item.cover" alt="" class="product-cover">
    <div v-if="moreCartItems" class="more-items">+ {{ moreCartItems }}</div>
  </div>
</template>

<style scoped>

</style>