<template>
  <el-sub-menu v-if="item.submenu.length" :index="item.key">
    <template #title>
      <span :class="item.class">
        <span  class="icon-kit" v-html="item.icon"></span>
        <span>{{ item.label }}</span>
      </span>
    </template>
    <menu-item v-for="menu in item.submenu" :item="menu" :link-class="linkClass" @itemClick="$emit('item-click')"/>
  </el-sub-menu>
  <el-menu-item v-else-if="item.to" :index="item.key" :route="!item.href ? item.to : {}" @click="onClickItem(item.to)" :class="item.class">
    <router-link v-if="item.to" :to="item.to" :class="linkClass">
      <span v-if="item.icon" class="icon-kit" v-html="item.icon"></span>
      {{ item.label }}
    </router-link>
    <span v-else><span v-if="item.icon" class="icon-kit" v-html="item.icon"></span>{{ item.label }}</span>
  </el-menu-item>
  <el-menu-item v-else :index="item.key" :class="item.class" @click="$emit('item-click')">
    <span class="icon-kit" v-html="item.icon"></span>
    <span>{{ item.label }}</span>
  </el-menu-item>
</template>
<script>
  export default {
    name:"MenuItem",
    emits: ['item-click'],
    props: {
      item: {
        default () {
          return {}
        }
      },
      linkClass: {
        default: '',
        type: String
      }
    },
    methods: {
      onClickItem (to) {
        this.$emit('item-click')
        if (to.href) {
          window.location.href = to.href
        } else {
          // this.$router.push(to)
        }
      }
    }
  }
</script>
<style>

</style>
