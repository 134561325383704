<template>
    <div>
        <div class="cart-modal">
            <div class="modal fade show" :style="styleModal">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                                {{$t('modals.equipment.title')}}
                            </h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div v-for="detailAnnonce in detailsEquipment">
                                    <div class="col-l-4">
                                        <table width="100%" border="0" cellpadding="5">
                                            <tbody>
                                                <tr>
                                                    <td><strong>{{$t('modals.equipment.quantity')}}</strong></td>
                                                    <td><strong>{{$t('modals.equipment.name')}}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>{{ detailAnnonce.quantity }}</td>
                                                    <td>{{ detailAnnonce.nomEquipement }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div>
                                <button type="submit" class="btn btn-short btn-primary" data-dismiss="modal" @click="closeModalEquipment()">{{$t('modals.equipment.close')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="overlay">
            <div class="cv-spinner">
                <span class="spinner"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LocationModalEquipment',
    emits: ["closeModalEquipment"],
    data() {
        return {
            styleModal: "padding-right: 19px; display: block; background-color: rgba(0,0,0,0.3);"
        }
    },
    props: {
        showModal: {
            type: Boolean
        },
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
        termined : {
            type: Boolean
        },
        equipmentsPA: {
            type: Array
        }
    },
    computed: {
        detailsEquipment () {
            return this.equipmentsPA
        },
    },
    methods: {
        modalAnnonceId(p) {
            return "modal-annonce-" + p.annonces.id
        },
        modalDetailsId(p) {
            return "modal-details-" + p.annonces.id
        },
        closeModalEquipment() {
            this.$emit('closeModalEquipment', false);
        },
    }
}


</script>