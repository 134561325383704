import User from "../../user/services/User.js";
import Media from "../../media/services/Media.js"
import { Model } from "@vuex-orm/core"
import Bundle from "./Bundle.js";
import Inventory from "~/modules/ad/services/Inventory.js";

export default class AdUnavailability extends Model {
  static entity = 'AdUnavailability'

  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api',
    dataKey: 'AdUnavailability',
    actions: {
      fetch: {
        method: 'get',
        url: 'annonces'
      }
    }
  }
  static fields () {
    return {
      id: this.attr(null),
      quantity: this.attr(0),
      status: this.attr(0),
      ad_id: this.attr(null),
      user_id: this.attr(null),
      bundleAdUnavailableRangeId: this.attr(null),
      unavailableRange: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      ad: this.belongsTo(User, 'ad_id'),
      bundleAdUnavailableRange: this.belongsTo(this, 'bundleAdUnavailableRangeId'),
      stockUnavailableRanges: this.attr([])
    }
  }
}
