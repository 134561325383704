<script>
import FormHandler from "~/mixins/formHandler.js";
import Inventory from "~/modules/ad/services/Inventory.js";
import { ElNotification } from 'element-plus'
import screenBehavior from "~/mixins/screenBehavior.js";

export default {
  name: "EditInventory",
  mixins: [FormHandler, screenBehavior],
  ApiUrl: 'user/inventory-edit',
  props: {
    inventory: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      loadingInventory: false,
      form: {
      },
      edit: {
        status: 1
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadInventory()
    })
  },
  watch: {
    // ModelEntity: {
    //   immediate: true,
    //   deep: true,
    //   handler (val) {
    //     console.log('ModelEntity changed')
    //     console.log(val)
    //     if (val && this.stockId) {
    //       console.log('ModelEntity changed')
    //       this.content = JSON.parse(JSON.stringify(val))
    //     }
    //   }
    // },
    inventory: {
      handler (val) {
        if (val) {
          // this.edit = val
          if (!val.id) {
            this.edit = JSON.parse(JSON.stringify(val))
          } else {
            this.content = JSON.parse(JSON.stringify(val))
          }
        }
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    formAlignement () {
      return this.screen.width > 800 ? 'left' : 'top'
    },
    apiUrl () {
      return 'user/inventory-edit/' + this.$route.params.id
    },
    stockErrors () {
      return this.formErrors && this.formErrors.Stock && Array.isArray(this.formErrors.Stock)
    },
    formSaveId () {
      return this.$route.params.stockId
    },
    fetchUrl () {
      return 'user/inventory/' + this.$route.params.id
    },
    saveButtonText () {
      return this.id ? 'Sauvegarder' : 'Ajouter'
    },
    stockId () {
      return this.$route.params.stockId ? this.$route.params.stockId : null
    },
    id () {
      return this.stockId
    },
    formKey () {
      return 'Stock'
    },
    Model () {
      return Inventory
    },
    statusOptions () {
      return [
        {
          value: 1,
          label: 'En stock',
        },
        {
          value: 2,
          label: 'Sortie',
        },
        {
          value: 3,
          label: 'Maintenance',
        },
        {
          value: 4,
          label: 'Archivé',
        }
      ]
    }
  },
  methods: {
    afterFormSend (response) {
      if (!this.id) {
        ElNotification({
          title: 'Unité ajouté',
          message: 'Une ajouté a bien été ajouté à votre produit',
          type: 'success'
        })
      } else {
        ElNotification({
          title: 'Produit sauvegardé',
          message: 'Les modifications de votre unité ont bien été sauvegardé',
          type: 'success'
        })
      }
      if (!this.id) {
        this.$router.push({name: 'product-inventory'})
      }
    },
    onCancel () {
      this.$router.push({
        name: 'product-inventory'
      })
    },
    // afterFormSend () {
    //   this.$emit('afterSubmit')
    // },
    transformHook (edit) {
      edit['annonce_id'] = this.$route.params.id
      return edit
    },
    async loadInventory () {
      // if (this.id) {
        this.loadingInventory = true
        await this.beforeDataFetch()
        this.loadingInventory = false
      // }
    }
  }
}
</script>

<template>
  <el-form v-loading="loadingInventory" :model="form" label-width="300px" :label-position="formAlignement" size="large" class="custom_form_lightyshare">
    <div v-for="Error in this.formErrors.Stock" class="alert mb-4" role="alert">
      <div class="alert-icon">
        <span class="fa fa-exclamation-circle" ></span>
      </div>
      <span>{{Error}}</span>
    </div>
    <el-form-item label="Status">
      <template #label>
        <div>
          <h4 class="label-title">Valeur d'achat</h4>
          <p class="label-info">Indiquez la valeur à laquelle vous avez acheté ce produit. La valeur doit être identique à votre facture d’achat.</p>
        </div>
      </template>
      <el-input v-model="edit.value" type="number" :placeholder="$t('steps-location-4.sell-input.placeholder')">
        <template #append>€</template>
      </el-input>
    </el-form-item>
    <el-form-item label="Status">
      <template #label>
        <div>
          <h4 class="label-title">Etat de la référence</h4>
          <p class="label-info">Etat du produit dans votre inventaire. Si le produit est archivé il n'apparaitra plus dans votre boutique</p>
        </div>
      </template>
      <el-select v-model="edit.status" class="m-2" placeholder="Select" size="large">
        <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        />
      </el-select>
    </el-form-item>
    <el-button v-if="edit && !edit.id" @click="onCancel">Annuler</el-button>
    <el-button :loading="formLoading" plain type="info" class="btn btn-save" :disabled="!this.hasUpdate" @click.prevent="sendForm">{{ saveButtonText }}</el-button>
  </el-form>
</template>

<style scoped>

</style>