import { Model } from "@vuex-orm/core"
import Ads from "./Ads.js";
import BundleItem from "./BundleItem.js";

export default class Bundle extends Model {
  static entity = 'bundle'

  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api',
    dataKey: 'bundle',
    actions: {
      fetch: {
        method: 'get',
        url: 'bundle'
      }
    }
  }
  static fields () {
    return {
      id: this.attr(null),
      variation: this.attr(''),
      annonce_id: this.attr(null),
      bundleItems: this.attr([]),
      // this.hasMany(BundleItem, 'bundleId', 'id')
    }
  }
}
