import { Model } from '@vuex-orm/core'
// import User from "../../user/services/User.js";

export default class Signup extends Model {
  static entity = 'signup'
  static apiConfig = {
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    baseURL: '/api',
    dataKey: 'signup'
  }

  static fields () {
    return {
      id: this.attr(null),
      statutUser: this.attr(null),
      email: this.attr(''),
      password: this.attr(''),
      prenom: this.attr(''),
      nom: this.attr(''),
      dateNaissance: this.attr(''),
      newsletter: this.attr(false),
      telephone: this.attr(false),
      adresse: this.attr(false),
      adresseSiege: this.attr(null),
      infoSociete: this.attr(false),
      siretRequest: this.attr(false),
      manually: this.attr(true),
      siegeSocial: this.attr(null)
    }
  }
}
