import {Model} from "@vuex-orm/core";

export default class MonthlyBilling extends Model {

  static entity = 'monthlyBilling'

  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api',
    dataKey: 'bills'
  }

  static fields () {
    return {
      id: this.attr(null),
      month: this.attr(''),
      periode: this.attr(''),
      totalLocations: this.attr(0),
      numeroFacture: this.attr(0),
      prixHt: this.attr(0),
      montantTva: this.attr(0)
    }
  }
}