import {Model} from "@vuex-orm/core";
import User from "../../user/services/User.js";
import Ads from "../../ad/services/Ads.js"

export default class Media extends Model {
  static entity = 'media'
  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api',
    dataKey: 'medias',
    actions: {
      fetch: {
        method: 'get',
        url: 'annonces'
      }
    }
  }
  static fields () {
    return {
      id: this.attr(null),
      path: this.attr(''),
      user_id: this.attr(null),
      user: this.hasOne(User, 'user_id'),
			annonce_id: this.attr(null),
			annonce: this.belongsTo(Ads, 'annonce_id'),
      error: this.attr(''),
      reason: this.attr(''),
      valide: this.attr(false),
      position: this.attr(0),
    }
  }
}
