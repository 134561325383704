import {Model} from "@vuex-orm/core";

export default class Media extends Model {
  static entity = 'mangopay'
  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api/admin',
    dataKey: 'mangopay',
    actions: {
      fetch: {
        method: 'get',
        url: 'annonces'
      }
    }
  }
  static fields () {
    return {
      id: this.attr(null),
      hooks: this.attr(null),
    }
  }
}
