<script>
import User from "~/modules/user/services/User.js";
import MediaEdit from "~/modules/media/components/MediaEdit.vue";
import BankValidationBehavior from "~/modules/payment/mixins/BankValidationBehavior.js";
import BankValidationNotifications from "~/modules/payment/components/BankValidationNotifications.vue";
import { ElNotification } from 'element-plus'

export default {
  name: "BankValidationIdentityManagement",
  mixins: [BankValidationBehavior],
  components: {
    BankValidationNotifications,
    MediaEdit
  },
  data () {
    return {
      detailsErrors: {},
      edit: {
        nationality: 'FR',
        countryOfResidence: 'FR',
        infoSociete: {
          prenomLegal: '',
          nomLegal: '',
          birthdateLegalString: '',
          legalCountryOfResidence: 'FR',
          legalNationality: 'FR',
        },
        medias: []
      },
      form: {
        identity: '',
        lastname: '',
        birthdate: '',
        quantity: ''
      }

    }
  },
  watch: {
    ModelEntity: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && this.content) {
          this.content = {...this.content, ...JSON.parse(JSON.stringify(val))}
        }
        if (val && !this.content) {
          this.content = JSON.parse(JSON.stringify(val))
        }
      }
    }
  },
  computed: {
    formKey () {
      return 'identity'
    },
    idInUrl () {
      return false
    },
    identityErrors () {
      // return
    },
    apiUrl () {
      return 'user/validation-banque'
    },
    Model () {
      return User
    },
    fetchUrl () {
      return '/api/user/validation-banque'
    },
    nationalitiesList () {
      if (!this.$nationalities) {
        return {}
      }
      return this.$nationalities
    },
    countriesList () {
      if (!this.$countries) {
        return {}
      }
      return this.$countries
    },
    alertClass () {
      if (this.ModelEntity.idKycDocuments && this.ModelEntity.idKycDocuments.Status === 'VALIDATION_ASKED') {
        return 'alert-info'
      } else if (this.ModelEntity.idKycDocuments && this.ModelEntity.idKycDocuments.Status === 'VALIDATED') {
        return 'alert-success'
      } else if (this.ModelEntity.idKycDocuments && this.ModelEntity.idKycDocuments.Status === 'OUT_OF_DATE') {
        return 'alert-warning'
      } else if (this.ModelEntity.idKycDocuments && this.ModelEntity.idKycDocuments.Status === 'REFUSED') {
        return 'alert-danger'
      }
      return ''
    },
    alertFaIcon () {
      if (this.ModelEntity.idKycDocuments && this.ModelEntity.idKycDocuments.Status === 'VALIDATION_ASKED') {
        return 'fa-clock'
      } else if (this.ModelEntity.idKycDocuments && this.ModelEntity.idKycDocuments.Status === 'VALIDATED') {
        return 'fa-check'
      } else if (this.ModelEntity.idKycDocuments && this.ModelEntity.idKycDocuments.Status === 'OUT_OF_DATE') {
        return 'fa-refresh'
      } else if (this.ModelEntity.idKycDocuments && this.ModelEntity.idKycDocuments.Status === 'REFUSED') {
        return 'fa-exclamation-circle'
      }
      return ''
    }
  },
  methods: {
    onUploadSuccess (response) {
      console.log(response)
      ElNotification({
        title: "Le document a bien été ajouté",
        type: 'success'
      })
    },
    onUploadError (action, error) {
      console.log(error)
      console.log(this.$t(error.error))
      if (error && error.details) {
        this.detailsErrors = error.details
      }
      ElNotification({
        title: this.$t(error.error),
        message: "Veuillez réssayer avec un autre document",
        type: 'error'
      })
      console.log('on upload error')
      console.log(action)
    },
    async onFormSubmit () {
      await this.sendForm()
      if (this.$route && this.$route.name !== 'BankValidation') {
        console.log('go to next step')
        let step = this.getNextStep('identity')
        // console.log(to)
        this.$router.push(step.to)
      }
    },
    transformHook (edit) {
      if (edit.statutUser === 1) {
        return {
          nationality: edit.nationality,
          countryOfResidence: edit.countryOfResidence,
        }
      }
      if (edit.infoSociete) {
        return {
          legalFirstname: edit.infoSociete.prenomLegal,
          legalLastname: edit.infoSociete.nomLegal,
          legalBirthdate: edit.infoSociete.birthdateLegalString,
          legalCountryOfResidence: edit.infoSociete.legalCountryOfResidence,
          legalNationality: edit.infoSociete.legalNationality,
        }
      }
    },
    afterFetchSuccess (response) {
      console.log('response after fetch success')
      console.log(response)
      // if (response.data && response.data.user){
      //   this.edit = response.data
      // }

    },
    // after
    preQuery (query) {
      if (this.id) {
        query.with('infoSociete')
        query.where('user_id', this.id)
      }
    },
  }
}
</script>

<template>
  <el-form
      v-loading="isLoading"
      :model="form"
      label-width="300px"
      label-position="left"
      size="large"
      class="custom_form_lightyshare"
  >
    <div class="product-creation-form-header">
      <h3>{{ $t('shop.settings.bank-validation.steps.identity.title') }}</h3>
      <p class="label-info">{{ $t('shop.settings.bank-validation.steps.identity.description') }}</p>
    </div>

    <BankValidationNotifications v-if="ModelEntity && ModelEntity.idKycDocuments" :document="ModelEntity.idKycDocuments" :details-errors="detailsErrors"/>
    <div v-if="ModelEntity && ModelEntity.idKycDocuments && ModelEntity.idKycDocuments.Status !== 'VALIDATION_ASKED' && ModelEntity.idKycDocuments.Status !== 'VALIDATED'">
      <div v-if="edit.statutUser !== 1 && edit.statutUser !== 5">
        <el-form-item label="firstname">
          <template #label>
            <div>
              <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.identity.firstname-label') }}</h4>
            </div>
          </template>
          <el-input v-model="edit.infoSociete.prenomLegal" size="large" />
        </el-form-item>
        <el-form-item label="lastname">
          <template #label>
            <div>
              <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.identity.lastname-label') }}</h4>
            </div>
          </template>
          <el-input v-model="edit.infoSociete.nomLegal" size="large" />
        </el-form-item>
        <el-form-item label="birthday">
          <template #label>
            <div>
              <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.identity.birthday-label') }}</h4>
            </div>
          </template>
          <el-input v-model="edit.infoSociete.birthdateLegalString" size="large" />
        </el-form-item>
      </div>
      <div v-if="edit.statutUser !== 1">
        <el-form-item label="legalNationality">
          <template #label>
            <div>
              <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.identity.legal-nationality') }}</h4>
            </div>
          </template>
          <el-select v-model="edit.infoSociete.legalNationality" filterable size="large">
            <el-option
                v-for="(value, key) in nationalitiesList"
                :key="key"
                :label="value"
                :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="legalCountryOfResidence">
          <template #label>
            <div>
              <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.identity.legal-countryOfResidence') }}</h4>
            </div>
          </template>
          <el-select v-model="edit.infoSociete.legalCountryOfResidence" filterable size="large">
            <el-option
                v-for="(value, key) in countriesList"
                :key="key"
                :label="value"
                :value="key"
            />
          </el-select>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item label="nationality">
          <template #label>
            <div>
              <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.identity.nationality') }}</h4>
            </div>
          </template>
          <el-select v-model="edit.nationality" filterable size="large">
            <el-option
                v-for="(value, key) in nationalitiesList"
                :key="key"
                :label="value"
                :value="key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="countryOfResidence">
          <template #label>
            <div>
              <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.identity.countryOfResidence') }}</h4>
            </div>
          </template>
          <el-select v-model="edit.countryOfResidence" filterable size="large">
            <el-option
                v-for="(value, key) in countriesList"
                :key="key"
                :label="value"
                :value="key"
            />
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="idCard">
        <template #label>
          <div>
            <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.identity.identity-document-label') }}</h4>
            <p class="label-info">{{ $t('shop.settings.bank-validation.steps.identity.identity-document-label-description') }}</p>
          </div>
        </template>
        <media-edit v-model="edit.medias" @error="onUploadError" @uploadSuccess="onUploadSuccess" :action="['api', 'user', 'upload-kyc-page', 'id']"/>
      </el-form-item>
    </div>
    <div class="actions">
      <el-button @click="onFormSubmit" :loading="formLoading" size="large" class="btn btn-primary">{{ $t('shop.settings.bank-validation.steps.buttons.next') }}</el-button>
    </div>
  </el-form>
</template>

<style scoped>

</style>