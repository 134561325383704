<template>
  <el-table
      :data="categories"
      row-key="nom"
      :table-layout="'auto'"
      :default-sort="defaultOrder"
      :row-class-name="tableRowClassName"
      @cell-click="onCellClick"
      :tree-props="{ children: 'sousCategories'}"
      class="pointer-row"
      style="width: 100%;"
  >
    <template #empty>
      <slot name="empty"></slot>
    </template>
<!--    <el-table-column fixed prop="id" label="#" width="60" />-->
    <el-table-column prop="#" :label="$t('owner-listing.tab-head.title')" sortable="custom" min-width="100">
      <template #default="{row}">
        {{ row.nom }}
      </template>
    </el-table-column>
    <el-table-column prop="nom" label="Nom" align="left" sortable="custom" min-width="250"/>
    <el-table-column prop="countModeles" label="Modèles" sortable="custom">
      <template #default="{row}">
        <el-tag
            :type="row.countModeles ? 'success' : 'danger'"
            class="mx-1 media-tag"
            effect="light"
            round
        >
          {{ row.countModeles }} Modèles
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column fixed="right" width="40px" prop="action">
      <template #default="scope">
        <div class="display-flex">
          <el-dropdown trigger="click" :ref="'dropdown' + scope.$index">
            <div class="dropdown">
              <button class="dropdown-edit-toogle" style="padding: 5px;">
                <span class="icon-kit">
                  &#xe9a5
                </span>
              </button>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <a class="dropdown-item" href="#" @click.prevent="archivedProduct(scope)">
                    <el-icon v-show="archivedProductLoading[scope.$index]" class="is-loading"><loading/></el-icon>
                    {{ $t('owner-listing.dropdown.delete') }}
                  </a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import AdTicket from "~/modules/ad/components/AdTicket.vue";

export default {
  components: {
    AdTicket
  },
  name: "CategoriesTable",
  emits: ['onArchivedProduct'],
  props: {
    defaultOrder: {
      type: Object,
      default () { return {}}
    },
    categories: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      archivedProductLoading: {},
      aLaUneChangeLoading: {}
    }
  },
  methods: {
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    },
    console(data) {
      console.log(data)
    },
    onCellClick (row, column) {
      console.log('on cel click')
      if (column.property !== 'action') {
        let routeName = 'product-edition'
        console.log('on cell click ')
        console.log(row)
        if (row.stockType === 1) {
          routeName = 'product-inventory'
        }
        this.$router.push({
          name: routeName,
          params: {
            id: row.id
          }
        })
      }
      // this.$router.push({
      //   name: 'product-inventory',
      //   params: {
      //     id: row.id
      //   }
      // })
      // console.log('row click')
      // console.log(row)
      // console.log(column)
    }
  }
}
</script>

<style scoped>

</style>