<script>
import LoadContent from "~/mixins/loadContent.js";
import User from "~/modules/user/services/User.js";
import Auth from "~/modules/auth/services/Auth.js";
import BankValidationBehavior from "~/modules/payment/mixins/BankValidationBehavior.js";
import FormHandler from "~/mixins/formHandler.js";



export default {
  name: "BankValidationTemplate",
  mixins: [BankValidationBehavior],
  data () {
    return {}
  },
  errorCaptured(err, instance, info) {
    console.error(`Erreur custom`);
    console.error(`Erreur capturée dans ${info}:`, err);
    return false; // retourne false pour continuer la propagation de l'erreur
  },
  async beforeRouteEnter (to, from, next) {
    // this.fetchData()
    // console.log(to.name === 'BankValidation')
    try {
      await next(async (vm) => {
        try {
          const fetch = await vm.beforeDataFetch()
        } catch (e) {
          console.log('during bank validation template before route enter')
          console.log(e)
        }
      })
    } catch (e) {
      console.log('during bank validation template before route enter')
      console.log(e)
    }
  },
  computed: {
    Model () {
      return User
    },
    // steps () {
    //   return [
    //     {
    //       step: 'identity',
    //       to: {name: 'BankValidationIdentityManagement'},
    //       redirectToNext (user) {
    //         return user && user.infoSociete && user.infoSociete.nomLegal && user.infoSociete.prenomLegal && user.idKycDocuments && (user.idKycDocuments.Status === 'VALIDATION_ASKED' || user.idKycDocuments.Status === 'VALIDATED')
    //       },
    //       order: 0,
    //       icon: '&#xe980'
    //     },
    //     {
    //       step: 'company',
    //       to: {name: 'BankValidationCompany'},
    //       redirectToNext (user) {
    //         return user && user.infoSociete && user.infoSociete.raisonSociale && user.kycDocumentationKbis
    //       },
    //       order: 1,
    //       icon: '&#xe926'
    //     },
    //     {
    //       step: 'beneficiary',
    //       redirectToNext (user) {
    //         return user && user.infoSociete && user.infoSociete.nomLegal && user.infoSociete.prenomLegal && user.kycDocumentationId
    //       },
    //       to: {name: 'BankValidationBeneficiary'},
    //       order: 1,
    //       icon: '&#xea05'
    //     },
    //     {
    //       step: 'complete',
    //       redirectToNext (user) {
    //         return user && user.infoSociete && user.infoSociete.nomLegal && user.infoSociete.prenomLegal && user.kycDocumentationId
    //       },
    //       to: {name: 'BankValidationComplete'},
    //       order: 1,
    //       icon: '&#xe92c'
    //     }
    //   ]
    // },
    fetchUrl () {
      return '/user/validation-banque'
    },
    user () {
      if (this.auth && this.auth.id) {
        return User.query().with('infoSociete').where('id', this.auth.id).first()
      } else {
        return null
      }
    },
    auth () {
      return Auth.query().first()
    },
    id () {
      return this.auth && this.auth.id
    },
    getUserStepI () {
      return this.getUserStepIndex(this.user, this.userSteps)
    },
    currentBankValidationStatus () {
      if (this.globalLoading) {
        return -1
      }
      return 1
    },
    tagType () {
      if (this.globalLoading) {
        return 'info'
      }
      return false
    }
  },
  methods: {
    preQuery (query) {
      query.with('infoSociete')
      query.where('user_id', this.id)
    },
    // getUserStepIndex (user, stepsArray) {
    //   for (let i = 0; i < stepsArray.length; i++) {
    //     const stepObject = stepsArray[i];
    //     if (stepObject.redirectToNext && !stepObject.redirectToNext(user)) {
    //       return i; // Retourne l'indice de l'étape courante si redirectToNext renvoie true
    //     }
    //   }
    //   return -1; // Retourne -1 si aucune étape correspondante n'est trouvée
    // },
    async redirectToBankValidationStep () {

      let allreadyConfirmed = vm.signupUser && vm.signupUser.telephone && vm.signupUser.telephone.certifie
      console.log(allreadyConfirmed)
      console.log('next step')
      console.log(vm.nextStep)

      if (allreadyConfirmed) {
        if (vm.nextStep) {
          vm.$router.push({name: vm.nextStep})
        }
      }
    },
    onClick (e) {
      console.log('redirect to')
      console.log(e.index)
      console.log(this.getUserStepI)
      console.log(e.index > this.getUserStepI)
      if (e.index <= this.getUserStepI) {
        console.log('redirect to')
        console.log(e.to)
        this.$router.push(e.to)
        console.log('finish push')
      }
      console.log(e)
    },
  },
}
</script>

<template>
  <div class="product-creation-form-header">
    <h3>{{ $t('shop.settings.bank-validation.title') }} <el-tag class="ml-2" :type="globalStatusType">{{$t('shop.settings.bank-validation.status.'+ globalDocumentsStatus)}}</el-tag></h3>
    <p class="label-info">{{ $t('shop.settings.bank-validation.description') }}</p>
  </div>
  <el-steps v-show="!globalLoading" :active="getUserStepI" align-center class="big-section-container">
    <el-step
        v-for="s in userSteps"
        :class="s.className"
        :title="$t('shop.settings.bank-validation.steps.' + s.step + '.title')"
        :description="$t('shop.settings.bank-validation.steps.' + s.step + '.short')"
        @click="onClick(s)"
    >
      <template v-if="s.icon" #icon>
        <span class="icomoon" v-html="s.icon"></span>
      </template>
    </el-step>
  </el-steps>
  <router-view v-show="!globalLoading"></router-view>
</template>

<style scoped>

</style>