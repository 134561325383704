
<template>
  <section class="auth-container">
    <div class="step-indicator d-lg-none phone-step-indicator" :style="stepPhoneStyle"></div>
    <div class="image-container" id="signup-cover">
      <a :href="path('lighty_share_lighty_share_homepage')">
        <img :src="asset('img/new/logo-dark.png')" alt="LightyShare" class="logo d-lg-none">
        <img :src="asset('img/new/logo-light.png')" alt="LightyShare" class="logo d-none d-lg-block">
      </a>
      <div class="bg">
        <div class="signup-review-section">
          <p class="signup-review-sentence">“Matériel conforme à l'annonce : état impeccable et les conseils de Fabien étaient précieux pour la prise en main du matériel !”</p>
          <p class="signup-review-author">Thomas - Réalisateur</p>
          <div class="signup-review-profils">
            <img :src="asset('img/review-statistics/avatar-reviewer.png')" alt="" class="thumb-profils">
            <img :src="asset('img/review-statistics/review-statistics.png')" alt="" class="stats-review">
          </div>
        </div>
      </div>
<!--      <img :src="asset('img/new/auth-pages/register.png')" alt="" class="bg">-->
<!--      <p class="photo-credit d-none d-lg-block">Photo by <a href="//google.com" target="_blank">Denis Philips</a></p>-->
    </div>
    <div class="form-container d-flex justify-content-center align-items-center">
      <div class="step-indicator d-none d-lg-block" :style="stepStyle"></div>
      <a v-if="!loggedUser" :href="path('lighty_share_lighty_share_connexion')" class="auth-link d-none d-lg-block" v-html="$t('signup.link-auth')">
      </a>
        <div class="tab-content" style="width: 100%;"
        >
<!--          <transition name="slide-right" mode="out-in">-->
            <router-view v-slot="{ Component, route }">
              <el-form action="/register/" method="POST" class="fos_user_registration_register" :size="'large'" :label-position="'top'">
<!--              <div :key="route.path" class="page-container">-->
                    <component :is="Component"/>
<!--              </div>-->
              </el-form>
            </router-view>
<!--          </transition>-->
        </div>
    </div>
  </section>
</template>

<script>
import signupHandler from "../modules/signup/mixins/signupHandler.js";

export default {
  mixins: [signupHandler],
  name: "BaseSignupLayout"
}
</script>
<style scoped>

</style>