
<template>
  <div>
    <el-alert v-if="generalError" :title="generalError.title" :description="generalError.description" type="error" show-icon style="margin-bottom: 12px;" :closable="false"/>
    <el-alert v-if="error" :title="error.title" :description="error.description" type="error" show-icon style="margin-bottom: 12px;" :closable="false"/>
    <h2 class="signup-form-title">{{$t('signup.phone.title')}}</h2>
    <div class="form-group">
      <p class="signup-form-description">{{$t('signup.phone.description')}}</p>
    </div>

    <el-form-item :label="$t('signup.phone.label')">
      <VueTelInput v-model="edit.phone" :inputOptions="inputOptions" mode="international" :default-country="'FR'" @country-changed="saveCountry"></VueTelInput>
    </el-form-item>
    <p class="condition-text"><Lock class="text-icon"/>{{$t('signup.phone.privacy')}}</p>
    <div class="form-group mt-4">
      <el-button class="signup-primary" :class="hasChanged ? '' : 'disabled'" @click="continu" :loading="isLoading">
        {{$t('signup.continue')}}
      </el-button>
    </div>
  </div>
</template>
Copy code
<script>
import { VueTelInput } from 'vue-tel-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import signupHandler from "../mixins/signupHandler.js";
import 'vue-tel-input/vue-tel-input.css';

export default {
  name: "RegisterPhoneNumber",
  mixins: [signupHandler],
  data () {
    return {
      inputOptions: {
        placeholder: this.$t('signup.phone.placeholder')
      },
      edit: {
        phone: '',
        country: ''
      }
    }
  },
  created() {
    let user = JSON.parse(JSON.stringify(this.signupUser))
    if (user.telephone) {
      this.edit.phone = user.telephone.telephone
    }
  },
  computed: {
    submitUrl () {
      return 'user/send-sms-code'
    },
    parsePhoneNumber () {
      if (this.edit.phone.length < 5) {
        return parsePhoneNumberFromString(this.edit.phone)
      } else {
        return false
      }
    },
    hasChanged () {
      // return this.edit.phone.length > 5
      const phoneNumber = parsePhoneNumberFromString(this.edit.phone.replaceAll(' ', ''))
      return typeof phoneNumber !== 'undefined' && phoneNumber.isValid()
    }
  },
  methods: {
    saveCountry ({iso2}) {

      this.edit.country = iso2
    },
    async beforeContinu () {
      const response = await this.sendData(this.edit, 'phone')
      if (response && response.response && response.response.data && response.response.data.form_errors) {
        this.formErrors = response.response.data.form_errors
        const errors = Object.keys(this.formErrors)

        return false
      }
      return response && response.response && response.response.data && response.response.data.valid
    }
  },
  components: {
    VueTelInput
  },
}
</script>

<style scoped>

</style>