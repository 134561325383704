import User from "../../user/services/User.js";
import {Model} from "@vuex-orm/core";

export default class Computed extends Model {
  static entity = 'computes'

  static apiConfig = {
    headers: {'X-Requested-With': 'XMLHttpRequest'},
    baseURL: '/api',
    dataKey: 'computations',
    // actions: {
    //   fetch: {
    //     method: 'get',
    //     url: 'comput'
    //   }
    // }
  }

  static fields () {
    return {
      id: this.attr(null),
      type: this.attr(0),
      online: this.attr(0),
      channel: this.attr(''),
      value: this.attr(''),
      key: this.attr(''),
      settings: this.attr(''),
      user_id: this.attr(''),
      user: this.belongsTo(User, 'user_id')
      // items: this.hasMany(Item, 'computed_id')
    }
  }
}