<template>
  <table class="datatable table">
    <thead>
    </thead>
    <tbody>
      <tr v-for="rental in rentals">
        <td class="custom-clickable-row proprietaire-th">
          <a href="">
            <user-profil :user-name="rental.locataire.prenom +' '+ rental.locataire.nom" :society="rental.locataire.statutUser > 1 && rental.locataire.ifnoSociete && rental.locataire.infoSociete.raison_sociale" :thumbnail="rental.locataire.image_profil" :loading="false"></user-profil>
          </a>
        </td>
        <rental-status :item="rental"></rental-status>
      </tr>
    </tbody>
  </table>
</template>

<script>
import RentalStatus from "./rentalStatus.vue";
import UserProfil from "../userProfil.vue";

export default {
  name: "smallReservationList",
  components: {
    RentalStatus,
    UserProfil
  },
  props: {
    rentals: {
      type: [Array]
    }
  }
}
</script>

<style scoped>

</style>