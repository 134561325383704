<template>
  <el-table
      :data="billings"
      style="width: 100%; "
      :table-layout="'auto'"
      :default-sort="defaultOrder"
      :row-class-name="tableRowClassName">
    <template #empty>
      <slot name="empty"></slot>
    </template>
    <el-table-column prop="numeroFacture" label="#" min-width="200" >
      <template #default="{row}">
        <span v-html="row.facture ? row.facture.numeroFacture : '-'"></span>
      </template>
    </el-table-column>
    <el-table-column prop="locataire" label="Client" sortable="custom" min-width="210">
      <template #default="{row}">
        <user-ticket-card :user="row.locataire"></user-ticket-card>
      </template>
    </el-table-column >
    <el-table-column prop="billStatus" label="Status" sortable="custom" min-width="100" >
      <template #default="{row}">
        <el-tag
            :type="billStatus(row) === 'En attente' ? '' : 'success'"
            class="mx-1"
            effect="light"
            round
        >
          {{ billStatus(row) }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="numeroLocation" label="Transaction" sortable="custom" min-width="130" />
    <el-table-column prop="departUnix" label="Départ" sortable="custom" min-width="130">
      <template #default="{row}">
        <span v-html="$moment.unix(row.departUnix).format('DD/MM/YYYY')"></span>
      </template>
    </el-table-column>
    <el-table-column prop="finUnix" label="Retour" sortable="custom" min-width="130">
      <template #default="{row}">
        <span v-html="$moment.unix(row.finUnix).format('DD/MM/YYYY')"></span>
      </template>
    </el-table-column>
    <el-table-column prop="ownerRevenuHT" label="Total (HT)" :sortable="'custom'" min-width="120">
      <template #default="{row, column}">
        <span v-html="$currencyFormat.format(row.ownerRevenuHT)"></span>
      </template>
    </el-table-column>
    <el-table-column prop="ownerFeesHt" label="Frais (HT)" :sortable="'custom'" min-width="140">
      <template #default="{row, column}">
        <span v-html="$currencyFormat.format(row.ownerFeesHt)"></span>
      </template>
    </el-table-column>
<!--    <el-table-column prop="virement" label="Virement" sortable="custom" />-->
    <el-table-column fixed="right" width="30">
      <template #default="scope">
        <el-dropdown trigger="click">
          <div class="dropdown">
            <button class="dropdown-edit-toogle" style="padding: 5px;">
                <span class="icon-kit">
                  &#xe9a5
                </span>
            </button>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-if="billStatus(scope.row) === 'Facturé'">
                <a class="dropdown-item" :href="'/user/download-pdf-facture-locataire-location/' + scope.row.id">
                  Télécharger
                </a>
              </el-dropdown-item>
              <el-dropdown-item v-if="hasFeesOwnerInvoice(scope.row)">
                <a class="dropdown-item" target="_blank" :href="'/user/download-pdf-facture-frais-proprietaire-location/' + scope.row.id">
                  {{ $t('owner-rental.dropdown.invoice-fees') }}
                </a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a class="dropdown-item" target="_blank" :href="'/user/location/' + scope.row.id">
                  Voir Location
                </a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import UserTicketCard from "../../user/components/UserTicketCard.vue";

export default {
  name: "BillingsTable",
  components: {
    UserTicketCard
  },
  props: {
    defaultOrder: {
      type: Object,
      default () { return {}}
    },
    billings: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      defaultColumns: [
        {
          dataKey: 'numeroLocation',
          title: '#'
        }
      ]
    }
  },
  methods: {
    hasFeesOwnerInvoice (bill) {
      return (bill.facture && bill.facture.id) || (Array.isArray(bill.invoices) && bill.invoices.filter((invoice) => {
        return invoice.name === 'OwnerFees'
      }).length > 0)
    },
    billStatus (bill) {
      if (bill.billedAt === null) {
        return 'En attente'
      } else {
        return 'Facturé'
      }
    },
    sortChange (payload) {
    },
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    },
  }
}
</script>

<style lang="scss">
.el-table__row:nth-child(2n+1) {
  //--el-table-tr-bg-color: #0e84b5;
  .td.el-table__cell {
    //--el-table-tr-bg-color: #0e84b5;
  }
  /*background-color: #0e84b5;*/
}
.el-table__header-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--left, .el-table__header-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--right, .el-table__header-wrapper tr:nth-child(2n+1) , .el-table__header-wrapper tr:nth-child(2n+1), .el-table__body-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--left, .el-table__body-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--right, .el-table__body-wrapper tr:nth-child(2n+1) , .el-table__body-wrapper tr:nth-child(2n+1), .el-table__footer-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--left, .el-table__footer-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--right, .el-table__footer-wrapper tr:nth-child(2n+1) , .el-table__footer-wrapper tr:nth-child(2n+1) {
  //--el-table-tr-bg-color: #0e84b5;
  //background: var(--el-table-tr-bg-color);
}
</style>