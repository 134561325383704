<script>


import AdminAddressInputDebug from "~/modules/user/components/AdminAddressInputDebug.vue";

export default {
  name: "AdminUserAddressEdit",
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object
    }
  },
  components: {
    AdminAddressInputDebug
  },
  watch: {
    modelValue: {
      handler (val, oldVal) {
        console.log('modelValue', val);
        console.log('modelValue', oldVal);
        if (val) {
          this.edit = JSON.parse(JSON.stringify(val));
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    updateValue (value) {
      // this.$emit('update:modelValue', value)
    }
  },
  data () {
    return {
      edit: {
      }
    }
  }
}
</script>

<template>
<!--  <adminEditInfoSociete></adminEditInfoSociete>-->
  <el-form-item>
    <template #label>
      <div>
        <h4 class="label-title">Adresse facturation proprietaire</h4>
        <p class="label-info">Adresse avec laquelle l'utilisateur doit facturer ses clients.</p>
      </div>
    </template>
<!--    <el-card class="p-0 mrg-25" style="width: 100%;" >-->

<!--      <el-switch></el-switch>-->
      <AdminAddressInputDebug :modelValue="modelValue" defaultField="" @update:value="updateValue"></AdminAddressInputDebug>
<!--    </el-card>-->
  </el-form-item>
</template>

<style scoped>

</style>