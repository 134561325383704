<template>
  <div class="boutique-header">
    <h3 class="parameters-title">{{$t('shop.settings.title')}}</h3>
    <h4 class="parameters-subtitle">{{ $t('shop.settings.description') }}</h4>
  </div>
  <CustomTabs :tabs="tabs"></CustomTabs>
</template>

<style>
.parameters-title {
  font-size: 30px;
}
</style>

<script>
import CustomTabs from "../../../components/CustomTabs.vue";

export default {
  components: {
    CustomTabs
  },
  name: "SettingsTabs",
  data () {
    return {
      tabs: [
        {
          label: 'Disponibilité',
          to: { name: 'disponibilite'}
        },
        {
          label: 'Tarification',
          to: { name: 'tarification'}
        },
        {
          label: 'Services',
          to: { name: 'services'}
        },
        {
          label: 'Offres',
          to: { name: 'offres'}
        },
        {
          label: 'Info bancaires',
          to: { name: 'BankInfo'}
        },
        {
          label: 'Validation banque',
          to: { name: 'BankValidation'}
        },
        {
          label: "Conditions d'annulation",
          to: { name: 'CancelConditions'}
        }
      ]
    }
  }
}
</script>
