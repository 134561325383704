import {Model} from '@vuex-orm/core'

export default class Brand extends Model {
	static entity = 'brands'

	static apiConfig = {
		headers: { 'X-Requested-With': 'XMLHttpRequest' },
		baseURL: '/api',
		dataKey: 'brands'
	}

	static fields() {
		return {
			id: this.attr(null),
			nom: this.attr(''),
			pictureSrc: this.attr(null),
			description: this.attr(null),
			countModeles: this.attr(null),
			countAnnonces: this.attr(null),
			// pictureSrc: this.attr(null),
		}
	}
}
