<script>
import FormHandler from "~/mixins/formHandler.js";
import Inventory from "~/modules/ad/services/Inventory.js";
import AdUnavailability from "~/modules/ad/services/AdUnavalability.js";
import screenBehavior from "~/mixins/screenBehavior.js";

function toIsoString(date) {
  let tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function(num) {
        let norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };
  return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
}

function addDays(date, days) {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export default {
  name: "EditUnavailability",
  mixins: [FormHandler, screenBehavior],
  ApiUrl: 'user/indisponibilite-edit',
  props: {
    quantityMax: {
      type: Number,
      default: 0
    },
    unavailabilityQuantities: {
      type: [Object],
      default () {
        return []
      }
    },
    unavailability: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      range: [],
      form: {
      },
      edit: {
        unavailability: {
          startAt: null,
          endAt: null
        },
        status: 1
      }
    }
  },
  watch: {
    range: {
      handler (val) {
        if (val) {
          if (Array.isArray(val) && val.length === 2) {
            // if (!val.id) {
              this.edit.unavailableRange.startAt = toIsoString(new Date(val[0]));
              this.edit.unavailableRange.endAt = toIsoString(new Date(val[1]));
              // this.edit.unavailableRange.startAt = this.$moment(val[0]).format('YYYY-MM-DD')
              // this.edit.unavailableRange.endAt = this.$moment(val[1]).format('YYYY-MM-DD')
              // this.content.unavailableRange = JSON.parse(JSON.stringify(this.edit.unavailableRange))
            // } else {
              // if (!this.content.unavailableRange) {
              //   this.content.unavailableRange = {
              //     startAt: null,
              //     endAt: null
              //   }
              // }
              // this.content.unavailableRange.startAt = new Date(val[0]).toISOString()
              // this.content.unavailableRange.endAt = new Date(val[1]).toISOString()
              // this.content.unavailableRange = JSON.parse(JSON.stringify(this.content.unavailableRange))
          }
        }
      },
      immediate: true,
      deep: true
    },
    unavailability: {
      handler (val) {
        if (val) {
          // this.edit = val
          // if (!val.id) {
          this.edit = JSON.parse(JSON.stringify(val))
          this.content = JSON.parse(JSON.stringify(val))
          // } else {
          //   this.content = JSON.parse(JSON.stringify(val))
            if (val.unavailableRange && val.unavailableRange.startAt && val.unavailableRange.endAt) {
              this.range = [new Date(val.unavailableRange.startAt), new Date(val.unavailableRange.endAt)]
            } else if (val.unavailableRange) {
              this.range = []
            }
        }
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    filterRangeUnavailability () {
      if (this.edit.unavailableRange && this.edit.unavailableRange.startAt && this.edit.unavailableRange.endAt) {
        return Object.keys(this.unavailabilityQuantities).filter((item) => {
          return new Date(this.edit.unavailableRange.startAt) <= new Date(item) && new Date(item) <= new Date(this.edit.unavailableRange.endAt)
        }).map((item) => {
          let newQuantity = this.unavailabilityQuantities[item] && typeof this.unavailabilityQuantities[item][this.ad_id] === 'number' ? this.unavailabilityQuantities[item][this.ad_id] : this.quantityMax
          if (this.unavailabilityQuantities[item] && typeof this.unavailabilityQuantities[item][this.ad_id] === 'number' && new Date(this.content.unavailableRange.startAt) <= new Date(item) && new Date(item) <= new Date(this.content.unavailableRange.endAt) && this.content.id) {
            newQuantity += this.content.quantity
          }
          return {
            date: item,
            // quantity: newQuantity,
            minQuantity: newQuantity,
            productsQuantity: this.unavailabilityQuantities[item]
          }
          // return typeof this.unavailabilityQuantities[item][this.ad_id] === 'number' ? this.unavailabilityQuantities[item][this.ad_id] : this.quantityMax
        })
      } else {
        return []
      }
    },
    quantityMaxFormatted () {
      if (this.filterRangeUnavailability && this.filterRangeUnavailability.length === 0) {
        return this.quantityMax
      } else {
        const quantityLeft = this.filterRangeUnavailability.reduce((acc, item) => {
          if (acc > item.minQuantity) {
            return item.minQuantity
          }
          return acc
        }, this.quantityMax)
        return quantityLeft < 0 ? 0 : quantityLeft
      }
    },
    ad_id () {
      return this.$route.params.id
    },
    id () {
      return false
    },
    formKey () {
      return 'AdUnavailableRange'
    },
    Model () {
      return AdUnavailability
    },
    statusOptions () {
      return [
        // {
        //   value: 1,
        //   label: 'En stock',
        // },
        {
          value: 2,
          label: 'Sortie',
        },
        {
          value: 3,
          label: 'Maintenance',
        }
      ]
    },
    saveLabel () {
      return this.edit.id ? 'Modifier' : 'Ajouter'
    },
    disabledInputs () {
      return this.quantityMaxFormatted <= 0
    },
    rangeError () {
      return this.disabledInputs ? 'Vous ne pouvez pas ajouter cette plage d\'indisponibilité car vous n\'avez plus de produit disponible' : false
    },
    buttonClass () {
      return this.hasUpdate && !this.disabledInputs ? '' : 'disabled'
    }
  },
  methods: {
    getQuantityAvailable (quantity) {
      if (!this.edit.id) {
        return quantity
      } else {
        return quantity + this.edit.quantity
      }
    },
    disabledDate (time) {
      let now = new Date()
      let yesterday = addDays(now,-1)
      let beforeYesterday = time < yesterday
      let unavailableDate = false
      let dateKey = this.$moment(time).format('YYYY-MM-DD')
      if (this.unavailabilityQuantities && this.unavailabilityQuantities[dateKey] && this.unavailabilityQuantities[dateKey][this.ad_id] <= 0) {
        unavailableDate = true
      }
      if (this.content && this.content.unavailableRange && this.content.unavailableRange.startAt && this.content.unavailableRange.endAt) {
        let startAt = new Date(this.content.unavailableRange.startAt)
        let endAt = new Date(this.content.unavailableRange.endAt)
        if (startAt <= time && time <= endAt) {
          unavailableDate = false
        }
      }
      return beforeYesterday || unavailableDate
    },
    transformData (data) {
      if (data.unavailableRange) {
        data['unavailable-range'] = data.unavailableRange
      }
      return data
    },
    afterFormSend (response) {
      this.$emit('afterSubmit', response)
    }
  }
}
</script>

<template>
  <div class="p-0" style="margin-bottom:20px">
    <div>
      <div class="product-creation-form-header">
        <h3 class="subTitle">Plage d'indisponibilité</h3>
        <p class="label-info">Configurez une plage d'indisponibilité pour que votre matériel ne soit plus disponible pendant cet interval de temps.</p>
      </div>

      <el-form :model="form" label-width="300px" :label-position="formAlignement" size="large" class="custom_form_lightyshare">
        <el-form-item label="Status" :error="rangeError">
          <template #label>
            <div>
              <h4 class="label-title">Interval d'indisponibilité</h4>
              <p class="label-info">Veuillez choisir une plage de date où votre produit ne sera plus disponible</p>
            </div>
          </template>
          <el-date-picker
              v-model="range"
              type="daterange"
              range-separator="à"
              start-placeholder="Date début"
              end-placeholder="Date fin"
              :disabled-date="disabledDate"
          />
        </el-form-item>
        <el-form-item label="Status" :error="formErrors && formErrors.quantity">
          <template #label>
            <div>
              <h4 class="label-title">Quantité</h4>
              <p class="label-info">Veuillez choisir une de ce produit où il ne sera plus disponible</p>
            </div>
          </template>
          <el-input v-model="edit.quantity" type="number" style="max-with:40px" :disabled="disabledInputs">
            <template #suffix>
              / {{ quantityMaxFormatted }}
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="Status">
          <template #label>
            <div>
              <h4 class="label-title">Etat de la référence</h4>
              <p class="label-info">Etat du produit dans votre inventaire. Si le produit est archivé il n'apparaitra plus dans votre boutique</p>
            </div>
          </template>
          <el-select v-model="edit.status" placeholder="Select" :disabled="disabledInputs">
            <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </el-form-item>
        <button class="btn btn-short btn-primary mt-4" :class="buttonClass" id="save-button-section-pricing" @click.prevent="sendForm">
          {{ saveLabel }}
        </button>
      </el-form>
    </div>
  </div>
</template>

<style scoped>

</style>