<template>
    <div class="contrat-de-location">
<!--        <h3 class="multisteps-form__title">Mettre à jour la location</h3>-->
        <!--<div class="demo-date-picker">
            <div class="block">
            <el-date-picker
                v-model="dates"
                type="daterange"
                :selected="dateFin"
                range-separator="To"
                :disabled-date="disabledDate"
                start-placeholder="Start date"
                end-placeholder="End date"
                :size="size"
            />
            </div>
        </div>
        <div v-if="errorMessage" id="error" class="alert alert-danger" role="alert" style="margin-top: 10px; width: fit-content;">{{errorMessage}}</div>
        <div class="button">
            <a href="#" type="submit" class="btn btn-outline-primary" id="btnCancel">Annuler</a>
            <a href="#" type="submit" class="btn btn-outline-primary" id="btnUpdate">
                Confirmer
            </a>
        </div> -->
        <div>
            <div class="demo-date-picker">
                <div class="block">
                    <el-date-picker
                        v-model="startDate"
                        type="date"
                        :disabled="true"
                        placeholder="Start date"
                        :size="size"
                        style="width:100%"
                    />
                </div>
            </div>
            <br>
            <div class="demo-date-picker">
                <div class="block">
                    <el-date-picker
                        v-model="endDate"
                        type="date"
                        :disabled-date="disabledDate"
                        :selected="dateFinPlusOneDay"
                        placeholder="End date"
                        :size="size"
                        style="width:100%"
                    />
                </div>
            </div>
            <div v-if="locationUpdated !== null && endDate">
                <div v-if="errorMessage" id="error" class="alert alert-danger" role="alert" style="margin-top: 10px; width: fit-content;">{{errorMessage}}</div>
            </div>
        </div>
    </div>
</template>

<script>

import { ref } from 'vue'
import { ajaxUpdateLocation } from '../../services/location.js';

export default {
    name: 'LocationBoxUpdate',
    emits: ["dlUpdated", "overlay", "showRentalDate"],
    props: {
        modify: {
          type: Boolean,
          default: false
        },
        auth : {
            type: Object
        },
        termined : {
            type: Boolean
        },
        DL : {
            type: Object
        },
    },
    data() {
        return {
            startDate: ref(''),
            endDate: ref(''),
            // dates: ref(''),
            size: 'large',
            locationUpdated: null
        }
    },
    watch: {
        endDate: {
            handler(newEndDate, oldEndDate) {
                if(oldEndDate && newEndDate !== oldEndDate) {
                    this.ajaxUpdateLocation(newEndDate)
                }
            },
            deep: true,
        }
        // dates: {
        //     handler(newDates, oldDates) {
        //         if(newDates[0] !== newDates[1]) {
        //             this.checkUpdateLocation(newDates)
        //         }
        //     },
        //     deep: true,
        // }
    },
    created() {
        this.startDate = ref(this.dateDebut);
        this.endDate = ref(this.dateFin);
        // this.dates = ref([this.dateFin, this.dateFin]);
    },
    computed: {
        dateDebut() {
            return this.DL.dateDebut
        },
        dateFin() {
            return this.DL.datefin
        },
        dateFinPlusOneDay() {
            return this.$moment(this.dateDebut).add(1, 'days')
        },
        errorMessage() {
            return this.locationUpdated && this.locationUpdated.error ? this.locationUpdated.error : null
        }
    },
    methods : {
        disabledDate(time) {
            return time.getTime() < this.$moment(this.dateFinPlusOneDay).valueOf()
        },
        // async checkUpdateLocation(dates) {
        //     var dateFinFormat = this.$moment(dates[1]).format('YYYY-MM-DD')
        //     this.$emit('overlay', true);
        //     this.locationUpdated = await ajaxUpdateLocation(this.DL.id, this.dateDebut, dateFinFormat)
        //     console.log(this.locationUpdated)
        //     this.$emit('dlUpdated', this.locationUpdated);
        //     this.$emit('overlay', false);
        // }
        async ajaxUpdateLocation(dateFin) {
            var dateFinFormat = this.$moment(dateFin).format('YYYY-MM-DD')
            this.$emit('overlay', true);
            this.locationUpdated = await ajaxUpdateLocation(this.DL.id, this.dateDebut, dateFinFormat)
            this.locationUpdated.error == null ? this.$emit('showRentalDate', false) : false;
            this.$emit('dlUpdated', this.locationUpdated);
            this.$emit('overlay', false);
        },
    }
}
</script>
