<template>
  <el-table  :data="equipements" style="width: 100%;" :table-layout="'auto'" stripe>
    <el-table-column prop="name" label="Référence" align="left" :min-width="400">
      <template #default="scope">
        <el-input v-model="scope.row.name"/>
      </template>
    </el-table-column>
    <el-table-column prop="quantity" label="Quantité" :min-width="20">
      <template #default="scope">
        <el-select v-model="scope.row.status" style="width:100%">
          <el-option
              v-for="type in statusType"
              :key="type.value"
              :label="type.label"
              :value="type.value"
          />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column prop="quantity" label="" width="230" align="right" fixed="right">
      <template #default="scope">
        <el-button v-if="scope.row.isNew" @click="pushItem(scope.row)" >Ajouter</el-button>
        <el-button v-if="!scope.row.isNew" style="padding: 5px;" @click="removeItem(scope.$index)">
          <span class="icon-kit">
            &#xe9f1
          </span>
        </el-button>

      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  name: "StockReferencesTable",
  props: {
    reference: {
      type: String,
      default: ''
    },
    details: Array,
    modelValue: {
      type: [Array]
    },
  },
  data () {
    return {
      newDetails: []
    }
  },
  methods: {
    removeItem (index) {
      const items = [...this.modelValue]
      // Remove item from modelValue with index
      const newValue = items.splice(index, 1)

      this.$emit('update:modelValue', items)
    },
    pushItem (row) {
      const items = [...this.modelValue]
      items.push({
        name: row.name,
        status: row.status
      })
      this.$emit('update:modelValue', items);
    }
  },
  computed: {
    statusType () {
      return [
        {
          label: 'Neuf',
          value: 1
        },
        {
          label: 'Bon état',
          value: 2
        },
        {
          label: "Etat d'usage",
          value: 3
        },
        {
          label: "Abimé",
          value: 4
        },
        {
          label: "Non fonctionnel",
          value: 5
        }
      ]
    },
    componentData () {
      return {
        data: this.equipements,
        stripe: true,
        style: "width: 100%;",
        'table-layout': "auto"
      }
    },
    equipements () {
      const generatedRef = this.reference + '-' + (this.modelValue.length + 1)
      let eq = [
        ...this.modelValue,
        {
          name: generatedRef,
          status: 1,
          isNew: true
        }
      ]
      console.log(eq)
      return eq
    }
  }
}
</script>

<style scoped>

</style>
