<template>
    <div class="panel">
        <h4 :class="prixNormalNbJoursS">
            {{ prixNormalNbJours }}€ x {{ nbJoursUtilisation }} jour(s)
        </h4>
        <h5 :class="prixNormalS">{{ prixNormal }} €</h5>
    </div>
    <div v-if="degress > 0" class="panel" :class="showDegressS">
        <h4>{{$t('box.price.degress')}} <a href="#" data-toggle="tooltip" title="Hooray!"><img :src="asset('img/svg/tooltip-icon.svg')" alt=""></a>
        </h4>
        <h5 class="disc">- {{ degress }} €</h5>
    </div>
    <div v-if="isTenant" class="panel">
        <h4>{{$t('box.price.priceHT')}}</h4>
        <h5>{{ prixLocationHT }} €</h5>
    </div>
    <div class="panel">
        <h4>Services</h4>
        <h5 class="services-location-price">{{ (priceAllServiceItem).toFixed(2) }} €</h5>
    </div>
  <div v-if="isTenant && assuranceHt" class="panel">
    <h4>Assurance
    </h4>
    <h5>{{ assuranceHt }} €</h5>
  </div>
    <div v-if="isTenant" class="panel">
        <h4>{{$t('box.price.service')}} (5%) <a href="#" data-toggle="tooltip" title="Hooray!"><img :src="asset('img/svg/tooltip-icon.svg')" alt=""></a>
        </h4>
        <h5>{{ fraisServiceHT }} €</h5>
    </div>
    <hr/>
    <div class="panel total-price-panel mb-0" style="border-top:none;">
        <h4>{{$t('box.price.totalHT')}}</h4>
        <h5 :class="prixHTS">{{ totalHT }} €</h5>
    </div>
    <div class="panel total-price-panel mb-0" style="border-top:none;">
        <h4 style="font-weight:600;">{{$t('box.price.tva')}}</h4>
        <h5 v-if="isTenant">{{ (totalTTC - totalHT).toFixed(2) }} €</h5>
        <h4 v-if="isOwner" :class="tvaS">{{ ownerTva.toFixed(2) }} €</h4>
    </div>
    <div class="panel total-price-panel mb-0" style="border-top:none;">
        <h4>{{$t('box.price.totalTTC')}}</h4>
        <h5 v-if="isTenant">{{ totalTTC }} €</h5>
        <h5 v-if="isOwner" :class="prixTTCS">{{ ownerTotalTtc.toFixed(2) }} €</h5>
    </div>
    <div v-if="stepIsLocationUpdating && isTenant && nothingLeftToPay">
        <div class="panel total-price-panel mb-0" style="border-top:none;">
            <h4>Montant payé</h4>
            <h5>{{ (Rental.amountPaid).toFixed(2) }} €</h5>
        </div>
        <div class="panel total-price-panel mb-0" style="border-top:none;">
            <h4>Reste à payer</h4>
            <h5>{{ (Rental.leftToPay).toFixed(2) }} €</h5>
        </div>
    </div>
</template>

<script>

import Rental from "~/modules/ask/services/Rental.js";

export default {
    name: 'LocationBoxPrice',
    props: {
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
        screen : {
            type: String
        },
        termined : {
            type: Boolean
        }
    },
    computed: {
      id () {
        return this.$symfony && this.$symfony.demandelocation ? this.$symfony.demandelocation.id : this.$route && this.$route.params && this.$route.params.id ? this.$route.params.id : 0
      },
      Rental () {
        return Rental.find(this.id)
      },
      ownerTva () {
        return this.Rental && this.Rental.ownerRevenuTTC && this.Rental.ownerRevenuHT ? this.Rental.ownerRevenuTTC - this.Rental.ownerRevenuHT : 0
      },
      ownerTotalTtc () {
        return this.Rental && this.Rental.ownerRevenuTTC ? this.Rental.ownerRevenuTTC : 0
      },
      // demandeLocation () {
      //   return
      // },
      assurance () {
        return this.$symfony.assurance
      },
      locataire () {
          return this.DL.locataire
      },
      proprietaire () {
          return this.DL.proprietaire
      },
      isTenant() {
          return this.auth.id == this.locataire.id ? true : false
      },
      isOwner() {
          return this.auth.id == this.proprietaire.id ? true : false
      },
      stepOne () {
          return this.DL.AcceptedAt === null && this.DL.PaidAt === null && this.termined === false ? true : false
      },
      stepIsLocationUpdating () {
          return this.DL.updateStatusLocation == 1 || this.DL.updateStatusLocation == 2 ? true : false
      },
      prixNormalNbJoursS() {
          return "prixNormalNbrJours" + this.screen
      },
      prixNormalS() {
          return "prixNormal" + this.screen
      },
      showDegressS() {
          return "showDegress" + this.screen
      },
      prixHTS() {
          return "prixHT" + this.screen
      },
      prixTTCS() {
          return "prixTTC" + this.screen
      },
      tvaS() {
          return "tva" + this.screen
      },
      panier() {
          return this.Rental.panier
      },
      prixNormalNbJours() {
          return (this.prixNormal / this.nbJoursUtilisation).toFixed(2)
      },
      degress() {
          return (this.prixNormal - this.prixLocationHT).toFixed(2)
      },
      nbJoursUtilisation() {
          return this.panier.nbr_jour_utilisation
      },
      assuranceHt () {
        return this.Rental.assurance_ht
      },
      prixNormal() {
          return this.panier.prixNormal
      },
      priceAllServiceItem() {
          return this.panier && this.panier.priceAllServiceItem ? parseFloat(this.panier.priceAllServiceItem) : 0
      },
      totalHT() {
          if(this.isTenant) {
              return this.panier.prixTotalHt
          } else if(this.isOwner) {
              return this.priceAllServiceItem > 0 && this.Rental.ownerRevenuHT ? (this.Rental.ownerRevenuHT).toFixed(2) : this.prixLocationHT
          }
      },
      totalTTC() {
          if(this.isTenant) {
              return this.panier.prixTotalTtc
          } else if(this.isOwner){
              return this.priceAllServiceItem > 0 && this.Rental.ownerRevenuTTC ? (this.Rental.ownerRevenuTTC).toFixed(2) : this.prixLocationTTC
          }
      },
      prixLocationHT() {
          return this.panier.prixLocationHt
      },
      prixLocationTTC() {
          return this.Rental.prixLocationTtc
      },
      fraisServiceHT() {
          return this.Rental.frais_service_ht
      },
      nothingLeftToPay() {
          return this.Rental.leftToPay === 0 ? true : false
      }
    },
}

</script>