import {Model} from '@vuex-orm/core'
import User from "~/modules/user/services/User.js";

export default class DemandeAjoutModele extends Model {
	static entity = 'demande-ajout-modele'

	static apiConfig = {
		headers: { 'X-Requested-With': 'XMLHttpRequest' },
		baseURL: '/api',
		dataKey: 'asks-models'
	}

	static fields() {
		return {
			id: this.attr(null),
			user_id: this.attr(null),
			user: this.belongsTo(User, 'user_id'),
			modele: this.attr(''),
			marque: this.attr(''),
			categorie: this.attr({}),
			sousCategorie: this.attr({}),
			createdUnix: this.attr(0),
		}
	}
}
