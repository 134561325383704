<template>
  <el-table  :data="equipements" style="width: 100%;" :table-layout="'auto'" stripe>
    <el-table-column prop="nomEquipement" label="Equipement" align="left" :min-width="400">
      <template #default="scope">
        <ad-ticket v-if="scope.row.migrationAd" :Ad="scope.row.migrationAd"></ad-ticket>
        <el-input v-else v-model="scope.row.nomEquipement" @input="updateItem(scope)"/>
      </template>
    </el-table-column>
    <el-table-column prop="quantity" label="Quantité" :min-width="20">
      <template #default="scope">
        <el-input-number v-model="scope.row.quantity"/>
      </template>
    </el-table-column>
    <el-table-column v-if="migrate" align="right">
      <template #default="scope">
        <el-button @click="this.$emit('migrate', scope.row)">Lier</el-button>
      </template>
    </el-table-column>
    <el-table-column v-if="migrate" label="" width="60" align="right" fixed="right">
      <template #default="scope">
        <el-button class="dropdown-edit-toogle" @click="removeItem(scope.$index)">
          <span class="icon-kit">
            &#xe9f1
          </span>
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import draggable from 'vuedraggable'
import Ads from "~/modules/ad/services/Ads.js";
import AdTicket from "~/modules/ad/components/AdTicket.vue";

export default {
  emits: ['migrate'],
  components: {
    AdTicket,
    draggable
  },
  name: "PackItemsTable",
  props: {
    allowToAdd: {
      type: Boolean,
      default: true
    },
    migrate: {
      type: Boolean,
      default: false
    },
    annonceId: Number,
    details: Array,
    modelValue: {
      type: [Array]
    },
  },
  data () {
    return {
      newDetails: []
    }
  },
  watch: {
    modelValue: {
      handler (val) {
        this.edit = JSON.parse(JSON.stringify(val))
      },
      immediate: true,
      deep: true
    },
    edit: {
      handler (val) {
        let adsToLoad = val.filter(item => item.migrationAdId && Ads.query().find(item.migrationAdId) === null)
        this.loadAds(adsToLoad)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    loadAds (items) {
      // foreach ads
      items.forEach((item, index) => {
        Ads.api().get('user/annonce-edit/' + item.migrationAdId)
      })
    },
    updateItem (scope) {
      if (this.allowToAdd) {
        if (scope.row.isNew && scope.row.nomEquipement && scope.row.nomEquipement.length) {
          this.pushItem(scope.row)
        }
        if (!scope.row.nomEquipement.length) {
          this.removeItem(scope.$index)
        }
      }
    },
    removeItem (index) {
      const items = [...this.modelValue]
      // Remove item from modelValue with index
      const newValue = items.splice(index, 1)
      this.$emit('update:modelValue', items)
    },
    pushItem (row) {
      const items = [...this.modelValue]
      items.push({
        // annonce_id: this.annonceId,
        nomEquipement: row.nomEquipement,
        quantity: row.quantity
      })
      // this.modelValue.push({
      //   nomEquipement: row.nomEquipement,
      //   quantity: row.quantity,
      //   annonce_id: this.annonceId,
      // })
      this.$emit('update:modelValue', items);
    }
  },
  computed: {
    componentData () {
      return {
        data: this.equipements,
        stripe: true,
        style: "width: 100%;",
        'table-layout': "auto"
      }
    },
    equipements () {
      this.modelValue.map((eq) => {
        if (eq.migrationAdId) {
          eq['migrationAd'] = Ads.query().find(eq.migrationAdId)
        }
        return {
          ...eq,
          isNew: false
        }
      })
      if (!this.allowToAdd) {
        return [...this.modelValue]
      }
      let eq = [
        ...this.modelValue,
        {
          nomEquipement: '',
          quantity: 1,
          isNew: true
        }
      ]
      return eq
    }
  }
}
</script>

<style scoped>

</style>
