<template>
  <el-dropdown trigger="click">
    <div class="dropdown">
      <button class="dropdown-toogle" style="padding: 5px;">
        <i class="fas fa-ellipsis-h">
        </i>
      </button>
    </div>
<!--    <template #dropdown>-->
<!--      <el-dropdown-menu>-->
<!--        <el-dropdown-item v-for="items in item">-->
<!--          <a class="dropdown-item" :href="'/user/telechargement-facture-mensuelle/' + scope.row.id">-->
<!--            Télécharger-->
<!--          </a>-->
<!--        </el-dropdown-item>-->
<!--      </el-dropdown-menu>-->
<!--    </template>-->
  </el-dropdown>
</template>

<script>
export default {
  name: "ButtonActions",
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>