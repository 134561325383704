<script>
import formHandler from "~/mixins/formHandler.js";
import screenBehavior from "~/mixins/screenBehavior.js";
import User from "../services/User.js";
import AdminUserAddressEdit from "~/modules/user/components/AdminUserAddressEdit.vue";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import AdminEditInfoSociete from "~/modules/user/components/AdminEditInfoSociete.vue";
import InputCity from "~/components/parameters/inputCity.vue";
import CompanyEdit from "~/modules/user/components/CompanyEdit.vue";

export default {
  name: "AdminUserDetails",
  components: {
    CompanyEdit,
    InputCity,
    AdminEditInfoSociete,
    AdminUserAddressEdit,
    VueTelInput
  },
  mixins: [formHandler, screenBehavior],
  ApiUrl: 'admin/user-details',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  computed: {
    allowNull () {
      return true
    },
    lightyShareCertifed: {
      get () {
        return Boolean(this.edit.lightyShareCertifed)
      },
      set (val) {
        this.edit.lightyShareCertifed = val ? 1 : 0
      }
    },
    telephoneCertifed: {
      get () {
        return Boolean(this.edit.telephoneCertifed)
      },
      set (val) {
        this.edit.telephoneCertifed = val ? 1 : 0
      }
    },
    idCertifed: {
      get () {
        return Boolean(this.edit.idCertifed)
      },
      set (val) {
        this.edit.idCertifed = val ? 1 : 0
      }
    },
    flag: {
      get () {
        return Boolean(this.edit.flag)
      },
      set (val) {
        this.edit.flag = val ? 1 : 0
      }
    },
    formKey () {
      return 'User'
    },
    saveButtonText () {
      return this.id ? 'Sauvegarder' : 'Ajouter'
    },
    Model () {
      return User
    },
  },
  methods: {
    saveTelephoneCountry ({iso2}) {
      this.edit.telephone.pays = iso2
    },
    transformHook (edit) {
      edit = JSON.parse(JSON.stringify(edit))
      edit.dateNaissance = edit.dateNaissanceString.replaceAll('/', '-')
      edit.lightyShareCertifed = edit.lightyShareCertifed ? 1 : 0
      edit.telephoneCertifed = edit.telephoneCertifed ? 1 : 0
      edit.idCertifed = edit.idCertifed ? 1 : 0
      edit.flag = edit.flag ? 1 : 0
      if (edit.infoSociete) {
        edit.infoSociete.hasVat = edit.infoSociete.hasVat ? 1 : 0
        // edit.infoSociete.validVAT = edit.infoSociete.validVAT ? 1 : 0
      }
      delete edit.dateNaissanceString
      delete edit.defaultAdresse
      delete edit.currentAdresse
      delete edit.adresseForFacturation
      return edit
    }
  },
  data () {
    return {
      inputTelephoneOptions: {
        placeholder: this.$t('signup.phone.placeholder')
      },
      form: {},
      edit: {},
      user: {},
      ads: [],
      loading: true,
      error: false,
      errorMessage: '',
      statutUserOptions: [
        // {value: 0, label: this.$t('signup.step-1.statut.0')},
        {value: 2, label: this.$t('signup.step-1.statut.2')},
        {value: 4, label: this.$t('signup.step-1.statut.4')},
        {value: 5, label: this.$t('signup.step-1.statut.5')},
        {value: 3, label: this.$t('signup.step-1.statut.3')},
        {value: 1, label: this.$t('signup.step-1.statut.1')},
      ]
    }
  }
}
</script>

<template>
  <el-form v-loading="isLoading" :model="form" label-width="300px" :label-position="formAlignement" size="large" class="custom_form_lightyshare" >

    <div class="product-creation-form-header">
      <h3>Compte</h3>
    </div>
    <el-form-item label="Statut :" prop="status">
      <template #label>
        <div>
          <h4 class="label-title">Statut :</h4>
        </div>
      </template>
      <el-select v-model="edit.statutUser" :placeholder="$t('signup.step-1.statut.0')" class="full-width-input" required>
        <el-option v-for="item in statutUserOptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="Nom" prop="nom">
      <template #label>
        <div>
          <h4 class="label-title">Nom</h4>
        </div>
      </template>
      <el-input v-model="edit.nom" ></el-input>
    </el-form-item>
    <el-form-item label="Prénom" prop="prenom">
      <template #label>
        <div>
          <h4 class="label-title">Prénom</h4>
        </div>
      </template>
      <el-input v-model="edit.prenom" ></el-input>
    </el-form-item>
    <el-form-item label="Prénom" prop="prenom">
      <template #label>
        <div>
          <h4 class="label-title">E-mail</h4>
        </div>
      </template>
      <el-input v-model="edit.email" ></el-input>
    </el-form-item>
    <el-form-item label="Prénom" prop="prenom">
      <template #label>
        <div>
          <h4 class="label-title">Date de naissance</h4>
        </div>
      </template>
      <el-input v-model="edit.dateNaissanceString" :placeholder="$t('signup.step-2.birthday-placeholder')" class="full-width-input" required />
    </el-form-item>
    <hr>
    <div class="product-creation-form-header">
      <h3>Certification</h3>
    </div>
    <el-form-item label="Identité" prop="prenom">
      <template #label>
        <div>
          <h4 class="label-title">Lightyshare</h4>
          <p class="label-info">Toutes les vérifications lightyshare effectuées</p>
        </div>
      </template>
      <div style="width: 100%;">
        <el-switch v-model="lightyShareCertifed"></el-switch>
      </div>
    </el-form-item>
    <el-form-item label="telephone" prop="prenom">
      <template #label>
        <div>
          <h4 class="label-title">Téléphone</h4>
          <p class="label-info">Toutes les vérifications lightyshare effectuées</p>
        </div>
      </template>
      <div style="width: 100%;">
        <el-switch v-model="telephoneCertifed"></el-switch>
      </div>
      <div style="width: 100%;" v-if="edit.telephone">
        <el-switch v-model="edit.telephone.certifie"></el-switch>
      </div>
      <VueTelInput v-if="edit.telephone" v-model="edit.telephone.telephone" :inputOptions="inputTelephoneOptions" mode="international" :default-country="'FR'" @country-changed="saveTelephoneCountry"></VueTelInput>
    </el-form-item>
    <el-form-item label="Identité" prop="prenom">
      <template #label>
        <div>
          <h4 class="label-title">Identité</h4>
          <p class="label-info">Vérification d'identité avec ubble.</p>
        </div>
      </template>
      <div style="width: 100%;">
        <el-switch v-model="edit.idCertifed"></el-switch>
      </div>
      <el-input
          v-model="edit.ubbleAttemptsLeft "
          placeholder="Please input"
      >
        <template #prepend>Tentative restantes</template>
      </el-input>
    </el-form-item>
    <hr>
    <div class="product-creation-form-header">
      <h3>Informations</h3>
    </div>
    <AdminUserAddressEdit v-model="edit"></AdminUserAddressEdit>
    <CompanyEdit v-if="edit.infoSociete" v-model="edit.infoSociete"/>
    <el-form-item label="Siège social" prop="prenom">
      <template #label>
        <div>
          <h4 class="label-title">Siège social</h4>
          <p class="label-info">Siège social de la société</p>
        </div>
      </template>
      <input-city
          :select-country="true"
          :labels="true"
          label="Adresse"
          :limit="10"
          ref="inputCityBilling"
          :manualMode="true"
          :remove-manual-mode-btn="true"
          name="siege"
          style="width: 100%;"
          v-model="edit.adresseSiege"
          size="large"
          class="city-input"
          placeholder="24 rue mouftard 75008 Paris"
      />
    </el-form-item>
    <hr>
    <div class="product-creation-form-header">
      <h3>Modération</h3>
    </div>
    <el-form-item label="Identité" prop="prenom">
      <template #label>
        <div>
          <h4 class="label-title">Signaler</h4>
          <p class="label-info">Utilisateur signalé</p>
        </div>
      </template>
      <div style="width: 100%;">
        <el-switch v-model="flag"></el-switch>
      </div>
    </el-form-item>
    <el-button :loading="formLoading" plain type="info" size="large" class="btn btn-save" :disabled="!this.hasUpdate" @click.prevent="sendForm">{{ saveButtonText }}</el-button>
  </el-form>

</template>

<style scoped>

</style>