<template>
  <div class="user_card">
    <div class="img" v-if="loading" >
      <div class="avatar avatar-sm-32 contentLoader"></div>
    </div>
    <div class="img" v-else>
      <img :src="thumbnail" alt="">
<!--      <div v-else :class="'avatar avatar-sm-32 ' + randomColorClass">-->
<!--        <div class="initials">{{ getInitial }}</div>-->
<!--      </div>-->
    </div>
    <div v-if="loading" class="text">
      <span class="texth4LoadingContent"></span>
      <!--    <span class="texth3LoadingContent"></span>-->
    </div>
    <div v-else class="text no-break-word">
      <h4 :style="titleStyle">{{titre}}</h4>
      <h5>{{subCategory}}</h5>
    </div>
  </div>
</template>


<script>
export default {
  name: "AdTicket",
  props: {
    maxWidthTitle: {
      type: String,
      default: ''
    },
    bundleItem: {
      type: Boolean,
      default: false
    },
    Ad: {
      type: Object,
      default () {
        return {}
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    titleField: {
      type: String,
      default: ''
    },
  },
  computed: {
    subCategory () {
      if (this.Ad.modele && this.Ad.modele.sousCategorie) {
        return this.Ad.modele.sousCategorie.nom
      }
      if (this.Ad.souscategorieannonce) {
        return this.Ad.souscategorieannonce.nom
      }
      if (this.Ad.modele && this.Ad.modele.categorie) {
        return this.Ad.modele.categorie.nom
      }
      return ''
    },
    titre () {
      if (this.titleField.length) {
        return this.Ad[this.titleField]
      }
      if (this.Ad.title) {
        return this.Ad.title
      }
      if (this.bundleItem) {
        return this.Ad.modele.nom
      } else {
        return this.Ad.titre
      }
    },
    ownerId () {
      return this.Ad.user_id
    },
    thumbnail () {
      const ad = this.Ad
      if (this.Ad.cover && this.Ad.cover.path && this.Ad.cover.path.length) {
        if (this.Ad.cover.path.includes('/display-media')) {
          return this.Ad.cover.path + '?w=32&h=32&type=fit'
        }
        return '/display-media' + this.Ad.cover.path + '?w=32&h=32&type=fit'
      }
      if (this.Ad.medias && Array.isArray(this.Ad.medias)) {
        const validMedias = this.Ad.medias.filter((media) => {
          return media.valide
        })
        if (validMedias.length) {
          if (validMedias[0].path.includes('/display-media')) {
            return validMedias[0].path + '?w=32&h=32&type=fit'
          }
          return '/display-media' + validMedias[0].path + '?w=32&h=32&type=fit'
        }
      }
      return Array.isArray(ad.imagesannonce) && ad.imagesannonce.length && ad.imagesannonce[0].imageName ? `/display-media/images-upload/${this.ownerId}/annonces/${ad.id}/${ad.imagesannonce[0].imageName}?w=32&h=32&type=fit` : '/img/sans-photo.jpg'
    },
    titleStyle () {
      if (this.maxWidthTitle.length) {
        return {
          'max-width': this.maxWidthTitle
        }
      }
      return {}
    }
  }
}
</script>

<style scoped>

</style>