<template>
  <div class="contrat-de-location">
    <h3 class="multisteps-form__title">{{ $t('infos.actions.label') }}</h3>
    <p>
      {{ $t('infos.actions.subtitle') }}
    </p>
    <div class="button">
      <a v-if="locationPaid" :href="path('download_pdf_contrat_location', {'id' : DL.id})" class="btn btn-primary">
        {{ $t('infos.actions.contract') }}
      </a>

      <a v-if="hasModifyButton && showCancelBtn" class="btn btn-outline-primary"
         @click.prevent="toogleUpdate" href="#">
        {{ modifyButton }}
      </a>

      <a v-if="isOwner && locationPaid" class="btn btn-outline-primary" :href="sinistreHref">
        {{ $t('infos.actions.sinistre') }}
      </a>

      <a v-if="isTenant && locationPaid" class="btn btn-outline-primary" :href="procurationHref">
        {{ $t('infos.actions.procuration') }}
      </a>
      <a v-if="isTenant && !locationPaid && !changeBillingAddress" class="btn btn-outline-primary" href="#" @click="$emit('toggleChangeBillingAddress')">
        {{ $t('infos.actions.changeBillingAddress') }}
      </a>

      <a v-if="showCancelBtn" class="btn btn-outline-primary" data-toggle="modal" data-target="#cancel" href="#" @click="showModalCancel()">
        {{ $t('infos.actions.cancel') }}
      </a>
    </div>
  </div>
</template>

<script>
import LocationModalCancel from "./LocationModalCancel.vue";

export default {
  name: 'LocationInfosAction',
  emits: ["showModalCancel", 'update', 'toggleBilling'],
  components: {
    LocationModalCancel,
  },
  data() {
    return {
      modifyRental: false
    }
  },
  props: {
    changeBillingAddress: {
      type: Boolean,
      default: false
    },
    auth: {
      type: Object
    },
    DL: {
      type: Object
    },
    termined: {
      type: Boolean
    },
    showCancelBtn: {
      type: Boolean
    }
  },
  watch: {
    showCancelBtn: {
        handler(newShow, oldShow) {
            if(newShow == false) {
                this.modifyRental = this.showCancelBtn
            }
        },
        deep: true,
    }
  },
  computed: {
    hasModifyButton () {
      return this.isOwner && !this.DL.termined
    },
    modifyButton () {
      return !this.modifyRental ? 'Modifier / Prolonger' : 'Annuler les modifications'
    },
    locataire() {
      return this.DL.locataire
    },
    proprietaire() {
      return this.DL.proprietaire
    },
    isTenant() {
      return this.auth.id == this.locataire.id ? true : false
    },
    isOwner() {
      return this.auth.id == this.proprietaire.id ? true : false
    },
    locationPaid() {
      return this.DL.PaidAt !== null ? true : false
    },
    stepIsLocationUpdating() {
      return this.DL.updateStatusLocation == 1 || this.DL.updateStatusLocation == 2 ? true : false
    },
    adresseLocataire() {
      if (this.DL.locataire.adresse) {
        return this.DL.locataire.adresse.numero + " " + this.DL.locataire.adresse.rue + " " + this.DL.locataire.adresse.ville + " " + this.DL.locataire.adresse.codePostal + " " + this.DL.locataire.adresse.pays
      } else if (this.DL.locataire.adresseSiege) {
        return this.DL.locataire.adresseSiege.numero + this.DL.locataire.adresseSiege.rue + this.DL.locataire.adresseSiege.ville + this.DL.locataire.adresseSiege.codePostal + this.DL.locataire.adresseSiege.pays
      } else {
        return ''
      }
    },
    panier() {
      return this.DL.panier
    },
    sinistreHref() {
      return "#tally-open=mZQpan&proprietaireNom=" + this.DL.proprietaire.nom + "&proprietairePrenom=" + this.DL.proprietaire.prenom + "&locatairePrenom=" + this.DL.locataire.prenom + "&locataireNom=" + this.DL.locataire.nom + "&locationValeur=" + this.panier.valeurTotalMateriel + "&locationCaution=" + this.panier.caution + "&locationId=" + this.DL.id + "&proprietaireMail=" + encodeURIComponent(this.DL.proprietaire.email) + "&locataireMail=" + encodeURIComponent(this.DL.locataire.email) + "&userLocationNum=" + this.DL.numeroLocation
      // encodeURIComponent()
    },
    procurationHref() {
      return "#tally-open=wogzMw&userId=" + this.DL.locataire.id + "&userNom=" + this.DL.locataire.nom + "&userPrenom=" + this.DL.locataire.prenom + "&userAdressePostale=" + this.adresseLocataire + "&userDateNaissance=" + this.$moment(this.DL.locataire.dateNaissance).format('DD-MM-YYYY') + "&userMail=" + encodeURIComponent(this.DL.locataire.email) + "&proprietaireMail=" + encodeURIComponent(this.DL.proprietaire.email) + "&userLocationId=" + this.DL.id + "&userLocationNum=" + this.DL.numeroLocation
    }

  },
  methods: {
    toogleUpdate() {
      this.modifyRental = !this.modifyRental
      this.$emit('update', this.modifyRental)
    },
    showModalCancel() {
      this.$emit('showModalCancel', true);
    },
  }
}
</script>
