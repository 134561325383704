<template>
    <div class="a-savior-section">
        <h3 class="multisteps-form__title">
            {{$t('infos.cancel.label')}}
        </h3>
        <p><strong>
            {{$t('infos.cancel.subtitle')}}
        </strong>
        </p>
        <br>
        <p v-html="typeAnnulationText" class="mb-0"/>
    </div>
</template>

<script>

export default {
    name: 'LocationInfosCancel',
    props: {
        DL : {
            type: Object
        },
    },
    computed: {
        typeAnnulation() {
            return this.DL.typeAnnulation
        },
        typeAnnulationText () {
            if (this.typeAnnulation.id == 1){
                return this.$t('infos.cancel.description.1')
            } else if (this.typeAnnulation.id == 2) {
                return this.$t('infos.cancel.description.2')
            } else if (this.typeAnnulation.id == 3) {
                return this.$t('infos.cancel.description.3')
            }
        }
    }
}
</script>

