<template>
    <div class="emplacement-recuperation">
        <h3 class="multisteps-form__title mt-30">
            {{$t('infos.address.label')}}
        </h3>
        <div class="googleMap" id="mapbox" ref="mapboxGl"> </div>
        <br>
        <p>
        {{$t('infos.address.location')}}<br>
            <strong v-if="adresseGeoExists">{{ proprietaire.currentAdresse.numero }} {{ proprietaire.currentAdresse.rue }}, {{ proprietaire.currentAdresse.codePostal }} {{ proprietaire.currentAdresse.ville }}</strong>
            <strong v-if="!adresseGeoExists">{{ proprietaire.adresse.numero }} {{ proprietaire.adresse.rue }}, {{ proprietaire.adresse.codePostal }} {{ proprietaire.adresse.ville }}</strong>
        </p>
    </div>
</template>

<script>

import Mapbox from 'mapbox-gl';

export default {
    name: 'LocationInfosAddress',
    props: {
        DL : {
            type: Object
        },
        proprietaire : {
            type: Object
        }
    },
    computed: {
        panier() {
            return this.DL.panier
        },
        proprietaire () {
            return this.DL.proprietaire
        },
        adresseGeoExists () {
            return this.proprietaire.currentAdresse && this.proprietaire.currentAdresse !== null ? true : false
        },
        lngMapBox() {
            if(this.adresseGeoExists && this.panier.proprietaire_annonce.currentAdresse.lng) {
                return this.panier.proprietaire_annonce.currentAdresse.lng
            } else if(this.panier.proprietaire_annonce.adresse.lng) {
                return this.panier.proprietaire_annonce.adresse.lng
            } else {
                return 0
            }
        },
        latMapBox() {
            if(this.adresseGeoExists && this.panier.proprietaire_annonce.currentAdresse.lat) {
                return this.panier.proprietaire_annonce.currentAdresse.lat
            } else if(this.panier.proprietaire_annonce.adresse.lat) {
                return this.panier.proprietaire_annonce.adresse.lat
            } else {
                return 0
            }
        },
    },
    mounted() {
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.js')
        document.head.appendChild(recaptchaScript)

        let mapbox;
        Mapbox.accessToken  = 'pk.eyJ1Ijoiam9uYXRoYW4tbGlnaHR5c2hhcmUiLCJhIjoiY2tpYTQ5M3ljMDRpNDJ3cG0wZHg2YWY3cyJ9.K6nOD4erdqaA8vOGdXyMCw';
        mapbox = new Mapbox.Map({
            container: this.$refs.mapboxGl,
            style: 'mapbox://styles/jonathan-lightyshare/ckia4xpw3764n1apc23o4t3hy',
            zoom: 11,
            minZoom: 6,
            maxZoom: 15,
            center: [this.lngMapBox, this.latMapBox]
        });

        var el = document.createElement('div');
        el.className = 'marker';
        el.innerHTML = '<div class="icon"></div>';

        var markermapbox = new Mapbox.Marker(el)
            .setLngLat([this.lngMapBox, this.latMapBox])
            .addTo(mapbox);
    }
}
</script>
