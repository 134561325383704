<template>
  <div ref="mainScroll" id="main-scroll">
    <div class="main-scroll-container">
      <AuthMenuSmall ></AuthMenuSmall>
      <router-view></router-view>
    </div>
<!--    <el-main style="min-height: 100vh;">-->
<!--    </el-main>-->
  </div>
</template>

<script>
import AuthMenuSmall from "../../modules/auth/components/AuthMenuSmall.vue";

export default {
  components: {
    AuthMenuSmall
  },
  watch: {
    $route: {
      immediate: true,
      handler (val) {
        if (val) {
          this.scrollToTop()
          // this.$refs.mainScroll.$el.scrollTop = 0
        }
      }
    }
  },
  methods: {
    scrollToTop () {
      if (this.$refs.mainScroll) {
        this.$refs.mainScroll.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  },
  name: "ParamatersView"
}
</script>

<style scoped>

</style>