<template>


<h3 class="multisteps-form__title">Votre demande (original)</h3>

<div class="votre-demande">
    <div class="date-box depart-date">
        <div class="date">
            <h5 style="text-transform: capitalize;">{{ jourDebut }} <br> <span>{{ numeroJourDebut }}</span><br><span>{{ moisDebut }}</span></h5>
        </div>
        <h4>
        {{$t('infos.date.start-label')}} <br> <span>{{$t('infos.date.start')}}</span>
        </h4>
    </div>
    <div class="arrow">
        <i class="fas fa-chevron-right"></i>
    </div>
    <div class="date-box retour-date">
            <div class="date">
                <h5>{{ jourOldP }} <br> <span>{{ numeroJourOldP }}</span><br><span>{{ moisOldP }}</span></h5>
            </div>
        <h4>
            {{$t('infos.date.return-label')}}<br> <span>{{$t('infos.date.return')}}</span>
        </h4>
    </div>
</div>

</template>

<script>

export default {
    name: 'LocationInfosDatesUpdate',
    props: {
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
        termined : {
            type: Boolean
        },
    },
    computed: {
        dateDebut() {
            return this.DL.dateDebut
        },
        dateFin() {
            return this.DL.datefin
        },
        oldPanierDRetour() {
            return this.$symfony.oldPanierDRetour
        },
        jourDebut() {
            console.log(this.$symfony)
            return this.$moment(this.dateDebut).format('dddd')
        },
        numeroJourDebut() {
            return this.$moment(this.dateDebut).format('DD')
        },
        moisDebut() {
            return this.$moment(this.dateDebut).format('MMM').toUpperCase()
        },
        jourOldP() {
            return this.$moment(this.oldPanierDRetour).format('dddd')
        },
        numeroJourOldP() {
            return this.$moment(this.oldPanierDRetour).format('DD')
        },
        moisOldP() {
            return this.$moment(this.oldPanierDRetour).format('MMM').toUpperCase()
        }
    }
}
</script>