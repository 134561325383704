
<template>
  <div v-if="isSwitchResearch" :class="!isSwitchResearch ? 'blur-background' : 'blur-background active'" ref="switchUserPopup" @click="cancelSwitch">
      <ItemSelect class="user-select" @click.stop ref="itemSelect" :types="['user']" @update:searchAsk="updateValue" @update:modelValue="switchUser" url="/admin/autocomplete-json" style="width: unset;height: fit-content;" itemInputClass="user_input_search">
        <template  #item="{element}" >
          <a href="" class="clickable-item" v-html="getItemText(element)"></a>
        </template>
      </ItemSelect>
  </div>
  <div class="common-layout">
    <el-config-provider :locale="local">
      <router-view/>
    </el-config-provider>
  </div>
</template>

<script>
import fr from 'element-plus/lib/locale/lang/fr'
import Auth from "./modules/auth/services/Auth";
import ItemSelect from "~/modules/ad/components/ItemSelect.vue";
import eventBus from "~/events/eventBus.js";

export default {
  name: 'App',
  components: {ItemSelect},
  data () {
    return {
      local: fr,
      value1: "",
      isListening: false,
    }
  },
  created () {
    Auth.api().get()
    // Auth.api().get('base_auth', {
    //   baseURL: '/user'
    // })
  },
  watch: {
    isSwitchResearch: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          console.log(this.$refs)
          if (this.$refs.itemSelect) {
            this.$refs.itemSelect.focus()
          }
        }
        // if (val) {
        //   this.$nextTick(() => {
        //     console.log(this.$refs)
        //     this.$refs.itemSelect.focus()
        //   })
        // }
      }
    },
    isUserSwitch: {
      handler (val) {
        console.log('switch user')
        console.log(val)
        console.log(val)
        if (val && !this.isListening) {
          this.startListening();
        } else if (!val && this.isListening) {
          this.stopListening();
        }
      },
      immediate: true
    }
  },
  mounted () {
    eventBus.$on('open-modal', () => {
      console.log('Custom event triggered!')
    })
  },
  beforeDestroy() {
    // removing eventBus listener
    eventBus.$off('open-modal')
  },
  computed: {
    isSwitchResearch () {
      return !!(this.$route.query && typeof this.$route.query['_switch_user'] !== 'undefined')
    },
    switchResearchClass () {
      return this.$route.query && this.$route.query['_switch_user'] === '' || this.$route.query['_switch_user'] ? 'switch-research' : ''
    },
    auth () {
      return Auth.query().first()
    },
    isUserSwitch () {
      return this.auth && this.auth.userSwitch !== null || this.auth && this.auth.rolesList.includes('ROLE_ALLOWED_TO_SWITCH')
    }
  },
  methods: {
    async switchUser (user) {
      // console.log(user)
      // if (this.auth.userSwitch !== null) {
      //   await Auth.api().get(null , {
      //     params: {
      //       _switch_user: '_exit'
      //     },
      //   })
      // }
      window.location.href = '?_switch_user=' + encodeURIComponent(user.email)
      // await Auth.api().get(null , {
      //   params: {
      //     _switch_user: user.email
      //   },
      // })
    },
    getItemText (item) {
      return `${item.nom} ${item.prenom} - ${item.email}`
    },
    updateValue (val) {
      // let query = {...this.$route.query, _switch_user: val}
      // this.$router.push({query})
    },
    startListening() {
      if (!this.isListening) {
        console.log('start listing switch User shortcut')
        window.addEventListener('keydown', this.handleSwitchUserShortcut);
        this.isListening = true;
      }
    },
    stopListening() {
      if (this.isListening) {
        console.log('Stop listing switch User shortcut')
        window.removeEventListener('keydown', this.handleSwitchUserShortcut);
        this.isListening = false;
      }
    },
    cancelSwitch () {
      console.log('cancelSwitch')
        let query = {...this.$route.query}
        delete query['_switch_user']
        this.$router.push({query})
    },
    handleSwitchUserShortcut(event) {
      event.escapeKey = event.key === 'Escape' || event.key === 'Esc';
      if (event.escapeKey) {
        event.preventDefault();
        // Gère l'action pour Esc
        this.cancelSwitch()
        console.log('Esc a été pressé');
      }
      if (event.ctrlKey && event.shiftKey && event.key === 'S') { // Note que 'S' est en majuscule
        event.preventDefault();
        // Gère l'action pour Ctrl+Maj+S
        console.log('Ctrl+Maj+S a été pressé');
        let query = {...this.$route.query, _switch_user: ''}
        this.$router.push({query})
        console.log('focus item select');
        setTimeout(() => {
          console.log(this.$refs);
          this.$refs.itemSelect.focus()
        }, 100)
      }
    }
  }
}
</script>

<style>
.fullHeight {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
