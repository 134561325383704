<template>
  <div class="col-sm-10 owner-settings-page-section availability-section d-block">
    <div class="alert alert-info mb-4" role="alert">
      <div class="alert-icon">
        <span class="fa fa-check fa-info-circle"></span>
      </div>
      {{$t('owner-settings.list-group.item.2.info')}}
    </div>
    <form action="" class="pt-2" method="POST">
      <h2 class="form-section-title">
        {{$t('owner-settings.list-group.item.2.degress.title')}}
      </h2>
      <p class="form-section-description mb-4">
        {{$t('owner-settings.list-group.item.2.degress.description')}}
      </p>

      <div class="form-row">
        <div class="form-group col-md-6 pr-md-3">
          <label for="two-days-pricing">
            {{$t('owner-settings.list-group.item.2.degress.form.1')}}
          </label>
          <el-input type="number" size="large" id="two-days-pricing" name="twoDay" placeholder="5%" v-model="edit.degressivityRates.twoDay"/>
          <div class="invalid-feedback"></div>
        </div>
        <div class="form-group col-md-6 pl-md-3">
          <label for="three-days-pricing">
            {{$t('owner-settings.list-group.item.2.degress.form.2')}}
          </label>
          <input type="number" class="form-control" id="three-days-pricing" name="threeDay" placeholder="10%" v-model="edit.degressivityRates.threeDay">
          <div class="invalid-feedback"></div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6 pr-md-3">
          <label for="one-week-pricing">
            {{$t('owner-settings.list-group.item.2.degress.form.3')}}
          </label>
          <input type="number" class="form-control" id="one-week-pricing" name="sevenDay" placeholder="35%" v-model="edit.degressivityRates.sevenDay">
          <div class="invalid-feedback"></div>
        </div>
        <div class="form-group col-md-6 pl-md-3">
          <label for="four-weeks-pricing">
            {{$t('owner-settings.list-group.item.2.degress.form.4')}}
          </label>
          <input type="number" class="form-control" id="four-weeks-pricing" name="twentyEightDay" placeholder="60%" v-model="edit.degressivityRates.twentyEightDay">
          <div class="invalid-feedback"></div>
        </div>
      </div>
      <button class="btn btn-short btn-primary mt-4" :class="buttonClass" id="save-button-section-pricing" @click.prevent="sendForm">
        {{$t('owner-settings.list-group.item.2.save')}}
      </button>
    </form>
  </div>
</template>

<script>
import formHandler from "../../mixins/formHandler";


export default {
  name: "tarification",
  mixins: [formHandler],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  data () {
    return {
      contentLoaded: false,
      edit: {
        degressivityRates: {
          twoDay: null,
          threeDay: null,
          sevenDay: null,
          twentyEightDay: null
        }
      },
      content: {
        degressivityRates: {
          twoDay: null,
          threeDay: null,
          sevenDay: null,
          twentyEightDay: null
        }
      }
    }
  },
  methods: {
    transformHook (edit) {
      return {
        ...edit,
        ...edit.degressivityRates
      }
    }
  }
}
</script>

<style scoped>

</style>