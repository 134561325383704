
<template>
  <div>
    <h2>{{$t('signup.siret.title')}}</h2>
    <div class="form-group">
      <p>{{$t('signup.siret.description')}}</p>
    </div>
    <el-form-item :label="$t('signup.siret.label')">
      <div class="inline-input-btn">
        <el-input v-model="edit.siret" :placeholder="$t('signup.siret.label')" class="full-width-input" required />

        <el-button :loading="verifySiretLoading" @click="verifySiret"></el-button>
      </div>
    </el-form-item>
  </div>
</template>
<script>

import {loadPageContent} from "~/services/parameters.js";

export default {
  name: "ConfirmSiret",
  data () {
    return {
      numberOfSiretRequest: 0,
      siret: false,
      edit: {
        siret: '',
        company: {
          name: "",
          siret: "",
          taxId: "",
          addressName: "",
          address: {}
        },
        content: {
          company: {
            siret: ""
          },
          addressName: "",
          address: {}
        }
      }
    }
  },
  computed: {
    verifySiretLoading () {
      return this.numberOfSiretRequest > 0
    }
  },
  methods: {
    verifySiret () {
      console.log("fetchData");
      this.numberOfSiretRequest++
      loadPageContent("/api/verify-siret/" + this.edit.company.siret)
          .then(response => {
            this.siret = response.data.siret;
            let vat = response.data.vat;
            if (this.siret) {
              this.edit.company.name = this.siret.nom_entreprise
              this.edit.company.taxId = this.siret.numero_tva_intracommunautaire
              this.edit.company.addressName = (
                  this.siret.siege.libelle_voie +
                  " " +
                  this.siret.siege.code_postal +
                  ", " +
                  this.siret.siege.ville
              )
            } else if (vat && vat.valid) {
              this.edit.company.name = vat.name + ''
              this.edit.company.addressName = vat.address + ''
            }
            // console.log(this.content)
            // this.content = response
          })
          .catch(e => {
            console.error(e);
          })
          .finally(() => {
            this.numberOfSiretRequest--
          })
      ;
    },
  }
}
</script>

<style scoped>

</style>