<script>
export default {
  name: "StockCalendar",
  props: {
    adUnavailability: {
      type: Array,
      default () {
        return []
      }
    },
    product: {
      type: Object,
      default () {
        return {}
      }
    },
    unavailabilityProduct: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  computed: {
    unavailabilityProductTree () {
      const unavailabilityProductTrees = {}
      for (const key in this.unavailabilityProduct) {
        // Make first level
        unavailabilityProductTrees[key] = [
          {
              id: key,
              label: this.product.quantity - this.unavailabilityProduct[key][this.id] + '/' + this.product.quantity + ' Dehors',
              children: []
          },
          {
            id: key,
            label: this.unavailabilityProduct[key][this.id] + ' Retour',
            children: []
          }
        ]

        unavailabilityProductTrees[key][0]['children'].push(
          {
            id: this.id,
            label: this.product.title,
            children: []
          }
        )
      }
      return unavailabilityProductTrees
    },
    id () {
      return this.$route.params.id
    }
  },
  methods: {
    handleNodeClick (data) {
      console.log(data)
    },
    debugDateCell (data) {
      console.log(data)
      return ''
    }
  }
}
</script>

<template>
  <el-calendar class="calendar-extendable-height">
    <template #date-cell="{ data }">
      <p :class="data.isSelected ? 'is-selected' : ''" @click="debugDateCell(data)">
        {{ data.day.split('-').slice(2).join('-') }}
      </p>
        <el-tree v-if="unavailabilityProductTree[data.day]" :indent="0" :data="unavailabilityProductTree[data.day]" @node-click="handleNodeClick" />
<!--      <span v-if="unavailabilityProduct && unavailabilityProduct[data.day] && (unavailabilityProduct[data.day][id] || unavailabilityProduct[data.day][id] === 0) && this.product && this.product.quantity">-->
<!--        {{ this.product.quantity - unavailabilityProduct[data.day][id] + '/' + product.quantity }} Sorties-->
<!--      </span>-->


    </template>
  </el-calendar>
</template>

<style scoped>

</style>