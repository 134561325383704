<script>
export default {
  name: "Statistic",
  props: {
    statistic: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<template>
  <div class="statistic-card">
    <div class="statistic-label">
      <h3>{{ statistic.label }}</h3>
    </div>
    <div class="statistic-value">
      <h2>{{ statistic.value }}</h2>
    </div>
  </div>
</template>

<style scoped>

</style>