<template>
  <div class="col-sm-10 owner-settings-page-section availability-section d-block">
    <form action="" method="POST" style="margin-bottom: 32px;">
      <h2 class="form-section-title">
        {{$t('owner-settings.list-group.item.4.title')}}
      </h2>
      <p class="form-section-description mb-4">
        {{$t('owner-settings.list-group.item.4.description')}}
      </p>

      <div class="form-row">
        <div class="form-group col-md-6 pr-md-3">
          <label for="discount-code-name">
            {{$t('owner-settings.list-group.item.4.form.1')}}
          </label>
          <input type="text" class="form-control" id="discount-code-name" name="codePromoIdentifiant" placeholder="MonCodePromo" v-model="edit.codePromoIdentifiant">
          <div class="invalid-feedback"></div>
        </div>
        <div class="form-group col-md-6 pl-md-3">
          <label for="discount-code-value">
            {{$t('owner-settings.list-group.item.4.form.2')}}
          </label>
          <input type="text" class="form-control" id="discount-code-value" name="pourcentReduction" placeholder="20%" v-model="edit.pourcentReduction">
          <div class="invalid-feedback"></div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6 pr-md-3">
          <label for="discount-code-limit">
            {{$t('owner-settings.list-group.item.4.form.3')}}
          </label>
          <input type="text" class="form-control" id="discount-code-limit" name="montantLocationMinHT" placeholder="15" v-model="edit.montantLocationMinHT">
          <div class="invalid-feedback"></div>
        </div>
        <div class="form-group col-md-6 pl-md-3">
          <label for="discount-code-period">
            {{$t('owner-settings.list-group.item.4.form.4')}}
          </label>
          <input type="text" class="form-control" id="discount-code-period" name="dateFin" placeholder="23/11/2022" v-model="edit.dateFin">
          <div class="invalid-feedback"></div>
        </div>
      </div>
      <input type="hidden" name="currentPage" value="Offres" />

      <el-button :loading="formLoading" plain type="info" size="large" class="btn btn-save" id="save-button-section-offer" @click="sendForm" :disabled="!this.hasUpdate">
        {{$t('owner-settings.list-group.item.4.create')}}
      </el-button>

    </form>
    <el-card class="p-0">
      <div class="table-filter-container">
        <h3 class="subTitle">
          {{$t('owner-settings.list-group.item.4.code.title')}}
        </h3>
      </div>
      <hr>
      <DiscountTable :discounts="discounts"></DiscountTable>
    </el-card>
  </div>
</template>

<script>
import User from "~/modules/user/services/User.js";
import DiscountTable from "~/modules/auth/components/DiscountTable.vue";
import Auth from "~/modules/auth/services/Auth.js";
import formHandler from "~/mixins/formHandler.js";
import { ElNotification } from 'element-plus'

export default {
  name: "offres",
  components: {DiscountTable},
  mixins: [formHandler],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log('loading offers page')
      vm.beforeDataFetch()
    })
  },
  data () {
    return {
      edit: {
        codePromoIdentifiant: '',
        pourcentReduction: '',
        montantLocationMinHT: '',
        dateFin: ''
      }
      // codesPromo: []
    }
  },
  methods: {
    transformHook (edit) {
      return {
        codePromoIdentifiant: edit.codePromoIdentifiant,
        pourcentReduction: edit.pourcentReduction,
        montantLocationMinHT: edit.montantLocationMinHT,
        dateFin: edit.dateFin
      }
    },
    afterFormSend (response) {
      this.edit = {
        codePromoIdentifiant: '',
        pourcentReduction: '',
        montantLocationMinHT: '',
        dateFin: ''
      }
      ElNotification({
        title: 'Code promo ajouté',
        message: 'Votre code promo a bien été créé',
        type: 'success'
      })
    },
  },
  computed: {
    Model () {
      return User
    },
    fetchUrl () {
      return '/user/offers'
    },
    apiUrl () {
      return '/user/offers'
    },
    auth () {
      return Auth.query().first()
    },
    formKey () {
      return 'offers'
    },
    user () {
      if (this.auth) {
        return User.query().where('id', this.auth.id).first()
      } else {
        return false
      }
    },
    discounts () {
      if (this.user) {
        return this.user.codes_promo
      } else {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>