<template>
  <el-table :data="inventories" style="width: 100%;" :table-layout="'auto'" :row-class-name="tableRowClassName" :default-sort="defaultOrder"  class="pointer-row" @current-change="handleCurrentChange" highlight-current-row>
    <template #empty>
      <slot name="empty"></slot>
    </template>
    <el-table-column prop="name" label="identifiant" sortable="custom" min-width="100">
    </el-table-column>
    <el-table-column prop="value" label="Valeur d'achat" sortable="custom" min-width="280"/>
    <el-table-column prop="publie" label="Status" sortable="custom" min-width="80">
      <template #default="{row}">
        <el-tag
            :type="getInventoryStatusType(row.status)"
            class="mx-1"
            effect="light"
            round
        >
          <span class="dot"></span>
          {{ getInventoryStatus(row.status)}}
        </el-tag>
      </template>
    </el-table-column>
<!--    <el-table-column label="Rentabilité" min-width="300" >-->
<!--      <template #default="{row}">-->
<!--        <el-progress :percentage="40" :color="'#3A8BBB'" />-->
<!--      </template>-->
<!--    </el-table-column>-->
<!--    <el-table-column fixed="right" width="100px" prop="action">-->
<!--      <template #default="scope">-->
<!--        <div class="display-flex">-->
<!--          <button class="dropdown-edit-toogle" style="padding: 5px;" @click="$router.push({ name: 'product-edit', params: { id: scope.row.id }})">-->
<!--            <span class="icon-kit">-->
<!--              &#xe95c-->
<!--            </span>-->
<!--          </button>-->
<!--        </div>-->
<!--      </template>-->
<!--    </el-table-column>-->
  </el-table>
</template>

<script>
import AdTicket from "./AdTicket.vue"
export default {
  components: {
    AdTicket
  },
  name: "InventoriesTable",
  data () {
    return {
      selectedRow: null
    }
  },
  props: {
    defaultOrder: {
      type: Object,
      default () { return {}}
    },
    inventories: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    },
    getInventoryStatus (status) {
      switch (status) {
        case 1:
          return 'En stock'
        case 2:
          return 'Sortie'
        case 3:
          return 'Maintenance'
        case 4:
          return 'Archivé'
      }
    },
    getInventoryStatusType (status) {
      switch (status) {
        case 1:
          return 'success'
        case 2:
          return ''
        case 3:
          return 'warning'
        case 4:
          return 'info'
      }
    },
    console(data) {
      console.log(data)
    },
    handleCurrentChange (val) {
      // console.log('handleCurrentChange')
      // console.log(val)
      this.selectedRow = val
      this.$emit('handleCurrentChange', val)
    },
    onCellClick (row, column) {
      if (column.property !== 'action') {
        this.$router.push({
          name: 'product-inventory',
          params: {
            id: row.id
          }
        })
      }
      // this.$router.push({
      //   name: 'product-inventory',
      //   params: {
      //     id: row.id
      //   }
      // })
      // console.log('row click')
      // console.log(row)
      // console.log(column)
    }
  }
}
</script>

<style scoped>

</style>