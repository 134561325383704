<script>
import loadContent from "~/mixins/loadContent.js";
import CustomFilterTable from "~/components/CustomFilterTable.vue";
import AsksTable from "~/modules/ask/components/AsksTable.vue";
import Mangopay from "~/modules/payment/services/Mangopay.js"

export default {
  name: "MangoPayHooks",
  components: {AsksTable, CustomFilterTable},
  mixins: [loadContent],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  computed: {
    Model () {
      return Mangopay
    },
    fetchUrl () {
      return '/mangopay/hooks'
    }
  }
}
</script>

<template>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div>
        <h3 class="subTitle">Hooks MangoPay</h3>
        <span class="card-description">
          {{ $t('owner-rental.card-description')}}
        </span>
      </div>
      <el-button @click="beforeDataFetch()">Actualiser</el-button>
      <CustomFilterTable />
    </div>
<!--    <AsksTable :default-order="tableSortData" :asks="modelList" @sort-change="sortChange">-->
<!--    </AsksTable>-->
<!--    <div class="pagination-container">-->
<!--      <el-pagination v-if="paginationQuery.count" :current-page="currentPage" background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>-->
<!--    </div>-->
  </el-card>

</template>

<style scoped>

</style>