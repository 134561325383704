import { Model } from "@vuex-orm/core"
import Ads from "~/modules/ad/services/Ads.js";
import Cart from "~/modules/cart/services/Cart.js";
/**
 * @typedef {import('@vuex-orm/core').BelongsTo} BelongsTo
 */

export default class CartItem extends Model {
  static entity = 'cart-item'

  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api',
    dataKey: 'cartItem',
    actions: {
      fetch: {
        method: 'get',
        url: 'bundle'
      }
    }
  }
  static fields () {
    return {
      id: this.attr(null),
      cart_id: this.attr(null),
      /** @type {BelongsTo} */
      cart: this.belongsTo(Cart, 'cart_id'),
      titre: this.attr(null),
      prixUnitDegreHT: this.attr(null),
      prixUniteCatalogueHt: this.attr(null),
      totalTTC: this.attr(null),
      totalHT: this.attr(null),
      fraisServiceTTC: this.attr(null),
      prixTTC: this.attr(null),
      prixHT: this.attr(null),
      prixNormal: this.attr(null),
      valeurMateriel: this.attr(null),
      quantity: this.attr(null),
      totalTva: this.attr(null),
      valide: this.attr(null),
      annonce_id: this.attr(null),
      annonces: this.belongsTo(Ads, 'annonce_id'),
    }
  }
}