<script>
import UserTicketCard from "~/modules/user/components/UserTicketCard.vue";
import CartItem from "~/modules/cart/services/CartItem.js";
import Cart from "~/modules/cart/services/Cart.js";

export default {
  name: "CartCard",
  components: {UserTicketCard},
  props: {
    ask: {
      type: [Object, Boolean],
      default: false
    },
    cart: {
      type: Object,
      default: () => {
        return {}
      }
    },
    userType: {
      type: String,
      default: 'proprietaire'
    },
    to: {
      type: [Object, Boolean],
      default: false
    }
  },
  computed: {
    user () {
      if (this.userType === 'proprietaire') {
        return this.cart.proprietaire_annonce
      } else {
        return this.cart.user
      }
    },
    dateRangeFormatted () {
      if (this.cart.startAt && this.cart.endAt) {
        return this.$moment(this.cart.startAt).format('ddd DD MMM') + ' - ' + this.$moment(this.cart.endAt).format('ddd DD MMM')
      } else {
        return ''
      }
    },
    cartPrice () {
      return this.$currencyFormat.format(this.cart.prixTotalTtc) + ' TTC soit ' + this.$currencyFormat.format(this.cart.prixTotalHt) + ' HT'
    },
    titleFormatted () {
      if (this.ask) {
        return this.ask.titre_location
      }
      if (Array.isArray(this.cart.cartItems) && this.cart.cartItems.length) {
        // make title shorter when it's too long (33 chars)
        if (this.cart.cartItems[0] && this.cart.cartItems[0].titre) {
          if (this.cart.cartItems[0].titre.length > 33) {
            return this.cart.cartItems[0].titre.substring(0, 33) + '...'
          }
          return this.cart.cartItems[0].titre
        }
        return ''
      } else {
        return ''
      }
    },
    threeFirstCartItems () {
      if (this.cart.cartItems && this.cart.cartItems.length) {
        return this.cart.cartItems.slice(0, 3)
      } else {
        return []
      }
    },
    moreCartItems () {
      if (Array.isArray(this.threeFirstCartItems) && Array.isArray(this.cartItems) && this.threeFirstCartItems.length < this.cart.cartItems.length) {
        return this.cart.cartItems.length - this.threeFirstCartItems.length
      } else {
        return false
      }
    }
  },
  methods: {
    onCardClik (cart, ask) {
      this.$emit('clickCard', cart, ask)
    },
    async deleteCart (id) {
      let url = 'user/supprimer-panier/' + id
      let removedCartItem = await Cart.api().delete(url, {
        delete: id
      })
    },
  }
}
</script>

<template>
  <router-link v-if="to" :to="to" v-bind="$attrs" class="cart-card">
    <user-ticket-card :user="cart.proprietaire_annonce"></user-ticket-card>
    <div>
      <h3 class="title">
        {{ titleFormatted }}
      </h3>
      <p>
        <span class="icon-kit">&#xe927</span> {{ dateRangeFormatted }} <br>
        <span class="icon-kit">&#xe957</span> {{ cartPrice }} <br>

      </p>

    </div>
    <div class="item-resume">
      <img v-for="item in threeFirstCartItems" :src="item.annonces.cover.path" alt="" class="product-cover">
      <div v-if="moreCartItems" class="more-items">+ {{ moreCartItems }}</div>
    </div>
    <slot name="footer">
      <div class="actions">
        <el-button size="large" icon="delete" class="float-left" @click="deleteCart(cart.id)">
          Supprimer
        </el-button>
        <el-button plain type="info" size="large" class="btn btn-primary btn-sm float-right" >
          Voir le panier
        </el-button>
      </div>
    </slot>
  </router-link>
  <div v-else v-bind="$attrs" class="cart-card" @click="onCardClik">
    <user-ticket-card :user="user"></user-ticket-card>
    <div>
      <h3 class="title">
        {{ titleFormatted }}
      </h3>
      <p>
        <span class="icon-kit">&#xe927</span> {{ dateRangeFormatted }} <br>
        <span class="icon-kit">&#xe957</span> {{ cartPrice }} <br>

      </p>

    </div>
    <div class="item-resume">
      <img v-for="item in threeFirstCartItems" :src="item.annonces.cover.path" alt="" class="product-cover">
      <div v-if="moreCartItems" class="more-items">+ {{ moreCartItems }}</div>
    </div>
    <slot name="footer">
      <div class="actions">
        <el-button size="large" icon="delete" class="float-left" @click="deleteCart(cart.id)">
          Supprimer
        </el-button>
        <el-button plain type="info" size="large" class="btn btn-primary btn-sm float-right" >
          Voir le panier
        </el-button>
      </div>
    </slot>
  </div>
</template>

<style scoped>

</style>