<template>
  <el-table
      :data="ads"
      :table-layout="'auto'"
      :default-sort="defaultOrder"
      :row-class-name="tableRowClassName"
      @cell-click="onCellClick"
      class="pointer-row"
      style="width: 100%;"
  >
    <template #empty>
      <slot name="empty"></slot>
    </template>
<!--    <el-table-column fixed prop="id" label="#" width="60" />-->
    <el-table-column prop="titre" :label="$t('owner-listing.tab-head.title')" sortable="custom" min-width="100">
      <template #default="{row}">
        <AdTicket :Ad="row" max-width-title="400px"/>
      </template>
    </el-table-column>
    <el-table-column prop="action" label="A la une" sortable="custom" min-width="80">
      <template #default="scope">
        <el-switch
            v-show="scope.row.publie || scope.row.aLaUneProfile"
            v-model="scope.row.aLaUneProfile"
            :loading="aLaUneChangeLoading[scope.row.id]"
            @change="onALaUneChange(scope)"
            class="mt-2"
            style="margin-left: 24px"
            inline-prompt
        />
      </template>
    </el-table-column>
<!--    <el-table-column label="SKU" min-width="150">-->
<!--      <template #default="{row}">-->
<!--        <span v-if="row.stockType === 1"> {{ row.sku }}</span>-->
<!--      </template>-->
<!--    </el-table-column>-->
    <el-table-column prop="position" label="Position" align="center" sortable="custom" min-width="250"/>
    <el-table-column prop="tarif.tarifInteger" label="Prix (HT)" sortable="custom" min-width="200" >
      <template #default="{row}">
        <span v-if="row.tarif" v-html="$currencyFormat.format(row.tarif.tarifInteger/100)"></span>
      </template>
    </el-table-column>
    <el-table-column prop="publie" label="Status" sortable="custom">
      <template #default="{row}">
        <el-tag
            :type="row.publie ? 'success' : 'danger'"
            class="mx-1 media-tag"
            effect="light"
            round
        >
          <span class="dot"></span>
          {{ row.publie ? 'Visible' : 'Hors ligne' }}
        </el-tag>
<!--        <el-switch-->
<!--            width="90px"-->
<!--            v-model="scope.row.publie"-->
<!--            inline-prompt-->
<!--            style="&#45;&#45;el-switch-on-color: #13ce66; &#45;&#45;el-switch-off-color: #ff4949"-->
<!--            active-text="En ligne"-->
<!--            inactive-text="Hors ligne"/>-->
      </template>
    </el-table-column>
    <el-table-column fixed="right" width="40px" prop="action">
      <template #default="scope">
        <div class="display-flex">
          <el-dropdown trigger="click" :ref="'dropdown' + scope.$index">
            <div class="dropdown">
              <button class="dropdown-edit-toogle" style="padding: 5px;">
                <span class="icon-kit">
                  &#xe9a5
                </span>
              </button>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <a class="dropdown-item" :href="path('lighty_share_user_detail_annonce', { id: scope.row.id})">
                    {{ $t('owner-listing.dropdown.show') }}
                  </a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link class="dropdown-item" :to="{ name: 'product-edition', params: { id: scope.row.id }}">
                    {{ $t('owner-listing.dropdown.update') }}
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a class="dropdown-item" :href="path('lighty_share_user_listing_duplicate', { id: scope.row.id})">
                    {{ $t('owner-listing.dropdown.duplicate') }}
                  </a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a class="dropdown-item" :href="'/user/telechargement-facture-mensuelle/' + scope.row.id">
                    {{ $t('owner-listing.dropdown.spotlight') }}
                  </a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a class="dropdown-item" href="#" @click.prevent="archivedProduct(scope)">
                    <el-icon v-show="archivedProductLoading[scope.$index]" class="is-loading"><loading/></el-icon>
                    {{ $t('owner-listing.dropdown.delete') }}
                  </a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import AdTicket from "./AdTicket.vue"
import Ads from "~/modules/ad/services/Ads.js";
import { ElNotification } from 'element-plus'
export default {
  components: {
    AdTicket
  },
  name: "AdsTable",
  emits: ['onArchivedProduct'],
  props: {
    defaultOrder: {
      type: Object,
      default () { return {}}
    },
    ads: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      archivedProductLoading: {},
      aLaUneChangeLoading: {}
    }
  },
  methods: {
    async onALaUneChange (scope) {
      // console.log(scope)
      // console.log(scope.row.aLaUne)
      const row = scope.row
      if (!this.aLaUneChangeLoading[row.id]) {
        this.aLaUneChangeLoading[row.id] = true
        try {
          let response = await Ads.api().get('user/produit/' + row.id + '/' + (row.aLaUneProfile ? 'a-la-une' : 'a-la-une-retirer'))
          console.log(response)
          if (response.response.data.error) {
            row.aLaUneProfile = !row.aLaUneProfile
            ElNotification({
              title: 'Erreur lors de l\'ajout à la une',
              message: response.response.data.error,
              type: 'error'
            })
          } else {
            ElNotification({
              title: row.aLaUneProfile ? 'Ajouté à la une' : 'Retiré de la une',
              message: row.aLaUneProfile ? 'Votre produit a bien été ajouté à la une' : 'Votre produit a bien été retiré de la une',
              type: 'success'
            })
          }
        } catch (e) {
          console.error(e)
          console.log(e.response)
        }
        this.aLaUneChangeLoading[row.id] = false
      }
    },
    async archivedProduct (scope) {
      const row = scope.row
      if (!this.archivedProductLoading[row.id]) {
        this.archivedProductLoading[row.id] = true
        if (this.$refs['dropdown' + scope.$index]) {
          await this.$refs['dropdown' + scope.$index].handleClose()
        }
        let response = await Ads.api().get('user/produit/' + row.id + '/archiver')
        this.$emit('onArchivedProduct')
        this.archivedProductLoading[row.id] = false
      }
    },
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    },
    console(data) {
      console.log(data)
    },
    onCellClick (row, column) {
      console.log('on cel click')
      if (column.property !== 'action') {
        let routeName = 'product-edition'
        console.log('on cell click ')
        console.log(row)
        if (row.stockType === 1) {
          routeName = 'product-inventory'
        }
        this.$router.push({
          name: routeName,
          params: {
            id: row.id
          }
        })
      }
      // this.$router.push({
      //   name: 'product-inventory',
      //   params: {
      //     id: row.id
      //   }
      // })
      // console.log('row click')
      // console.log(row)
      // console.log(column)
    }
  }
}
</script>

<style scoped>

</style>