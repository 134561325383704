<script>
import InputDescription from "~/modules/ad/components/InputDescription.vue";

export default {
  name: "AdminModelEdit",
  components: {InputDescription},
  props: {
    modelValue: {
      type: [Object, String]
    },
    name: String
  },
  data () {
    return {
      edit: {
        nom: '',
        pictureSrc: '',
        description: '',
        seo: '',
        monture: '',
        populaire: null,
        yearOfProd: null
      }
    }
  },
  mounted () {
    this.edit.nom = this.name + ''
  },
  watch: {
    edit: {
      deep: true,
      handler (val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>

<template>
  <el-form-item>
    <template #label>
      <div>
        <h4 class="label-title">Modèle</h4>
        <p class="label-info">Nom du modèle "{{name}}"</p>
      </div>
    </template>
    <el-input v-model="edit.nom"></el-input>
  </el-form-item>
  <el-form-item>
    <template #label>
      <div>
        <h4 class="label-title">Image</h4>
        <p class="label-info">Chemin de l'image du modèle</p>
      </div>
    </template>
    <el-input v-model="edit.pictureSrc"></el-input>
  </el-form-item>
  <el-form-item>
    <template #label>
      <div>
        <h4 class="label-title">Description</h4>
        <p class="label-info">Texte pour référencer le modèle</p>
      </div>
    </template>
    <input-description v-model="edit.description"></input-description>
  </el-form-item>
  <el-form-item>
    <template #label>
      <div>
        <h4 class="label-title">Seo</h4>
        <p class="label-info">Nom pour le référencement</p>
      </div>
    </template>
    <el-input v-model="edit.seo"></el-input>
  </el-form-item>
  <el-form-item>
    <template #label>
      <div>
        <h4 class="label-title">Popularité du modèle</h4>
        <p class="label-info">Définir un ordre de popularité pour l'afficher sur la page d'accueil Lightyshare</p>
      </div>
    </template>
    <el-input v-model="edit.populaire" type="number"></el-input>
  </el-form-item>
  <el-form-item>
    <template #label>
      <div>
        <h4 class="label-title">Année de production</h4>
        <p class="label-info">Année de sortie du modèle</p>
      </div>
    </template>
    <el-input v-model="edit.yearOfProd" type="number"></el-input>
  </el-form-item>
  <hr>
</template>

<style scoped>

</style>