<script>
import Media from "~/modules/media/services/Media.js";

export default {
  name: "MediaEdit",
  emits: ['update:modelValue', 'error', 'uploadSuccess'],
  data () {
    return {
      dialogVisible: false,
      dialogImageUrl: null,
      dialogMedia: null
    }
  },
  props: {
    modelValue: {
      type: Array,
      default () {
        return []
      }
    },
    action: {
      type: [String, Array]
    },
    annonceId: {
      type: [String, Number]
    }
  },
  computed: {
    newPosition () {
      return this.modelValue.length + 1
    },
    mediaAction () {
      if (typeof this.action !== 'string' && Array.isArray(this.action)) {
        return '/' + this.action.join('/')
      }
      if (this.annonceId) {
        return this.action + '/' + this.newPosition + '/' + this.annonceId
      } else {
        return this.action + '/' + this.newPosition
      }
    }
  },
  methods: {
    transformHook (edit) {
      return {
        legalFirstname: edit.infoSociete.prenomLegal,
      }
    },
    onError (error) {
      // console.log(error.message)
      // console.log('on error media edit')
      this.$emit('error', this.action, JSON.parse(error.message))
    },
    mediaOverlayClass (media) {
      return media && media.valide ? '' : 'media-invalid'
    },
    async handleRemove (media) {
      let url = 'user/media-delete/' + media.id
      let mediaDelete = await Media.api().delete(url, {
        delete: media.id
      });
      if (mediaDelete.response && mediaDelete.response.data && mediaDelete.response.data.success && mediaDelete.response.data.medias) {
        this.$emit('update:modelValue', mediaDelete.response.data.medias)
      }
    },
    handleSuccess (response, file, fileList) {
      console.log('emit upload success')
      console.log(response)
      if (response && response.success) {
        this.$emit('update:modelValue', response.medias)
        console.log('emit upload success')
        this.$emit('uploadSuccess', response, file, fileList)
      }
      // console.log('handle success')
      // console.log(response)
      // console.log(file)
      // console.log(fileList)
      // this.$emit('update:modelValue', response)
    },
    handlePreview (media) {
      this.dialogVisible = true
      this.dialogImageUrl = media.path
      this.dialogMedia = media
    }
  }
}
</script>

<template>
  <div class="lighty-upload-list-img" >
    <div v-for="media in modelValue" class="lighty-upload-list-item-img">
      <el-image style="width: 150px; height: 110px" :src="media.path" :fit="'cover'" />
      <div class="media-upload-overlay" :class="mediaOverlayClass(media)">
        <span class="el-upload-list__item-actions" >
          <span @click="handlePreview(media)" class="icon-kit" v-html="'&#xea18'"></span>
          <span @click="handleRemove(media)" class="icon-kit" v-html="'&#xe9f1'"></span>
        </span>
        <el-tag
            v-if="media.reason && media.reason.length"
            :type="'danger'"
            class="mx-1 "
            effect="plain"
            size="small"
            round
        >
          <span class="dot"></span>
          {{ media.reason }}
        </el-tag>
      </div>
      <!--      <img :src="media.path" alt="">-->
    </div>
  </div>
  <el-upload
      class="upload-media"
      :action="mediaAction"
      drag
      multiple
      :on-error="onError"
      :on-success="handleSuccess"
  >
    <div class="el-upload__text">
      <span class="icon-kit upload-icon circle">&#xe9ff</span>
      <p>
        Glissez / déposez ou <em>cliquez ici pour importer</em> votre image
      </p>
    </div>
    <template #tip>
    </template>
  </el-upload>
</template>

<style scoped>
.media-upload-overlay .el-upload-list__item-actions {
  color: white;
  cursor: pointer;
}
.media-upload-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  flex-direction: column;
}
.media-preview-overlay.media-invalid {
  background: rgba(252,34,73,0.5);
}
.media-tag {
  word-break: break-word;
}
.media-upload-overlay.media-invalid {
  background: rgba(252,34,73,0.5);
}
.lighty-upload-list-item-img:hover .media-upload-overlay {
  opacity: 1;
}
.upload-media {
  width: 100%;
}
.upload-icon {
  font-size: 20px;
}
.upload-media .circle {
  display: flex;
  margin-bottom: 12px;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border: 6px solid var(--gray-50, #F9FAFB);
  background: var(--gray-100, #F2F4F7);
}
.el-upload__text {
  display: flex;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  color: var(--gray-500, #667085);
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.lighty-upload-list-img {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  flex-direction: row;
}
.lighty-upload-list-item-img {
  position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
  width: 150px;
  height: 110px;
}
</style>