import Signup from '../services/Signup.js'
import {transformDataToFormContent} from "../../../services/parameters.js";
import Auth from "~/modules/auth/services/Auth.js";

export default {
    data () {
      return {
        error: false,
        generalError: false,
        formErrors: {},
        isLoading: false
      }
    },
    created () {
      this.CreateSignupUser()
      this._token = this.$symfony.token
      this.username = this.$symfony.username
    },
    computed: {
      submitUrl () {
        return '/signup'
      },
      symfony () {
        return this.$symfony
      },
      signupUser () {
        if (this.loggedUser && this.loggedUser.id) {
          return Signup.query().find(this.loggedUser.id)
        }
        return Signup.query().find(1)
      },
      loggedUser () {
        return Auth.query().first()
      },
      stepCoeficient () {
        return this.currentStep / this.steps.length
      },
      stepStyle () {
        return {
          width: `calc((100% + 50px)*${(this.currentStep / this.steps.length)})`
        }
      },
      stepPhoneStyle () {
        return {
          width: `calc((100%)*${(this.currentStep / this.steps.length)})`
        }
      },
      currentStep () {
        return this.steps.indexOf(this.$route.name) + 1
      },
      nextStep () {
        return typeof this.steps[this.currentStep] !== 'undefined' ? this.steps[this.currentStep] : false
      },
      accountSteps () {
        return [
          'RegisterStatus',
          'RegisterLogin',
          'RegisterIdentity'
        ]
      },
      lastAccountStep () {
        return this.steps.reduce((acc, step) => {
          if (this.accountSteps.includes(step)) {
            acc = step
          }
          return acc
        }, null)
      },
      steps () {
        let userSteps = [
          'RegisterStatus',
          'RegisterLogin',
          'RegisterIdentity',
          'RegisterPhoneNumber',
          'ConfirmPhoneNumber',
          'RegisterLocation'
        ]
        const comapnySteps = [
          'RegisterSiret',
          // 'ConfirmSiret',
          'RegisterCompany',
          'RegisterSiege'
        ]
        if (this.signupUser && this.signupUser.statutUser === 1) {
          userSteps = [
            ...userSteps
          ]
        } else {
          // delete userSteps[userSteps.length - 1] // delete location step
          userSteps = [
            ...userSteps,
            ...comapnySteps
          ].filter((step) => {
            return step !== 'RegisterLocation'
          })
        }
        userSteps.push('RegisterComplete')
        return userSteps
      }
    },
    methods: {
      resetError(field) {
        if (this.formErrors && this.formErrors[field]) {
          delete this.formErrors[field]
        }
      },
      UpdateSignupUser (data) {
        Signup.insertOrUpdate({
          data
        })
        for (let key in data) {
          if (data[key] !== null || data[key] !== '') {
            localStorage.setItem('signup.'+ key, data[key])
          }
        }
        // foreach(data, (value, key) => {
        //   localStorage.setItem('signup.'+ key, value)
        // })
      },
      removeSignupSession () {
        const keys = [
          'id',
          'statutUser',
          'email',
          'password',
          'prenom',
          'nom',
          'dateNaissance',
          'newsletter'
        ]
        for (let key of keys) {
          localStorage.removeItem('signup.'+ key)
        }
      },
      CreateSignupUser () {
        const keys = [
          'id',
          'statutUser',
          'email',
          'password',
          'prenom',
          'nom',
          'dateNaissance',
          'newsletter'
        ]
        let data = {}
        for (let key of keys) {
          if ((!this.signupUser || (this.signupUser && (!this.signupUser[key] || this.signupUser[key] === ''))) && localStorage.getItem('signup.'+ key) !== null) {
            if (key === 'statutUser') {
              data[key] = parseInt(localStorage.getItem('signup.' + key))
            } else if (key === 'newsletter') {
              data[key] = localStorage.getItem('signup.'+ key) === 'true'
            } else {
              data[key] = localStorage.getItem('signup.'+ key)
            }
          }
        }
        if (Object.keys(data).length > 0) {
          Signup.insertOrUpdate({
            data
          })
        }
        if (this.symfony && this.symfony.user) {
          if (!this.signupUser) {
            console.log('createSignupUser')
            Signup.insertOrUpdate({
              data: this.symfony.user,
            })
          }
          Auth.insertOrUpdate({
            data: this.symfony.user,
          })
        }
      },
      async sendData (data, form = 'fos_user_registration_form') {
        if (this._token) {
          data['_token'] = this._token
        }
        let dataFormContent = {}
        dataFormContent[form] = {...data}
        let dataToSend = transformDataToFormContent(dataFormContent, [])
        let url = this.submitUrl
        this.isLoading = true
        try {
          const response = await Signup.api().post(url, dataToSend, {
            headers: {
              'content-type': 'application/x-www-form-urlencoded'
            }
          })
          this.isLoading = false
          if (response && response.response && response.response.data) {
            if (response && response.response && response.response.data && response.response.data.form_errors) {
              this.formErrors = response.response.data.form_errors
            }
            if (response.response.data.token_csrf) {
              this._token = response.response.data.token_csrf
            }
            if (response.response.data.error) {
              this.error = response.response.data.error
            }
            if (response.response.data.csrf_token_valid === false) {
              this.generalError = {
                title: 'Le token csrf est expiré',
                description: 'Veuillez recharger la page et réessayer.'
              }
            } else {
              this.generalError = false
            }
            if (response.response.data.signup) {
              await Signup.insertOrUpdate({
                data: response.response.data.signup
              })
            }
            if (response.response.data.auth_id) {
              let auth_id = response.response.data.auth_id
              await Auth.insertOrUpdate({
                data: {
                  id: auth_id,
                }
              })
            }
          }
          return response
        } catch (e) {
          console.error(e)
          if (e.response && e.response.data && e.response.data.redirect_url) {
            window.location.href = e.response.data.redirect_url
          }
          if (e.response && e.response.data && e.response.data.error) {
            this.error = e.response.data.error
          }
          // console.log(response)
          this.isLoading = false
        }
      },
      async saveEditSignup () {
        let edit = this.signupUser
        edit['_token'] = this._token
        edit['username'] = this.username
        edit['plainPassword'] = edit['password']
        let dataFormContent = {}
        dataFormContent['fos_user_registration_form'] = {...edit}
        delete dataFormContent['fos_user_registration_form']['id']
        dataFormContent['fos_user_registration_form']['dateNaissance'] = dataFormContent['fos_user_registration_form']['dateNaissance'].replaceAll('/', '-')
        delete dataFormContent['fos_user_registration_form']['$id']
        let data = transformDataToFormContent(dataFormContent, [])
        let url = this.submitUrl
        this.isLoading = true
        try {
          const response = await Signup.api().post(url, data, {
            headers: {
              'content-type': 'application/x-www-form-urlencoded'
            }
          })
          this.isLoading = false
          if (response && response.response && response.response.data) {
            if (response && response.response && response.response.data && response.response.data.form_errors) {
              this.formErrors = response.response.data.form_errors
            }
            if (response.response.data.token_csrf) {
              this._token = response.response.data.token_csrf
            }
            if (response.response.data.token_csrf_valid === false) {
              this.generalError = 'Le token csrf est expiré, veuillez recharger la page et réessayer.'
            }
            if (response.response.data.valid) {
              this.removeSignupSession()
            }
            if (response.response.data.auth_id) {
              let auth_id = response.response.data.auth_id
              await Auth.insertOrUpdate({
                data: {
                  id: auth_id,
                }
              })
            }
          }
          return response
        } catch (e) {
          this.isLoading = false
          return e
        } finally {
          this.isLoading = false
        }

      },

      async continu () {
        let continu = true
        let index = this.steps.indexOf(this.$route.name)
        if (this.$route.name === this.lastAccountStep) {
          const signupResponse = await this.saveEditSignup()
          continu = signupResponse && signupResponse.response && signupResponse.response.data && signupResponse.response.data.valid
        }
        if (typeof this.beforeContinu === 'function') {
          continu = await this.beforeContinu()
        }
        let nextStepIndex = index + 1
        if (nextStepIndex < this.steps.length && continu) {
          this.$router.push({ name: this.steps[nextStepIndex] })
        }
      }
    }
}
