<template>
  <div class="documentaire-step-section" v-if="location && location.proprietaire && location.locataire">
    <div class="container">
      <div class="product-creation-page-title checkout-cart-page-title">
        <h2>{{ locationTitle }}</h2>
      </div>
      <div class="multisteps-form">
        <div class="row">
          <div class="col-12 col-lg-12 ml-auto mr-auto mb-4">
            <LocationProgress :auth="auth" :DL="demandeLocation" :termined="termined"/>
            <div v-if="isOwnerAndTenantNotCertified" style="width: 100%">
              <div class="certification-badge">
                <span class="certification-icon"></span>
                <span class="certification-description">
                    {{ $t('certification-badge.description-vue', {firstname: location.locataire.prenom }) }}
                </span>
                <a class="read-more" target="_blank" href="https://support.lightyshare.com/article/195-un-membre-non-certifie-ma-envoye-une-demande-de-location">
                  {{ $t('certification-badge.more-content') }}
                  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="arrow-right">
                      <path id="Icon" d="M2.7085 7.00065H10.2918M10.2918 7.00065L6.50016 3.20898M10.2918 7.00065L6.50016 10.7923" stroke="#080025" stroke-width="1.3333" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12 m-auto">
            <div class="multisteps-form__form">
              <div class="multisteps-form__panel rounded bg-white js-active" data-animation="scaleIn">
                <div class="row desktop-only">
                  <LocationInfos @goRentalPayment="goRentalPayment" @showModalCancel="openModalCancel" @showModalEquipment="openModalEquipment"
                                 @overlay="overlay" @dlUpdated="updatedPrices" @update="rentalInfosUpdate"
                                 :auth="auth" :DL="demandeLocation" :termined="termined" :showCancelBtn="showCancelBtn"/>
                  <LocationBox @showModalCancel="openModalCancel" @goRentalPayment="goRentalPayment" @showModalEquipment="openModalEquipment"
                               @dlUpdated="updatedPrices" @overlay="overlay" @showRentalDate="showRentalDate" :auth="auth" :DL="demandeLocation" :update="updateRentalDate"
                               :termined="termined" :screen="Desktop"/>
                </div>
                <div class="row mobile-only">
                  <LocationInfos @goRentalPayment="goRentalPayment" @showModalCancel="openModalCancel" @showModalEquipment="openModalEquipment"
                                 @overlay="overlay" @dlUpdated="updatedPrices" :auth="auth"
                                 :DL="demandeLocation" :termined="termined" :screen="Mobile" :showCancelBtn="showCancelBtn"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showOverlay == true" id="overlay" style="display: block;">
    <div class="cv-spinner">
      <span class="spinner"></span>
    </div>
  </div>
  <el-dialog v-model="noCertificationPopup" class="no-certification-popup" :close-on-click-modal="true">
    <div class="no-certification-popup-inner">
      <div class="welcome-model-header">
        <h3><img :src="asset('img/svg/thumb-icon.svg')">{{$t('certified.title')}}</h3>
      </div>
      <div class="welcome-model-content">
        <p>
          {{$t('certified.description')}}
        </p>
        <div class="welcome-model-btns">
          <a :href="path('lighty_share_lighty_share_account_settings')  + '#section-certification'" class="btn btn-primary">
            {{$t('certified.button.accept')}}
          </a>
          <a href="#" @click="closeNoCertifiedPopup" class="btn btn-close-model">
            {{$t('certified.button.later')}}
          </a>
        </div>
      </div>
    </div>
  </el-dialog>
  <transition name="slide" appear>
    <LocationModalCancel @closeModalCancel="closeModalCancel" v-if="showModalCancel === true" :auth="auth"
                         :DL="demandeLocation" :termined="termined"/>
  </transition>
  <transition name="slide" appear>
    <LocationModalEquipment
        @closeModalEquipment="closeModalEquipment"
        v-if="showModalEquipment === true"
        :auth="auth"
        :DL="demandeLocation"
        :termined="termined"
        :equipmentsPA="equipmentsPA"/>
  </transition>
</template>

<script>
import LocationProgress from "./LocationProgress.vue";
import LocationInfos from "./LocationInfos.vue";
import LocationBox from "./LocationBox.vue";
import LocationModalCancel from "./LocationModalCancel.vue";
import LocationModalEquipment from "./LocationModalEquipment.vue";
import Rental from "~/modules/ask/services/Rental.js";
import loadContent from "~/mixins/loadContent.js";

export default {
  name: "StepsLocation",
  mixins: [loadContent],
  components: {
    LocationProgress,
    LocationInfos,
    LocationBox,
    LocationModalCancel,
    LocationModalEquipment
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  mounted () {
    // this.noCertificationPopup = !this.auth.lightyShareCertifed
  },
  data() {
    return {
      noCertificationPopup: false,
      updateRentalDate: false,
      showModalCancel: false,
      showModalEquipment: false,
      equipmentsPA: null,
      showOverlay: false,
      // showCancelBtn: true,
      dlUpdated: null,
      panierUpdated: null,
      location: this.$symfony.demandelocation,
    }
  },
  created () {
    if (this.$symfony.demandelocation) {
      console.log('start insertOrUpdateRental')
      console.log(this.$symfony.demandelocation)
      this.insertOrUpdateRental(this.$symfony.demandelocation)
    }
  },
  watch: {
    'auth.lightyShareCertifed': {
      immediate: true,
      handler (newCertified, oldCertified) {
        if (typeof newCertified === 'undefined') {
          return
        }
        this.noCertificationPopup = !newCertified
      },
    },
    dlUpdated: {
      handler(newDlUpdated, oldDlUpdated) {
        if(newDlUpdated != null) {
          this.demandeLocation = newDlUpdated
        }
      },
      deep: true,
    },
    panierUpdated: {
      handler(newPanierUpdated, oldPanierUpdated) {
        if(newPanierUpdated != null) {
          this.location.panier = newPanierUpdated
        }
      },
      deep: true,
    },
    ask: {
      handler (val) {
        if (val) {
          console.log('on change Val')
          this.location = JSON.parse(JSON.stringify(val))
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    showCancelBtn() {
      return !this.demandeLocation.canceled
    },
    id () {
      return this.$route && this.$route.params && this.$route.params.id
    },
    ask () {
      return Rental
          .query()
          .with(['locataire.*', 'proprietaire.*'])
          .where('id', parseInt(this.id))
          .first()
    },
    fetchUrl () {
      return '/api/user/location/'+ this.id
    },
    Model () {
      return Rental
    },
    isOwnerAndTenantNotCertified () {
      return this.location.proprietaire.id === this.auth.id && !this.location.locataire.lightyShareCertifed
    },
    demandeLocation: {
      get() {
        return this.location
      },
      set(value) {
        this.location = value
      }
    },
    termined() {
      return this.ask.Termined
    },
    locationTitle() {
      return this.demandeLocation.titreLocation && this.demandeLocation.titreLocation.length > 40 ? this.demandeLocation.titreLocation.slice(0, 40) + '...' : this.demandeLocation.titreLocation ? this.demandeLocation.titreLocation : ''
    },
    auth() {
      return this.$symfony.auth
    },
    Desktop() {
      return "Desktop"
    },
    Mobile() {
      return "Mobile"
    }
  },
  methods: {
    async insertOrUpdateRental (rental) {
      Rental.insertOrUpdate({
        data: rental
      })
    },
    closeNoCertifiedPopup () {
      this.noCertificationPopup = false
    },
    goRentalPayment ($event) {
      if (!this.auth.lightyShareCertifed) {
        this.noCertificationPopup = true
        $event.preventDefault()
      } else {
      }
    },
    rentalInfosUpdate(payload) {
      if (payload.type === 'updateAction') {
        this.updateRentalDate = payload.value
      }
    },
    updatedPrices(val) {
      if(typeof val.demandeLocation !== 'undefined') {
        this.dlUpdated = val.demandeLocation;
      }
      if(typeof val.panier !== 'undefined') {
        this.panierUpdated = val.panier;
      }
      this.beforeDataFetch()
    },
    closeModalCancel(val) {
      this.showModalCancel = val
    },
    closeModalEquipment(val) {
      this.showModalEquipment = val
    },
    openModalCancel(val) {
      this.showModalCancel = val
    },
    openModalEquipment(val) {
      this.showModalEquipment = val[0]
      this.equipmentsPA = val[1]
    },
    overlay(val) {
      this.showOverlay = val
    },
    showRentalDate(val) {
      this.updateRentalDate = val
      // this.showCancelBtn = val
    }
  }
}
</script>

<style>

.mapboxgl-canvas {
  outline: none;
}

#penalite_appliquer {
  /* button-1 */

  /* Auto Layout */
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  padding: 7px 24px !important;

  position: static !important;
  width: 130px !important;
  height: 40px !important;
  left: 0px !important;
  top: 0px !important;

  /* Red Lighty */
  border: 1px solid #FC2249 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;

  /* Inside Auto Layout */
  flex: none !important;
  order: 0 !important;
  flex-grow: 0 !important;
  margin: 0px 83px !important;


  /* 💬 Value */

  position: static !important;
  left: 24px !important;
  right: 24px !important;
  top: 7px !important;
  bottom: 7px !important;

  font-family: Nunito Sans !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 26px !important;

  /* Red Lighty */
  color: #FC2249 !important;


  /* Inside Auto Layout */
  flex: none !important;
  order: 0 !important;
  flex-grow: 0 !important;
  margin: 10px 0px !important;
  margin-right: 100px !important;
  background-color: white;
}

#penalite_rembourser {
  /* button-1 */

  /* Auto Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px 24px;

  position: static;
  width: 150px;
  height: 40px;
  left: 213px;
  top: 0px;

  /* Red Lighty */
  background: #FC2249;
  border-radius: 8px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 83px;


  /* 💬 Value */

  position: static;
  left: 24px;
  right: 24px;
  top: 7px;
  bottom: 7px;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  /* identical to box height, or 144% */

  /* white */
  color: #FFFFFF;


  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;

}
</style>
