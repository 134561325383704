<template>
  <div class="boutique-header">
    <h3 class="parameters-title">Catégories</h3>
  </div>
  <CustomTabs :tabs="tabs"></CustomTabs>
</template>

<script>
import CustomTabs from "../../../components/CustomTabs.vue";

export default {
  components: {
    CustomTabs
  },
  name: "AdminCategoriesTabs",
  data () {
    return {
      tabs: [
          {
            label: "Demande d'ajout",
            to: { name: 'admin-asks-new-model-list'}
          },
          {
            label: "Catégories",
            to: { name: 'admin-categories-list'}
          },
          {
            label: "Marques",
            to: { name: 'admin-brands-list'}
          }
          // {
          //   label: 'Modèle économique',
          //   to: { name: 'admin-economics'}
          // },

      ]
    }
  },
  computed: {
  }
}
</script>

<style scoped>

</style>
