<template>
    <div class="modal-header d-flex flex-column">
        <h5 class="modal-title mb-2">
            {{$t('modals.cancel.pro.title')}} 
        </h5>
    </div>
    <div class="modal-body">
        <div v-if="stepOne">
            <div class="form-group">
                <div class="radio">
                    <label>
                        <input checked type="radio" name="reason" value="Matériel non disponible" v-model="picked">
                        {{$t('modals.cancel.pro.reason.1')}} 
                    </label>
                </div>
            </div>
            <div class="form-group">
                <div class="radio">
                    <label>
                        <input type="radio" name="reason" value="Propriétaire non disponible" v-model="picked">
                        {{$t('modals.cancel.pro.reason.2')}} 
                    </label>
                </div>
            </div>
            <div class="form-group">
                <div class="radio">
                    <label>
                        <input type="radio" name="reason" class="other-reasons" value="Autre" v-model="picked">
                        {{$t('modals.cancel.pro.reason.3')}} 
                    </label>
                </div>
            </div>
            <div id="raisonAutre" v-if="picked == 'Autre'">
                <p>{{$t('modals.cancel.pro.reason.title')}} </p>
                <input type = "text" name="autre-raison" id="cancel-area" maxlength="500" class="form-control" placeholder="Indiquez la raison"><br>
            </div>
        </div>
        <div v-if="stepThree">
            <div v-if="cancelDifferenceDays < 7">
                {{$t('modals.cancel.pro.text.1', {nbDays: cancelDifferenceDays})}}
                <br><br>
                <ul>
                    <li>
                        {{$t('modals.cancel.pro.text.2')}} 
                    </li>
                </ul>
            </div>
        </div>
        <p>
            {{$t('modals.cancel.leaveMessage', {user: locataire.prenom})}} 
        </p>
        <textarea required name="message" rows="6" id="message" maxlength="1000" class="form-control" :placeholder="$t('modals.cancel.placeholder', {user: firstNameCapitalize})" v-on:keyup="enableButton()" v-model="message"></textarea>
    </div>
</template>

<script>

export default {
    name: "LocationModalCancelProprietaire",
    data() {
        return {
            message: '',
            picked: ''
        }
    },
    props: {
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
        termined : {
            type: Boolean
        },
    },
    computed: {
        locataire () {
            return this.DL.locataire
        },
        proprietaire () {
            return this.DL.proprietaire
        },
        isTenant() {
            return this.auth.id == this.locataire.id ? true : false
        },
        isOwner() {
            return this.auth.id == this.proprietaire.id ? true : false
        },
        firstNameCapitalize() {
            return this.capitalizeFirstLetter(this.locataire.prenom)
        },
        stepOne() {
            return this.DL.AcceptedAt === null ? true : false
        },
        stepTwo () {
            return this.DL.AcceptedAt !== null && this.DL.PaidAt === null
        },
        stepThree () {
            return this.DL.PaidAt !== null && this.termined == false && this.DL.CanceledAt === null ? true : false
        },
        cancelDifferenceDays() {
          if (this.$symfony.cancelDifferenceDays) {
            return this.$symfony.cancelDifferenceDays
          } else {
            return this.DL.cancelDifferenceDays
          }
        },

    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        enableButton() {
            if(this.message.length > 0) {
                this.$parent.isDisabled = "btn btn-short btn-primary"
            } else if (this.message.length < 1) {
                this.$parent.isDisabled = "btn btn-short btn-primary disabled"
            }
        }
    }
}

</script>