<script>
import FormHandler from "~/mixins/formHandler.js";
import screenBehavior from "~/mixins/screenBehavior.js";
import DemandeAjoutModele from "~/modules/ad/services/DemandeAjoutModele.js";
import ItemSelect from "~/modules/ad/components/ItemSelect.vue";
import AdminBrandEdit from "~/modules/admin/components/AdminBrandEdit.vue";
import AdminModelEdit from "~/modules/admin/components/AdminModelEdit.vue";
import { ElNotification } from 'element-plus'

export default {
  name: "AdminAskNewModelEdit",
  components: {AdminModelEdit, AdminBrandEdit, ItemSelect},
  mixins: [FormHandler, screenBehavior],
  ApiUrl: 'admin/asks-modele-edit',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  watch: {
    'edit.modele': {
      deep: true,
      handler () {

      }
      // if (typeof val === 'object' && val !== null && val.id) {
      //   this.content.modele = val.nom
      // } else {
      //   this.content.modele = val
      // }
    },
  },
  computed: {
    formSave () {
      return false
    },
    Model () {
      return DemandeAjoutModele
    },
    formKey () {
      return typeof this.edit.modele === 'object' && this.edit.modele !== null && this.edit.modele.id ? 'DemandeAjoutModele' : 'Modele'
    },
    formAlignement () {
      return this.screen.width > 800 ? 'left' : 'top'
    },
    isMarqueSet () {
      if (typeof this.edit.marque === 'object' && this.edit.marque !== null && this.edit.marque.id) {
        return true
      } else if (typeof this.edit.marque === 'object' && this.edit.marque !== null && !this.edit.marque.id) {
        return this.edit.marque.nom && this.edit.marque.nom.length
      } else {
        return false
      }
    },
    isModelSet () {
      if (typeof this.edit.modele === 'object' && this.edit.modele !== null && this.edit.modele.id) {
        return true
      } else if (typeof this.edit.modele === 'object' && this.edit.modele !== null && !this.edit.modele.id) {
        return this.edit.modele.nom && this.edit.modele.nom.length
      } else {
        return false
      }
    },
    disabled () {
      return this.isModelSet && this.isMarqueSet
    },
    modelESOptions () {
      let options = {
        params: {
          p: 12
        }
      }
      if (typeof this.edit.marque === 'object' && this.edit.marque !== null && this.edit.marque.id) {
        options.params['ma'] = this.edit.marque.id
      }
      return options
    },
    brandName () {
      return typeof this.edit.marque === 'string' ? this.edit.marque : typeof this.edit.marque === 'object' && this.edit.marque !== null && this.edit.marque.nom ? this.edit.marque.nom : ''
    },
    modelName () {
      return typeof this.edit.modele === 'string' ? this.edit.modele : typeof this.edit.modele === 'object' && this.edit.modele !== null && this.edit.modele.nom ? this.edit.modele.nom : ''
    },
    googleResearch () {
      let researchTerm = this.brandName + ' ' + this.modelName
      return researchTerm.replace(' ', '+')
    }
  },
  data () {
    return {
      newMarque: false,
      newModele: false,
      edit: {
        modele: null,
        marque: null,
      },
      content: {
        marque: null
      }
    }
  },
  methods: {
    afterFormSend (response) {
      ElNotification({
        title: "Demande d'ajout traité",
        message: "La demande d'ajout a été traité avec succès",
        type: 'success'
      })
      DemandeAjoutModele.delete(this.$route.params.id)
      this.$router.push({name: 'admin-asks-new-model-list'})
      this.$emit('afterSave', response)
    },
    addNewMarque () {
      this.newMarque = true
    },
    addNewModele () {
      this.newModele = true
    },
    transformHook (edit) {
      if (this.formKey === 'Modele') {
        let data = {
          ...edit.modele
        }
        if (typeof edit.marque === 'object' && edit.marque !== null && edit.marque.id) {
          data['marque'] = edit.marque.id
        }
        if (typeof edit.marque === 'object' && edit.marque !== null && !edit.marque.id) {
          data['marque'] = edit.marque
        }
        if (typeof edit.sousCategorie === 'object' && edit.sousCategorie !== null && edit.sousCategorie.id) {
          data['sousCategorie'] = edit.sousCategorie.id
        }
        if (typeof edit.sousCategorie === 'object' && edit.sousCategorie !== null && !edit.sousCategorie.id) {
          data['sousCategorie'] = edit.sousCategorie
        }
        return data
      } else {
        // console.log('coucou 3')
        return {
          modele: edit.modele.id,
          marque: edit.marque.id,
          sousCategorie: edit.sousCategorie.id
        }
      }
    }
  }
}
</script>

<template>
  <el-form v-loading="isLoading" label-width="300px" :label-position="formAlignement" size="large" class="custom_form_lightyshare">
    <div class="product-creation-form-header">
      <h3>Traitement de la demande d'ajout</h3>
      <p>Retrouve plus d'info sur la référence : "<a :href="'https://www.google.com/search?q=' + googleResearch" target="_blank">{{brandName}} {{modelName}} sur google</a>" </p>
    </div>
    <el-form-item v-if="!newMarque">
      <template #label>
        <div>
          <h4 class="label-title">Marque</h4>
          <p class="label-info">Choisir une marque pour "{{content.marque}}"</p>
        </div>
      </template>
      <ItemSelect v-model="edit.marque" :types="['marque']" :ESOptions="{params: {p: 12}}" @new="addNewMarque" :new-item="'adEdit.addNewModele'" :load-on-focus="true"/>
    </el-form-item>
    <AdminBrandEdit v-else v-model="edit.marque" :name="content.marque"/>
    <el-form-item v-if="!newModele">
      <template #label>
        <div>
          <h4 class="label-title">Modèle</h4>
          <p class="label-info">Choisir un modèle pour "{{content.modele}}"</p>
        </div>
      </template>
      <ItemSelect v-model="edit.modele" :types="['modele']" :ESOptions="modelESOptions" @new="addNewModele" :new-item="'adEdit.addNewModele'" :load-on-focus="true"/>
    </el-form-item>
    <AdminModelEdit v-else v-model="edit.modele" :name="content.modele"/>
    <el-form-item>
      <template #label>
        <div>
          <h4 class="label-title">Sous catégorie</h4>
          <p class="label-info">Choisir une sous catégorie pour "{{content.modele}}"</p>
        </div>
      </template>
      <ItemSelect v-model="edit.sousCategorie" :types="['categorie']" :ESOptions="{params: {p: 12}}" @new="addNewModele" :new-item="'adEdit.addNewModele'" :load-on-focus="true"/>
    </el-form-item>
    <hr>
    <el-button :loading="formLoading" plain type="info" size="large" class="btn btn-save" :disabled="!disabled" @click.prevent="sendForm">Sauvegarder
    </el-button>
  </el-form>
</template>

<style scoped>

</style>