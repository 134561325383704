import { Model } from '@vuex-orm/core'
// import User from "../../user/services/User.js";

export default class Carousel extends Model {
  static entity = 'carousels'
  static apiConfig = {
    headers: {'X-Requested-With': 'XMLHttpRequest'},
    baseURL: '/api',
    dataKey: 'carousel',
  }

  static fields () {
    return {
      id: this.attr(null),
      title: this.attr(''),
      description: this.attr(''),
      link: this.attr(''),
      buttonText: this.attr(null),
      picture: this.attr(null),
      logo: this.attr(null),
      forDashboard: this.attr(null),
    }
  }
}