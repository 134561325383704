<script>
import MediaEdit from "~/modules/media/components/MediaEdit.vue";
import BankValidationBehavior from "~/modules/payment/mixins/BankValidationBehavior.js";
import BankValidationNotifications from "~/modules/payment/components/BankValidationNotifications.vue";
import { ElNotification } from 'element-plus'

export default {
  name: "BankValidationCompany",
  components: {BankValidationNotifications, MediaEdit},
  mixins: [BankValidationBehavior],
  data () {
    return {
      sirenDetailsErrors: {},
      kbisDetailsErrors: {},
      statusDetailsErrors: {},
      detailsErrors: {},
      edit: {
        infoSociete: {
          raisonSocial: ''
        },
        name: '',
        kbis: [],
        status: [],
      }
    }
  },
  computed: {
    isCompanySubmit () {
      return this.isSubmit('company')
    },
    formKey () {
      return 'company'
    },
    id () {
      return this.Auth ? this.Auth.id : null
    },
  },
  methods: {
    async onFormSubmit () {
      await this.sendForm()
      if (this.$route && this.$route.name !== 'BankValidation') {
        let step = this.getNextStep('company')
        this.$router.push(step.to)
      }
    },
    transformHook (edit) {
      return {
        raisonSocial: edit.infoSociete.raisonSociale,
      }
    },
    onUploadSuccess (response) {
      ElNotification({
        title: "Le document a bien été ajouté",
        type: 'success'
      })
    },
    onKbisUploadError (action, error) {
      if (error && error.details) {
        this.kbisDetailsErrors = error.details
      }
      ElNotification({
        title: this.$t(error.error),
        message: "Veuillez réssayer avec un autre document",
        type: 'error'
      })
    },
    onStatusUploadError (action, error) {
      if (error && error.details) {
        this.statusDetailsErrors = error.details
      }
      ElNotification({
        title: this.$t(error.error),
        message: "Veuillez réssayer avec un autre document",
        type: 'error'
      })

    },
    onSirenUploadError (action, error) {
      if (error && error.details) {
        this.sirenDetailsErrors = error.details
      }
      ElNotification({
        title: this.$t(error.error),
        message: "Veuillez réssayer avec un autre document",
        type: 'error'
      })
    },
  }
}
</script>

<template>
  <div class="product-creation-form-header">
    <h3>{{ $t('shop.settings.bank-validation.steps.company.title') }}</h3>
    <p class="label-info">{{ $t('shop.settings.bank-validation.steps.company.description') }}</p>
  </div>
  <el-form
      :model="edit"
      label-width="300px"
      label-position="left"
      size="large"
      class="custom_form_lightyshare"
  >
    <el-form-item label="firstname">
      <template #label>
        <div>
          <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.company.company-name.label') }}</h4>
          <p class="label-info">{{ $t('shop.settings.bank-validation.steps.company.company-name.description') }}</p>
        </div>
      </template>
      <el-input v-model="edit.infoSociete.raisonSociale" disabled size="large" />
    </el-form-item>

    <div v-if="Auth && Auth.statutUser !== 5">
      <BankValidationNotifications v-if="ModelEntity && ModelEntity.kbisKycDocuments" :document="ModelEntity.kbisKycDocuments" :details-errors="kbisDetailsErrors"/>
      <el-form-item label="kbis" v-if="ModelEntity && ModelEntity.kbisKycDocuments && !['VALIDATION_ASKED', 'VALIDATED'].includes(ModelEntity.kbisKycDocuments.Status)">
        <template #label>
          <div>
            <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.company.kbis.label') }}</h4>
            <p class="label-info">{{ $t('shop.settings.bank-validation.steps.company.kbis.description') }}</p>
          </div>
        </template>
        <media-edit v-model="edit.kbis" :action="['api', 'user', 'upload-kyc-page', 'kbis']" @error="onKbisUploadError" @upload-success="onUploadSuccess"/>
      </el-form-item>
      <BankValidationNotifications v-if="ModelEntity && ModelEntity.statusKycDocuments" :document="ModelEntity.statusKycDocuments" :details-errors="statusDetailsErrors"/>
      <el-form-item label="status" v-if="ModelEntity && ModelEntity.statusKycDocuments && !['VALIDATION_ASKED', 'VALIDATED'].includes(ModelEntity.statusKycDocuments.Status)" >
        <template #label>
          <div>
            <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.company.status.label') }}</h4>
            <p class="label-info">{{ $t('shop.settings.bank-validation.steps.company.status.description') }}</p>
          </div>
        </template>
        <media-edit v-model="edit.status" :action="['api', 'user', 'upload-kyc-page', 'statut']" @error="onStatusUploadError" @upload-success="onUploadSuccess" />
      </el-form-item>
    </div>
    <div v-else>
      <BankValidationNotifications v-if="ModelEntity && ModelEntity.sirenKycDocuments" :document="ModelEntity.sirenKycDocuments" :details-errors="sirenDetailsErrors"/>
      <el-form-item label="siren" v-if="ModelEntity && ModelEntity.sirenKycDocuments && !['VALIDATION_ASKED', 'VALIDATED'].includes(ModelEntity.sirenKycDocuments.Status)">
        <template #label>
          <div>
            <h4 class="label-title">{{ $t('shop.settings.bank-validation.steps.company.siren.label') }}</h4>
<!--            <p class="label-info">{{ $t('shop.settings.bank-validation.steps.company.siren.description') }}</p>-->
          </div>
        </template>
        <media-edit v-model="edit.status" :action="['api', 'user', 'upload-kyc-page', 'siren']" @error="onStatusUploadError" @upload-success="onUploadSuccess" />
      </el-form-item>
    </div>
    <div class="actions">
      <el-button @click="onFormSubmit" :loading="formLoading" size="large" class="btn btn-primary">{{ $t('shop.settings.bank-validation.steps.buttons.next') }}</el-button>
    </div>
  </el-form>
</template>

<style scoped>

</style>