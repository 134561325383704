<template>
  <div class="boutique-header">
    <h3 class="parameters-title">{{ $t('asks.title') }}</h3>
    <h4 class="parameters-subtitle">{{ $t('asks.description') }}</h4>
  </div>
  <CustomTabs :tabs="tabs"></CustomTabs>
</template>

<script>
import CustomTabs from "../../../components/CustomTabs.vue";

export default {
  components: {
    CustomTabs
  },
  name: "AsksTabs",
  data () {
    return {
      tabs: [
          {
            label: this.$t('owner-rental.tabs.today'),
            to: { name: 'today-asks'}
          },
          {
            label: this.$t('owner-rental.tabs.waiting'),
            to: { name: 'waiting-asks'}
          },
          {
            label: this.$t('owner-rental.tabs.departures'),
            to: { name: 'departures-asks', query: { s:  'departUnix-asc'}}
          },
          {
            label: this.$t('owner-rental.tabs.arrivals'),
            to: { name: 'arrivals-asks', query: { s: 'finUnix-asc'}}
          },
          {
            label: this.$t('owner-rental.tabs.cancel'),
            to: { name: 'cancel-asks'}
          },
          {
            label: this.$t('owner-rental.tabs.all'),
            to: { name: 'all-asks'}
          }
          // {
          //   label: this.$t('owner-rental.tabs.1'),
          //   to: { name: 'reservations-annulees'}
          // }

      ]
    }
  },
  computed: {
  }
}
</script>

<style scoped>

</style>