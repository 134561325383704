<template>
  <div class="nav-item dropdown AuthSmallMenuContent" v-click-outside="closeDropdown">
    <a v-if="user" class="nav-link dropdown-toggle user-drop-down AuthMenu" href="#" id="navbarDropdown" role="button" @click.prevent="toogleDropdown"
       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
      <span class="user-name">{{ $symfony.auth.prenom }}</span>
      <img v-if="profileThumb" :src="'/display-media/images-upload/' + $symfony.auth.id + '/profil/' + profileThumb + '?w=32&h=32&type=fit'" alt="" class="user-image" />
      <div v-else class="avatar avatar-sm-32 avatar-color-1">
        <div class="initials">{{ initials }}</div>
      </div>
      <span v-if="countTotalNotification" class="badge-notification">{{countTotalNotification}}</span>
    </a>
    <div class="dropdown-menu dropdown-menu-right" :class="dropDownClass">
      <a class="dropdown-item" href="/user/messagerie-new">
        {{$t('navbar-auth.dropdown-item.1')}}
        <span v-if="countNewMessages" class="badge-notification">{{countNewMessages}}</span>
      </a>
      <a class="dropdown-item" href="/user/mes-favoris">{{$t('navbar-auth.dropdown-item.2')}}</a>
      <a class="dropdown-item" href="/user/mes-paniers">
        {{$t('navbar-auth.dropdown-item.3')}}
        <span v-if="countNewPanierSessions" class="badge-notification">{{countNewPanierSessions}}</span>
      </a>
      <a class="dropdown-item" href="/user/rental">
        {{$t('navbar-auth.dropdown-item.4')}}
        <span v-if="countNewLocations" class="badge-notification">{{countNewLocations}}</span>
      </a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" :href="'/public/'+$symfony.auth.id+'/show'">{{$t('navbar-auth.dropdown-item.5')}}</a>
      <a v-if="$symfony.auth.boutiqueActive" class="dropdown-item" href="/owner/dashboard">
        {{$t('navbar-auth.dropdown-item.6')}}
        <span v-if="countNewRentals" class="badge-notification">{{countNewRentals}}</span>
      </a>
      <a class="dropdown-item" href="/account-settings">{{$t('navbar-auth.dropdown-item.7')}}</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item logout" href="/logout">{{$t('navbar-auth.dropdown-item.8')}}</a>
      <a v-if="isSwitched" class="dropdown-item logout" href="?_switch_user=_exit">Switch déconnecter</a>
    </div>
  </div>
</template>

<script>
import notifications from "../../../mixins/notifications.js";
import Auth from "~/modules/auth/services/Auth.js";

export default {
  name: "AuthMenuSmall",
  mixins: [notifications],
  data () {
    return {
      dropdown: false
    }
  },
  props: {
    notifications: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    closeDropdown () {
      this.dropdown = false
    },
    toogleDropdown () {
      this.dropdown = !this.dropdown
    }
  },
  computed: {
    auth () {
      return Auth.query().first()
    },
    isSwitched () {
      return this.auth && this.auth.userSwitch !== null
    },
    user () {
      if (this.$symfony.auth && this.$symfony.auth !== 'anon.') {
        return this.$symfony.auth
      } else {
        return false
      }
    },
    granted () {
      return this.$symfony.isGranted
    },
    profileThumb () {
      if (this.user && this.user.ImageProfil ) {
        return this.user.ImageProfil.imageName
      } else {
        return false
      }
    },
    dropDownClass () {
      let classes = ''
      if (this.dropdown) {
        classes = 'open'
      }
      return classes
    },
    fetchData () {
      return this.$symfony
    },
    initials () {
      if (this.user) {
        return this.user.prenom[0] + '' + this.user.nom[0]
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.dropdown-menu.open {
  display: block;
}
header .dropdown-menu {
  position: absolute;
}
</style>

<style lang="scss">

</style>