import Ads from "~/modules/ad/services/Ads.js";
import {Model} from "@vuex-orm/core";

export default class Inventory extends Model {

  static entity = 'inventory'

  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api',
    dataKey: 'inventory'
  }

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(''),
      status: this.attr(0),
      value: this.attr(0),
      annonce: this.belongsTo(Ads, 'annonce_id'),
      stockUnavailableRanges: this.attr([]),
      annonce_id: this.attr(null),
      createdAt: this.attr('')
    }
  }
}