<template>
  <div class="boutique-header">
    <h3 class="parameters-title">Documents</h3>
  </div>
  <CustomTabs :tabs="tabs"></CustomTabs>
</template>

<script>
import CustomTabs from "../../../components/CustomTabs.vue";
import Auth from "~/modules/auth/services/Auth.js";

export default {
  components: {
    CustomTabs
  },
  data () {
    return {
    }
  },
  computed: {
    tabs () {
      let tabs = [
        {
          label: 'Facture',
          to: { name: 'documents'}
        },
        {
          label: 'Rapport Annuel',
          to: { name: 'documents-rapport'}
        }
      ]
      if (this.auth && this.auth.mensualisation) {
        tabs.push({
          label: 'Mensualisation',
          to: { name: 'documents-mensualisation'}
        })
      }
      return tabs
    },
    auth () {
      return Auth.query().first()
    },
  },
  name: "DocumentTabs"
}
</script>

<style scoped>

</style>