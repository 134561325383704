
<template>
  <div>
    <h2 class="signup-form-title">{{$t('signup.complete.title', { firstname })}}</h2>
    <p class="signup-form-description" v-html="$t('signup.complete.description')">
    </p>
    <div class="form-group mt-4">
      <el-button class="signup-primary" @click="continu">
        {{$t('signup.complete.btn')}}
      </el-button>
    </div>
  </div>
</template>
<script>
import signupHandler from '../mixins/signupHandler.js'

export default {
  name: "RegisterComplete",
  mixins: [signupHandler],
  methods: {
    continu () {
      let url = '/catalogue-search'
      if (this.signupUser && this.signupUser.adresse && this.signupUser.adresse.location) {
        url = url + '?c=' + this.signupUser.adresse.location
      } else if (this.signupUser && this.signupUser.infoSociete && this.signupUser.infoSociete.address && this.signupUser.infoSociete.address.location) {
        url = url + '?c=' + this.signupUser.infoSociete.address.location
      }
      window.location.href = url
    }
  },
  computed: {
    firstname () {
      return this.signupUser.prenom.charAt(0).toUpperCase() + this.signupUser.prenom.slice(1)
    }
  }
}
</script>

<style scoped>

</style>