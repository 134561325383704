<script>
import loadContent from "~/mixins/loadContent.js";
import Rental from "~/modules/ask/services/Rental.js";
import CartCard from "~/modules/cart/components/CartCard.vue";

export default {
  name: "AskListShortcut",
  components: {CartCard},
  mixins: [loadContent],
  props: {
    asksCount: {
      type: Object,
      default () {
        return {
        }
      }
    },
    btns: {
      type: Array,
      default () {
        return [
          'today',
          'departures',
          'arrivals',
          'waiting'
        ]
      }
    }
  },
  data () {
    return {
      loadingStatus: {},
      status: 'today',
      statusForFetch: 'today'
    }
  },
  watch: {
    asksCount: {
      handler (val, oldVal) {
        if (val && Object.keys(val).length) {
          let availableCount = Object.keys(val).filter((v) => {
            return val[v] ? parseInt(val[v]) : false
          })
          if (availableCount.length) {
            this.status = availableCount[0]
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    fetchUrl () {
      return '/api/user/reservations/' + this.status
    },
    Model () {
      return Rental
    },
    perPage () {
      return 3
    },
    AskCountButton () {
      return this.btns.map((value, index) => {
        let count = this.asksCount[value] ? parseInt(this.asksCount[value]) : 0
        return {
          action: value,
          count: count,
          disabled: count === 0,
          selectedClass: value === this.status ? 'selected' : ''
        }
      })
    }
  },
  methods: {
    async onClickSection (button) {
      if (button.action !== this.status) {
        this.status = button.action
        this.loadingStatus[button.action] = true
        await this.beforeDataFetch()
        this.loadingStatus[button.action] = false
      }
    },
    goToChat (ask) {
      window.location.href = this.path('lighty_share_user_messagerie', {roomopen: ask.chatRoomId})
    },
    goToDetail (ask) {
      window.location.href = this.path('steps_location', {id: ask.id})
    },
    preQuery (query) {
      query.with('locataire.ImageProfil')
      let now = new Date();
      now.setHours(0,0,0,0);
      if (this.status === 'today') {
        query.where((ask) => {
          let departDate = new Date(ask.departUnix * 1000);
          departDate.setHours(0,0,0,0);
          let finDate = new Date(ask.finUnix * 1000);
          finDate.setHours(0,0,0,0);
          return (departDate.getTime() === now.getTime() || finDate.getTime() === now.getTime()) && !ask.canceled
        })
      } else if (this.status === 'waiting') {
        query.where((ask) => {
          return (ask.statutLocataire === 1 && ask.statutProprietaire < 3 && ask.departUnix >= now.getTime() / 1000 && !ask.canceled)
        })
      } else if (this.status === 'departures') {
        query.where((ask) => {
          return (ask.statutLocataire === 3 && ask.statutProprietaire === 3 && ask.departUnix >= now.getTime() / 1000 && !ask.canceled)
        })
      } else if (this.status === 'arrivals') {
        query.where((ask) => {
          return (ask.statutLocataire >= 3 && ask.statutProprietaire >= 3) && ask.departUnix < now.getTime() / 1000 && ask.finUnix >= now.getTime() / 1000 && !ask.canceled
        })
      } else if (this.status === 'cancel') {
        query.where((ask) => {
          return ask.canceled
        })

      }
    }
  }
}
</script>

<template>
  <el-scrollbar class="remove-container-padding outside-scrollbar">
    <div class="asks-shortcut-button">
      <el-button v-for="button in AskCountButton" :loading="!!loadingStatus[button.action]" size="large" :disabled="button.disabled" @click="onClickSection(button)" :class="button.selectedClass">
        {{ $t('asks.labels.' + button.action) }} <span class="count" style="margin-left: 5px;" >  ({{ button.count }})</span>
      </el-button>
    </div>
  </el-scrollbar>
  <el-scrollbar class="remove-container-padding">
    <div class="important-actions-grid">
      <!--    <CartCard></CartCard>-->
      <cart-card v-for="ask in modelList" :ask="ask" :cart="ask.panier" :user-type="'locataire'" class="with-absolute-buttons">
        <template #footer>
          <div class="ask-actions">
            <!--            <button @click="goToAsk(ask)" class="ask-action action-card-left">{{ $t('shop.dashboard.important-actions.ask-actions.see-more') }}</button>-->
            <button class="ask-action action-card-left" @click="goToChat(ask)">{{ $t('shop.dashboard.asks.actions.message') }}</button>
            <button class="ask-action action-card-right" @click="goToDetail(ask)">{{ $t('shop.dashboard.asks.actions.look') }}</button>
          </div>
        </template>
      </cart-card>
    </div>
  </el-scrollbar>
  <router-link class="more-button" :to="{name: status +'-asks'}">Tout afficher <span class="icomoon d-inline">&#xe912</span></router-link>
</template>

<style scoped>

</style>