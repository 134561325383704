<script>
import CustomFilterTable from "~/components/CustomFilterTable.vue";
import Conflict from "~/modules/ad/services/Conflict.js";
import ConflictTable from "~/modules/ad/components/ConflictTable.vue";
import ProductsConflictTable from "~/modules/ad/components/ProductsConflictTable.vue";
import LoadContent from "~/mixins/loadContent.js";
import Ads from "~/modules/ad/services/Ads.js";
import ProductMigration from "~/modules/ad/services/ProductMigration.js";
import BundleEdit from "~/modules/ad/pages/BundleEdit.vue";
import Modele from "~/modules/ad/services/Modele.js";
import ProductEdit from "~/modules/ad/pages/ProductEdit.vue";
import { ElNotification } from 'element-plus'
import screenBehavior from "~/mixins/screenBehavior.js";

export default {
  name: "ConflictsList",
  components: {ProductEdit, BundleEdit, ConflictTable, ProductsConflictTable},
  mixins: [LoadContent, screenBehavior],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchConflicts()
    })
  },
  watch: {
    'productMigration': {
      handler (val, oldVal) {
        if (val) {
          if (!oldVal || (oldVal && oldVal.product_id !== val.product_id)) {
            if (val.product && val.product.archived) {
              this.edit.stockType = 0
            } else {
              this.edit.stockType = 1
            }
          }
        }

      }
    }
  },
  data () {
    return {
      singleProductLoading: false,
      archivedProductloading: {},
      edit: {
        stockType: 1
      },
      selectMode: false,
    }
  },
  methods: {
    computedConflicts (row) {
      return parseInt(row.count_conflicts) - parseInt(row.count_resolved_conflicts)
    },
    async setSingleProduct (id) {
      this.singleProductLoading = true
      try {
        await ProductMigration.api().get('user/migrer-en-produit/' + id + '/product')
      } catch (e) {
      } finally {
        this.singleProductLoading = false
      }
    },
    afterNewAloneProduct (MigrationProduct) {

      if (MigrationProduct && MigrationProduct.response && MigrationProduct.response.data && MigrationProduct.response.data.ads && MigrationProduct.response.data.ads.id) {
        console.log('set single product')

        this.setSingleProduct(MigrationProduct.response.data.ads.id)
      }
      this.$router.push({
        name: 'conflict-model'
      })
    },
    cancelProductAction () {
      this.$router.push({
        name: 'conflict-model'
      })
    },
    prefilledProductData (edit) {
      if (!edit) {
        edit = {}
      }
      edit.modele = Modele.find(this.$route.params.modelId)
      edit.stockType = 1
      // edit.stockType = 1
      return edit
    },
    prefilledData (edit) {
      if (!edit) {
        edit = {}
      }
      if (!edit.bundle) {
        edit.bundle = {}
      }
      if (!edit.bundle.bundleItems && this.aloneProduct) {
        edit.bundle.bundleItems = [{
          quantity: 1,
          discount: 0,
          annonceId: this.aloneProduct.product_id
        }]
      }
      edit.stockType = 1
      if (this.aloneProduct && edit.bundle.bundleItems.filter((item) => {
        return parseInt(item.annonceId) === parseInt(this.aloneProduct.product_id)
      }).length === 0) {
        edit.bundle.bundleItems.push({
          quantity: 1,
          discount: 0,
          annonceId: this.aloneProduct.product_id
        })
      }
      if (typeof edit.autoPriceComputation === 'undefined') {
        edit.autoPriceComputation = false
      }
      return edit
    },
    async fetchConflicts () {
      if (this.modelId) {
        const data = await this.beforeDataFetch()
        if (data && data.response && data.response.data && data.response.data.conflicts) {
          Conflict.insertOrUpdate({ data: data.response.data.conflicts })
        }
      }
    },
    async openDrawer () {
      if (this.$refs.editPanel && !this.isProductAloneSelected) {
        await this.$refs.editPanel.beforeDataFetch()
      } else {
        await this.$refs.editProductPanel.beforeDataFetch()
      }
    },
    async handleClose () {
      await this.$router.push({
        name: 'conflict-model'
      })
    },
    getProductLabelType (migration) {
      if (migration.status === 3) {
        return 'success'
      } else {
        if (migration.product && migration.product.productType === 0 && !migration.product.archived) {
          return 'warning'
        } else if (migration.product && migration.product.archived) {
          return 'info'
        } else if (migration.product && migration.product.productType === 1) {
          return 'success'
        }
        return 'warning'
      }
    },
    goToConflictsList () {
      this.$router.push({
        name: 'conflicts'
      })
    },
    async archiveProduct () {
      this.archivedProductloading[this.productMigration.product_id] = true
      try {
        let migratedProduct = await ProductMigration.api().get('user/produit/' + this.productMigration.product_id + '/archiver')
        if (migratedProduct && migratedProduct.isSaved) {
          ElNotification({
            title: 'Conflit résolu',
            message: 'Le produit a bien été archivé',
            type: 'success'
          })
          this.archivedProductloading[this.productMigration.product_id] = false
          await this.$router.push({
            name: 'conflict-model'
          })
        } else {
          throw new Error('La requête a échoué')
        }
      } catch (e) {
        ElNotification({
          title: 'Une erreur est survenue',
          message: e.message,
          type: 'error'
        })
        this.archivedProductloading[this.productMigration.product_id] = false
      } finally {
      }
    }
  },
  computed: {
    migrationCount () {
      if (!this.modelId) {
        return this.count
      }
      if (this.modelId) {
        return this.migrationLeft
      }
    },
    migrationLeft () {
      return this.productMigrations.map(this.getProductLabelType).filter((migration) => {
        return migration === 'warning'
      }).length
    },
    highlightCurrentRow () {
      return this.hasAloneProduct || this.selectMode
    },
    aloneProduct () {
      return !this.hasAloneProduct ? this.productMigrations.find((conflict) => {
        return conflict.status === 3
      }) : null
    },
    hasAloneProduct () {
      return this.productMigrations.filter((conflict) => {
        return conflict.status === 3
      }).length <= 0
    },
    isProductAloneSelected () {
      return this.productMigration && parseInt(this.productMigration.status) === 3
    },
    Model () {
      return ProductMigration
    },
    product () {
      return Ads.find(this.id)
    },
    modelSelected () {
      if (!this.modelId) {
        return null
      }
      return Conflict.find(this.modelId)
    },
    fetchUrl () {
      return '/user/produits/conflicts/' + this.modelId
    },
    conflicts () {
      return Conflict.all()
    },
    count () {
      return this.conflicts.filter((conflict) => {
        return this.computedConflicts(conflict)
      }).length
    },
    id () {
      return this.$route.params.id;
    },
    drawer: {
      get () {
        return !!this.id
      },
      set () {
        this.$router.push({
          name: 'conflict-model'
        })
      }
    },
    modelId () {
      return this.$route.params.modelId
    },
    currentConflicts () {
      if (this.$route.params.modelId) {
        return this.conflicts.filter(c => parseInt(c.id) === parseInt(this.$route.params.modelId))
      } else {
        return this.conflicts
      }
    },
    productMigration () {
      if (!this.id) {
        return null
      }
      return ProductMigration.query()
          .with(['product'])
          .where((productMigration) => {
            return parseInt(productMigration.product_id) === parseInt(this.id)
          }).first()
    },
    productMigrations () {
      if (!this.modelId) {
        return []
      }
      return ProductMigration.query()
          .with('product')
          .where((productMigration) => {
            return parseInt(productMigration.model_id) === parseInt(this.modelId)
          })
          .orderBy((migration) => {
            const product = Ads.find(migration.product_id)
            if (migration.status === 3) {
              return 'a'
            } else {
              if (product && product.productType === 0 && !product.archived) {
                return 'd'
              } else if (product && product.archived) {
                return 'c'
              } else if (product && product.productType === 1) {
                return 'b'
              }
              return 'd'
            }
          }, 'asc')
          .get()
    },
    conflictTitle () {
      if (this.modelId && this.modelSelected) {
        return this.modelSelected.name
        // return this.ProductMi
      } else {
        return this.$t('shop.products.conflicts.title')
      }
    },
    hasNotification () {
      if (this.migrationCount === 0) {
        return false
      }
      return true
    },
    notificationType () {
      if (this.migrationCount === 0) {
        return 'alert-success'
      }
      if (!this.modelId) {
        return 'alert-info'
      }
      if (this.highlightCurrentRow && this.modelSelected && this.migrationCount > 0) {
        return 'alert-warning'
        // return 'alert-info'
      } else if (this.modelSelected && this.migrationCount > 0) {
        return 'alert-warning'
        // return 'alert-info'
      }
      return 'alert-success'
    },
    faIcon () {
      if (this.migrationCount === 0) {
        return 'fa-success-circle'
      }
      if (!this.modelId) {
        return 'fa-info-circle'
      }
      if (this.highlightCurrentRow && this.modelSelected && this.migrationCount > 0) {
        return "fa-question-circle"
      } else if (this.modelSelected && this.migrationCount > 0) {
        return "fa-exclamation-circle"
      }
      return 'fa-success-circle'
    },
    notificationText () {
      if (!this.modelId) {
        return 'Suite à la nouvelle version de l\'espace loueur et a une restructuration du parc matériel, vous ne pouvez plus créer plusieurs annonce avec le même produit. Un conflit intervient donc lorsque vous avez plusieurs annonce avec le même produit enregistré.'
      }
      if (this.highlightCurrentRow && this.modelSelected && this.migrationCount > 0) {
        return "Etape 1/2 - Quelle annonce représente le produit “"+ this.modelSelected.name +"” seul ?"
      } else if (this.modelSelected && this.migrationCount > 0) {
        return "Etape 2/2 - Résoudre les conflits du produit “"+ this.modelSelected.name +"”"
      }

      return ''
    },
    conflictDescription () {
      if (!this.modelId) {
        return 'Voici les conflits qui existent aujourd’hui dans votre catalogue. Plus d’informations sur les conflits  <a  href="https://support.lightyshare.com/article/203-gerer-les-conflits-du-parc-materiel" class="regular-underline-link" target="_blank">dans cet article de notre centre de support. </a>'
      } else {
        return 'Plus d’informations sur les conflits <a  href="https://support.lightyshare.com/article/203-gerer-les-conflits-du-parc-materiel" class="regular-underline-link" target="_blank">dans cet article de notre centre de support. </a>'
      }
      if (this.highlightCurrentRow && this.modelSelected) {
        return "Quelle annonce représente le produit “"+ this.modelSelected.name +"” seul ?"
      } else if (this.modelSelected) {
        return "Résoudre les conflits du produit “"+ this.modelSelected.name +"”"
      }
      return ''
    }
  }
}
</script>

<template>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div>
        <h3 class="subTitle">
          <span v-if="modelId" class="icomoon link-icon first" @click="goToConflictsList">&#xe910</span>{{conflictTitle}}
          <span v-if="modelId" class="countLabel" :class="migrationCount ? 'warning' : 'success'">{{ $t('shop.products.conflicts.count-problems', { count: migrationCount})}}
          </span>
          <span v-else class="countLabel warning" :class="migrationCount ? 'warning' : 'success'">{{ $t('shop.products.conflicts.count-units', { count: migrationCount})}}
          </span>
        </h3>
        <span class="card-description" v-html="conflictDescription">
        </span>
        <div v-if="hasNotification" class="alert-cart-errors" >
          <div class="alert alert-warning mb-4" :class="notificationType" role="alert">
            <div class="alert-icon">
              <span class="fa" :class="faIcon"></span>
            </div>
            <span v-html="notificationText"></span>
          </div>
        </div>
      </div>
    </div>
    <ConflictTable v-if="!modelId" :conflicts="currentConflicts"></ConflictTable>
    <ProductsConflictTable v-if="modelId" :productMigrations="productMigrations">
      <template #empty>
        <div class="empty-background">
          <div v-if="isLoading" class="loading-content">
            <div class="cv-spinner">
              <span class="spinner"></span>
            </div>
            <div class="empty-background-content-title">
              Charger les conflits pour {{ conflictTitle }}
            </div>
          </div>
        </div>
      </template>
    </ProductsConflictTable>
  </el-card>
  <el-drawer
      :withHeader="false"
      v-model="drawer"
      title="I am the title"
      :size="'80vw'"
      :before-close="handleClose"
      @open="openDrawer"
  >
    <div style="padding: 32px 12px">
      <div v-if="!isProductAloneSelected && $route.params.id !== 'new'">
        <el-form :label-position="formAlignement" label-width="300px" class="custom_form_lightyshare">
          <div class="product-creation-form-header" v-if="product">
            <h3>Gestion conflit : {{ product.titre}}</h3>
            <p class="label-info">Ajouter un produit dans votre inventaire. Vous pourrez par la suite choisir de le proposer à la location à l’unité ou créer un pack en sélectionnant plusieurs produits.</p>
          </div>
          <el-form-item >
            <template #label>
              <div>
                <h4 class="label-title">Résolution</h4>
                <p class="label-info">Comment voulez-vous résoudre ce conflit</p>
              </div>
            </template>
            <h2></h2>
            <el-card shadow="never" class="bordered-card" style="width:100%">
              <el-radio-group v-model="edit.stockType" class="block-radio">
                <el-radio :label="1" class="label-title">Transformer en pack</el-radio>
                <p class="label-info text-radio-info" >Transformer cette annonce en pack et ajouter des produits</p>
                <el-radio :label="0" class="label-title">Archiver cette annonce</el-radio>
                <p class="label-info text-radio-info" >Supprimer cette annonce de mon catalogue</p>
              </el-radio-group>
            </el-card>
          </el-form-item>
        </el-form>
        <div v-show="edit.stockType === 1">
          <BundleEdit  ref="editPanel" :formAlign="formAlignement" :title-header="false" :prefillData="prefilledData" :autoPriceComputation="false" @afterSave="handleClose" :migrateFromDetails="true" ></BundleEdit>
        </div>
        <el-button v-if="edit.stockType === 0 && this.id" @click="archiveProduct" :loading="archivedProductloading[productMigration.product_id]" plain type="info" size="large" class="btn btn-save">Archiver</el-button>
      </div>
      <div v-else>
        <ProductEdit v-if="$route.params.id === 'new'" ref="editProductPanel" :prefill-data="prefilledProductData" :newEntity="true" :formAlign="formAlignement"  :allowConflicts="true" :cancelAction="cancelProductAction" @afterSave="afterNewAloneProduct"></ProductEdit>
        <ProductEdit v-else ref="editProductPanel" :prefill-data="prefilledProductData"  formAlign="top" :cancelAction="cancelProductAction" @afterSave="handleClose"></ProductEdit>
      </div>
    </div>
  </el-drawer>
</template>

<style scoped>

</style>