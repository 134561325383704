<script>
export default {
  name: "FooterBackground"
}
</script>

<template>
  <footer class="desktop-only">
    <div class="container">
      <div class="row">
        <div class="col-md-6 text-center text-md-left">
          <img :src="asset('img/new/logo-light.png')" alt="LightyShare" class="footer-logo"/>
        </div>

        <div class="col-md-6 d-md-flex justify-content-md-between">
          <div>
            <h4>{{$t('footer.title.1')}}</h4>
            <nav>
              <ul>
                <!-- <li><a href="{{ path('lighty_share_service_vendez') }}">Achat / vente</a></li> -->
                <li><a :href="path('lighty_share_service_louez')">{{ $t('footer.link.1.1')}}</a></li>
                <li><a :href="path('lighty_share_service_rentabilisez')">{{$t('footer.link.1.2')}}</a></li>
                <li><a :href="path('lighty_share_user_search')">{{$t('footer.link.1.3')}}</a></li>
              </ul>
            </nav>
          </div>
          <div>
            <h4>{{$t('footer.title.2')}}</h4>
            <nav>
              <ul>
                <li><a href="#">{{$t('footer.link.2.1')}}</a></li>
                <li><a href="https://lightyshare.welcomekit.co">{{$t('footer.link.2.2')}}</a></li>
                <li>
                  <a href="https://feedback.lightyshare.com/announcements" target="_blank">{{$t('footer.link.2.3')}}</a>
                  <button class="btn btn-secondary new-btn">
                    <i class="fas fa-fire"></i> NEW
                  </button>
                </li>
                <li>
                  <a href="https://feedback.lightyshare.com/roadmap" target="_blank">{{$t('footer.link.2.4')}}</a>
                  <button class="btn btn-secondary new-btn">
                    <i class="fas fa-fire"></i> NEW
                  </button>
                </li>
                <li><a :href="path('lighty_share_lighty_share_presse')">{{$t('footer.link.2.5')}}</a></li>
                <li><a href="http://blog.lightyshare.com">{{$t('footer.link.2.6')}}</a></li>
                <!-- <li><a href="{{ path('lighty_share_lighty_share_notice_assurance') }}">Assurance</a></li> -->
              </ul>
            </nav>
          </div>
          <div>
            <h4>{{$t('footer.title.3')}}</h4>
            <nav>
              <ul>
                <li><a href="https://support.lightyshare.com/" >{{$t('footer.link.3.1')}}</a></li>
                <li><a href="https://support.lightyshare.com/collection/52-locataires">{{$t('footer.link.3.2')}}</a></li>
                <li><a href="https://support.lightyshare.com/collection/60-proprietaires">{{$t('footer.link.3.3')}}</a></li>
                <li>
                  <a target="_blank" href="https://feedback.lightyshare.com/">{{$t('footer.link.3.4')}}</a>
                  <button class="btn btn-secondary new-btn">
                    <i class="fas fa-fire"></i> NEW
                  </button>
                </li>
                <li><a :href="path('lighty_share_lighty_share_contact')">{{$t('footer.link.3.5')}}</a></li>

              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </footer>


  <section class="post-footer desktop-only">
    <div class="container">
      <div class="row">
        <nav class="col-md-4 text-center order-md-2 post-footer-links">
          <a :href="path('lighty_share_lighty_share_conditions_generales')">{{$t('footer.link.4.1')}}</a>
          <a :href="path('lighty_share_lighty_share_politique_confidentialite')">{{$t('footer.link.4.2')}}</a>
          <a :href="path('lighty_share_lighty_share_notice_assurance')">{{$t('footer.link.4.3')}}</a>
        </nav>
        <nav class="col-md-4 text-center text-md-right order-md-3 post-footer-social-icons">
          <a href="http://www.facebook.com/lightyshare" target="_blank"><i class="fab fa-facebook-f"></i></a>
          <a href="http://www.twitter.com/lightyshare" target="_blank"><i class="fab fa-twitter"></i></a>
          <a href="https://www.instagram.com/lightyshare" target="_blank"><i class="fab fa-instagram"></i></a>
          <a href="https://www.linkedin.com/company/lightyshare" target="_blank"><i class="fab fa-linkedin"></i></a>
        </nav>
        <div class="col-md-4 text-center text-md-left order-md-1">
          <p>&copy; {{$t('footer.text')}}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>