<template>
    <div class="first">
        <div class="img">
            <img v-if="user.ImageProfil" :src="asset('images-upload/' + user.id +'/profil/' + user.ImageProfil.imageName)" alt="">
            <div v-if="user.ImageProfil === null" :class="avatarColor">
                <div class="initials">{{ initials }}</div>
            </div>
        </div>
        <div class="text">
            <div class="name">
                <h4>
                    <a :href = "path('my_user_show', {'id' : user.id})" style="text-decoration: none; color: black;"> {{ user.fullName }}</a>
                    <span v-html="statusUser(user)" class="pro"/>
                    <span v-if="isExpert(user)" class="export"><i class="fa fa-star"></i> {{$t('box.user.status.expert')}}</span>
                </h4>
            </div>
            <div class="name2">
            <h4>
                <span class="star">
                    <i class="fas fa-star"></i> {{notationAvis}} <em>({{nbAvis}})</em>
                </span>
                <span v-if="userHasSociety(user)" class="local-cam"> <em></em> <img :src="asset('img/svg/Local-cam.svg')"> {{ user.infoSociete.raisonSociale }}</span>
            </h4>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LocationBoxUser',
    props: {
        auth : {
            type: Object
        },
        user : {
            type: Object
        },
        DL : {
            type: Object
        },
    },
    computed: {
        locataire () {
            return this.DL.locataire
        },
        proprietaire () {
            return this.DL.proprietaire
        },
        isTenant() {
            return this.auth.id == this.locataire.id ? true : false
        },
        isOwner() {
            return this.auth.id == this.proprietaire.id ? true : false
        },
        avatarColor() {
            var items = ['avatar-color-2', 'avatar-color-3', 'avatar-color-4'];
            var item = items[Math.floor(Math.random() * items.length)];
            return "avatar avatar-sm-55 " + item
        },
        nbAvisL() {
            return this.locataire.nbAvis
        },
        nbAvisP() {
            return this.proprietaire.nbAvis
        },
        notationAverageL() {
            return this.locataire.notationAverage
        },
        notationAverageP() {
            return this.proprietaire.notationAverage
        },
        notationAvis () {
            if(this.isOwner) {
                return this.nbAvisL > 0 ? this.notationAverageL : "-"
            } else if(this.isTenant) {
                return this.nbAvisP > 0 ? this.notationAverageP : "-"
            }
        },
        nbAvis () {
            if(this.isOwner) {
                return this.nbAvisL > 0 ? this.nbAvisL : this.$t('box.user.nbAvis')
            } else if(this.isTenant) {
                return this.nbAvisP > 0 ? this.nbAvisP : this.$t('box.user.nbAvis')
            }
        },
        initials () {
          return this.user.fullName.split(' ').length >= 2 ? this.user.fullName.split(' ')[0].charAt(0) + this.user.fullName.split(' ')[1].charAt(0) : ''
        }
    },
    methods: {
        capitalizeFirstLetter(string) {
            return typeof string === 'string' && string.length ? string.charAt(0).toUpperCase() + string.slice(1) : '' ;
        },
        fullName(user) {
            return typeof user.prenom === 'string' && typeof user.nom === 'string' ? this.capitalizeFirstLetter(user.prenom) + " " + user.nom.charAt(0) + "." : ''
        },
        statusUser(user) {
            if(user.statutUser == 2 || user.statutUser == 3) {
                return this.$t('box.user.status.pro')
            }
        },
        isExpert (user) {
            return user.isExpert == true ? true : false
        },
        userHasSociety(user) {
            return user.statutUser > 1 && user.statutUser && user.infoSociete && user.infoSociete !== null ? true : false
        }
    }
}

</script>