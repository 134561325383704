
<template>
  <div>
    <el-alert v-if="generalError" :title="generalError.title" :description="generalError.description" type="error" show-icon style="margin-bottom: 12px;" :closable="false"/>
    <el-alert v-if="error" :title="error.title" :description="error.description" type="error" show-icon style="margin-bottom: 12px;" :closable="false"/>
    <h2 class="signup-form-title">{{$t('signup.company.title')}}</h2>
    <div class="form-group">
      <p class="signup-form-description">{{$t('signup.company.description')}}</p>
    </div>
    <el-form-item :label="$t('signup.company.label-name')" :error="formErrors && formErrors.email && formErrors.raisonSociale[0]" @input="resetError('raisonSociale')">
      <el-input v-model="edit.user.infoSociete.raisonSociale" :placeholder="$t('signup.company.label-name')" class="full-width-input" required />
    </el-form-item>
    <el-form-item :label="$t('signup.company.label-status')" :error="formErrors && formErrors.formeJuridique && formErrors.formeJuridique[0]" @input="resetError('formeJuridique')">
      <el-input v-model="edit.user.infoSociete.formeJuridique" :placeholder="$t('signup.company.placeholder-status')" class="full-width-input" required />
    </el-form-item>
    <el-form-item :label="$t('signup.company.label-registration-number')" :error="formErrors && formErrors.siret && formErrors.siret[0]" @input="resetError('siret')">
      <el-input v-model="edit.user.infoSociete.siret" :placeholder="$t('signup.company.placeholder-registration-number')" class="full-width-input" required />
    </el-form-item>
    <div class="form-group mt-4">
      <el-button class="signup-primary" :class="hasChanged ? '' : 'disabled'" @click="continu" :loading="isLoading">
        {{$t('signup.continue')}}
      </el-button>
    </div>
  </div>
</template>
<script>
import signupHandler from '../mixins/signupHandler.js'

export default {
  name: "RegisterCompany",
  mixins: [signupHandler],
  data () {
    return {
      edit: {
        user: {
          infoSociete: {
            raisonSociale: '',
            formeJuridique: '',
            siret: '',
          },
          adresseSiege: null,
          raisonSociale: '',
          formeJuridique: '',
          siret: '',
        },
        raisonSociale: '',
        formeJuridique: '',
        siret: '',
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log('beforeRouteEnter')
    next((vm) => {
      vm.setDefaultData()
      vm.redirectIfNoSiretTry()
    })
  },
  methods: {
    setDefaultData () {
      // console.log('setDefaultData')
      // console.log(this.signupUser)
      if (this.signupUser && this.signupUser.infoSociete) {
        this.edit.user = JSON.parse(JSON.stringify(this.signupUser))
      }
    },
    redirectIfNoSiretTry () {
      if (!this.signupUser.infoSociete) {
        this.$router.push({name: 'RegisterSiret'})
      }
    },
    async beforeContinu () {
      // console.log(this.edit)
      const data = {...this.edit.user}
      // console.log(data)
      const response = await this.sendData(data, 'User')

      return response && response.response && response.response.data && response.response.data.valid
    }
  },
  computed: {
    submitUrl () {
      return 'user/check-company'
    },
    hasChanged () {
      // return true
      return this.edit.user && this.edit.user.infoSociete && this.edit.user.infoSociete.raisonSociale && this.edit.user.infoSociete.raisonSociale.length > 1 && this.edit.user.infoSociete.formeJuridique && this.edit.user.infoSociete.formeJuridique.length > 2 && this.edit.user.infoSociete.siret && this.edit.user.infoSociete.siret.length > 3
    }
  },
}
</script>

<style scoped>

</style>