<template>
  <AdminComputationEdit v-if="addComputationActive"></AdminComputationEdit>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div>
        <h3 class="subTitle">Modèles de Calculs <span v-if="paginationQuery.count" class="countLabel">{{paginationQuery.count}} Modèles</span></h3>
      </div>
        <el-button size="large" v-if="!addComputationActive" @click.prevent="addComputation">
          Ajouter un modèle
        </el-button>
    </div>
    <ComputationsTable :default-order="tableSortData" :computations="modelList" @sort-change="sortChange" @row-dblclick="editComputation">
    </ComputationsTable>
    <div class="pagination-container">
      <el-pagination v-if="!isLoading" :current-page="currentPage" background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>
    </div>
  </el-card>
</template>

<script>
import CustomFilterTable from "../../../components/CustomFilterTable.vue";
import BillingsTable from "../../billing/components/BillingsTable.vue";
import BillingsTableV2 from "../../billing/components/BillingsTableV2.vue";
import LoadContent from "../../../mixins/loadContent.js";
import Computed from "../services/Computed.js";
import ComputationsTable from "../components/ComputationsTable.vue"
import AdminComputationEdit from "./AdminComputationEdit.vue";

export default {
  name: "ComputedList",
  fetchUrl: 'admin/computes',
  mixins: [LoadContent],
  data () {
    return {
      addComputationActive: false
    }
  },
  computed: {
    Model() {
      return Computed
    },
  },
  methods: {
    addComputation () {
      this.addComputationActive = true
    },
    editComputation (row) {
      this.$router.push({name: 'admin-edit-economics', params: { id: row.id}})
    },
  },
  components: {
    AdminComputationEdit,
    CustomFilterTable,
    ComputationsTable,
    BillingsTableV2
  },
}
</script>

<style scoped>

</style>