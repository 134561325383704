<template>
  <ul v-show="!noSpaceLeft" class="tab-navigation" ref="custom-tabs">
      <RouterLink v-for="(tab) in tabs" :class="'tab-item'" :to="tab.to">
<!--        <el-badge is-dot :value="12" class="item">-->
          <li>{{tab.label + ' '}}</li>
<!--        </el-badge>-->
      </RouterLink>
  </ul>
  <el-select v-show="noSpaceLeft" v-model="currentPage"  placeholder="Select" size="large" class="tabsSelect">
    <el-option
        v-for="(tab) in tabs"
        :key="tab.to.name"
        :label="tab.label"
        :value="tab.to.name"
    />
  </el-select>
</template>

<script>
import screenBehavior from "~/mixins/screenBehavior.js";

export default {
  name: "CustomTabs",
  mixins: [screenBehavior],
  props: {
    tabs: {
      default () {
        return []
      }
    }
  },
  data () {
    return {
      tabsWidth: 0
    }
  },
  methods: {
    getTo (name) {
      return this.tabs.find((tab) => tab.to.name === name)
    },
    updateTabsWidth () {
      if (this.$refs && this.$refs['custom-tabs']) {
        // get custom tabs childrens
        const childrens = this.$refs['custom-tabs'].children
        // get custom tabs childrens width + margin
        const childrensWidth = Array.from(childrens).map((child) => {
          return child.offsetWidth + 20
        })
        // sum childrens width
        const totalWidth = childrensWidth.reduce((a, b) => a + b, 0)
        this.tabsWidth = totalWidth + 22
        // return this.$refs['custom-tabs']
      }
    }
  },
  mounted() {
    this.updateTabsWidth();
  },
  computed: {
    currentPage: {
      get () {
        return this.$route.name
      },
      set (val) {
        const tab = this.getTo(val)
        this.$router.push(tab.to)
      }
    },
    noSpaceLeft () {
      return this.tabsWidth > this.screen.width
    },
    customTabsTotalElementWidth () {
    }
  }
}
</script>


<style scoped>
.tab-navigation {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #dee2e6;
  margin: 0 0 32px 0;
  padding-left: 0px;
  /*height: 19px;*/
}
.tab-item {
  /*height: 40px;*/
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  font-family: "Nunito Sans", sans-serif;
  & li {
    font-family: "Nunito Sans", sans-serif;
  }
  /* identical to box height */


  /* Gray/500 */
  margin-right: 20px;
  padding-bottom: 16px;
  color: #667085;
  font-family: "Nunito Sans", sans-serif;
  margin-bottom: -1px !important;
  /*display:block;*/
  /*float: left;*/
  list-style: none;
}
.tab-item.router-link-active {
  border-bottom: 3px solid var(--el-color-primary);
  color: #FC2249;
}
.availability-section {
  padding: 0px;
}
.tabsSelect {
  width: 100%;
  margin-bottom: 32px;
}

</style>