<template>
  <section class="col-sm-10 owner-settings-page-section offer-section" id="bank-infos-section" section-title="Infos bancaires">
    <form action="" method="POST" v-if="contentLoaded">
      <h2 class="form-section-title">
        {{$t('owner-settings.list-group.item.5.title')}}
      </h2>
      <p class="form-section-description mb-4">
        {{$t('owner-settings.list-group.item.5.description')}}
      </p>

      <div v-if="hasErrors" class="alert-cart-errors" >
        <div v-for="(error, key) in errors" class="alert alert-warning mb-4" role="alert">
          <div class="alert-icon">
            <span class="fa fa-thumbs-down" ></span>
          </div>
          <span v-html="$t('mangopay.BankInfo.Errors.' + key)"></span>
        </div>
      </div>
      <div class="form-row" v-if="contentLoaded">
        <div class="form-group col-md-6 pr-md-3">
          <label for="bank-info-owner">
            {{$t('owner-settings.list-group.item.5.form.1')}}
          </label>
          <input type="text" class="form-control" id="bank-info-owner" name="bankOwner" placeholder="Titulaire du compte" v-model="edit.bankAccount.name">
          <div class="invalid-feedback"></div>
        </div>
        <div class="form-group col-md-6 pl-md-3">
          <label for="bank-info-BIC">
            {{$t('owner-settings.list-group.item.5.form.2')}}
          </label>
          <input type="text" class="form-control" id="bank-info-BIC" name="bankBic" placeholder="XXXXXXXXXXX" v-model="edit.bankAccount.bic">
          <div class="invalid-feedback"></div>
        </div>
      </div>

      <div class="form-group">
        <label for="bank-info-IBAN">
          {{$t('owner-settings.list-group.item.5.form.3')}}
        </label>
        <input type="text" class="form-control" id="bank-info-IBAN" name="bankIban" placeholder="XXXX XXXX XXXX XXXX XXXX XXXXX" v-model="edit.bankAccount.iban">
        <div class="invalid-feedback"></div>
      </div>

      <div class="form-group">
        <label for="bank-info-adress">
          {{$t('owner-settings.list-group.item.5.form.4')}}
        </label>
        <input type="text" class="form-control" id="bank-info-adress" name="bankAddress" placeholder="12 Rue du cinéma" v-model="edit.bankAccount.adresseCompte">
        <div class="invalid-feedback"></div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6 pr-md-3">
          <label for="bank-info-city">
            {{$t('owner-settings.list-group.item.5.form.5')}}
          </label>
          <input type="text" class="form-control" id="bank-info-city" name="bankCity" placeholder="Hollywood" v-model="edit.bankAccount.villeCompte">
          <div class="invalid-feedback"></div>
        </div>
        <div class="form-group d-none col-md-6 pl-md-3">
          <label for="bank-info-country">
            {{$t('owner-settings.list-group.item.5.form.6')}}
          </label>
          <input type="text" class="form-control" id="bank-info-country" name="bankCountry" placeholder="France">
          <div class="invalid-feedback"></div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-2">
          <label for="bank-info-zip">
            {{$t('owner-settings.list-group.item.5.form.7')}}
          </label>
          <input type="text" class="form-control" id="bank-info-ZIP" name="bankZipCode" placeholder="00000" v-model="edit.bankAccount.codePostalCompte">
          <div class="invalid-feedback"></div>
        </div>
      </div>
      <div v-if="edit.user.isMensualise" class="form-check">
        <input class="form-check-input" value="on" type="checkbox" id="mandat" name="mandat">
        <label class="form-check-label" for="mandat">
          {{$t('owner-settings.list-group.item.5.form.8')}}
        </label>
      </div>

      <button class="btn btn-short btn-primary mt-4 mb-4" :class="buttonClass" id="save-button-section-bank-info" @click.prevent="sendForm">
        <el-icon v-if="formLoading" class="is-loading" style="margin: 0px 10px;
    margin-left: 0px;" > <Loading/></el-icon>{{$t('owner-settings.list-group.item.5.save')}}
      </button>

    </form>
  </section>
</template>

<script>
import formHandler from "../../mixins/formHandler";
import { ElNotification } from 'element-plus'

export default {
  name: "BankInfo",
  mixins: [formHandler],
  data () {
    return {
      edit: {
        user: {
          isMensualise: false
        },
        bankAccount: {
          codePostalCompte: null,
          villeCompte: null,
          adresseCompte: null,
          iban: null,
          bic: null,
          name: null
        }
      },
      user: {
        isMensualise: false
      },
      errors: {},
      content: {
        bankAccount: {
          codePostalCompte: null,
          villeCompte: null,
          adresseCompte: null,
          iban: null,
          bic: null,
          name: null
        }
      },
      bankAccount: {
        codePostalCompte: null,
        villeCompte: null,
        adresseCompte: null,
        iban: null,
        bic: null,
        name: null
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  computed: {
    hasErrors () {
      return Object.keys(this.errors).length > 0
    },
    checkBankAccountFields () {
      return [
        'codePostalCompte',
        'villeCompte',
        'adresseCompte',
        'iban',
        'bic',
        'name'
      ]
    },
    bankAccountValid () {
      return this.checkBankAccountFields.reduce((acc, key, value) => {
        return acc && this.edit.bankAccount[key] !== null && this.edit.bankAccount[key] !== ''
      }, true)
    },
    buttonClass () {
      return this.hasUpdate && this.bankAccountValid ? '' : 'disabled'
    }
  },
  watch: {
    'content.bankAccount': {
      handler (val, oldVal) {
        console.log(val)
        console.log(oldVal)
        if (val === null) {
          val = {
            ...oldVal
          }
          this.content.bankAccount = {
            ...oldVal
          }
        }
      }
    }
  },
  methods: {
    afterFormSend (response) {
      if (response && response.data && response.data.error && response.data.error.Errors) {
        ElNotification({
          title: 'Erreur lors de la sauvegarde',
          message: 'Vos informations n\'ont pas été enregistré',
          type: 'error'
        })
        this.errors = response.data.error.Errors
      } else {
        ElNotification({
          title: 'Modification des informations bancaire réussi',
          message: 'Vos informations ont été enregistré',
          type: 'success'
        })
        this.errors = {}
      }
      console.log('after send form')
      console.log(response)
    },
    transformHook (edit) {
      const content = {
        ...edit['bankAccount'],

      }
      return content
    }
  }

}
</script>

<style scoped>

</style>