<template>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div>
        <h3 class="subTitle">{{ $t('owner-rental.title') }} <span class="countLabel"> {{$t('owner-rental.count.'+ status, {count: paginationQuery.count}) }}</span></h3>
        <span class="card-description">
          {{ $t('owner-rental.card-description')}}
        </span>
      </div>
      <CustomFilterTable :placeholderSearch="'Recherche une reservation'"/>
    </div>
    <AsksTable :default-order="tableSortData" :asks="modelList" @sort-change="sortChange">
      <template #empty>
        <div class="empty-background">
          <div v-if="isLoadingContent" class="loading-content">
            <div class="cv-spinner">
              <span class="spinner"></span>
            </div>
            <div class="empty-background-content-title">
              {{ $t('owner-rental.asks-list.loading')}}
            </div>
          </div>
          <div v-else class="empty-content">
            <div>
              <div class="non-clickable-button icomoon">
                &#xea16
              </div>
              <div class="empty-background-content-title">
                {{ $t('owner-rental.asks-list.empty.title')}}
              </div>
              <div class="empty-background-content-description">
                {{ $t('owner-rental.asks-list.empty.description')}}
              </div>
            </div>
            <div class="empty-background-content-button">
              <el-button size="large" type="primary" @click="openModal"><span class="icomoon" style="margin-right: 8px">&#xe9d1</span>   {{ $t('owner-rental.asks-list.empty.button')}}</el-button>
            </div>
          </div>
        </div>
      </template>
    </AsksTable>
    <Teleport to="body">
      <ModalLayout :show="openShareModal" @close="closeModal">
        <template #header>
          <h3><span class="icon-kit">&#xe9d1</span>Partager ma boutique</h3>
        </template>
        <template #body>
          <ShareCart @close="closeModal" :share-link="shareLinkShop">
          </ShareCart>
        </template>
      </ModalLayout>
    </Teleport>
    <div class="pagination-container" v-if="paginationQuery.countPage > 1">
      <el-pagination :current-page="currentPage" background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>
    </div>
  </el-card>
</template>

<script>
import CustomFilterTable from "~/components/CustomFilterTable.vue";
import BillingsTable from "~/modules/billing/components/BillingsTable.vue";
import LoadContent from "~/mixins/loadContent.js";
import Rental from "~/modules/ask/services/Rental.js";
import AsksTable from "~/modules/ask/components/AsksTable.vue";
import ShareCart from "~/modules/cart/components/ShareCart.vue";
import ModalLayout from "~/layouts/ModalLayout.vue";
import Auth from "~/modules/auth/services/Auth.js";

export default {
  name: "AsksList",
  mixins: [LoadContent],
  components: {ModalLayout, ShareCart, AsksTable, BillingsTable, CustomFilterTable},
  props: {
    status: {
      default: 'all'
    }
  },
  data () {
    return {
      openShareModal: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  computed: {
    shareLinkShop () {
      if (this.auth && this.auth.id) {
        let nomDeDomaine = window.location.hostname;
        return 'https://' + nomDeDomaine + '/public/' + this.auth.id + '/show'
      } else {
        return ''
      }
    },
    auth () {
      return Auth.query().first()
    },
    Model () {
      return Rental
    },
    fetchUrl () {
      return '/api/user/reservations/' + this.status
    }
  },
  methods: {
    async openModal () {
      this.openShareModal = true
    },
    async closeModal () {
      this.openShareModal = false
    },
    applySearchQuery (query) {
      if (this.search.length > 0) {
        query.whereHas('locataire', (query) => {
          query.where((locataire) => {
            return (locataire.fullName && locataire.fullName.toLowerCase().includes(this.search.toLowerCase()))
          })
        }).orWhere((ask) => {
          let find = false
          return find || (ask.titre_location && ask.titre_location.includes(this.search)) || ask.id === parseInt(this.search)
        })
      }
    },
    preQuery (query) {
      query.with(['locataire.ImageProfil', 'locataire.infoSociete'])
      let now = new Date();
      now.setHours(0,0,0,0);
      if (this.status === 'today') {
        query.where((ask) => {
          let departDate = new Date(ask.departUnix * 1000);
          departDate.setHours(0,0,0,0);
          let finDate = new Date(ask.finUnix * 1000);
          finDate.setHours(0,0,0,0);
          return (departDate.getTime() === now.getTime() || finDate.getTime() === now.getTime()) && !ask.canceled
        })
      } else if (this.status === 'waiting') {
        query.where((ask) => {
          return (ask.statutLocataire === 1 && ask.statutProprietaire < 3 && ask.departUnix >= now.getTime() / 1000 && !ask.canceled)
        })
      } else if (this.status === 'departures') {
        query.where((ask) => {
          return (ask.statutLocataire === 3 && ask.statutProprietaire === 3 && ask.departUnix >= now.getTime() / 1000 && !ask.canceled)
        })
      } else if (this.status === 'arrivals') {
        query.where((ask) => {
          return (ask.statutLocataire >= 3 && ask.statutProprietaire >= 3) && ask.departUnix < now.getTime() / 1000 && ask.finUnix >= now.getTime() / 1000 && !ask.canceled
        })
      } else if (this.status === 'cancel') {
        query.where((ask) => {
          return ask.canceled
        })

      }
    },
  }
}
</script>

<style scoped>

</style>