<template>
  <div class="product-creation-form-header">
    <h3>{{ $t('steps-location.product-modal.title') }}</h3>
  </div>
<!--  <el-form :model="form" label-width="30%" label-position="left" size="large">-->
    <el-form-item  label="Marque">
      <el-input v-model="edit.marque" type="text" placeholder="Sony"/>
<!--      <ItemSelect v-model="edit.marque" :types="['marque']" :ESOptions="{params: {p: 12}}" placeholder="Sony"/>-->
<!--        <el-input v-model="edit.marque.nom" type="text" placeholder="Alpha 7S">-->
<!--        </el-input>-->
    </el-form-item>
    <el-form-item  label="Modèle">
      <el-input v-model="edit.modele" type="text" placeholder="Alpha 7S"/>
    </el-form-item>
    <el-form-item  label="Catégorie">
      <ItemSelect v-model="edit.sousCategorie" :types="['categorie']" :ESOptions="{params: {p: 12}}" placeholder="Camera DSLR" :loadOnFocus="true"/>
    </el-form-item>
    <div class="actions">
      <el-button @click.prevent="cancel">
        Annuler la demande
      </el-button>
    </div>
  <hr>
<!--  </el-form>-->
</template>
<script>
  import formHandler from "../../../mixins/formHandler";
  import Modele from '../services/Modele'
  import ItemSelect from "./ItemSelect.vue";
  import ItemElSelect from "~/modules/ad/components/ItemElSelect.vue";
  function objectEquals(x, y, ignoreKeys) {
    'use strict';

    if (x === null || x === undefined || y === null || y === undefined) { return x === y; }
    if (x.constructor !== y.constructor) { return false; }
    if (x instanceof Function) { return x === y; }
    if (x instanceof RegExp) { return x === y; }
    if (x === y || x.valueOf() === y.valueOf()) { return true; }
    if (Array.isArray(x) && x.length !== y.length) { return false; }
    if (x instanceof Date) { return false; }
    if (!(x instanceof Object)) { return false; }
    if (!(y instanceof Object)) { return false; }

    let p = Object.keys(x);
    let q = Object.keys(y);

    // Remove ignored keys
    p = p.filter(key => !ignoreKeys.includes(key));
    q = q.filter(key => !ignoreKeys.includes(key));

    return q.every(function (i) { return p.indexOf(i) !== -1; }) &&
        p.every(function (i) { return objectEquals(x[i], y[i], ignoreKeys); });
  }
  export default {
    ApiUrl: 'user/annonce-edit',
    emits: ['cancel', 'update:modelValue'],
    // mixins: [formHandler],
    components: {
      ItemSelect,
      ItemElSelect
    },
    props: {
      isNew: {
        type: Boolean,
        default: true
      },
      modelValue: {
        type: Object,
        default () {
          return {}
        }
      }
    },
    data () {
      return {
        edit: {
          marque: '',
          modele: '',
          sousCategorie: {}
        },
        form: {

        }
      }
    },
    created () {

    },
    watch: {
      modelValue: {
        handler (val, oldVal) {
          console.log('change model value')
          if (val && !objectEquals(JSON.parse(JSON.stringify(val)), JSON.parse(JSON.stringify(this.edit)), [])) {
            this.edit = JSON.parse(JSON.stringify(val))
          }
        },
        deep: true,
        immediate: true
      },
      edit: {
        handler (val) {
          console.log('change edit value')
          this.$emit('update:modelValue', val)
        },
        deep: true
      }
    },
    computed: {
      id () {
        return false
      },
      Model () {
        return Modele
      }
    },
    methods: {
      categorieTransformer (r) {
        console.log('categorie')
        console.log(r)
        return r
      },
      cancel () {
        this.$emit('cancel')
      },
      addNewMarque () {

      }
    }
  }
</script>
