<template>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div>
        <h3 class="subTitle">Produits <span v-if="paginationQuery.count" class="countLabel">{{paginationQuery.count}} produits</span></h3>
      </div>
      <CustomFilterTable :radio-filters="FiltersProps" :new-button="'Ajouter un produit'" :placeholderSearch="'Recherche un produit'"  @new-click="handleNewClick"/>
    </div>
    <AdsTable :ads="modelList" @sort-change="sortChange" @onArchivedProduct="handleArchivedProduct">
<!--    <AdsTable :ads="[]" @sort-change="sortChange">-->
      <template #empty>
        <div class="empty-background">
          <div v-if="isLoadingContent" class="loading-content">
            <div class="cv-spinner">
              <span class="spinner"></span>
            </div>
            <div class="empty-background-content-title">
              {{ $t('shop.products.product-list.loading')}}
            </div>
<!--            <div class="empty-background-content-description">-->
<!--              {{ $t('shop.products.product-list.loading.description')}}-->
<!--            </div>-->
          </div>
          <div v-else class="empty-content">
            <div>
              <div class="non-clickable-button icomoon">
                &#xe9cc
              </div>
              <div class="empty-background-content-title">
                {{ $t('shop.products.product-list.empty.title')}}
              </div>
              <div class="empty-background-content-description">
                {{ $t('shop.products.product-list.empty.description')}}
              </div>

            </div>
            <div class="empty-background-content-button">
              <el-button @click="handleNewClick" size="large" type="primary"><span class="icomoon" style="margin-right: 8px">&#xe9bc</span>   {{ $t('shop.products.add-product')}}</el-button>
            </div>
          </div>
        </div>
      </template>
    </AdsTable>
    <div class="pagination-container">
      <el-pagination :current-page="currentPage" hide-on-single-page background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>
    </div>
  </el-card>
</template>

<script>
import CustomFilterTable from "../../../components/CustomFilterTable.vue";
import LoadContent from "../../../mixins/loadContent.js";
import Ads from "../services/Ads.js";
import AdsTable from "../components/AdsTable.vue";
import BundleTable from "~/modules/ad/components/BundleTable.vue";

const modesValues = {
  "tous": 'Tous',
  "offline": "Hors ligne",
  "online": "En ligne",
}

export default {
  defaultOrderBy: {
    order: 'desc',
    prop: 'position'
  },
  fetchUrl: 'user/annonces',
  mixins: [LoadContent],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  components: {
    BundleTable,
    AdsTable,
    CustomFilterTable
  },
  name: "AnnoncesList",
  data () {
    return {
      FiltersProps: {
        "visible": "Visible",
        "offline": "Hors ligne",
      }
    }
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler (route) {
        // if (route.query.p) {
        //   this.currentPage = parseInt(route.query.p)
        // }
        // this.fetchDataThrottle()
      }
    }
  },
  computed: {
    Model() {
      return Ads
    },
  },
  methods: {
    handleArchivedProduct () {
      this.beforeDataFetch()
    },
    handleNewClick () {
      this.$router.push({ name: 'add-product' });
    },
    applySearchQuery (query) {
      if (this.search.length > 0) {
        query.where((ad) => {
          // console.log(ad)
          let find = false
          if (ad && ad.titre) {
            find = ad.titre.toLocaleLowerCase().includes(this.search.toLowerCase())
          }
          if (ad && ad.title) {
            find = ad.title.toLocaleLowerCase().includes(this.search.toLowerCase())
          }
          if (ad && ad.categorieAnnonce && ad.categorieAnnonce.nom) {
            find = find || (ad.categorieAnnonce.nom.toLocaleLowerCase() + " " + ad.souscategorieannonce.nom.toLocaleLowerCase()).includes(this.search.toLowerCase())
          }
          if (ad && ad.modele && ad.modele.nom) {

            let marqueAndModele = ad.modele.nom.toLocaleLowerCase() + ' ' + ad.modele.marque.nom.toLocaleLowerCase()

            find = find || marqueAndModele.toLocaleLowerCase().includes(this.search.toLowerCase())
          }
          if (ad && ad.id) {
            find = find || ad.id === parseInt(this.search)
          }
          return find
        })
      }
    },
    preQuery (query) {
      query.with('medias')
      query.where('archived', false)
      if (this.mode !== 'tous') {
        const modeValue = this.mode !== 'offline'
        query.where('publie', modeValue)
      }
      query.where('productType', 0)
      query.where((ad) => {
        return typeof ad.id === 'number'
      })
    },
    applySortQuery (query) {
      if (this.sortData) {
        if (this.sortData[0] === 'tarif.tarifInteger') {
          query.orderBy((ad) => {
            // console.log(ad)
            return ad.tarif ? ad.tarif.tarifInteger : 0
          }, this.sortData[1])
        } else {
          console.log(this.sortData[0])
          console.log(this.sortData[1])
          let sort = this.sortData[0] === 'action' ? 'aLaUneProfile' : this.sortData[0]
          query.orderBy(sort, this.sortData[1])
        }
      } else {
        query.orderBy('id', 'desc')
      }
    },
    sortChange (payload) {
      console.log('sortChange')
      if (payload.order) {
        const order = payload.order === 'ascending' ? 'asc' : 'desc'
        const sort = `${payload.prop}-${order}`
        this.addQuery({
          s: sort
        })
      } else {
        this.addQuery({
          s: false
        })
      }
    },
  }
}
</script>

<style scoped>

</style>