<script>
import LoadContent from "~/mixins/loadContent.js";
import AskModelTable from "~/modules/ad/components/AskModelTable.vue";
import DemandeAjoutModele from "~/modules/ad/services/DemandeAjoutModele.js";

export default {
  name: "AdminAskNewModel",
  components: {
    AskModelTable
  },
  fetchUrl: 'admin/asks-modele-list',
  mixins: [LoadContent],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  computed: {
    Model() {
      return DemandeAjoutModele
    },
  },
  methods: {
    preQuery (query) {
      query.with(['user.ImageProfil', 'user.infoSociete'])
    },
    handleNewClick () {
      console.log('handle new click')
    }
  }
}
</script>

<template>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div>
        <h3 class="subTitle">Demande d'ajout <span v-if="paginationQuery.count" class="countLabel">{{paginationQuery.count}} Demandes d'ajouts</span></h3>
      </div>
    </div>
    <AskModelTable :ask-models="modelList">
      <template #empty>
        <div class="empty-background">
          <div v-if="isLoadingContent" class="loading-content">
            <div class="cv-spinner">
              <span class="spinner"></span>
            </div>
            <div class="empty-background-content-title">
              {{ $t('shop.products.product-list.loading')}}
            </div>
            <!--            <div class="empty-background-content-description">-->
            <!--              {{ $t('shop.products.product-list.loading.description')}}-->
            <!--            </div>-->
          </div>
          <div v-else class="empty-content">
            <div>
              <div class="non-clickable-button icomoon">
                &#xe979
              </div>
              <div class="empty-background-content-title">
                Félicitation !
              </div>
              <div class="empty-background-content-description">
                Aucune de demande d'ajout de modèle a traiter
              </div>

            </div>
<!--            <div class="empty-background-content-button">-->
<!--              <el-button @click="handleNewClick" size="large" type="primary"><span class="icomoon" style="margin-right: 8px">&#xe9bc</span>   {{ $t('shop.products.add-product')}}</el-button>-->
<!--            </div>-->
          </div>
        </div>
      </template>
    </AskModelTable>
    <div class="pagination-container" v-if="paginationQuery && paginationQuery.count">
      <el-pagination :current-page="currentPage" hide-on-single-page background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>
    </div>
  </el-card>
</template>

<style scoped>

</style>