<template>
  <div class="rapport-annuel-section">
    <h3 class="subTitle">{{$t('owner-income.yearly.title')}}</h3>
    <p>{{$t('owner-income.yearly.description')}}</p>
    <el-form size="large">
      <el-select v-model="edit.year" placeholder="Année" style="max-width: 150px">
        <el-option
            v-for="(year, key) in yearsSelect"
            :key="key"
            :label="year"
            :value="year">
        </el-option>
      </el-select>
      <el-button class="btn btn-save" :loading="downloadLoading" :disabled="!edit.year" @click="download" style="margin-left: 12px;" plain type="info"><span class="icomoon el-icon">&#xe959</span> Télécharger </el-button>
    </el-form>
    <div v-if="responseHtml" v-html="responseHtml">

    </div>
  </div>
</template>

<script>
import Auth from "~/modules/auth/services/Auth.js";

function formatDate(date) {
  let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export default {
  name: "ReportBillings",

  data () {
    return {
      downloadLoading: false,
      responseHtml: null,
      edit: {
        year: null
      },
      elements: [
        {
          id: 2
        }
      ],
      cols: [
        {
          key: 'id',
          dataKey: 'id',
          title: 'id'
        }
      ]
    }
  },
  computed: {
    auth () {
      return Auth.query().first()
    },
    yearsSelect () {
      if (!this.auth) return []
      if (!this.auth.createdAtUnix) return []
      const dateDebut = new Date(this.auth.createdAtUnix * 1000);
      const anneeDebut = dateDebut.getFullYear();
      const anneeActuelle = new Date().getFullYear();
      const tableauAnnees = [];
      for (let annee = anneeDebut; annee <= anneeActuelle; annee++) {
        // Ajoute l'année au tableau
        tableauAnnees.push(annee);
      }
      return tableauAnnees;
    }
  },
  methods: {
    download () {
      this.downloadLoading = true
      let fromDate = new Date(this.edit.year, 0, 1);
      let toDate = new Date(this.edit.year, 11, 31);
      let from = formatDate(fromDate);
      let to = formatDate(toDate);
      // window.location = '/user/download-rapport?from=' + encodeURIComponent(from) + '&to=' + encodeURIComponent(to)
      fetch('/user/download-rapport', {
        method: 'POST',

        headers: {
          // 'content-type': 'Application/json'
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: 'from=' + encodeURIComponent(from) + '&to=' + encodeURIComponent(to)
      })
          .then(response => {
            if (!response.ok) {
              // Lance une erreur avec le statut pour la traiter dans le bloc 'catch'
              throw new Error('Erreur réseau: ' + response.statusText);
            }
            // console.log('response', response)
            // return response.text()
            return response.blob()
          })
          .then(blob => {
            // this.responseHtml = blob
            // Crée un lien et télécharge le fichier PDF
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'rapport-annuel-' + from.split('-')[0] + '.pdf';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.downloadLoading = false
          })
          .catch(error => {

            this.downloadLoading = false
            console.error('Erreur:', error)
          });
    }
  }
}
</script>

<style scoped>

</style>