<template>
  <div class="rapport-annuel-section" style="border-bottom: 0px; margin-bottom: 0px;">
    <h2 class="subTitle">{{$t('owner-income.monthly.title')}}</h2>
    <p class="explain">{{periodeText}}</p>
    <el-row >
      <el-col :span="12" :xs="24" :sm="12" class="box">
        <h3><span id="spanMontantMensu">{{ currentMonthPayoutTotal }}</span></h3>
        <p>{{$t('owner-income.monthly.amount.ttc')}}</p>
      </el-col>
      <el-col :span="12" :xs="24" :sm="12" class="box">
        <h3><span id="spanFraisMensu">{{ currentMonthPayoutFees }}</span></h3>
        <p>{{$t('owner-income.monthly.amount.ht')}}</p>
      </el-col>
    </el-row>
  </div>
  <el-card>
    <MonthlyBillingTable :billings="modelList" :defaultOrder="tableSortData" @sort-change="sortChange"></MonthlyBillingTable>
    <div class="pagination-container">
      <el-pagination v-if="!isLoading" :current-page="currentPage" background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>
    </div>
  </el-card>
</template>

<script>
import loadContent from "../../../mixins/loadContent.js";
import MonthlyBilling from "../services/MonthlyBilling.js";
import MonthlyBillingTable from "../components/MonthlyBillingTable.vue";
import Billing from "~/modules/billing/services/Billing.js";

export default {
  name: "MonthlyBillingsList",
  components: {
    MonthlyBillingTable
  },
  mixins: [loadContent],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  fetchUrl: 'monthly-billings',
  data () {
    return {
      FiltersProps: {
        "attente": "En attente",
        "facture": "Facturé",
      },
      tableOptions: {
        mode: 'Tous',
        perPage: 10,
        currentLoadingPage: 1,
        currentPage: 1,
        countPage: 0,
        search: '',
      },
      countBills: null,
      billings: [
      ],
      currentLoadedData: [],
      loadingData: {},
      currentMonthPayoutTotal: '-',
      currentMonthPayoutFees: '-',
      currentLoadingPage: 1,
      countPage: 0,
      start: false,
      end: false,
    }
  },
  computed: {
    tableSortData () {
      const sort = {}
      if (this.sortData && this.sortData.length === 2) {
        sort['prop'] = this.sortData[0]
        sort['order'] = this.sortData[1] === 'desc' ? 'descending' : 'ascending'
      }
      // if (this.)
      return sort
    },
    perPage () {
      return 10
    },
    currentDataIds () {
      return this.currentLoadedData.map(i => i.id)
    },
    Model () {
      return MonthlyBilling
    },
    periodeText () {
      if (this.start && this.end) {
        return `Suivez votre usage pour la période du ${this.start} au ${this.end}`
      }
    },
    bills () {
      const query = Billing.query().with('locataire.ImageProfil')
      query.with('facture')
      if (this.currentLoadedData.length && !this.allLoaded) {
        query.whereIdIn(this.currentDataIds)
      } else {
        const offset = (this.loadingData.currentPage - 1) * this.perPage
        query.offset(offset).limit(this.perPage)
      }
      if (this.sortData) {
        query.orderBy(this.sortData[0], this.sortData[1])
      }
      return query.get()
    },
    loadingClass () {
      return this.isLoading ? 'texth3LoadingContent' : ''
    },
  },
  watch: {
    // tableOptions: {
    //   deep: true,
    //   immediate: true,
    //   handler (tableOptions, oldTableOption) {
    //     if (!oldTableOption) {
    //       // this.updateLoadingData(tableOptions)
    //     } else {
    //       // this.updateLoadingDataThrottle()
    //     }
    //   }
    // },
    $route: {
      deep: true,
      immediate: true,
      handler (route) {
        if (route.query.p) {
          this.tableOptions.currentPage = parseInt(route.query.p)
        }
        // this.fetchDataThrottle()
      }
    },
    // search: {
    //   async handler (v) {
    //     //
    //     // this.updateSearch()
    //   }
    // }
  },
  methods: {
    // async fetchData () {
    //   const axios = await MonthlyBilling.api().get()
    //   this.currentMonthPayoutTotal = this.$currencyFormat.format(axios.response.data.currentMonthPayoutTotal)
    //   this.currentMonthPayoutFees = this.$currencyFormat.format(axios.response.data.currentMonthPayoutFees)
    //   this.start = axios.response.data.start
    //   this.end = axios.response.data.end
    // },
    async addQuery (q) {
      // console.log('add query')
      // console.log(this.$route.query)
      // console.log(q)
      const query = {
        ...this.$route.query,
        ...q
      }
      // const filters = {
      //   ...q
      // }
      if (q.q === '') {
        delete query.q
      }
      if (q.s === false) {
        delete query.s
      }
      if (q.f === false) {
        delete query.f
      }
      try {
        await this.$router.push({
          // name: 'revenus'
          query: query
        })
      } catch (e) {
        console.log('during push route', query)
        console.log(e)
      }
      console.log('finish push route', query)
    },
    changePage (page) {
      this.addQuery({
        p: page
      })
    },
    sortChange (payload) {
      console.log('sort change')
      if (payload.order) {
        const order = payload.order === 'ascending' ? 'asc' : 'desc'
        const sort = `${payload.prop}-${order}`
        this.addQuery({
          s: sort
        })
      } else {
        this.addQuery({
          s: false
        })
      }
      console.log('finish sort change')
    },
  }
}
</script>

<style>
.box {
  text-align: center;
}
.explain {
  color: #667085;
}
</style>