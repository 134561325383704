<template>
  <el-table :data="billings" style="width: 100%" :table-layout="'auto'" stripe :default-sort="defaultOrder">
    <el-table-column fixed prop="numeroFacture" label="#" width="70"/>
    <el-table-column prop="totalLocations" label="Nombre de locations" sortable />
    <el-table-column prop="prixHt" label="Total (HT)" sortable />
    <el-table-column prop="montantTva" label="TVA (TTC)" sortable />
    <el-table-column prop="periode" label="Période" width="230" sortable />
    <el-table-column fixed="right" width="30">
          <template #default="scope">
            <el-dropdown trigger="click">
              <div class="dropdown">
                <button class="dropdown-toogle" style="padding: 5px;">
                  <i class="fas fa-ellipsis-h">
                  </i>
                </button>
              </div>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <a class="dropdown-item" :href="'/user/telechargement-facture-mensuelle/' + scope.row.id">
                      Télécharger
                    </a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
    <!--        <el-button-->
    <!--            link-->
    <!--            type="primary"-->
    <!--            size="small"-->
    <!--        >-->
    <!--          Remove-->
    <!--        </el-button>-->
          </template>
      </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "MonthlyBillingTable",
  props: {
    defaultOrder: {
      type: Object,
      default () { return {}}
    },
    billings: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    // sortChange (payload) {
    //   console.log(payload)
    // }
  }
}
</script>

<style scoped>
  .dropdown-actions {
    overflow: visible;
  }
</style>