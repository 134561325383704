
<template>
  <div>
    <h2 class="signup-form-title">{{ $t('signup.confirm-phone.title') }}</h2>
    <div class="form-group">
      <p class="signup-form-description">{{$t('signup.confirm-phone.description')}} <span v-if="signupUser.telephone" v-html="signupUser.telephone.telephone"></span> - <router-link :to="{name: 'RegisterPhoneNumber'}" class="strong-link">{{$t('signup.confirm-phone.modify')}}</router-link></p>
    </div>
    <el-alert v-if="nexmo_error" :title="$t('signup.confirm-phone.error')" :description="$t('signup.confirm-phone.error_message')" type="error" show-icon style="margin-bottom: 12px;" :closable="false"/>
    <el-form-item :label="$t('signup.confirm-phone.label')">
      <div class="grid-container">
        <div v-for="(input, index) in inputs" :key="index" class="grid-item">
          <input type="text" v-model="inputs[index]" @input="handleInput(index)" @keydown.backspace="handleBackspace(index)" ref="inputRef" class="input-item"/>
        </div>
      </div>
    </el-form-item>
    <p class="condition-text"><InfoFilled class="text-icon"/>{{$t('signup.confirm-phone.warning')}}</p>
    <div class="form-group mt-4">
      <el-button class="signup-primary" :class="hasChanged ? '' : 'disabled'" @click="continu" :loading="isLoading">
        {{$t('signup.continue')}}
      </el-button>
    </div>
  </div>
</template>
<script>
import signupHandler from "../mixins/signupHandler.js";

export default {
  name: "ConfirmPhoneNumber",
  mixins: [signupHandler],
  data () {
    return {
      inputs: ['', '', '', ''],
      form_errors: {},
      nexmo_error: false
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let allreadyConfirmed = vm.signupUser && vm.signupUser.telephone && vm.signupUser.telephone.certifie
      console.log(allreadyConfirmed)
      console.log('next step')
      console.log(vm.nextStep)
      if (allreadyConfirmed) {
        if (vm.nextStep) {
          vm.$router.push({name: vm.nextStep})
        }
      }
    })
  },
  methods: {
    handleBackspace(index) {
      if (this.inputs[index] === '' && index > 0) {
        this.$refs.inputRef[index - 1].focus();
      }
    },
    handleInput(index) {
      if (this.inputs[index].length > 1) {
        this.inputs[index] = this.inputs[index].slice(0, 1);
      }
      if (index < this.inputs.length - 1 && this.inputs[index] !== '') {
        this.$refs.inputRef[index + 1].focus();
      }
    },
    async beforeContinu () {
      const response = await this.sendData({code: this.inputs.join('')}, 'phone')
      if (response && response.response && response.response.data && response.response.data.form_errors) {
        this.formErrors = response.response.data.form_errors
      }
      if (response && response.response && response.response.data && response.response.data.nexmo_error) {
        this.inputs = ['', '', '', '']
        this.nexmo_error = response.response.data.nexmo_error
      }
      return response && response.response && response.response.data && response.response.data.valid
    },
  },
  computed: {
    submitUrl () {
      return 'user/confirm-sms-code'
    },
    hasChanged () {
      return this.inputs.every(input => input !== '')
    }
  }
}
</script>
<style scoped>
input[type="number"] {
  width: 50px;
  height: 50px;
  margin: 5px;
  text-align: center;
}
</style>