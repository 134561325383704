<template>
  <div class="col-sm-10 owner-settings-page-section availability-section d-block">
    <div class="alert alert-info mb-4" role="alert">
      <div class="alert-icon">
        <span class="fa fa-check fa-info-circle"></span>
      </div>
      {{$t('owner-settings.list-group.item.3.info')}}
    </div>
    <form action="" method="POST">
      <h2 class="form-section-title">
        {{$t('owner-settings.list-group.item.3.delivery.title')}}
      </h2>
      <p class="form-section-description mb-4">
        {{$t('owner-settings.list-group.item.3.delivery.description')}}
      </p>
      <label for="livraison" class="form-checkbox-label mb-3">
        <div class="custom-checkbox">

          <input v-model="livraison" type="checkbox" id="livraison" value = "1" name="livraison" >
          <span class="check"><i class="fas fa-check"></i></span>
        </div>
        {{$t('owner-settings.list-group.item.3.delivery.checkbox')}}
      </label>

      <div class="form-row">
        <div class="form-group col-md-6 pr-md-3">
          <label for="distanceMin">
            {{$t('owner-settings.list-group.item.3.delivery.distanceMin')}}
          </label>
          <input v-model="distanceMin" type="text" id="distanceMin" name="distanceMin" maxlength="255" class="form-control " placeholder="20€"
          />
          <div class="invalid-feedback"></div>
        </div>
        <div class="form-group col-md-6 pr-md-3">
          <label for="distanceMax">
            {{$t('owner-settings.list-group.item.3.delivery.distanceMax')}}
          </label>
          <input v-model="distanceMax" type="text" id="distanceMax" name="distanceMax" maxlength="255" class="form-control " placeholder="35€"
          >
          <div class="invalid-feedback"></div>
        </div>
      </div>

      <br>
      <hr class="mt-4 mb-4">
      <br>

      <h2 class="form-section-description">
        {{$t('owner-settings.list-group.item.3.operator.title')}}
      </h2>
      <p class="form-section-description mb-4">
        {{$t('owner-settings.list-group.item.3.operator.description')}}
      </p>

      <label for="operateur" class="form-checkbox-label mb-3">
        <div class="custom-checkbox">
          <input v-model="edit.services.operateur" type="checkbox" id="operateur" value = "1" name="operateur">
          <span class="check"><i class="fas fa-check"></i></span>
        </div>
        {{$t('owner-settings.list-group.item.3.operator.description')}}
      </label>

      <br>
      <hr class="mt-4 mb-4">
      <br>

      <h2 class="form-section-description">
        {{$t('owner-settings.list-group.item.3.test.title')}}
      </h2>
      <p class="form-section-description mb-4">
        {{$t('owner-settings.list-group.item.3.test.description')}}
      </p>

      <label for="bancDeTest" class="form-checkbox-label mb-3">
        <div class="custom-checkbox">
          <input v-model="edit.services.bancDeTest" type="checkbox" id="bancDeTest" value = "1" name="bancDeTest">
          <span class="check"><i class="fas fa-check"></i></span>
        </div>
        {{$t('owner-settings.list-group.item.3.test.checkbox')}}
      </label>
      <button class="btn btn-short btn-primary mt-4" name="confirmer" :class="buttonClass" id="save-button-section-services" @click.prevent="sendForm">
        {{$t('owner-settings.list-group.item.3.save')}}
      </button>
    </form>
  </div>
</template>

<script>
import formHandler from "~/mixins/formHandler.js";

export default {
  name: "services",
  mixins: [formHandler],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  data () {
    return {
      edit: {
        serviceLivraison: {
          name: "livraison",
          validated: false
        },
        services: {
          livraison: false,
          distanceMin: '',
          distanceMax: '',
          operateur: false,
          bancDeTest: false
        }
      },
      content: {
        serviceLivraison: {
          name: "livraison",
          validated: false
        },
        services: {
          livraison: false,
          distanceMax5: '',
          distanceMin: '',
          distanceMax15: '',
          distanceMax: '',
          operateur: false,
          bancDeTest: false
        }
      }
    }
  },
  computed: {
    livraison: {
      get () {
        return this.edit && this.edit.serviceLivraison && this.edit.serviceLivraison.validated ? this.edit.serviceLivraison.validated : false
      },
      set (value) {
        if (this.edit && this.edit.serviceLivraison && this.edit.serviceLivraison.validated !== value) {
          this.edit.serviceLivraison.validated = value
        } else {
          this.edit.serviceLivraison = {
            name: "livraison",
            validated: value
          }
        }
        this.edit.services.livraison = value
      }
    },
    distanceMin: {
      get () {
        if (this.edit && this.edit.services && this.edit.services.distanceMax5) {
          return this.edit.services.distanceMax5
        }
        if (this.edit && this.edit.serviceLivraison && this.edit.serviceLivraison.service_settings && Array.isArray(this.edit.serviceLivraison.service_settings)) {

          let distanceMin = this.edit.serviceLivraison.service_settings.find((item) => item.name === 'distanceMin')
          console.log('distanceMin', distanceMin)
          if (distanceMin) {
            return distanceMin.value
          }
        } else {
          return ''
        }
      },
      set (value) {
        if (this.edit && this.edit.services) {
          this.edit.services.distanceMax5 = value
          this.edit.services.distanceMin = value
        }
        if (this.edit && this.edit.serviceLivraison && this.edit.serviceLivraison.service_settings && Array.isArray(this.edit.serviceLivraison.service_settings)) {
          // name = DistanceMin
          let distanceMin = this.edit.serviceLivraison.service_settings.find((item) => item.name === 'distanceMin')
          if (distanceMin) {
            distanceMin.value = value
          }
        }
      }
    },
    distanceMax: {
      get () {
        if (this.edit && this.edit.services && this.edit.services.distanceMax15) {
          return this.edit.services.distanceMax15
        }
        if (this.edit && this.edit.serviceLivraison && this.edit.serviceLivraison.service_settings && Array.isArray(this.edit.serviceLivraison.service_settings)) {
          let distanceMax = this.edit.serviceLivraison.service_settings.find((item) => item.name === 'distanceMax')
          if (distanceMax) {
            return distanceMax.value
          }
        } else {
          return ''
        }
      },
      set (value) {
        if (this.edit && this.edit.services && this.edit.services) {
          this.edit.services.distanceMax15 = value
          this.edit.services.distanceMax = value
        }
        if (this.edit && this.edit.serviceLivraison && this.edit.serviceLivraison.service_settings && Array.isArray(this.edit.serviceLivraison.service_settings)) {
          // name = DistanceMax
          let distanceMax = this.edit.serviceLivraison.service_settings.find((item) => item.name === 'distanceMax')
          if (distanceMax) {
            distanceMax.value = value
          }
        }
      }
    },
  },
  methods: {
    transformHook (edit) {
      const content = {
        ...edit['services'],
        livraison: this.livraison ? true : false,
        distanceMin: this.distanceMin,
        distanceMax: this.distanceMax
      }
      return content
    }
  }

}
</script>

<style scoped>

</style>