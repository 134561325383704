<script>
import AdsTable from "~/modules/ad/components/AdsTable.vue";
import CustomFilterTable from "~/components/CustomFilterTable.vue";
import Ads from "~/modules/ad/services/Ads.js";
import LoadContent from "~/mixins/loadContent.js";
import InventoriesTable from "~/modules/ad/components/InventoriesTable.vue";
import Inventory from "~/modules/ad/services/Inventory.js";
import EditInventory from "~/modules/ad/components/EditInventory.vue";

export default {
  name: "InventoryList",
  components: {EditInventory, InventoriesTable, CustomFilterTable, AdsTable},
  fetchUrl: 'inventory',
  mixins: [LoadContent],
  data () {
    return {
      selectedItem: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      console.log('beforeRouteEnter')
      const rep = await vm.beforeDataFetch()
      // rep[]
      console.log('rep')
      console.log(rep)
    })
  },
  computed: {
    fetchUrl () {
      return 'user/inventory/' + this.id
    },
    Model () {
      return Inventory
    },
    id () {
      return this.$route.params.id
    },
    ad () {
      return Ads.find(this.id)
    },
    title () {
      return this.ad ? this.ad.title : ''
    },
  },
  methods: {
    handleCurrentChange (val) {
      console.log('handleCurrentChange')
      console.log(val)
      this.$router.push({
        name: 'edit-stock',
        params: {
          id: this.id,
          stockId: val.id
        }
      })
      // if (val) {
      //   val['_token'] = this.token_csrf
      // }
      // this.selectedItem = val
    },
    handleNewClick () {
      this.$router.push({
        name: 'add-stock'
      })
      // this.selectedItem = {
      //   annonce_id: this.id,
      //   name: this.ad.sku + (this.modelList.length + 1),
      //   status: 1,
      //   value: this.ad.computeds.find((item) => item.name === 'cost').value,
      //   _token: this.token_csrf
      // }
    },
    preQuery (query) {
      query.with('annonce.unavailableRanges')
      query.where('annonce_id', parseInt(this.id))
    },
    afterSubmit () {
      this.selectedItem = null
    }
  }
}
</script>

<template>
    <div v-if="selectedItem" class="p-0" style="margin-bottom:20px">
      <h3 class="subTitle">{{selectedItem.name}}</h3>
      <EditInventory :inventory="selectedItem" @afterSubmit="afterSubmit"/>
    </div>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div>
        <h3 class="subTitle">{{title}} <span class="countLabel">{{ $t('shop.products.stock.count-units', { count: paginationQuery.count})}}</span></h3>
      </div>
      <CustomFilterTable :new-button="$t('shop.products.stock.add-stock.title')"  @new-click="handleNewClick"/>
    </div>
    <InventoriesTable :inventories="modelListWithoutPagination" @sort-change="sortChange" @handleCurrentChange="handleCurrentChange">
      <template #empty>
        <div class="empty-background">
          <div v-if="isLoadingContent" class="loading-content">
            <div class="cv-spinner">
              <span class="spinner"></span>
            </div>
            <div class="empty-background-content-title">
              {{ $t('shop.products.stock.loading')}}
            </div>
          </div>
        </div>
      </template>
    </InventoriesTable>
  </el-card>
</template>

<style scoped>

</style>