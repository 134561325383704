
<template>
  <div>
    <el-alert v-if="generalError" :title="generalError.title" :description="generalError.description" type="error" show-icon style="margin-bottom: 12px;" :closable="false"/>
    <div class="tab-pane active" id="tab1">
      <h2 class="signup-form-title">{{$t('signup.step-3.title')}}</h2>
    </div>
    <el-form-item :label="mailLabel" :error="formErrors && formErrors.email && formErrors.email[0]" @input="resetError('email')">
      <el-input v-model="edit.email" :placeholder="$t('signup.step-3.mail-user') + '@' + $t('signup.step-3.mail-domain')" class="full-width-input" required />
    </el-form-item>
    <el-form-item :label="$t('signup.step-3.password')" :error="formErrors && formErrors.plainPassword && formErrors.plainPassword[0]" @input="resetError('plainPassword')">
      <el-input v-model="edit.password" :placeholder="$t('signup.step-3.password-placeholder')" class="full-width-input" :type="passwordType" required>
        <template #suffix>
          <el-icon class="el-input__icon icon-link" @click="toogleViewPassword">
            <View v-if="!showPassword"/>
            <Hide v-else />
          </el-icon>
        </template>
      </el-input>
      <p v-if="passwordSecureLevel" class="secure-text">{{$t('signup.step-3.password-secure.title')}} : <span :class="'password-secure ' + passwordSecureClass">{{ passwordSecureWord }}</span></p>
    </el-form-item>
    <el-checkbox v-model="edit.newsletter" :label="$t('signup.step-3.label-condition-2')" size="large" />
    <p class="condition-text">
      En créant mon compte j'accepte les <a target="_blank" :href="path('lighty_share_lighty_share_conditions_generales')">conditions générales</a>, <a target="_blank" :href="path('lighty_share_lighty_share_conditions_generales')">politique de confidentialité</a> et <a target="_blank" :href="path('lighty_share_lighty_share_conditions_generales')">notice d'assurance</a>.
    </p>
    <div class="form-group mt-4">
      <el-button class="signup-primary" :class="hasChanged ? '' : 'disabled'" @click="continu" :loading="isLoading">
        {{$t('signup.continue')}}
      </el-button>
    </div>
  </div>
</template>

<script>
import signupHandler from "../mixins/signupHandler.js"

export default {
  name: "RegisterLogin",
  mixins: [signupHandler],
  data () {
    return {
      showPassword: false,
      edit: {
        email: '',
        password: '',
        plainPassword: '',
        newsletter: false
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setDefaultData()
      let allreadyLogged = vm.loggedUser
      if (allreadyLogged) {
        if (vm.nextStep) {
          vm.$router.push({name: vm.nextStep})
        }
      }
    })
  },
  methods: {
    resetError(field) {
      if (this.formErrors && this.formErrors[field]) {
        delete this.formErrors[field]
      }
    },
    async beforeContinu () {
      const response = await this.saveEditSignup()
      if (response && response.response && response.response.data) {
        this.formErrors = response.response.data.form_errors
      }
      return !this.formErrors || (!this.formErrors.email && !this.formErrors.plainPassword)
    },
    setDefaultData () {
      if (this.signupUser) {
        this.edit.email = this.signupUser.email
        this.edit.password = this.signupUser.password
        this.edit.newsletter = this.signupUser.newsletter
      }
    },
    toogleViewPassword () {
      this.showPassword = !this.showPassword
    }
  },
  watch: {
    edit: {
      handler: function (val, oldVal) {
        // console.log(val)
        if (val) {
          this.UpdateSignupUser({
            id: 1,
            email: val.email,
            password: val.password,
            newsletter: val.newsletter
          })
        }
      },
      deep: true
    }
  },
  computed: {
    mailLabel () {
      if (this.signupUser.statutUser === 1) {
        return this.$t('signup.step-3.mail')
      } else {
        return this.$t('signup.step-3.mail-pro')
      }
    },
    submitUrl () {
      return '/check-signup'
    },
    passwordSecureLevel () {
      const $badRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{0,1}$/
      const $goodRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/
      const $betterRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
      const $bestRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{9,}$/
      if ($bestRegex.test(this.edit.password)) {
        return 'strong'
      } else if ($betterRegex.test(this.edit.password)) {
        return 'medium'
      } else if ($goodRegex.test(this.edit.password)) {
        return 'weak'
      } else {
        if (this.edit.password.length < 2) {
          return false
        }
        return 'very-weak'
      }
    },
    passwordSecureClass () {
      if (this.passwordSecureLevel) {
        return 'password-secure-' + this.passwordSecureLevel
      }
      return ''
    },
    passwordSecureWord () {
      if (this.passwordSecureLevel) {
        return this.$t('signup.step-3.password-secure.' + this.passwordSecureLevel)
      }
      return ''
    },
    passwordType () {
      return this.showPassword ? 'text' : 'password'
    },
    hasChanged () {
      let mailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      // let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
      let passwordRegex = /(?=.{8,}).*/
      return mailRegex.test(this.edit.email) && passwordRegex.test(this.edit.password)
    }
  },
  components: {
  }
}
</script>

<style scoped>
.password-secure {
  font-weight: bold;
}

.password-secure-very-weak {
  color: #FF0000; /* Red */
}

.password-secure-weak {
  color: #FFA500; /* Orange */
}

.password-secure-medium {
  color: #FFD700; /* Gold (More visible than Yellow) */
}

.password-secure-strong {
  color: #008000; /* Green */
}
</style>