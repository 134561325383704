<template>
  <ElAutoResizer>
    <template #default="{ height, width }">
      <slot :height="height" :width="width"></slot>
    </template>
  </ElAutoResizer>
</template>

<script>
import { ElAutoResizer } from 'element-plus/lib'
/* unplugin-vue-components disabled */
export default {
  components: {
    ElAutoResizer
  },
  name: "AutoResizer"
}
</script>

<style scoped>

</style>