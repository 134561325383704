<template>
  <RouterView></RouterView>
</template>

<script>
export default {
  name: "SimpleLayout"
}
</script>

<style scoped>

</style>