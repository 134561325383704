<template>
  <RouterView name="Tabs"></RouterView>
  <RouterView name="Page"></RouterView>
</template>

<script>
export default {
  name: "TabsLayouts"
}
</script>

<style scoped>

</style>