<template>
  <td v-if="loading">
    <span class="textRowLoadingContent"></span>
  </td>
  <td v-else-if="UserStatus.status != 'paid'" :class="'Statut-td custom-clickable-row ' + UserStatus.class">
    <a :href="demandeLink" class="blackA" target="__blank">
      <p><span> {{UserStatus.message}}</span></p>
    </a>
  </td>
  <td v-else-if="timeLeft" :class="'Statut-td custom-clickable-row ' + startPast.class">
    <a :href="demandeLink" class="blackA" target="__blank">
      <p><span> {{startPast.sentence}}</span></p>
    </a>
  </td>
  <td v-else class="Statut-td gray custom-clickable-row">
    <a class="blackA" :href="demandeLink" @click.prevent="getInfo" target="__blank">
      <p><span>-</span></p>
    </a>
  </td>
</template>

<script>
// require("moment/min/locales.min")
// moment.locale('fr')
// moment().format('D MMM YY')
// moment.updateLocale('fr', fr)
function mergeData (...datas) {
  let result = {}
  console.log('merge data')
  for (let value of datas) {
    for (let key in value) {
      result[key] = value[key]
    }
  }
  return result
}
function spreadMergeData (...datas) {
  let result = {}
  console.log('merge data')
  for (let value of datas) {
    result = {
      ...result,
      ...value
    }
  }
  return result
}
const Status = {
  attente: 'attente',
}

export default {
  name: 'rental-status',
  props: {
    item: {
      type: [Object, Boolean]
    },
    loading: {
      default: false
    }
  },
  data () {
    return {
      statusStructure: {
        cancel: {
          class: 'red',
          message: 'Annulée'
        },
        finish: {
          class: 'green',
          message: 'Terminée'
        },
        yourTurn: {
          class: 'blue',
          message: "C'est à vous"
        },
        attente: {
          class: 'gray',
          message: "Attente Locataire"
        },
        avis: {
          class: 'blue',
          message: "Ajouter Avis"
        },
        paid: {
          isPaid: true
        }
      }
    }
  },
  computed: {
    startPast () {
      let returnData = {}
      let toDay = this.$moment()
      toDay = toDay.format('YYYY-MM-DD')
      let depart = this.$moment(this.item.depart)
      depart = this.$moment(this.item.depart, 'D MMMM YYYY').format('YYYY-MM-DD')
      if (this.$moment(depart).isSameOrAfter(toDay)) {
        returnData.class = this.$moment(this.item.depart, 'D MMMM YYYY') > this.$moment() ? 'blue' : 'red'
        const leftDays = this.$moment(this.item.depart,'D MMMM YYYY').diff(toDay, "days")
        // const leftDays = diff.format('DDD')
        returnData.sentence = leftDays == 0 ? 'Depart aujourd\'hui' : 'Depart dans ' + leftDays  + ' jours'

      } else if (this.$moment(this.item.depart, 'D MMMM YYYY') < this.$moment()) {
        returnData.class = this.$moment(this.item.retour, 'D MMMM YYYY') > this.$moment() ? 'blue' : 'red'
        const leftDays = this.$moment(this.item.retour, 'D MMMM YYYY').diff(toDay, "days")
        // const leftDays = diff.format('DDD')
        returnData.sentence = leftDays  == 0 ? 'Retour aujourd\'hui' : 'Retour dans ' + leftDays +  ' jours'
      } else {
        returnData = false
      }
      return returnData
    },
    statusWithKey () {
      let status = {}
      for (let k in this.statusStructure) {
        status[k] = {
          ...this.statusStructure[k],
          status: k
        }
      }
      return status
    },
    UserStatus() {
      const statutLocataire = this.item.statutLocataire
      const statutProprietaire = this.item.statutProprietaire
      switch (true) {
        case (this.item.canceled || this.item.locationProblem):
          return this.statusWithKey.cancel
          break;
        case (statutProprietaire == 5):
          return this.statusWithKey.terminee
          break;
        case (statutProprietaire == 4):
         return this.statusWithKey.avis
          break;
        case (statutLocataire == 1 && statutProprietaire == 1):
          return this.statusWithKey.yourTurn
          break;
        case (statutLocataire == 1 && statutProprietaire == 2):
          return this.statusWithKey.attente
          break;
        case (statutLocataire >=3 && statutProprietaire >=3 && this.item.paidAtFormat):
          return this.statusWithKey.paid
        default:
          return false
      }
    },
     timeLeft () {
      if (this.UserStatus.status == 'cancel') {
        return false
      } else if (this.UserStatus.status == 'paid'){
        return true
      } else{
        return 'rien'
      }
    },
    tsStart () {
      return this.$moment(this.item.depart, 'D MMMM YYYY')
    },
    // isPast () {
    //   const tsStart = moment(this.item.depart, 'D MMMM YYYY')
    //   return this.item.paid_at && item
    // },

    demandeLink () {
      const baseUrl = this.$lightyshareConfig && this.$lightyshareConfig.api_base_url ? this.$lightyshareConfig.api_base_url : ''
      return baseUrl + '/user/location/' + this.item.demande_location_id
    }
  },
  methods: {
    getInfo () {
      let mergedDatas = mergeData(this.data1, this.data2)
      let spreadMergedDatas = spreadMergeData(this.data1, this.data2)
    }
  }
}
</script>

<style scoped>

</style>
