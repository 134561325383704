import axios from "axios";

export const uncheckAnnonce = async function(annonceId, panierId) {
    let url = '/user/uncheck-annonce-from-panier'
    return axios.post(url, {annonceId: annonceId, panierId: panierId })
    .then(function (response) {
        return response.data
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const checkAnnonce = async function(annonceId, panierId) {
    let url = '/user/check-annonce-from-panier'
    return axios.post(url, {annonceId: annonceId, panierId: panierId })
    .then(function (response) {
        return response.data
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const uncheckLivraison = async function(panierId) {
    let url = '/user/uncheck-livraison-from-panier'
    return axios.post(url, {panierId: panierId })
    .then(function (response) {
        return response.data
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const checkLivraison = async function(panierId) {
    let url = '/user/check-livraison-from-panier'
    return axios.post(url, {panierId: panierId })
    .then(function (response) {
        return response.data
    })
    .catch(function (error) {
        console.log(error);
    });
}
function transformObjectToFormData (data) {
	const keys = Object.keys(data)
	const formData = new FormData();
	keys.forEach((key) => {
		formData.append(key, data[key])
	})
	return formData
}
export async function checkPanierAvailibility (params = {}) {
	let config = {
		headers: {'content-type': 'Application/json'}
	}
	let url = '/user/check-panier-availability'
	if (typeof params.id !== 'undefined') {
		url += '/' + params.id
		delete params.id
	}
	let data = transformObjectToFormData(params)
	return axios.post(url, data, config)
}

export const ajaxCheckUpdateLocation = async function(demandeId, dateDebut, dateFin) {
    let url = '/user/check-update-location'
    return axios.post(url, {demandeId: demandeId, dateD: dateDebut, dateR: dateFin })
    .then(function (response) {
        return response.data
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const ajaxUpdateLocation = async function(demandeId, dateDebut, dateFin) {
    let url = '/user/update-location'
    return axios.post(url, {demandeId: demandeId, dateD: dateDebut, dateR: dateFin })
    .then(function (response) {
        return response.data
    })
    .catch(function (error) {
        console.log(error);
    });
}
