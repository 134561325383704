<template>
    <b>{{$t('infos.penalty.label')}}</b>
    <p>{{$t('infos.penalty.refund')}} {{ DL.remboursement.montantRemboursementProprietaire }} € TTC</p>
    <p>{{$t('infos.penalty.choice')}}</p>
    <form method='POST' :action="path('appliquer_penalite_locataire',{'id': DL.id})">
        <div class="row col-sm-12">
            <button type="submit" class="btn submit" id="penalite_appliquer" value="Appliquer" name="appliquer">
                {{$t('infos.penalty.btnApply')}}
            </button>
            <button type="submit" class="btn submit" id="penalite_rembourser" value="Rembourser" name="cancel">
                {{$t('infos.penalty.btnRefund')}}
            </button>
        </div>
    </form>
    <p>{{$t('infos.penalty.alert')}}</p>
</template>

<script>

export default {
    name: 'LocationInfosPenalty',
    props: {
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
    },
}

</script>