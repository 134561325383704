<script>
import CustomFilterTable from "~/components/CustomFilterTable.vue";
import LoadContent from "~/mixins/loadContent.js";
import Rental from "~/modules/ask/services/Rental.js";
import AsksTable from "~/modules/ask/components/AsksTable.vue";
import ShareCart from "~/modules/cart/components/ShareCart.vue";
import ModalLayout from "~/layouts/ModalLayout.vue";

export default {
  name: "ProductTransactions",
  mixins: [LoadContent],
  components: {ModalLayout, ShareCart, AsksTable, CustomFilterTable},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  data () {
    return {
      openShareModal: false,
    }
  },
  computed: {
    shareLinkShop () {
      let nomDeDomaine = window.location.hostname;
      return 'https://' + nomDeDomaine + '/annonce/show/' + this.id
    },
    id () {
      return this.$route.params.id
    },
    Model () {
      return Rental
    },
    fetchUrl () {
      return '/api/user/produit/' + this.id + '/reservations'
    }
  },
  methods: {
    async openModal () {
      this.openShareModal = true
    },
    async closeModal () {
      this.openShareModal = false
    },
    handleNewClick () {
      console.log('new')
    },
    preQuery (query) {
      query.with('locataire.ImageProfil')
      query.where((ask) => {
        if (ask.panier && Array.isArray(ask.panier.paniersAnnonce)) {
          return ask.panier.paniersAnnonce.some((panier) => {
            return panier.annonces.id === parseInt(this.id)
          })
        } else {
          return false
        }
      })
    },
  }
}
</script>

<template>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div>
        <h3 class="subTitle">{{ $t('shop.products.histories.title') }} <span class="countLabel">{{ $t('shop.products.histories.count-units', { count: paginationQuery.count}) }}</span></h3>
      </div>
      <CustomFilterTable/>
    </div>
    <AsksTable :asks="modelList" @sort-change="sortChange">
      <template #empty>
        <div class="empty-background">
          <div v-if="isLoadingContent" class="loading-content">
            <div class="cv-spinner">
              <span class="spinner"></span>
            </div>
            <div class="empty-background-content-title">
              {{ $t('shop.products.histories.loading')}}
            </div>
          </div>
          <div v-else class="empty-content">
            <div>
              <div class="non-clickable-button icomoon">
                &#xea16
              </div>
              <div class="empty-background-content-title">
                {{ $t('shop.products.histories.empty.title')}}
              </div>
              <div class="empty-background-content-description">
                {{ $t('shop.products.histories.empty.description')}}
              </div>
            </div>
            <div class="empty-background-content-button">
              <el-button @click="openModal" size="large" type="primary"><span class="icomoon" style="margin-right: 8px">&#xe9d1</span>   {{ $t('shop.products.histories.empty.button')}}</el-button>
            </div>
          </div>
        </div>
      </template>
    </AsksTable>
    <Teleport to="body">
      <ModalLayout :show="openShareModal" @close="closeModal">
        <template #header>
          <h3><span class="icon-kit">&#xe9d1</span>Partager mon annonce</h3>
        </template>
        <template #body>
          <ShareCart @close="closeModal" :share-link="shareLinkShop">
          </ShareCart>
        </template>
      </ModalLayout>
    </Teleport>
    <div class="pagination-container" v-if="paginationQuery.countPage > 1">
      <el-pagination v-if="paginationQuery.count" :current-page="currentPage" background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>
    </div>
  </el-card>
</template>

<style scoped>

</style>