import {Model} from '@vuex-orm/core'

export default class Modele extends Model {
	static entity = 'modele'

	static apiConfig = {
		headers: { 'X-Requested-With': 'XMLHttpRequest' },
		baseURL: '/api/modele',
		dataKey: 'ads'
	}

	static fields() {
		return {
			id: this.attr(null),
			nom: this.attr(''),
			marque: this.attr({nom: ''})
		}
	}
}
