<template>
    <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 chat-right-box checkout-cart-right-box">
        <div class="checkout-cart-right-box-inner">
            <div class="chat-right-box-inner">
                <LocationBoxUser v-if="isOwner" :user="locataire" :DL="DL" :auth="auth"/>
                <LocationBoxUser v-else :user="proprietaire" :DL="DL" :auth="auth"/>
                <LocationBoxUpdate v-if="update" @dlUpdated="updatedPrices" @overlay="overlay" @showRentalDate="showRentalDate" :DL="DL" :auth="auth" :termined="termined" />
                <div class="cart-right-bottom border-none pt-0">
                    <LocationBoxPrice :DL="DL" :auth="auth" :screen="screen" :termined="termined"/>
                    <br>
                    <div class="desktop-only">
                        <LocationBoxButtons @showModalCancel="showModalCancel" :DL="DL" :auth="auth" :termined="termined" @goRentalPayment="goRentalPayment"/>
                    </div>
                </div>
            </div>
            <div class="desktop-only">
                <LocationBoxOptions :DL="DL" :auth="auth" :termined="termined"/>
            </div>
        </div>
    </div>
</template>

<script>
import LocationBoxUser from "./LocationBoxUser.vue";
import LocationBoxPrice from "./LocationBoxPrice.vue";
import LocationBoxButtons from "./LocationBoxButtons.vue";
import LocationBoxOptions from "./LocationBoxOptions.vue";
import LocationBoxUpdate from "./LocationBoxUpdate.vue";

export default {
    name: 'LocationBox',
    emits: ["showModalCancel", "dlUpdated", "overlay", "showRentalDate", "goRentalPayment"],
    components : {
        LocationBoxUser,
        LocationBoxPrice,
        LocationBoxButtons,
        LocationBoxOptions,
        LocationBoxUpdate
        },
    props: {
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
        termined : {
            type: Boolean
        },
        screen : {
            type: String
        },
        update: {
          type: Boolean,
          default: false
        },
    },
    computed: {
        locataire () {
            return this.DL.locataire
        },
        proprietaire () {
            return this.DL.proprietaire
        },
        isTenant() {
            return this.auth.id === this.locataire.id
        },
        isOwner() {
            return this.auth.id === this.proprietaire.id
        },
        stepOne () {
            return this.DL.AcceptedAt === null && this.DL.PaidAt === null && this.termined === false ? true : false
        },
        startDateLimit () {
            return this.$moment(this.DL.dateDebut).format('dddd d MMMM')
        },
        stepTwo () {
            return this.DL.AcceptedAt !== null && this.DL.PaidAt === null && this.termined == false ? true : false
        },
        stepThree () {
            return this.DL.AcceptedAt !== null && this.DL.PaidAt !== null && this.termined == false && this.DL.CanceledAt === null ? true : false
        },
        stepTermined () {
            return this.DL.AcceptedAt !== null && this.DL.PaidAt !== null && this.termined == true ? true : false
        },
        stepCanceled () {
            return this.DL.CanceledAt !== null ? true : false
        },
        stepIsLocationUpdating () {
            return this.DL.updateStatusLocation == 1 || this.DL.updateStatusLocation == 2 ? true : false
        },
    },
    methods: {
        goRentalPayment ($event) {
          this.$emit('goRentalPayment', $event);
        },
        showModalCancel(val) {
            this.$emit('showModalCancel', val);
            // this.showModalCancel = true
        },
        updatedPrices(val){
            this.$emit('dlUpdated', val);
        },
        overlay(val) {
            this.$emit('overlay', val);
        },
        showRentalDate(val) {
            this.$emit('showRentalDate', val);
        }
    }
}

</script>
