<script>
export default {
  name: "ShopBottomBar",
  emits: ['onClickMenu'],
  beforeRouteUpdate(to, from, next) {
    // Logique à exécuter avant la mise à jour de la route
    if (this.drawer) {
      this.drawer = false
    }

    // N'oublie pas d'appeler next() pour confirmer la navigation
    next();
  },
  data () {
    return {
      drawer: false
    }
  },
  methods: {
    onClickMenu () {
      this.$emit('onClickMenu')
    },
    onClickMenuItem () {
      // this.$emit()
      // this.drawer = false
    }
  }
}
</script>

<template>
  <ul class="horizontally-menu mobile-bottom-bar">
    <li><router-link :to="{name: 'dashboard'}"><span class="icon-kit">&#xe988</span><span>Dashboard</span></router-link></li>
    <li><a :href="path('lighty_share_user_messagerie_default')"><span class="icon-kit">&#xe99a</span><span>Message</span></a></li>
    <li><router-link :to="{ name: 'today-asks'}"><span class="icon-kit">&#xea16</span><span>Réservation</span></router-link></li>
    <li><a href="/" @click.prevent="onClickMenu"><span class="icon-kit">&#xe998</span><span>Menu</span></a></li>
  </ul>
</template>

<style scoped>

</style>