import User from "../../user/services/User.js";
import Media from "../../media/services/Media.js"
import { Model } from "@vuex-orm/core"
import Bundle from "./Bundle.js";
import Inventory from "~/modules/ad/services/Inventory.js";
import AdUnavailability from "~/modules/ad/services/AdUnavalability.js";

export default class Ads extends Model {
  static entity = 'ads'

  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api',
    dataKey: 'ads',
    actions: {
      fetch: {
        method: 'get',
        url: 'annonces'
      }
    }
  }
  static fields () {
    return {
      id: this.attr(null),
      sku: this.attr(null),
      oldTitle: this.attr(''),
      title: this.attr(''),
      titre: this.attr(''),
      description: this.attr(''),
      tarif: this.attr({}),
      position: this.attr(''),
      publie: this.attr(false),
      valeurAchat: this.attr(''),
      quantity: this.attr(false),
      productType: this.attr(null),
      stockType: this.attr(null),
      user_id: this.attr(null),
			medias: this.hasMany(Media, 'annonce_id', 'id'),
      // unavailableRanges: this.hasMany(AdUnavailability, 'ad_id', 'id'),
      user: this.belongsTo(User, 'user_id'),
      modele: this.attr({}),
			marque: this.attr({}),
			cover: this.attr({}),
      aLaUneProfile: this.attr(null),
      aLaUne: this.attr(false),
			souscategorieannonce: this.attr({}),
			categorieAnnonce: this.attr({}),
      detailsannonce: this.attr([]),
      imagesannonce: this.attr([]),
      computeds: this.attr([]),
      bundle: this.hasOne(Bundle, 'annonce_id'),
      stocks: this.hasMany(Inventory, 'annonce_id'),
      archived: this.attr(false),
    }
  }
}
