import User from "../../user/services/User.js";
import Media from "../../media/services/Media.js"
import { Model } from "@vuex-orm/core"
import Modele from "~/modules/ad/services/Modele.js";
import Ads from "~/modules/ad/services/Ads.js";

export default class ProductMigration extends Model {
  static entity = 'product_migrations'

  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api',
    dataKey: 'product_migrations',
  }
  static fields () {
    return {
      id: this.attr(null),
      status: this.attr(null),
      model: this.belongsTo(Modele, 'model_id'),
      model_id: this.attr(null),
      product: this.belongsTo(Ads, 'product_id'),
      product_id: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      user_id: this.attr(null),
    }
  }
}
