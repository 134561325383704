import { Model } from '@vuex-orm/core'
import User from "../../user/services/User.js";
import Bill from "./Bill.js";

export default class Billing extends Model {
  static entity = 'billing'

  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api',
    dataKey: 'bills',
    actions: {
      fetch: {
        method: 'get',
        url: 'billings'
      }
    }
    // dataTransformer: ({ data, headers }) => {
    //   // data[data]
    //   data[data.dataKey]
    //   console.log(data)
    //   const PriceValue = [
    //     'ownerRevenuTTC',
    //     'ownerRevenuHT',
    //     'ownerFeesTtc',
    //     'ownerFeesHt',
    //   ]
    //   // for (const p of PriceValue) {
    //   //   data[p] = new Intl.NumberFormat("fr-FR").format(data[p])
    //   // }
    //   return data['bills'].map((bill) => {
    //     for (const p of PriceValue) {
    //       bill[p] = new Intl.NumberFormat("fr-FR", {
    //         style: 'currency',
    //         // currency: 'EUR'
    //       }).format(bill[p])
    //     }
    //     return bill
    //   })
    // }
  }

  static fields () {
    return {
      id: this.attr(null),
      ownerRevenuTTC: this.attr(''),
      ownerRevenuHT: this.attr(''),
      billStatus: this.attr(''),
      numeroLocation: this.attr(''),
      invoices: this.attr([]),
      payoutAmount: this.attr(''),
      ownerBillingId: this.attr(''),
      tenantId: this.attr(''),
      virement: this.attr(0),
      ownerFeesTtc: this.attr(0),
      ownerFeesHt: this.attr(0),
      departUnix: this.attr(0),
      finUnix: this.attr(0),
      billedAt: this.attr(null),
      locataire: this.belongsTo(User, 'tenantId'),
      facture: this.belongsTo(Bill, 'ownerBillingId')
    }
  }
}