
<template>
  <div class="tab-pane active" id="tab1">
    <h2 class="signup-form-title">{{$t('signup.step-1.title')}}</h2>
    <div class="form-group">
      <p class="signup-form-description">{{$t('signup.step-1.description')}}</p>
    </div>
    <el-form-item :label="$t('signup.step-1.statut.title')">
      <el-select v-model="edit.statutUser" :placeholder="$t('signup.step-1.statut.0')" class="full-width-input" required>
        <el-option v-for="item in edit.statutUserOptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>

    <div class="form-group mt-4">
      <el-button class="signup-primary" :class="hasChanged ? '' : 'disabled'" @click="continu" :loading="isLoading">
        {{$t('signup.continue')}}
      </el-button>
    </div>
  </div>
</template>

<script>
  import signupHandler from "../mixins/signupHandler.js";

  export default {
    name: "RegisterStatus",
    mixins: [signupHandler],
    data () {
      return {
        edit: {
          statutUser: null,
          statutUserOptions: [
            // {value: 0, label: this.$t('signup.step-1.statut.0')},
            {value: 2, label: this.$t('signup.step-1.statut.2')},
            {value: 4, label: this.$t('signup.step-1.statut.4')},
            {value: 5, label: this.$t('signup.step-1.statut.5')},
            {value: 3, label: this.$t('signup.step-1.statut.3')},
            {value: 1, label: this.$t('signup.step-1.statut.1')},
          ]
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.setDefaultData()
        let allreadyLogged = vm.loggedUser
        if (allreadyLogged) {
          if (vm.nextStep) {
            vm.$router.push({name: vm.nextStep})
          }
        }
      })
    },
    watch: {
      edit: {
        handler: function (val, oldVal) {
          if (val) {
            this.UpdateSignupUser({
              id: 1,
              statutUser: val.statutUser
            })
          }
        },
        deep: true
      }
    },
    methods: {
      setDefaultData () {
        if (this.signupUser) {
          this.edit.statutUser = this.signupUser.statutUser
        }
      }
    },
    computed: {
      hasChanged () {
        return this.edit.statutUser !== null
      }
    }
  }
</script>
<style scoped>
.el-input--large .el-input__inner {
  --el-input-inner-height: 50px;
}
.full-width-input {
  width: 100%;
  height: 50px;
}

</style>