<script>
import User from "~/modules/user/services/User.js";
import { ElNotification } from 'element-plus'

export default {
  name: "DiscountTable",
  props: {
    defaultOrder: {
      type: Object,
      default () { return {}}
    },
    discounts: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      activateLoading: {},
      deleteLoading: {}
    }
  },
  methods: {
    async remove (id) {
      this.deleteLoading[id] = true
      try {
        let rep = await User.api().get('user/offer/delete/' + id)
        ElNotification({
          title: 'Code promo supprimé',
          message: 'Votre code promo a bien été supprimé',
          type: 'success'
        })
      } catch (e) {
        ElNotification({
          title: 'Une erreur est survenu',
          message: e.message,
          type: 'error'
        })
      }
      this.deleteLoading[id] = false
    },
    async toogleActivate (id) {
      this.activateLoading[id] = true
      try {
        let rep = await User.api().get('user/offer/desactiver/' + id)
        if (rep && rep.response && rep.response.data && rep.response.data.code) {
          let word = rep.response.data.code.actived ? 'activé' : 'désactivé'
          ElNotification({
            title: 'Code promo ' + word,
            message: 'Votre code promo a bien été ' + word,
            type: 'success'
          })
        }
      } catch (e) {
        ElNotification({
          title: 'Une erreur est survenu',
          message: e.message,
          type: 'error'
        })
      }
      this.activateLoading[id] = false
    },
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    },
    getInventoryStatus (status) {
      switch (status) {
        case true:
          return 'Activé'
        case false:
          return 'Désactivé'
      }
    },
    getInventoryStatusType (status) {
      switch (status) {
        case true:
          return 'success'
        case false:
          return 'error'
      }
    },
  }
}
</script>

<template>
  <el-table :data="discounts" style="width: 100%;" :table-layout="'auto'" :default-sort="defaultOrder"  class="pointer-row" @current-change="handleCurrentChange" highlight-current-row :row-class-name="tableRowClassName">
    <!--    <el-table-column fixed prop="id" label="#" width="60" />-->
    <el-table-column prop="identifiant" label="Code" min-width="100">
    </el-table-column>
    <el-table-column prop="publie" label="Status" sortable="custom" min-width="80">
      <template #default="{row}">
        <el-tag
            :type="getInventoryStatusType(row.actived)"
            class="mx-1"
            effect="light"
            round
        >
          <span class="dot"></span>
          {{ getInventoryStatus(row.actived)}}
        </el-tag>
        <!--        <el-switch-->
        <!--            width="90px"-->
        <!--            v-model="scope.row.publie"-->
        <!--            inline-prompt-->
        <!--            style="&#45;&#45;el-switch-on-color: #13ce66; &#45;&#45;el-switch-off-color: #ff4949"-->
        <!--            active-text="En ligne"-->
        <!--            inactive-text="Hors ligne"/>-->
      </template>
    </el-table-column>
    <el-table-column prop="pourcent_reduction" label="Réduction" sortable="custom" min-width="80"/>
    <el-table-column prop="compteur_utilisation" label="Utilisation" min-width="100" >
    </el-table-column>
    <el-table-column fixed="right" width="100px" prop="action">
      <template #default="scope">
        <div class="display-flex">
          <el-dropdown trigger="click">
            <div class="dropdown">
              <button class="dropdown-edit-toogle" style="padding: 5px;">
                <span class="icon-kit">
                  &#xe9a5
                </span>
              </button>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <a class="dropdown-item" href="#" @click.prevent="toogleActivate(scope.row.id)">
                    <el-icon v-show="activateLoading[scope.row.id]" class="is-loading"><loading/></el-icon>{{ scope.row.actived ? 'Désactiver' : 'Activer' }}
                  </a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a class="dropdown-item" href="#" @click.prevent="remove(scope.row.id)">
                    <el-icon v-show="deleteLoading[scope.row.id]" class="is-loading"><loading/></el-icon>Supprimer le code promo
                  </a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<style scoped>

</style>