<script>
import Rental from "~/modules/ask/services/Rental.js";
import LoadContent from "~/mixins/loadContent.js";

export default {
  name: "ShopAction",
  props: {
    action: {
      type: Object,
      default () {
        return {
          img: false,
          action: '',
          description: ''
        }
      }
    }
  }
}
</script>

<template>
  <div class="action-card">
    <div class="thumbnail" v-if="action && action.img">
      <img :src="action.img" alt="">
    </div>
    <div class="action-label">
      <h3>{{ action.action }}</h3>
      <p class="action-description">{{ action.description }}</p>
    </div>
  </div>
</template>

<style scoped>

</style>