<template>
    <div class="votre-project-section" style="padding: 0 0 0 0;">
        <h3 v-if="isOwner" class="multisteps-form__title">{{$t('infos.project.label.p')}}</h3>
        <h3 v-if="isTenant" class="multisteps-form__title">{{$t('infos.project.label.l')}}</h3>
        <div class="text show-more-height">
            <p v-html="description"></p>
        </div>
        <a v-if="stepOneOwner && descriptionExists" target="__blank" class="btn btn-primary btn-blue js-btn-next" style="margin-top: 55px;" :href="path('lighty_share_user_messagerie', {'roomopen': DL.chatLocation[0].chatroom.id })">
            {{$t('infos.project.btn-chatroom')}}
        </a>
    </div>
</template>


<script>


export default {
    name: 'LocationInfosProjet',
    props: {
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
        termined : {
            type: Boolean
        }
    },
    computed: {
        locataire () {
            return this.DL.locataire
        },
        proprietaire () {
            return this.DL.proprietaire
        },
        isTenant() {
            return this.auth.id == this.locataire.id ? true : false
        },
        isOwner() {
            return this.auth.id == this.proprietaire.id ? true : false
        },
        stepOneOwner() {
            return this.isOwner && this.DL.AcceptedAt === null && this.DL.CanceledAt === null && this.termined == false ? true : false
        },
        descriptionExists() {
            return this.description ? true : false
        },
        description() {
          if (this.$symfony.description !== null) {
            return this.$symfony.description
          } else if (this.DL && this.DL.description) {
            return this.DL.description
          } else {
            return ''
          }
        }
    }
}
</script>