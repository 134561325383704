<script>
import InputCity from "~/components/parameters/inputCity.vue";

export default {
  name: "AdminAddressInputDebug",
  components: {InputCity},
  props: {
    modelValue: {
      type: [Object, String]
    },
    defaultField: {
      type: String,
      default: ''
    },
    field: {
      type: String,
      default: ''
    }
  },
  watch: {
    modelValue: {
      handler (val, oldVal) {
        if (val) {
          this.edit = JSON.parse(JSON.stringify(val));
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onChangeModeAddress (val) {
      console.log('change mode')
      this.manualModeAddress = val
    },
    onIsValidAddress (val, fields) {
      this.notValidAddressFields = fields
      this.defaultAddressValid = val
    }
  },
  data () {
    return {
      edit: {
        address: null,
      },
      manualModeAddress: false,
      notValidAddressFields: false,
      defaultAddressValid: false,
    }
  }
}
</script>

<template>
  <input-city
      :select-country="true"
      :labels="true"
      label="Adresse"
      :limit="10"
      ref="inputCityBilling"
      :manualMode="true"
      :remove-manual-mode-btn="true"
      name="siege"
      style="width: 100%;"
      v-model="edit.address"
      size="large"
      class="city-input"
      placeholder="24 rue mouftard 75008 Paris"
      @change-mode="onChangeModeAddress"
      @is-valid="onIsValidAddress"
  />
</template>

<style scoped>

</style>