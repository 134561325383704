
<template>
  <div>
    <h2 class="signup-form-title">{{$t('signup.location.title')}}</h2>
    <div class="form-group">
      <p class="signup-form-description">{{$t('signup.location.description')}}</p>
    </div>
    <el-form-item :label="$t('signup.location.label')" style="width: 100%;">
      <input-city
          name="siege"
          style="width: 100%;"
          v-model="edit.address"
          size="large"
          class="city-input"
          placeholder="24 rue mouftard 75008 Paris"
      ></input-city>
    </el-form-item>
    <p class="condition-text">
      <Lock class="text-icon"/>
      {{$t('signup.location.privacy')}}
    </p>
    <div class="form-group mt-4">
      <el-button class="signup-primary" :class="hasChanged ? '' : 'disabled'" @click="continu" :loading="isLoading">
        {{$t('signup.continue')}}
      </el-button>
    </div>
  </div>
</template>
<script>

import InputCity from "~/components/parameters/inputCity.vue";
import signupHandler from "../mixins/signupHandler.js";

export default {
  mixins: [signupHandler],
  name: "RegisterLocation",
  data () {
    return {
      edit: {
        address: null
      }
    }
  },
  created() {
    let user = JSON.parse(JSON.stringify(this.signupUser))
    if (user.adresse) {
      this.edit.address = user.adresse
    }
  },
  computed: {
    submitUrl () {
      return 'user/save-location'
    },
    hasChanged () {
      return this.edit.address
    }
  },
  methods: {
    async beforeContinu () {
      const response = await this.sendData({...this.edit.address}, 'adresse')
      if (response && response.response && response.response.data && response.response.data.form_errors) {
        this.formErrors = response.response.data.form_errors
      }
      return response && response.response && response.response.data && response.response.data.valid
    },
  },
  components: {InputCity}
}
</script>

<style scoped>

</style>