<script>
import FormHandler from "~/mixins/formHandler.js";
import BankValidationBehavior from "~/modules/payment/mixins/BankValidationBehavior.js";
import BankValidationIdentityManagement from "~/modules/payment/pages/BankValidationIdentityManagement.vue";
import BankValidationCompany from "~/modules/payment/pages/BankValidationCompany.vue";
import BankValidationComplete from "~/modules/payment/pages/BankValidationComplete.vue";
import BankValidationBeneficiary from "~/modules/payment/pages/BankValidationBeneficiary.vue";

export default {
  name: "BankValidationStep",
  components: {BankValidationComplete, BankValidationCompany, BankValidationIdentityManagement, BankValidationBeneficiary},
  mixins: [BankValidationBehavior],
  computed: {
    getUserStepI () {
      return this.getUserStepIndex(this.user, this.userSteps)
    }
  }
}
</script>

<template>
  <div>

    <div v-if="currentStep">
      <BankValidationIdentityManagement v-show="currentStep.step === 'identity'"/>
      <BankValidationCompany v-if="currentStep.step === 'company'"/>
      <BankValidationBeneficiary v-if="currentStep.step === 'beneficiary'"/>
      <BankValidationComplete v-if="currentStep.step === 'complete'"/>
    </div>

  </div>
</template>

<style scoped>

</style>