<template>
  <div class="common-layout" id="website">
  <router-view name="TopBar"></router-view>
  <router-view name="Page"></router-view>
  <router-view name="Footer"></router-view>
  </div>
</template>

<script>
export default {
  name: "BaseDefaultLayout"
}
</script>

<style>
  #website {
    margin-top: 70px;
  }
</style>