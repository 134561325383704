import User from "~/modules/user/services/User.js";
import Auth from "~/modules/auth/services/Auth.js";
import FormHandler from "~/mixins/formHandler.js";

export default {
  mixins: [FormHandler],
  watch: {
    ModelEntity: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && this.content) {
          this.content = {...this.content, ...JSON.parse(JSON.stringify(val))}
        }
        if (val && !this.content) {
          this.content = JSON.parse(JSON.stringify(val))
        }
      }
    }
  },
  computed: {
    globalLoading () {
      return (this.ModelEntity && this.ModelEntity.idKycDocuments === null && this.ModelEntity.kbisKycDocuments === null && this.ModelEntity.statusKycDocuments === null && this.ModelEntity.uboKycDocuments === null && this.isLoading) || !this.ModelEntity && this.isLoading
    },
    globalDocumentsStatus () {
      if (this.isLoading) {
        return 'LOADING'
      }
      let errors = this.userDocuments.filter((step) => {
        return step && step.Status && step.Status.length && step.Status.includes('REFUSED')
      })
      if (errors.length > 0) {
        return 'REFUSED'
      }
      let outOfDate = this.userDocuments.filter((step) => {
        return step && step.Status && step.Status.length && step.Status.includes('OUT_OF_DATE')
      })
      if (outOfDate.length > 0) {
        return 'OUT_OF_DATE'
      }
      let created = this.userDocuments.filter((step) => {
        return step && step.Status && step.Status.length && step.Status.includes('CREATED')
      })
      if (created.length > 0) {
        return 'CREATED'
      }
      let validationAskeds = this.userDocuments.filter((step) => {
        return step && step.Status && step.Status.length && step.Status.includes('VALIDATION_ASKED')
      })
      if (validationAskeds.length > 0) {
        return 'VALIDATION_ASKED'
      }
      let validateds = this.userDocuments.filter((step) => {
        return step && step.Status && step.Status.length && step.Status.includes('VALIDATED')
      })
      if (validateds.length === this.userDocuments.length) {
        return 'VALIDATED'
      }
      return ''
    },
    globalStatus () {
      if (this.isLoading) {
        return 'LOADING'
      }
      let errors = this.userSteps.filter((step) => {
        return step && step.className && step.className.length && step.className.includes('REFUSED')
      })
      if (errors.length > 0) {
        return 'REFUSED'
      }
      let outOfDate = this.userSteps.filter((step) => {
        return step && step.className && step.className.length && step.className.includes('OUT_OF_DATE')
      })
      if (outOfDate.length > 0) {
        return 'OUT_OF_DATE'
      }
      let created = this.userSteps.filter((step) => {
        return step && step.className && step.className.length && step.className.includes('CREATED')
      })
      if (created.length > 0) {
        return 'CREATED'
      }
      let validationAskeds = this.userSteps.filter((step) => {
        return step && step.className && step.className.length && step.className.includes('VALIDATION_ASKED')
      })
      if (validationAskeds.length > 0) {
        return 'VALIDATION_ASKED'
      }
      let validateds = this.userSteps.filter((step) => {
        return step && step.className && step.className.length && step.className.includes('VALIDATED')
      })
      if (validateds.length === this.userSteps.length - 1) {
        return 'VALIDATED'
      }
      return ''
    },
    globalStatusType () {
      if (['OUT_OF_DATE', 'CREATED'].includes(this.globalDocumentsStatus)) {
        return 'warning'
      }
      if (['REFUSED'].includes(this.globalDocumentsStatus)) {
        return 'danger'
      }
      if (['VALIDATED'].includes(this.globalDocumentsStatus)) {
        return 'success'
      }
      if (['VALIDATION_ASKED'].includes(this.globalDocumentsStatus)) {
        return ''
      }
      return 'info'
    },
    idInUrl () {
      return false
    },
    apiUrl () {
      return 'user/validation-banque'
    },
    Model () {
      return User
    },
    auth () {
      return Auth.query().first()
    },
    ModelEntity () {
      const id = this.id
      if (id) {
        return this.fullQuery.find(id)
      } {
        return false
      }
    },
    id () {
      return this.Auth ? this.Auth.id : null
    },
    loadUrl () {
      return '/api/user/validation-banque'
    },
    fetchUrl () {
      return '/api/user/validation-banque'
    },
    userDocumentsKeys () {
      if (this.Auth.statutUser === 1) {
        return ['idKycDocuments']
      } else if (this.Auth.statutUser === 5) {
        return ['idKycDocuments', 'sirenKycDocuments']
      } else {
        return ['idKycDocuments', 'uboKycDocuments', 'kbisKycDocuments', 'statusKycDocuments']
      }
    },
    userDocuments () {
      return this.userDocumentsKeys.filter((doc) => {
        return this.ModelEntity && this.ModelEntity[doc]
      }).map((key) => {
        let data = this.ModelEntity && this.ModelEntity[key] ? this.ModelEntity[key] : {}
        data['document'] = key
        data['status'] = data['Status']
        data['labelStatus'] = this.$t('shop.settings.bank-validation.status.'+ data['Status'])
        data['labelClass'] = ['VALIDATED'].includes(data['Status']) ? 'success' : ['OUT_OF_DATE', 'CREATED'].includes(data['Status']) ? 'warning' : ['REFUSED'].includes(data['Status']) ? 'danger' : ['VALIDATION_ASKED'].includes(data['Status']) ? '' : 'info'
        return data
      })
    },
    userStepsNames () {
      if (this.Auth && this.Auth.statutUser === 1) {
        return ['identity', 'complete']
      } else if (this.Auth && this.Auth.statutUser === 5) {
        return ['identity', 'company', 'complete']
      } else {
        return ['identity', 'company', 'beneficiary', 'complete']
      }
    },
    userSteps () {
      return this.steps.filter((step) => {
        return this.userStepsNames.includes(step.step)
      }).map((step, index) => {
        return {...step, index}
      })
    },
    currentStepIndex () {
      return this.getUserStepIndex(this.user, this.userSteps)
    },
    currentStep () {
      return this.userSteps[this.currentBankValidationStepIndex]
    },

    companyStepClass () {
      let className = ''
      if (!this.ModelEntity) {
        return className
      }
      if (this.ModelEntity.statutUser !== 5) {
        console.log('kbis validation')
        console.log(this.ModelEntity && this.ModelEntity.kbisKycDocuments && this.ModelEntity.statusKycDocuments && this.ModelEntity.kbisKycDocuments.Status)
        console.log(this.ModelEntity && this.ModelEntity.kbisKycDocuments && this.ModelEntity.statusKycDocuments && (['VALIDATION_ASKED', 'REFUSED', 'OUT_OF_DATE'].includes(this.ModelEntity.kbisKycDocuments.Status)))
        console.log('status validation')
        console.log(this.ModelEntity && this.ModelEntity.kbisKycDocuments && this.ModelEntity.statusKycDocuments && ['VALIDATION_ASKED', 'REFUSED', 'OUT_OF_DATE'].includes(this.ModelEntity.statusKycDocuments.Status))
        console.log(this.ModelEntity && this.ModelEntity.kbisKycDocuments && this.ModelEntity.statusKycDocuments && this.ModelEntity.statusKycDocuments.Status)
        console.log('status twice')
        console.log(!!this.ModelEntity && this.ModelEntity.kbisKycDocuments && this.ModelEntity.statusKycDocuments && (['VALIDATION_ASKED', 'REFUSED', 'OUT_OF_DATE'].includes(this.ModelEntity.kbisKycDocuments.Status) || ['VALIDATION_ASKED', 'REFUSED', 'OUT_OF_DATE'].includes(this.ModelEntity.statusKycDocuments.Status)))
        if (this.ModelEntity && this.ModelEntity.kbisKycDocuments && this.ModelEntity.statusKycDocuments && (['VALIDATION_ASKED', 'REFUSED', 'OUT_OF_DATE'].includes(this.ModelEntity.kbisKycDocuments.Status) || ['VALIDATION_ASKED', 'REFUSED', 'OUT_OF_DATE'].includes(this.ModelEntity.statusKycDocuments.Status))) {
          if ( this.ModelEntity.statusKycDocuments && ['VALIDATION_ASKED', 'REFUSED', 'OUT_OF_DATE'].includes(this.ModelEntity.statusKycDocuments.Status)) {
            return ' ' + this.ModelEntity.statusKycDocuments.Status
          }
          if ( this.ModelEntity.kbisKycDocuments && ['VALIDATION_ASKED', 'REFUSED', 'OUT_OF_DATE'].includes(this.ModelEntity.kbisKycDocuments.Status)) {
            return ' ' + this.ModelEntity.kbisKycDocuments.Status
          }
        }
        if (this.ModelEntity && this.ModelEntity.kbisKycDocuments && this.ModelEntity.kbisKycDocuments.Status) {
          className += ' ' + this.ModelEntity.kbisKycDocuments.Status
        }
        if (this.ModelEntity && this.ModelEntity.statusKycDocuments && this.ModelEntity.statusKycDocuments.Status) {
          className += ' ' + this.ModelEntity.statusKycDocuments.Status
        }
      } else {
        if (this.ModelEntity && this.ModelEntity.sirenKycDocuments && this.ModelEntity.sirenKycDocuments.Status) {
          className += ' ' + this.ModelEntity.sirenKycDocuments.Status
        }

      }
      return className
    },
    steps () {
      return [
        {
          step: 'identity',

          className: this.ModelEntity && this.ModelEntity.idKycDocuments && this.ModelEntity.idKycDocuments && this.ModelEntity.idKycDocuments.Status ? this.ModelEntity.idKycDocuments.Status : '',
          to: {name: 'BankValidationIdentityManagement'},
          submit: this.isSubmit('id'),
          redirectToNext (user) {
            return user && (user.statutUser !== 1 && user.statutUser !== 5 && user.infoSociete && user.infoSociete.nomLegal && user.infoSociete.prenomLegal) && user.idKycDocuments && (user.idKycDocuments.Status === 'VALIDATION_ASKED' || user.idKycDocuments.Status === 'VALIDATED')
          },
          order: 0,
          icon: '&#xe980'
        },
        {
          step: 'company',
          className: this.companyStepClass,
          submit: this.isSubmit('company'),
          to: {name: 'BankValidationCompany'},
          redirectToNext (user) {
            if (user.statutUser === 5) {
              if (user.sirenKycDocuments && user.sirenKycDocuments.Status === 'VALIDATED' || user.sirenKycDocuments.Status === 'VALIDATION_ASKED') {
                return true
              }
            } else {
              if (user.kbisKycDocuments && (user.kbisKycDocuments.Status === 'VALIDATED' || user.kbisKycDocuments.Status === 'VALIDATION_ASKED') && user.statusKycDocuments && (user.statusKycDocuments.Status === 'VALIDATED' || user.statusKycDocuments.Status === 'VALIDATION_ASKED')) {
                return true
              }
            }
            return false
            // return user &&
            //   user.infoSociete &&
            //   user.infoSociete.raisonSociale &&
            //   user.kycDocumentationKbis &&
            //   (
            //     (user.statutUser !== 5 && (user.kycDocumentationKbis.Status === 'VALIDATION_ASKED' || user.kycDocumentationKbis.Status === 'VALIDATED')) &&
            //     (user.statutUser !== 5 && (user.statusKycDocuments.Status === 'VALIDATION_ASKED' || user.statusKycDocuments.Status === 'VALIDATED'))
            //   ) ||
            //   (user.statutUser === 5 && (user.sirenKycDocuments.Status === 'VALIDATION_ASKED' || user.sirenKycDocuments.Status === 'VALIDATED'))
          },
          order: 1,
          icon: '&#xe926'
        },
        {
          step: 'beneficiary',
          className: this.ModelEntity && this.ModelEntity.uboKycDocuments && this.ModelEntity.uboKycDocuments && this.ModelEntity.uboKycDocuments.Status ? this.ModelEntity.uboKycDocuments.Status : "",
          submit: this.isSubmit('ubo'),

          redirectToNext (user) {
            return user && user.uboKycDocuments && (user.uboKycDocuments.Status === 'VALIDATION_ASKED' || user.uboKycDocuments.Status === 'VALIDATED')
          },
          to: {name: 'BankValidationBeneficiary'},
          order: 1,
          icon: '&#xea05'
        },
        {
          step: 'complete',
          className: 'complete-step ' + this.globalDocumentsStatus,
          redirectToNext (user) {
            return false
          },
          to: {name: 'BankValidationComplete'},
          order: 1,
          icon: '&#xe92c'
        }
      ]
    },
    currentBankValidationStatus () {
      return 1
    },
    currentBankValidationStepIndex () {
      return this.userSteps.findIndex((step) => {
        return !step.submit
      })
      // let stepIndex = 0;
      // for (let i = 0; i < this.userSteps.length; i++) {
      //   const stepObject = this.userSteps[i];
      //   if (stepObject.redirectToNext && !stepObject.redirectToNext(this.ModelEntity)) {
      //     return stepIndex = i
      //   }
      // }
      // return stepIndex;
    }
  },
  methods: {
    isSubmit (form, status = ['VALIDATION_ASKED', 'VALIDATED']) {
      if (!this.ModelEntity) {
        return false
      }
      if (form === 'id') {
        return this.ModelEntity && this.ModelEntity.idKycDocuments && (this.ModelEntity.idKycDocuments.Status === 'VALIDATION_ASKED' || this.ModelEntity.idKycDocuments.Status === 'VALIDATED')
      }
      if (form === 'company') {
        if (this.ModelEntity.statutUser === 5) {
          return this.ModelEntity && this.ModelEntity.sirenKycDocuments && (this.ModelEntity.sirenKycDocuments.Status === 'VALIDATION_ASKED' || this.ModelEntity.sirenKycDocuments.Status === 'VALIDATED')
        } else {
          return this.ModelEntity && this.ModelEntity.kbisKycDocuments && (this.ModelEntity.kbisKycDocuments.Status === 'VALIDATION_ASKED' || this.ModelEntity.kbisKycDocuments.Status === 'VALIDATED') && this.ModelEntity.statusKycDocuments && (this.ModelEntity.statusKycDocuments.Status === 'VALIDATION_ASKED' || this.ModelEntity.statusKycDocuments.Status === 'VALIDATED')
        }
      }
      if (form === 'ubo') {
        return this.ModelEntity && this.ModelEntity.uboKycDocuments && (this.ModelEntity.uboKycDocuments.Status === 'VALIDATION_ASKED' || this.ModelEntity.uboKycDocuments.Status === 'VALIDATED')
      }
      return false
    },
    getNextStep (step) {
      let stepIndex = this.userSteps.findIndex((s) => s.step === step)
      return this.userSteps[stepIndex + 1]
    },
    preQuery (query) {
      query.with('infoSociete')
      query.where('user_id', this.id)
    },
    getUserStepIndex (user, stepsArray) {
      let stepIndex = 0;
      for (let i = 0; i < stepsArray.length; i++) {
        const stepObject = stepsArray[i];
        // console.log('stepObject ' + i)
        // console.log('user ')
        // console.log(user)
        // console.log(!stepObject.redirectToNext(user))
        if (stepObject.redirectToNext && !stepObject.redirectToNext(user)) {
          return stepIndex = i
        }
      }
      return stepIndex; // Retourne -1 si aucune étape correspondante n'est trouvée
    },
  }
}