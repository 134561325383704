import {Model} from '@vuex-orm/core'

export default class Category extends Model {
	static entity = 'categories'

	static apiConfig = {
		headers: { 'X-Requested-With': 'XMLHttpRequest' },
		baseURL: '/api',
		dataKey: 'categories'
	}

	static fields() {
		return {
			id: this.attr(null),
			nom: this.attr(''),
			countModeles: this.attr(null),
			sousCategories: this.attr([]),
		}
	}
}
