<template>
  <el-table @cell-click="onCellClick" class="pointer-row" :data="conflicts" style="width: 100%;" :table-layout="'auto'"  :row-class-name="tableRowClassName" :default-sort="defaultOrder">
<!--    <el-table-column fixed prop="id" label="#" width="60" />-->
    <el-table-column prop="name" :label="$t('owner-listing.tab-head.title')">
    </el-table-column>
    <el-table-column prop="publie">
      <template #default="{row}">
        <el-tag
            :type="computedConflicts(row) ? 'warning' : 'success'"
            class="mx-1"
            effect="light"
            round
        >
          <span class="dot"></span>

          {{ $t('shop.products.conflicts.count-problems', { count: computedConflicts(row) }) }}
        </el-tag>
<!--        <el-switch-->
<!--            width="90px"-->
<!--            v-model="scope.row.publie"-->
<!--            inline-prompt-->
<!--            style="&#45;&#45;el-switch-on-color: #13ce66; &#45;&#45;el-switch-off-color: #ff4949"-->
<!--            active-text="En ligne"-->
<!--            inactive-text="Hors ligne"/>-->
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import AdTicket from "./AdTicket.vue"
export default {
  components: {
    AdTicket
  },
  name: "ConflictTable",
  props: {
    defaultOrder: {
      type: Object,
      default () { return {}}
    },
    conflicts: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    computedConflicts (row) {
      return parseInt(row.count_conflicts) - parseInt(row.count_resolved_conflicts)
    },
    parseInt (parse) {
      return parseInt(parse)
    },
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    },
    onCellClick (row, column) {
      if (column.property !== 'action') {
        let routeName = 'conflict-model'
        this.$router.push({
          name: routeName,
          params: {
            modelId: row.id
          }
        })
      }
    },
    bundleNbItem (ad) {
      if (ad.bundle && ad.bundle.bundleItems) {
        return ad.bundle.bundleItems.reduce((total, ad) => {
          return total + (1*ad.quantity)
        }, 0)
      } else {
        return 0
      }
    },
    console(data) {
      console.log(data)
    }
  }
}
</script>

<style scoped>

</style>