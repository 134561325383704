<template>
    <div class="depot-section">
            <h3 v-if="isOwner && showSinistre"><p style="color:red">{{$t('box.options.sinistre')}}  </p></h3>
        <h4>
            {{$t('box.options.guarantee')}}  
            <span> {{ caution }} € </span> 
            <a href="#" data-toggle="tooltip" title="Hooray!"><img :src="asset('img/svg/tooltip-icon.svg')" alt=""></a>
        </h4>
        <h4>
            {{$t('box.options.replacement')}}  
            <span> {{ valeurTotalMateriel }} € </span>
            <a href="#" data-toggle="tooltip" title="Hooray!"><img :src="asset('img/svg/tooltip-icon.svg')" alt=""></a>
        </h4>
    </div>
</template>

<script>

export default {
    name: 'LocationBoxOptions',
    props: {
        auth : {
            type: Object
        },
        termined : {
            type: Boolean
        },
        DL : {
            type: Object
        },
    },
    computed: {
        proprietaire () {
            return this.DL.proprietaire
        },
        isOwner() {
            return this.auth.id == this.proprietaire.id ? true : false
        },
        showSinistre() {
            return (this.DL.PaidAt !== null || this.termined == true) && this.DL.CanceledAt === null && this.sinistre ? true : false
        },
        sinistre() {
          if (this.$symfony.sinistre !== null) {
            return this.$symfony.sinistre
          } else {
            return this.DL.sinistre
          }
        },
        caution() {
            return this.DL.caution
        },
        valeurTotalMateriel() {
            return this.DL.panier.valeurTotalMateriel
        }
    }
}

</script>
