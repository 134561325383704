<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import DescriptionMenuBar from "~/modules/ad/components/DescriptionMenuBar.vue";
import Italic from '@tiptap/extension-italic'
import { SmilieReplacer } from './SmilieReplacer.ts'
import {ColorHighlighter} from "./colorHighlighter.ts";
import CharacterCount from '@tiptap/extension-character-count'
import Typography from '@tiptap/extension-typography'
import Link from '@tiptap/extension-link'
import { marked }  from 'marked'

export default {
  name: "InputDescription",
  emits: ['update:modelValue'],
  components: {
    DescriptionMenuBar,
    EditorContent,
  },
  props: {
    modelValue: {
      type: [String]
    },
    limit: {
      type: Number,
      default: 10000
    }
  },
  data () {
    return {
      editor: null,
      content: '',
    }
  },
  watch: {
    // immediate: true,
    modelValue(value) {
      // HTML
      if (value) {
        value = this.markdownToHtml(value)
      }
      // console.log('model value change')
      // console.log(value)
      // console.log('is marque down')
      if (!this.editor || !value) {
        return
      }
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }
      console.log('setCOntent')
      this.editor.commands.setContent(value, false)
    },
  },
  mounted() {
    let value = ''
    if (this.modelValue) {
      value = this.markdownToHtml(this.modelValue)
    }
    this.editor = new Editor({
      editorProps: {
        attributes: {
          class: 'wysiwyg-editor',
        },
      },
      extensions: [
        StarterKit,
        Typography,
        // Italic,
        Link,
        SmilieReplacer,
        ColorHighlighter,
        CharacterCount.configure({
          limit: this.limit,
        }),
      ],
      content: value,
      onUpdate: () => {
        console.log('update description')
        this.$emit('update:modelValue', this.editor.getHTML())
      },
    })
  },
  methods: {
    convertTextLinksToHtml(text) {
      const urlRegex = /(https?:\/\/[^\s]+)\s-\s([^ ]+)/g;
      return text.replace(urlRegex, (match, url, description) => {
        return `<a href="${url}">${description}</a>`;
      });
    },
    detectMarkdown(text) {
      const markdownPatterns = [/^\#{1,6}\s/, /^-{3,}/, /^\*{3,}/, /\[.+\]\(.+\)/, /\!\[.+\]\(.+\)/];
      return markdownPatterns.some(pattern => pattern.test(text));
    },
    markdownToHtml(markdownText) {
      return marked(markdownText);
    }
  },
  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>

<template>
  <div v-if="editor" style="width: 100%;">
    <DescriptionMenuBar :editor="editor" />
    <editor-content :editor="editor" />
    <p class="chars-count-left">{{ $t('shop.products.product-edit.description-count-left', { count: limit - editor.storage.characterCount.characters()}) }} </p>
  </div>
</template>

<style scoped>

</style>