import TopBar from "../components/topBar.vue";
import CartTopBar from "~/modules/cart/components/TopBar.vue";
import SearchSection from "../components/SearchSection.vue";
import StepsLocation from "../components/steps-location/StepsLocation.vue";
import LeftBar from "../components/leftBar.vue";
import ParamatersView from "../components/parameters/ParamatersView.vue";
import ShopDashboard from "../components/ShopDashboard.vue";
// import Reservations from "../components/parameters/reservations.vue";
import Disponibilite from "../components/parameters/disponibilite.vue";
import tarification from "../components/parameters/tarification.vue";
import services from "../components/parameters/services.vue";
import offres from "../components/parameters/offres.vue";
import BankInfo from "../components/parameters/BankInfo.vue";
// import BankValidation from "../components/parameters/BankValidation.vue";
import CancelConditions from "../components/parameters/CancelConditions.vue";
import revenus from "../components/parameters/revenus.vue";
// import ProductsList from "../modules/inventory/pages/ProductsList.vue";
// import ProductsIndex from "../modules/inventory/pages/ProductsIndex.vue";
import TabsLayouts from "../layouts/TabsLayouts.vue";
import SettingsTabs from "../modules/settings/components/SettingsTabs.vue";
import SimpleLayout from "../layouts/SimpleLayout.vue";
import AsksTabs from "../modules/ask/components/AsksTabs.vue";
import DocumentTabs from "../modules/billing/components/DocumentTabs.vue";
import BillingsList from "../modules/billing/pages/BillingsList.vue";
import MonthlyBillingsList from "../modules/billing/pages/MonthlyBillingsList.vue";
import ReportBillings from "../modules/billing/pages/ReportBillings.vue";
// import SimpleCardLayout from "../layouts/SimpleCardLayout.vue";
import AnnoncesList from "../modules/ad/pages/AnnoncesList.vue";
import ProductsTabs from "../modules/ad/components/ProductsTabs.vue";
import BaseDefaultLayout from "../layouts/BaseDefaultLayout.vue";
import BaseShopLayout from "../layouts/BaseShopLayout.vue";
import AdEdit from "../modules/ad/pages/AdEdit.vue";
import BundleEdit from "../modules/ad/pages/BundleEdit.vue";
import ProductEdit from "../modules/ad/pages/ProductEdit.vue";
import PackList from "../modules/ad/pages/PackList.vue";
import ItemList from "../modules/ad/pages/ItemList.vue";
import AdminTabs from "../modules/admin/components/AdminTabs.vue";
import AdminDashboard from "../modules/admin/pages/AdminDashboard.vue";
import AdminEvents from "../modules/admin/pages/AdminEvents.vue";
import AdminComputedList from "../modules/economic/pages/AdminComputedList.vue";
import AdminComputationEdit from "../modules/economic/pages/AdminComputationEdit.vue";
import BaseSignupLayout from "../layouts/BaseSignupLayout.vue";
import RegisterStatus from "../modules/signup/pages/RegisterStatus.vue";
import RegisterLogin from "../modules/signup/pages/RegisterLogin.vue";
import RegisterIdentity from "../modules/signup/pages/RegisterIdentity.vue";
import RegisterPhoneNumber from "../modules/signup/pages/RegisterPhoneNumber.vue";
import ConfirmPhoneNumber from "../modules/signup/pages/ConfirmPhoneNumber.vue";
import RegisterLocation from "../modules/signup/pages/RegisterLocation.vue";
import RegisterSiret from "../modules/signup/pages/RegisterSiret.vue";
import ConfirmSiret from "../modules/signup/pages/ConfirmSiret.vue";
import RegisterCompany from "../modules/signup/pages/RegisterCompany.vue";
import RegisterSiege from "../modules/signup/pages/RegisterSiege.vue";
import RegisterComplete from "../modules/signup/pages/RegisterComplete.vue";
import AsksList from "~/modules/ask/pages/AsksList.vue";
import ProductTabs from "~/modules/ad/components/ProductTabs.vue";
import InventoryList from "~/modules/ad/pages/InventoryList.vue";
import ProductTransactions from "~/modules/ad/pages/ProductTransactions.vue";
import UnavailabilityList from "~/modules/ad/pages/UnavailabilityList.vue";
import CartsList from "~/modules/cart/pages/CartsList.vue";
import FooterBackground from "~/layouts/FooterBackground.vue";
import CartEdit from "~/modules/cart/pages/CartEdit.vue";
import BankValidationTemplate from "~/modules/payment/templates/BankValidationTemplate.vue";
import BankValidationIdentityManagement from "~/modules/payment/pages/BankValidationIdentityManagement.vue";
import BankValidationBeneficiary from "~/modules/payment/pages/BankValidationBeneficiary.vue";
import BankValidationCompany from "~/modules/payment/pages/BankValidationCompany.vue";
import BankValidationComplete from "~/modules/payment/pages/BankValidationComplete.vue";
import AdminMangopayTabs from "~/modules/admin/components/AdminMangopayTabs.vue";
import MangoPayHooks from "~/modules/payment/pages/MangoPayHooks.vue";
import AdminLeftBar from "~/modules/admin/components/AdminLeftBar.vue";
import BankValidationStep from "~/modules/payment/pages/BankValidationStep.vue";
import EditInventory from "~/modules/ad/components/EditInventory.vue";
import ConflictsList from "~/modules/ad/pages/ConflictsList.vue";
import AdminAskNewModel from "~/modules/admin/pages/AdminAskNewModel.vue";
import AdminCategoriesTabs from "~/modules/admin/components/AdminCategoriesTabs.vue";
import AdminAskNewModelEdit from "~/modules/admin/pages/AdminAskNewModelEdit.vue";
import ShopBottomBar from "~/modules/shop/templates/ShopBottomBar.vue";
import chatRoom from "~/components/chatRoom.vue";
import ProductsArchived from "~/modules/ad/pages/ProductsArchived.vue";
import AdminUsersList from "~/modules/user/pages/AdminUsersList.vue";
import AdminUsersTabs from "~/modules/admin/components/AdminUsersTabs.vue";
import AdminUserTabs from "~/modules/admin/components/AdminUserTabs.vue";
import AdminUserDetails from "~/modules/user/pages/AdminUserDetails.vue";
import AdminUserRentals from "~/modules/user/pages/AdminUserRentals.vue";
import AdminUserAds from "~/modules/user/pages/AdminUserAds.vue";
import AdminUserAsks from "~/modules/user/pages/AdminUserAsks.vue";
import AdminUserDocuments from "~/modules/user/pages/AdminUserDocuments.vue";
import AdminCarouselsList from "~/modules/promotions/pages/CarouselsList.vue";
import AdminPromotionsTabs from "~/modules/admin/components/AdminPromotionsTabs.vue";
import AdminCarouselEdit from "~/modules/promotions/pages/AdminCarouselEdit.vue";
import AdminListCategories from "~/modules/admin/pages/AdminListCategories.vue";
import AdminListBrands from "~/modules/admin/pages/AdminListBrands.vue";

const shopRoutes = [
  {
    name: 'admin',
    path: '/admin',
    components: {
      TopBar: AdminLeftBar,
      Page: ParamatersView,
      BottomBar: ShopBottomBar
    },
    children: [
      {
        path: '',
        component: TabsLayouts,
        children: [
          {
            name: 'mangopay',
            path: 'mangopay',
            components: {
              Tabs: AdminMangopayTabs,
              Page: SimpleLayout
            },
            children: [
              {
                name: 'admin-mangopay-hooks',
                path: 'hooks',
                component: MangoPayHooks
              }
            ]
          },
          {
            path: 'promotions',
            components: {
              Tabs: AdminPromotionsTabs,
              Page: SimpleLayout
            },
            children: [
              {
                name: 'admin-carousels',
                path: 'carousels',
                component: AdminCarouselsList
              },
              {
                name: 'admin-carousel-edit',
                path: 'carousel/:id',
                component: AdminCarouselEdit
              }
            ]
          },
          {
            path: 'utilisateurs',
            components: {
              Tabs: AdminUsersTabs,
              Page: SimpleLayout
            },
            children: [
              {
                name: 'admin-users',
                path: 'list/:type',
                component: AdminUsersList
              }
            ]
          },
          {
            path: 'utilisateur/:id',
            components: {
              Tabs: AdminUserTabs,
              Page: SimpleLayout
            },
            children: [
              {
                name: 'admin-user-details',
                path: 'detail',
                component: AdminUserDetails
              },
              {
                name: 'admin-user-rentals',
                path: 'locations',
                component: AdminUserRentals
              },
              {
                name: 'admin-user-ads',
                path: 'materiel',
                component: AdminUserAds
              },
              {
                name: 'admin-user-asks',
                path: 'Réservations',
                component: AdminUserAsks
              },
              {
                name: 'admin-user-documents',
                path: 'documents',
                component: AdminUserDocuments
              }
            ]
          },
          {
            path: 'economics',
            components: {
              Tabs: AdminTabs,
              Page: SimpleLayout
            },
            children: [
              {
                name: 'admin-statistics',
                path: 'statistics',
                component: AdminDashboard
              },
              {
                name: 'admin-events',
                path: 'events',
                component: AdminEvents
              },
              {
                name: 'admin-economics',
                path: 'modele-economique',
                component: AdminComputedList
              },
              {
                name: 'admin-edit-economics',
                path: 'edit-economique/:id',
                component: AdminComputationEdit
              }
            ]
          },
        ]
      },
      {
        path: '',
        component: TabsLayouts,
        children: [
          {
            name: 'admin-categories',
            path: 'categories',
            components: {
              Tabs: AdminCategoriesTabs,
              Page: SimpleLayout
            },
            children: [
              {
                name: 'admin-asks-new-model-list',
                path: 'ask-new-models',
                component: AdminAskNewModel
              },
              {
                name: 'admin-asks-new-model',
                path: 'ask-new-models/:id',
                component: AdminAskNewModelEdit
              },
              {
                name: 'admin-categories-list',
                path: 'categories',
                component: AdminListCategories
              },
              {
                name: 'admin-brands-list',
                path: 'marques',
                component: AdminListBrands
              }

            ]
          }
        ]
      }
    ]
  },
  {
    name: 'shop-chat',
    path: '/shop',
    components: {
      TopBar: LeftBar,
      Page: ParamatersView,
      BottomBar: ShopBottomBar
    },
    children: [
      {
        name: 'shop-chat-room',
        path: 'messagerie',
        component: chatRoom
      }
    ]
  },
  {
    name: 'shop',
    path: '/shop',
    alias: '/app_dev.php/shop',
    components: {
      TopBar: LeftBar,
      Page: ParamatersView,
      BottomBar: ShopBottomBar
    },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: ShopDashboard
      },
      {
        path: '',
        component: TabsLayouts,
        children: [
          {
            path: '',
            components: {
              Tabs: SettingsTabs,
              Page: SimpleLayout
            },
            children: [
              {
                name: 'disponibilite',
                path: 'disponibilite',
                component: Disponibilite
              },
              {
                name: 'tarification',
                path: 'tarification',
                component: tarification
              },
              {
                name: 'services',
                path: 'services',
                component: services
              },
              {
                name: 'offres',
                path: 'offres',
                component: offres
              },
              {
                name: 'BankInfo',
                path: 'info-bancaire',
                component: BankInfo
              },
              {
                path: 'validation-banque',
                component: BankValidationTemplate,
                children: [
                  {
                    name: 'BankValidation',
                    path: '',
                    component: BankValidationStep
                  },
                  {
                    name: 'BankValidationIdentityManagement',
                    path: 'identite',
                    component: BankValidationIdentityManagement
                  },
                  {
                    name: 'BankValidationCompany',
                    path: 'societe',
                    component: BankValidationCompany
                  },
                  {
                    name: 'BankValidationBeneficiary',
                    path: 'beneficiaires',
                    component: BankValidationBeneficiary
                  },
                  {
                    name: 'BankValidationComplete',
                    path: 'complete',
                    component: BankValidationComplete
                  }
                ]
              },
              {
                name: 'CancelConditions',
                path: 'conditions-annulation',
                component: CancelConditions
              },
            ]
          }
        ]
      },
      {
        name: '',
        path: 'documents',
        component: TabsLayouts,
        children: [
          {
            name: '',
            path: '',
            components: {
              Tabs: DocumentTabs,
              Page: SimpleLayout
            },
            children: [
              {
                name: 'documents',
                path: 'factures',
                component: BillingsList
              },
              {
                name: 'documents-mensualisation',
                path: 'factures-mensuelle',
                component: MonthlyBillingsList
              },
              {
                name: 'documents-rapport',
                path: 'rapport',
                component: ReportBillings
              }
            ]
          }
        ]
      },
      {
        path: 'produit/:id',
        component: TabsLayouts,
        children: [
          {
            path: '',
            components: {
              Tabs: ProductTabs,
              Page: SimpleLayout
            },
            children: [
              {
                path: 'inventory',
                name: 'product-inventory',
                component: InventoryList
              },
              {
                path: 'edition',
                name: 'product-edition',
                component: ProductEdit
              },
              {
                path: 'indisponibilite',
                name: 'product-unavailability',
                component: UnavailabilityList
              },
              {
                path: 'bundle-edition',
                name: 'bundle-edition',
                component: BundleEdit
              },
              {
                path: 'transactions',
                name: 'product-history',
                component: ProductTransactions
              },
              {
                name: 'add-stock',
                path: 'stock-edit',
                component: EditInventory
              },
              {
                name: 'edit-stock',
                path: 'stock-edit/:stockId',
                component: EditInventory
              },
              {
                name: 'stock-unavailability',
                path: 'unite-indisponibilite/:stockId',
                component: UnavailabilityList
              }
            ]
          }
        ]
      },
      {
        // name: 'produits-root',
        path: 'produits',
        component: TabsLayouts,
        children: [
          {
            // name: '',
            path: '',
            components: {
              Tabs: ProductsTabs,
              Page: SimpleLayout
            },
            children: [
              {
                // name: '',
                path: '',
                name: 'produits-root',
                component: AnnoncesList
              },
              {
                name: 'annonces',
                path: 'annonces',
                component: AnnoncesList
              },
              {
                name: 'conflicts',
                path: 'conflits',
                component: ConflictsList
              },
              {
                name: 'products_archives',
                path: 'archives',
                component: ProductsArchived
              },
              {
                name: 'conflict-model',
                path: 'conflit/:modelId',
                component: ConflictsList
              },
              {
                name: 'conflict-product',
                path: 'conflit/:modelId/:id',
                component: ConflictsList
              },
              {
                // name: '',
                path: 'inventaire',
                name: 'items',
                component: ItemList
              },
              {
                // name: '',
                path: 'packs',
                name: 'pack',
                component: PackList
              },
              {
                name: 'ad-new',
                path: 'annonce-edit',
                component: AdEdit
              },
              {
                name: 'ad-edit',
                path: 'annonce-edit/:id',
                component: AdEdit
              },
              {
                name: 'add-product',
                path: 'produit-edit',
                component: ProductEdit
              },
              {
                name: 'product-edit',
                path: 'produit-edit/:id',
                component: ProductEdit
              },
              {
                name: 'add-bundle',
                path: 'pack-edit',
                component: BundleEdit
              },
              {
                name: 'bundle-edit',
                path: 'pack-edit/:id',
                component: BundleEdit
              }
              // {
              //   name: 'documents-mensualisation',
              //   path: 'factures-mensuelle',
              //   component: MonthlyBillingsList
              // },
              // {
              //   name: 'documents-rapport',
              //   path: 'rapport',
              //   component: ReportBillings
              // }
            ]
          }
        ]
      },
      {
        name: 'reservations-index',
        path: 'reservations',
        component: TabsLayouts,
        children: [
          {
            name: 'reservations-all',
            path: '',
            components: {
              Tabs: AsksTabs,
              Page: SimpleLayout
            },
            children: [
              {
                name: 'all-asks',
                path: 'toutes',
                component: AsksList,
                props: {status: 'all'}
              },
              {
                name: 'today-asks',
                path: 'aujourd-hui',
                component: AsksList,
                props: {status: 'today'}
              },
              {
                name: 'waiting-asks',
                path: 'en-attentes',
                component: AsksList,
                props: {status: 'waiting'}
              },
              {
                name: 'incoming-asks',
                path: 'a-venir',
                component: AsksList,
                props: {status: 'incoming'}
              },
              {
                name: 'todo-asks',
                path: 'a-faire',
                component: AsksList,
                props: {status: 'todo'}
              },
              {
                name: 'departures-asks',
                path: 'depart',
                component: AsksList,
                props: {status: 'departures'}
              },
              {
                name: 'arrivals-asks',
                path: 'retours',
                component: AsksList,
                props: {status: 'arrivals'}
              },
              {
                name: 'cancel-asks',
                path: 'annulees',
                component: AsksList,
                props: {status: 'cancel'}
              }
            ]
          }
        ]
      },
      // {
      //   path: 'inventaire',
      //   component: ProductsIndex,
      //   children: [
      //     {
      //       name: 'Products',
      //       path: '',
      //       component: ProductsList
      //     },
      //     {
      //       name: 'ProductsList',
      //       path: 'list',
      //       component: ProductsList
      //     }
      //   ]
      // },
      {
        name: 'revenus',
        path: 'revenus',
        component: revenus
      }
    ]
  }
]

const devRoutes = import.meta.env.DEV ? [
  {
    name: 'main_dev',
    path: '/app_dev.php/catalogue-search',
    components: {
      TopBar,
      Page: SearchSection
    }
  },
  {
    name: 'main_steps-location',
    path: '/app_dev.php/user/location/:id',
    components: {
      TopBar,
      Page: StepsLocation
    }
  },
] : []

const signupRoutes = [
  {
    name: 'RegisterStatus',
    path: '/inscription',
    component: RegisterStatus
  },
  {
    name: 'RegisterLogin',
    path: '/inscription/connexion',
    component: RegisterLogin
  },
  {
    name: 'RegisterIdentity',
    path: '/inscription/identite',
    component: RegisterIdentity
  },
  {
    name: 'RegisterPhoneNumber',
    path: '/profil/inscription/telephone',
    component: RegisterPhoneNumber
  },
  {
    name: 'ConfirmPhoneNumber',
    path: '/profil/confirmation/telephone',
    component: ConfirmPhoneNumber
  },
  {
    name: 'RegisterLocation',
    path: '/profil/inscription/emplacement',
    component: RegisterLocation
  },
  {
    name: 'RegisterSiret',
    path: '/profil/inscription/siret',
    component: RegisterSiret
  },
  {
    name: 'ConfirmSiret',
    path: '/profil/confirmation/siret',
    component: ConfirmSiret
  },
  {
    name: 'RegisterCompany',
    path: '/profil/inscription/societe',
    component: RegisterCompany
  },
  {
    name: 'RegisterSiege',
    path: '/profil/inscription/adresse',
    component: RegisterSiege
  },
  {
    name: 'RegisterComplete',
    path: '/profil/inscription/bienvenu',
    component: RegisterComplete
  },
]

const cartRoutes = [
  {
    name: 'my-carts',
    path: '/user/mes-paniers',
    components: {
      TopBar,
      Page: CartsList,
      Footer: FooterBackground
    }
  },
  {
    name: 'cart',
    path: '/user/panier/:id',
    components: {
      TopBar: CartTopBar,
      Page: CartEdit,
      Footer: FooterBackground
    }
  }
]

export default [
  {
    // name: 'website',
    path: '',
    component: BaseDefaultLayout,
    children: [
      {
        name: 'main',
        path: '/catalogue-search',
        components: {
          TopBar,
          Page: SearchSection
        }
      },
      ...cartRoutes,
      {
        name: 'steps-location',
        path: '/user/location/:id',
        components: {
          TopBar,
          Page: StepsLocation
        }
      },
      ...devRoutes
    ]
  },
  {
    path: '',
    component: BaseSignupLayout,
    children: signupRoutes
  },
  {
    // name: 'shopLayout',
    path: '',
    alias: '/app_dev.php',
    component: BaseShopLayout,
    children: shopRoutes
  },
  // { name: 'room', path: '/user/messagerie', component: chatRoom },
  // // { name: 'new_room', path: '/user/new-chatroom/:user', component: chatRoom },
  // { name: 'room_select', path: '/user/messagerie/:id', component: chatRoom },
  // {
  //   name: 'room_create',
  //   path: '/user/room-create/:id',
  //   props: {
  //     newRoom: true
  //   },
  //   component: chatRoom
  // },
]
