<template>
  <el-table @cell-click="onCellClick" class="pointer-row" :data="productMigrations" style="width: 100%;" :table-layout="'auto'" :highlight-current-row="highlightCurrentRow" :row-class-name="tableRowClassName" :default-sort="defaultOrder" @current-change="handleCurrentChange">
    <template #empty>
      <slot name="empty"></slot>
    </template>
    <el-table-column prop="name" :label="$t('owner-listing.tab-head.title')">
      <template #default="{row}">
        <AdTicket :Ad="row.product" title-field="titre"/>
      </template>
    </el-table-column>
    <el-table-column prop="tarif.tarifInteger" label="Prix (HT)" sortable="custom" min-width="200" >
      <template #default="{row}">
        <span v-if="row.product.tarif" v-html="$currencyFormat.format(row.product.tarif.tarifInteger/100)"></span>
      </template>
    </el-table-column>
    <el-table-column prop="valeurAchat" label="Valeur d'achat" sortable="custom" min-width="200" >
      <template #default="{row}">
        <span v-if="row.product.valeurAchat" v-html="$currencyFormat.format(row.product.valeurAchat)"></span>
      </template>
    </el-table-column>
    <el-table-column prop="publie" label="Status" sortable="custom">
      <template #default="{row}">
        <el-tag
            :type="row.product.publie ? 'success' : 'danger'"
            class="mx-1 media-tag"
            effect="light"
            round
        >
          <span class="dot"></span>
          {{ row.product.publie ? 'Visible' : 'Hors ligne' }}
        </el-tag>
        <!--        <el-switch-->
        <!--            width="90px"-->
        <!--            v-model="scope.row.publie"-->
        <!--            inline-prompt-->
        <!--            style="&#45;&#45;el-switch-on-color: #13ce66; &#45;&#45;el-switch-off-color: #ff4949"-->
        <!--            active-text="En ligne"-->
        <!--            inactive-text="Hors ligne"/>-->
      </template>
    </el-table-column>
    <el-table-column prop="status">
      <template #default="{row}">
        <el-tag
            :type="getProductLabelType(row)"
            class="mx-1"
            effect="light"
            round
        >
          <span class="dot"></span>
          {{ getProductLabel(row) }}
        </el-tag>
<!--        <el-switch-->
<!--            width="90px"-->
<!--            v-model="scope.row.publie"-->
<!--            inline-prompt-->
<!--            style="&#45;&#45;el-switch-on-color: #13ce66; &#45;&#45;el-switch-off-color: #ff4949"-->
<!--            active-text="En ligne"-->
<!--            inactive-text="Hors ligne"/>-->
      </template>
    </el-table-column>
  </el-table>
  <div class="actions-buttons" v-if="highlightCurrentRow">
    <el-button :loading="singleProductLoading" plain type="info" size="large" class="btn btn-save" :disabled="!this.productIdSelected" @click.prevent="setSingleProduct">{{ singleProductBtnText }}</el-button>
    <el-button size="large" @click="$router.push({name: 'conflict-product', params: { id: 'new'}})">Ajouter un nouveau produit seul</el-button>
  </div>
  <div v-else class="actions-buttons" >
    <el-button size="large" @click="reselectAloneProduct">Changer le produit seul</el-button>
  </div>
</template>

<script>
import AdTicket from "./AdTicket.vue"
import ProductMigration from "~/modules/ad/services/ProductMigration.js";
export default {
  components: {
    AdTicket
  },
  emits: ['product-migrated'],
  name: "ProductsConflictTable",
  props: {
    defaultOrder: {
      type: Object,
      default() {
        return {}
      }
    },
    productMigrations: {
      type: Array,
      default() {
        return []
      }
    },
    selectMode: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selectModeInternal: false,
      singleProductLoading: false,
      aloneProductId: null,
      productIdSelected: null
    }
  },
  computed: {
    singleProductBtnText () {
      return this.productIdSelected ? 'Choisir ' + this.productIdSelected.product.titre + ' en produit seul' : 'Choisir'
    },
    highlightCurrentRow () {
      return this.hasAloneProduct || this.selectMode || this.selectModeInternal
    },
    hasAloneProduct () {
      return this.productMigrations.filter((conflict) => {
        return conflict.status === 3
      }).length <= 0
    },
    aloneProduct () {
      return !this.hasAloneProduct ? this.productMigrations.filter((conflict) => {
        return conflict.status === 3
      })[0] : null
    },
    checkedProductId() {
      return this.aloneProductId ? this.aloneProductId : this.productMigrations.filter((conflict) => {
        return conflict.status === 3
      }).reduce((isAloneProduct, conflict) => {
        return isAloneProduct || conflict.id
      }, null)
    },
    aloneProductObjectId: {
      get () {
        let productIdChecked = {}

        for (const product of this.productMigrations) {
          productIdChecked[product.id] = false
        }

        return productIdChecked
      },
      set (val) {
        this.aloneProductId = val
      }
    }
  },
  methods: {
    reselectAloneProduct () {
      this.selectModeInternal = true
    },
    getProductLabel (migration) {
      if (migration.status === 3) {
        return 'Produit seul'
      } else {
        if (migration.product && migration.product.productType === 0 && !migration.product.archived) {
          return 'Produit en conflit'
        } else if (migration.product && migration.product.archived) {
          return 'Produit archivé'

        } else if (migration.product && migration.product.productType === 1) {
          return 'Transformé en pack'
        }
        return 'Produit en conflit'
      }
    },
    getProductLabelType (migration) {
      if (migration.status === 3) {
        return 'success'
      } else {
        if (migration.product && migration.product.productType === 0 && !migration.product.archived) {
          return 'warning'
        } else if (migration.product && migration.product.archived && migration.product.archived) {
          return 'info'
        } else if (migration.product && migration.product.productType === 1) {
          return 'success'
        }
        return 'warning'
      }
    },
    async setSingleProduct () {
      this.singleProductLoading = true
      this.selectModeInternal = false
      try {
        const productId = parseInt(this.productIdSelected.id + '')
        let migratedProduct = await ProductMigration.api().get('user/migrer-en-produit/' + this.productIdSelected.id)
        console.log('migratedProduct')
        console.log(this.aloneProduct)
        this.$router.push({name: 'conflict-product', params: { id: this.aloneProduct.product_id }})
      } catch (e) {
        console.log(e)
      } finally {
        this.singleProductLoading = false
      }
    },
    handleCurrentChange (val) {
      return this.productIdSelected = val
    },
    parseInt (parse) {
      return parseInt(parse)
    },
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    },
    onCellClick (row, column) {
      if (column.property !== 'action' && !this.highlightCurrentRow) {
        let routeName = 'conflict-product'
        this.$router.push({
          name: routeName,
          params: {
            id: row.product.id
          }
        })
      }
    },
    bundleNbItem (ad) {
      if (ad.bundle && ad.bundle.bundleItems) {
        return ad.bundle.bundleItems.reduce((total, ad) => {
          return total + (1*ad.quantity)
        }, 0)
      } else {
        return 0
      }
    },
    console(data) {

    }
  }
}
</script>

<style scoped>

</style>