<template>
  <el-table :data="askModels"
            style="width: 100%;"
            :table-layout="'auto'"
            :default-sort="defaultOrder"
            @cell-click="onCellClick"
            :row-class-name="tableRowClassName"
            class="pointer-row">
    <template #empty>
      <slot name="empty"></slot>
    </template>
    <el-table-column prop="locataire" label="Utilisateur" sortable="custom" min-width="210">
      <template #default="{row}">
        <user-ticket-card :user="row.user" :show-company-icon="false"></user-ticket-card>
      </template>
    </el-table-column >
<!--    <el-table-column fixed prop="id" label="#" width="60" />-->
<!--    <el-table-column prop="titre" :label="$t('owner-listing.tab-head.title')" sortable="custom" min-width="100">-->
<!--      <template #default="{row}">-->
<!--        <AdTicket :Ad="row"/>-->
<!--      </template>-->
<!--    </el-table-column>-->
    <el-table-column prop="marque" label="Marque" sortable="custom" min-width="250"/>
    <el-table-column prop="modele" label="Modèle" sortable="custom" min-width="250"/>
  </el-table>
</template>

<script>
import AdTicket from "./AdTicket.vue"
import UserTicketCard from "~/modules/user/components/UserTicketCard.vue";
export default {
  components: {
    UserTicketCard,
    AdTicket
  },
  name: "AdsTable",
  props: {
    defaultOrder: {
      type: Object,
      default () { return {}}
    },
    askModels: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    },
    console(data) {
      console.log(data)
    },
    onCellClick (row, column) {
      if (column.property !== 'action') {
        let routeName = 'admin-asks-new-model'
        this.$router.push({
          name: routeName,
          params: {
            id: row.id
          }
        })
      }
      // this.$router.push({
      //   name: 'product-inventory',
      //   params: {
      //     id: row.id
      //   }
      // })
      // console.log('row click')
      // console.log(row)
      // console.log(column)
    }
  }
}
</script>

<style scoped>

</style>