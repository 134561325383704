 <template>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div>
        <h3 class="subTitle">{{$t('shop.products.bundle-list.title')}} <span v-if="paginationQuery.count" class="countLabel">{{ $t('shop.products.bundle-list.count-units', { count: paginationQuery.count})}}</span></h3>
      </div>
      <CustomFilterTable :radio-filters="FiltersProps" :new-button="$t('shop.products.bundle-list.new-button')" :placeholderSearch="'Recherche un produit'" @new-click="handleNewClick" />
    </div>
    <BundleTable :ads="modelList" @sort-change="sortChange" @onArchivedProduct="handleArchivedProduct">
      <template #empty>
        <div class="empty-background">
          <div v-if="isLoadingContent" class="loading-content">
            <div class="cv-spinner">
              <span class="spinner"></span>
            </div>
            <div class="empty-background-content-title">
              {{ $t('shop.products.bundle-list.loading')}}
            </div>
          </div>
          <div v-else class="empty-content">
            <div>
              <div class="non-clickable-button icomoon">
                &#xe9cc
              </div>
              <div class="empty-background-content-title">
                {{ $t('shop.products.bundle-list.empty.title')}}
              </div>
              <div class="empty-background-content-description">
                {{ $t('shop.products.bundle-list.empty.description')}}
              </div>

            </div>
            <div class="empty-background-content-button">
              <el-button @click="handleNewClick" size="large" type="primary"><span class="icomoon" style="margin-right: 8px">&#xe9bc</span>   {{ $t('shop.products.bundle-list.empty.button')}}</el-button>
            </div>
          </div>
        </div>
      </template>
    </BundleTable>
    <div class="pagination-container">
      <el-pagination :current-page="currentPage" hide-on-single-page background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>
    </div>
  </el-card>
</template>

<script>
import CustomFilterTable from "../../../components/CustomFilterTable.vue";
import LoadContent from "../../../mixins/loadContent.js";
import Ads from "../services/Ads.js";
import AdsTable from "../components/AdsTable.vue";
import BundleTable from "~/modules/ad/components/BundleTable.vue";

const modesValues = {
  "tous": 'Tous',
  "offline": "Hors ligne",
  "online": "En ligne",
}

export default {
  defaultOrderBy: {
    order: 'desc',
    prop: 'position'
  },
  fetchUrl: 'user/bundles',
  mixins: [LoadContent],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  components: {
    BundleTable,
    AdsTable,
    CustomFilterTable
  },
  name: "PackList",
  data () {
    return {
      FiltersProps: {
        "visible": "Visible",
        "offline": "Hors ligne",
      }
    }
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler (route) {
        // if (route.query.p) {
        //   this.currentPage = parseInt(route.query.p)
        // }
        // this.fetchDataThrottle()
      }
    }
  },
  computed: {
    Model() {
      return Ads
    },
  },
  methods: {
    handleArchivedProduct () {
      this.beforeDataFetch()
    },
    handleNewClick () {
      this.$router.push({ name: 'add-bundle' });
    },
    applySearchQuery (query) {
      if (this.search.length > 0) {
        query.where((ad) => {
          let find = false
          return find || ad.titre.toLocaleLowerCase().includes(this.search.toLowerCase())
        })
      }
    },
    preQuery (query) {
      query.with('bundle')
      query.with('medias')
      query.where('archived', false)
      if (this.mode !== 'tous') {
        console.log(this.mode)
        const modeValue = this.mode !== 'offline'
        query.where('publie', modeValue)
        // query.where('publie', modeValue).where('productType', 1)
      }
      query.where('productType', 1)
      query.where((ad) => {
        return typeof ad.id === 'number'
      })
      // query.where((ads) => {
      //     // console.log(ads)
      //   ads.productType === 1
      // })
    },
    applySortQuery (query) {
      if (this.sortData) {
        if (this.sortData[0] === 'tarif.tarifInteger') {
          query.orderBy((ad) => {
            // console.log(ad)
            return ad.tarif ? ad.tarif.tarifInteger : 0
          }, this.sortData[1])
        } else {
          let sort = this.sortData[0] === 'action' ? 'aLaUneProfile' : this.sortData[0]
          query.orderBy(sort, this.sortData[1])
        }
      } else {
        query.orderBy('id', 'desc')
      }
    },
  }
}
</script>

<style scoped>

</style>