<template>
  <section class="col-sm-10 owner-settings-page-section annulation-section" id="annulation-section" section-title="Annulation">
    <div class="alert alert-info mb-4" role="alert">
      <div class="alert-icon">
        <span class="fa fa-check fa-info-circle"></span>
      </div>
      Pour chaque annulation il vous sera demandé de confirmes les pénalités. Sans confirmation dans les 24 heures celles-ci seront automatiquement appliqués.
    </div>
    <br>
    <h2 class="form-section-title">Conditions d'annulation</h2>
    <p class="form-section-description mb-4">
      Vous avez la possibilité de choisir parmi 3 différents types d'annulations pour vos locations <a href="#">En savoir plus</a>
    </p>
    <br>
    <form action="" method="POST" v-if="contentLoaded">
      <div class="form-row">
        <label for="condition1">
          <input type="radio" id="condition1" value = "1" name="condition" v-model="edit.annulationType.id">
          &nbsp;Conditions Flexibles
          <br>
          Jusqu'à 2 jours avant le début de la location : <b>remboursement complet</b>
          <br>
          1 jour avant le début de la location : <b>25% de la location en pénalité</b>
          <br>
          Le jour de début de la location : <b>50% de la location en pénalité</b>
        </label>
      </div>
      <br>
      <div class="form-row">
        <label for="condition2">
          <input type="radio" id="condition2" value = "2" name="condition" v-model="edit.annulationType.id">
          &nbsp;Conditions Modérées
          <br>
          Jusqu'à 3 jours avant le début de la location : <b>remboursement complet</b>
          <br>
          Moins de 3 jours avant le début de la location : <b>25% de la location en pénalité</b>
          <br>
          La veille du début de la location : <b>50% de la location en pénalité</b>
          <br>
          Le jour de début de la location : <b>100% de la location en pénalité</b>
        </label>
      </div>
      <br>
      <div class="form-row">
        <label for="condition3">
          <input type="radio" id="condition3" value ="3" name="condition" v-model="edit.annulationType.id">
          &nbsp;Conditions Strictes
          <br>
          Jusqu'à 10 jours avant le début de la location : <b>remboursement complet</b>
          <br>
          Moins de 10 jours avant le début de la location : <b>25% de la location en pénalité</b>
          <br>
          Moins de 5 jours avant le début de la location : <b>100% de la location en pénalité</b>
        </label>
      </div>
      <br>
      <button class="btn btn-short btn-primary mt-4" :class="buttonClass" id="save-button-section-annulation" @click.prevent="sendForm">Sauvegarder les modifications</button>
    </form>
  </section>
</template>

<script>
import formHandler from "../../mixins/formHandler";

export default {
  name: "cancel-conditions",
  mixins: [formHandler],
  data () {
    return {
      edit: {
        annulationType: {
          id: 2
        }
      },
      annulationType: {
        id: null
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  methods: {
    transformHook (edit) {
      const content = {
        id: parseInt(edit.annulationType.id),
        ...edit.annulationType,

      }
      return content
    }
  }
}
</script>

<style scoped>

</style>