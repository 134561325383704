<template>
  <div class="boutique-header">
    <h3 class="parameters-title">{{ fullName }}</h3>
    <p class="breadcrumb-product">
      <router-link :to="routeBack" class="c-black breadcrumb-item first">
        <span v-html="iconType" class="icomoon"></span>
      </router-link>
      <span v-if="!hasLink">
        <span class="icomoon breadcrumb-icon">&#xe930</span><span class="breadcrumb-item">#{{id}} {{title}}</span>
      </span>
      <span v-else >
        <span class="icomoon breadcrumb-icon">&#xe930</span>
        <router-link :to="{ name: 'product-inventory', params: {id: $route.params.id }}" class="breadcrumb-item">
          {{ title }}
        </router-link>
        <span class="icomoon breadcrumb-icon">&#xe930</span><span class="breadcrumb-item">{{ secondTitle }}</span>
      </span>
    </p>
  </div>
  <CustomTabs :tabs="tabs"></CustomTabs>
</template>

<script>
import CustomTabs from "../../../components/CustomTabs.vue";
import User from "~/modules/user/services/User.js";

export default {
  components: {
    CustomTabs
  },
  name: "AdminUsersTabs",
  computed: {
    userSelected () {
      return User.find(this.$route.params.id) || null
    },
    routeBack () {
      return { name: 'admin-users', 'params': { type: 'all' } }
    },
    fullName () {
      if (!this.userSelected) {
        return ''
      }
      return this.userSelected.prenom + ' ' + this.userSelected.nom
    },
    secondTitle () {
      return ''
    },
    hasLink () {
      return false
    },
    linkRouteName () {
      if (!this.userSelected) {
        return ''
      }
      return this.userSelected.email
    },
    iconType () {
      return '&#xea05'
    },
    title () {
      if (!this.userSelected) {
        return ''
      }
      return this.userSelected.email
    },
    id () {
      if (!this.userSelected) {
        return ''
      }
      return this.userSelected.id
    }
  },
  data () {
    return {
      tabs: [
        {
          label: 'Details',
          to: { name: 'admin-user-details'}
        },
        {
          label: 'Locations',
              to: { name: 'admin-user-rentals'}
        },
        {
          label: 'Matériel',
              to: { name: 'admin-user-ads'}
        },
        // {
        //     label: 'Adresses',
        //     to: { name: 'admin-user-address'}
        // },
        {
          label: 'Réservations',
              to: { name: "admin-user-ads"}
        }
    // {
    //   label: 'Modèle économique',
    //   to: { name: 'admin-economics'}
    // },

      ]
    }
  }
}
</script>

<style scoped>

</style>
