<template>
    <form :action="path(notationTo)" method="post">
        <div class="contrat-de-location">
            <h3 class="multisteps-form__title">{{$t('infos.avis.label')}} </h3>
                <p v-if="isTenant">{{$t('infos.avis.description.1')}}</p>
                <p v-if="isOwner">{{$t('infos.avis.description.2')}}</p>
            <h5 class="sub-title">
                {{$t('infos.avis.subtitle.1')}}
            </h5>
            <div class="star-feedback rating-stars">
                <ul id='stars' ref='ratings'>
                    <li class='star selected' title='Poor' @click="valueStar = 1" @mouseover="mouseStar = 1">
                    <i class='fa fa-star fa-fw'></i>
                    </li>
                    <li class='star selected' title='Fair' @click="valueStar = 2" @mouseover="mouseStar = 2">
                    <i class='fa fa-star fa-fw'></i>
                    </li>
                    <li class='star selected' title='Good' @click="valueStar = 3" @mouseover="mouseStar = 3">
                    <i class='fa fa-star fa-fw'></i>
                    </li>
                    <li class='star selected' title='Excellent' @click="valueStar = 4" @mouseover="mouseStar = 4">
                    <i class='fa fa-star fa-fw'></i>
                    </li>
                    <li class='star selected' title='WOW!!!' @click="valueStar = 5" @mouseover="mouseStar = 5">
                    <i class='fa fa-star fa-fw'></i>
                    </li>
                    <li class="success-box text">
                        <div class='text-message'>{{rating}}  / 5</div>
                    </li>
                </ul>
            </div>
            <div class="product-creation-form-item">
                <div class="product-form-field mrg-25 p-0">
                    <label class="creation-description">
                        {{$t('infos.avis.subtitle.2')}}
                    <div id="the-count2" class="lableNote">
                        <span id="current2">0</span>
                        <span id="maximum2">/ 1000</span>
                    </div>
                    </label>
                        <textarea name="avis" class='form-control-input' id="the-textarea" maxlength="1000"  :placeholder="$t('infos.avis.placeholder', {user: placeholderName})" v-on:keyup="enableButton()" v-model="message"></textarea>
                    <div class="invalid-feedback"></div>
                </div>
            </div>
            <input type="hidden" id ="ratingVal" name="rating" :value="valueStar" />
            <input type="hidden" name="demandeloc" :value="DL.id" />
            <input type="submit" id="btnPublier" :class="isDisabled" :value="$t('infos.avis.publish')" />
        </div>
    </form>
</template>

<script>

export default {
    name: 'LocationInfosAvis',
    data() {
        return {
            disable: "btn btn-primary max-width-300 mb-20 disabled",
            message: '',
            rating: 5,
            mouseStar: 5
        }
    },
    props: {
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
    },
    computed: {
        locataire () {
            return this.DL.locataire
        },
        proprietaire () {
            return this.DL.proprietaire
        },
        isTenant() {
            return this.auth.id == this.locataire.id ? true : false
        },
        isOwner() {
            return this.auth.id == this.proprietaire.id ? true : false
        },
        notationTo() {
            if(this.isOwner) {
                return 'make_notation_to_locataire'
            } else if(this.isTenant) {
                return 'make_notation_to_proprietaire'
            }
        },
        placeholderName() {
            if(this.isOwner) {
                return this.locataire.prenom
            } else if(this.isTenant) {
                return this.proprietaire.prenom
            }
        },
        isDisabled: {
            get() {
                return this.disable
            },
            set(value) {
                this.disable = value
            }
        },
        valueStar: {
            get() {
                return this.rating
            },
            set(value) {
                this.rating = value
                var stars = this.$refs.ratings.childNodes
                
                for (var i = 0; i < stars.length; i++) {
                    stars[i].classList.remove('selected');
                }

                for (var i = 0; i < this.mouseStar; i++) {
                    stars[i].classList.add('selected');
                }
            }
        }
    },
    methods: {
        enableButton() {
            if(this.message.length > 0) {
                this.isDisabled = "btn btn-primary max-width-300 mb-20"
            } else if (this.message.length < 1) {
                this.isDisabled = "btn btn-primary max-width-300 mb-20 disabled"
            }
        }
    }
}
</script>