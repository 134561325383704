export default {
  props: {
    formAlign: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      screen: {
        width: 0,
        height: 0
      }
    }
  },
  mounted() {
    this.screen.width = window.innerWidth
    this.screen.height = window.innerHeight
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.screen.width = window.innerWidth
    }
  },
  computed: {
    formAlignement () {
      if (this.formAlign) return this.formAlign
      return this.screen.width > 800 ? 'left' : 'top'
    },
  }
}