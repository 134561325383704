<script>
import formHandler from "~/mixins/formHandler.js";
import screenBehavior from "~/mixins/screenBehavior.js";
import AdminUserAddressEdit from "~/modules/user/components/AdminUserAddressEdit.vue";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import AdminEditInfoSociete from "~/modules/user/components/AdminEditInfoSociete.vue";
import InputCity from "~/components/parameters/inputCity.vue";
import Carousel from "~/modules/promotions/services/Carousel.js";

export default {
  name: "AdminUserDetails",
  components: {
    InputCity,
    AdminEditInfoSociete,
    AdminUserAddressEdit,
    VueTelInput
  },
  mixins: [formHandler, screenBehavior],
  ApiUrl: 'admin/carousel-edit',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  computed: {
    lightyShareCertifed: {
      get () {
        return Boolean(this.edit.lightyShareCertifed)
      },
      set (val) {
        this.edit.lightyShareCertifed = val ? 1 : 0
      }
    },
    telephoneCertifed: {
      get () {
        return Boolean(this.edit.telephoneCertifed)
      },
      set (val) {
        this.edit.telephoneCertifed = val ? 1 : 0
      }
    },
    idCertifed: {
      get () {
        return Boolean(this.edit.idCertifed)
      },
      set (val) {
        this.edit.idCertifed = val ? 1 : 0
      }
    },
    forDashboard: {
      get () {
        return Boolean(this.edit.forDashboard)
      },
      set (val) {
        this.edit.forDashboard = val ? 1 : 0
      }
    },
    formKey () {
      return 'User'
    },
    saveButtonText () {
      return this.id ? 'Sauvegarder' : 'Ajouter'
    },
    Model () {
      return Carousel
    },
  },
  methods: {
    saveTelephoneCountry ({iso2}) {
      this.edit.telephone.pays = iso2
    },
    transformHook (edit) {
      edit = JSON.parse(JSON.stringify(edit))
      edit.forDashboard = edit.forDashboard ? 1 : 0
      return edit
    }
  },
  data () {
    return {
      inputTelephoneOptions: {
        placeholder: this.$t('signup.phone.placeholder')
      },
      form: {},
      edit: {},
      user: {},
      ads: [],
      loading: true,
      error: false,
      errorMessage: '',
      statutUserOptions: [
        // {value: 0, label: this.$t('signup.step-1.statut.0')},
        {value: 2, label: this.$t('signup.step-1.statut.2')},
        {value: 4, label: this.$t('signup.step-1.statut.4')},
        {value: 5, label: this.$t('signup.step-1.statut.5')},
        {value: 3, label: this.$t('signup.step-1.statut.3')},
        {value: 1, label: this.$t('signup.step-1.statut.1')},
      ]
    }
  }
}
</script>

<template>
  <el-form v-loading="isLoading" :model="form" label-width="300px" :label-position="formAlignement" size="large" class="custom_form_lightyshare" >

    <div class="product-creation-form-header">
      <h3>Compte</h3>
    </div>
    <el-form-item label="Nom" prop="nom">
      <template #label>
        <div>
          <h4 class="label-title">Titre</h4>
        </div>
      </template>
      <el-input v-model="edit.title" ></el-input>
    </el-form-item>
    <el-form-item label="Nom" prop="nom">
      <template #label>
        <div>
          <h4 class="label-title">Description</h4>
        </div>
      </template>
      <el-input
          v-model="edit.description"
          autosize
          type="textarea"
      />
    </el-form-item>
    <el-form-item label="Identité" prop="prenom">
      <template #label>
        <div>
          <h4 class="label-title">Dashboard</h4>
          <p class="label-info">Afficher sur le dashboard</p>
        </div>
      </template>
      <div style="width: 100%;">
        <el-switch v-model="forDashboard"></el-switch>
      </div>
    </el-form-item>
    <el-button :loading="formLoading" plain type="info" size="large" class="btn btn-save" :disabled="!this.hasUpdate" @click.prevent="sendForm">{{ saveButtonText }}</el-button>
  </el-form>

</template>

<style scoped>

</style>