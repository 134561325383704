<script>
export default {
  name: "ImportantActions",
  props: {
    asks: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<template>
  <div>
    <h2>important action</h2>
  </div>
</template>

<style scoped>

</style>