<template>
  <div
      v-click-outside="looseFocus"
      style="position:relative; width:100%" v-bind="$attrs">
    <el-input
        type="text"
        ref="inputRef"
        :disabled="disabled"
        :placeholder="placeholder"
        v-model="searchAsk"
        @focusin="onFocus"
        @focusout="onFocusOut"
        :class="itemInputClass"
    >
      <template #suffix>
        <span style="min-width: 20px;"><el-icon v-if="numberOfRequest > 0" class="is-loading"><loading/></el-icon></span>
      </template>
    </el-input>
    <div
        v-show="showSuggestion"
        class="custom_autocomplete"
        :style="listContainerStyle"
    >
      <el-scrollbar max-height="400px">
        <div v-for="slg in groups" class="suggestion-group" :class="classGroup">
          <!--    Item Suggestion    -->
          <span v-if="displayGroupTitle" class="search-group-autocomplete">{{slg.name}}</span>
          <ul class="suggestion-list">
            <li v-for="s in slg.suggestions" @click.prevent="onSuggestionClick(s)" class="item clickable-item">
              <slot v-if="hasItemSlot" name="item" :element="s"></slot>
              <a v-else href="" class="clickable-item" v-html="s.nom"></a>
            </li>
          </ul>
        </div>
        <div v-if="newItem" class="item clickable-item">
          <a href="#" @click.prevent="addNew(searchAsk)" class="item clickable-item" v-html="$t(newItem, {term: searchAsk})"></a>
        </div>
      </el-scrollbar>
    </div>
    <input v-if="selectedSuggestion" v-model="selectedValue" :name="selectedName" type="hidden">
  </div>
</template>

<script>
import {throttle} from "../../../utils/TimeExecution.js";
import {autocomplete} from "../../../services/search.js";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default {
  name: "ItemSelect",
  props: {
    itemInputClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loadOnFocus: {
      type: Boolean,
      default: false
    },
    dataTransformer: {
      type: [Function, Boolean],
      default () {
        return false
      }
    },
    placeholder: {
      type: String,
      default () {
        return ''
      }
    },
    throttleOptions: {
      type: Object,
      default () {
        return {
          wait: 500,
          leading: true,
          trailing: true
        }
      }
    },
    url: {
      type: String,
      default: '/autocomplete-json'
    },
    resultFilter: {
      type: [Function, Boolean],
      default: false
    },
    ESOptions: {
      type: Object,
      default () {
        return {}
      }
    },
    types: {
      type: Array,
      default () {
        return []
      }
    },
    modelValue: {
      type: [Object, String]
    },
    groupSections: {
      type: [Array],
      default () {
        return []
      }
    },
    newItem: {
      type: [Boolean, String],
      default: false
    }
  },
  mounted() {
    this.updateInputWidth();
    window.addEventListener('resize', this.updateInputWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateInputWidth);
  },
  created () {
    this.runThrottle = throttle(function (data) {
      const types = this.types.length ? this.types : false
      let params = {
        ...data,
      }
      if (this.ESOptions.params) {
        params = {
          ...data,
          ...this.ESOptions.params
        }
      }

      if (types) {
        params.types = types.join(',')
      }
      this.numberOfRequest++
      this.autocomplete({
        params
      }).then(this.saveSuggestion)
          .catch(this.handleError)
          .finally(() => {
            this.isResultLoading = false
            this.numberOfRequest--
          })
    }, this.throttleOptions.wait, this.throttleOptions.leading, this.throttleOptions.trailing)
  },
  data () {
    return {
      numberOfRequest: 0,
      inputWidth: 0,
      isResultLoading: false,
      selectedSuggestion: null,
      focusedSuggestion: null,
      suggestionResponse: null,
      currentSelectedSuggestion: {},
      suggestionsLoaded: [],
      displaySuggestions: true,
      suggestionFocus: false,
      searchAsk: ''
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler (val) {
        let newVal = ''
        if (typeof val === 'string') {
          newVal = val
        }
        if (val && val.marque && val.marque.nom) {
          newVal = val.marque.nom + ''
        }
        if (val && val.nom) {
          newVal += ' ' + this.modelValue.nom
        }
        console.log('new val')
        this.searchAsk = newVal
      }
    },
    searchAsk: {
      handler (val) {
        this.runThrottle({q: val})
        this.$emit('update:searchAsk', val)
        // this.runAutocomplete({q: val})
      }
    }
  },
  methods: {
    focus () {
      console.log('item select focus')
      this.$refs.inputRef.focus()
    },
    onFocusOut () {
      // loose focus methods after half a second delay
      setTimeout(() => {
        this.looseFocus()
      }, 500)
    },
    updateInputWidth() {
      if (this.$refs.inputRef) {
        this.inputWidth = this.$refs.inputRef.$el.clientWidth;
      }
    },
    addNew (item) {
      this.$emit('new', item)
    },
    onFocus () {
      this.updateInputWidth()
      this.suggestionFocus = true
      if (this.loadOnFocus) {
        this.runThrottle({q: this.searchAsk})
      }
    },
    onSuggestionClick (s) {
      this.selectedSuggestion = s
      this.searchAsk = ''
      this.suggestionFocus = false
      // const data = this.dataTransformer(s)
      let data = {
        ...s,
        id: s.id,
        nom: s.term,
        marque: s.marque
      }
      if (typeof this.dataTransformer === 'function') {
        data = this.dataTransformer(s)
      }
      this.$emit('update:modelValue', data)
    },
    looseFocus() {
      this.suggestionFocus = false
    },
    handleError () {
    },
    saveSuggestion (response) {
      this.suggestionResponse = response.data
    },
    runAutocomplete (data) {
      this.autocompleteThrottle(data)
    },
    autocompleteThrottle: throttle(function (data) {
      autocomplete(data, this.url)
          .then(this.saveSuggestion)
          .catch(this.handleError)
          .finally(() => {
            this.isResultLoading = false
          })
    }, 500, true, true),
    async autocomplete (params) {
      let config = {
        headers: {'content-type': 'Application/json'},
        ...params
      }
      const response = await this.$axios.get(this.url, config)
      if (response.statusText === "OK" && response.data) {
        return response
      } else {
        throw {response : response}
      }
    }
  },
  computed: {
    listContainerStyle () {
      return {
        width: this.inputWidth + 'px'
      }
    },
    classGroup () {
      return this.displayGroupTitle ? '' : 'no-padding'
    },
    displayGroupTitle () {
      return this.groupSections.length > 1
    },
    getPlaceholder () {
      if (this.placeholder) {
        return this.placeholder
      }
      return this.$t('home.input.1')
    },
    hasItemSlot () {
      return Object.keys(this.$slots).includes('item')
    },
    selectedValue () {
      if (this.selectedSuggestion === null) {
        return ''
      }
      return this.selectedSuggestion.id
    },
    selectedName () {
      if (this.selectedSuggestion === null) {
        return ''
      }
      const Types = {
        modele: 'mo',
        marque: 'ma',
        categorie: 'sc'
      }
      return Types[this.selectedSuggestion.type]
    },
    showSuggestion ()
    {
      return this.suggestionList.length && this.suggestionFocus
    },
    suggestionList () {
      return this.suggestionResponse === null ? [] : this.suggestionResponse.hits.hits;
    },
    suggestionListGroups () {
      return this.suggestionList.reduce((group, suggestion) => {
        if (!group.includes(suggestion._type)) {
          group.push(suggestion._type)
        }
        return group
      }, [])
    },
    groups () {
      return this.suggestionListGroups.map((v) => {
        let suggestions = this.suggestionList.filter((suggestion) => {
          return suggestion['_type'] === v
        }).map((suggestion) => {
          let nom = suggestion['highlight'] && suggestion['highlight'].nom ? suggestion['highlight'].nom.join('') : suggestion['_source'].nom
          if (suggestion['_type'] === 'modele') {
            nom = suggestion['_source'].marque.nom + ' - ' + nom
          }
          const add = {
            type: suggestion['_type'],
            term: suggestion['_source'].nom,
            nom
          }
          return {...suggestion['_source'], ...add}
        })
        if (typeof this.resultFilter === 'function') {
          suggestions = suggestions.filter(this.resultFilter)
        }
        return {
          name: capitalizeFirstLetter(v),
          suggestions
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
