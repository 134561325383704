<template>
  <div v-if="pricings">
    <div v-show="edit[rentIndex].active">
      <el-form-item v-if="sellMode" :label="$t('steps-location.top-section.ad-type.location.title')" >
        <el-switch v-model="edit[rentIndex].active"></el-switch>
      </el-form-item>
      <el-form-item  :label="$t('steps-location-4.rent-input.label')" :error="fieldsErrors && fieldsErrors.tarif && fieldsErrors.tarif.prixUnJour">
        <template #label>
          <div>
            <h4 class="label-title">Prix HT</h4>
            <p class="label-info">Indiquez le prix de location par jour hors taxe. </p>
          </div>
        </template>
        <el-input v-model="edit[rentIndex].value" type="number" :placeholder="$t('steps-location-4.rent-input.placeholder')">
          <template #append>€</template>
        </el-input>
      </el-form-item>
      <el-form-item v-if="valueMode" label="Valeur d'achat" :error="fieldsErrors && fieldsErrors.valeurAchat" >
        <template #label>
          <div>
            <h4 class="label-title">Valeur d’achat</h4>
            <p class="label-info">Indiquez la valeur à laquelle vous avez acheté ce produit. La valeur doit être identique à votre facture d’achat.</p>
          </div>
        </template>
        <el-input v-model="edit[costIndex].value" type="number" :placeholder="$t('steps-location-4.rent-input.cost-placeholder')">
          <template #append>€</template>
        </el-input>
      </el-form-item>
    </div>
    <div v-if="edit.sellMode" v-show="!edit[rentIndex].active">
      <div class="product-creation-form-header">
        <h3>{{$t('steps-location.top-section.ad-type.location.title')}}</h3>
      </div>
      <el-form-item v-if="edit[rentIndex]"  :label="$t('steps-location.top-section.ad-type.location.description')" >
        <el-switch v-model="edit[rentIndex].active"></el-switch>
      </el-form-item>
    </div>

    <div v-show="edit[sellIndex].active" v-if="sellMode">
      <div class="product-creation-form-header">
        <h3>{{$t('steps-location.top-section.ad-type.vente.title')}}</h3>
        <p>{{$t('steps-location-4.sell-input.description')}} </p>
      </div>
      <el-form-item v-if="edit[sellIndex]"  :label="$t('steps-location.top-section.ad-type.vente.title')" >
        <el-switch v-model="edit[sellIndex].active"></el-switch>
      </el-form-item>
      <el-form-item v-if="edit[sellIndex]" :label="$t('steps-location-4.sell-input.label')">
        <el-input v-model="edit[sellIndex].value" type="number" :placeholder="$t('steps-location-4.sell-input.placeholder')">
          <template #append>€</template>
        </el-input>
      </el-form-item>
    </div>
    <div v-show="!edit[sellIndex].active" v-if="sellMode">
      <div class="product-creation-form-header">
        <h3>{{$t('steps-location.top-section.ad-type.vente.title')}}</h3>
      </div>
      <el-form-item v-if="edit[sellIndex]"   :label="$t('steps-location.top-section.ad-type.vente.description')" >
        <el-switch v-model="edit[sellIndex].active"></el-switch>
      </el-form-item>
    </div>

  </div>
</template>

<script>

function objectEquals(x, y) {
  'use strict';

  if (x === null || x === undefined || y === null || y === undefined) { return x === y; }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) { return false; }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) { return x === y; }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) { return x === y; }
  if (x === y || x.valueOf() === y.valueOf()) { return true; }
  if (Array.isArray(x) && x.length !== y.length) { return false; }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) { return false; }

  // if they are strictly equal, they both need to be an object at least
  if (!(x instanceof Object)) { return false; }
  if (!(y instanceof Object)) { return false; }

  // recursive object equality check
  let p = Object.keys(x);
  return Object.keys(y).every(function (i) { return p.indexOf(i) !== -1; }) &&
      p.every(function (i) { return objectEquals(x[i], y[i]); });
}
export default {
  name: "EditAdEconomics",
  props: {
    valueMode: {
      type: Boolean,
      default: true
    },
    sellMode: {
      type: Boolean,
      default: false
    },
    fieldsErrors: {
      type: Object
    },
    modelValue: {
      type: [Array]
    },
    defaultValue: {
      type: [Array, Boolean],
      default: false
    }
  },
  data () {
    return {
      edit: []
    }
  },
  watch: {
    modelValue: {
      handler (val, oldVal) {
        this.edit = JSON.parse(JSON.stringify(this.editForm))
      },
      immediate: true,
      deep: true
    },
    edit: {
      handler (val) {
        if (val && this.hasUpdate(this.transformToValue(val))) {
          this.$emit('update:modelValue', this.transformToValue(val))
        }
        // if (val && this.hasUpdate(this.transformToValue(val))) {
        //   console.log('Edit value watch')
        //   console.log(this.transformToValue(val))
          // this.$emit('update:modelValue', this.transformToValue(val))
        // }
        // if (this.hasUpdate && val) {
        //   console.log('Edit value watch')
        //   this.$emit('update:modelValue', this.transformToValue(val))
        // }
      },
      deep: true
    }
  },
  computed: {
    editFormObject () {
      return this.editForm.reduce((acc, item) => {
        acc[item.name] = item
        return acc
      }, {})
    },
    editForm () {
      return Object.keys(this.defaultEditForm).map(key => {
        if (Array.isArray(this.modelValue)) {
          const value = this.modelValue.find(item => item.name === key)
          return {
            name: key,
            ...this.defaultEditForm[key],
            ...value
          }
        }
        return {
          name: key,
          ...this.defaultEditForm[key]
        }
      })
    },
    defaultEditForm () {
      return {
        rent: {
          active: true,
          value: null
        },
        cost: {
          active: true,
          value: null
        },
        sell: {
          active: false,
          value: null
        }
      }
    },
    rentIndex () {
      return this.edit.findIndex(item => item.name === 'rent')
    },
    sellIndex () {
      return this.edit.findIndex(item => item.name === 'sell')
    },
    costIndex () {
      return this.edit.findIndex(item => item.name === 'cost')
    },
    editPricingArray () {
      return this.transformToValue(this.pricings)
    },
    pricings () {
      try {
        return ['rent', 'sell', 'cost'].reduce((acc, v, k) => {
          acc[v] = {
            name: v,
            id: null,
            value: null,
            active: false
          }
          if (this.modelValue && this.modelValue.length) {
            const item = this.modelValue.find(item => item.name === v)
            if (item) {
              acc[v] = {...acc[v],...item}
            }
          }
          return acc
        }, {})
      }catch(e) {
        console.log(e)
      }
    },
  },
  methods: {
    hasUpdate (val) {
      return !objectEquals(this.modelValue, val)
    },
    // mergeEdit (modelValue) {
    //   return this.
    // },
    mergeAllPrincing (val) {
      return ['rent', 'sell', 'cost'].map((key) => {
        let item = {
          name: key,
          id: null,
          value: null,
          active: false
        }
        if (this.modelValue && this.modelValue.length) {
          const it = this.modelValue.find(i => i.name === key)
          if (it) {
            item = {...item,...it}
          }
        }
        return item
      })
    },
    princingMap (item) {
      item = {
        name: v,
        id: null,
        value: null,
        active: false
      }
      if (this.modelValue && this.modelValue.length) {
        const it = this.modelValue.find(i => i.name === v)
        if (it) {
          item = {...item,...it}
        }
      }
      return item
    },
    transformToObject (val) {
      return val.reduce((acc, item) => {
        acc[item.key]['value'] = item.value
        acc[item.key]['name'] = item.name
        acc[item.key]['active'] = item.active
        return acc
      }, {...this.edit})
    },
    transformToValue (val) {
      return Object.keys(val).filter(key => {
        if (val[key].name === 'cost' && !this.valueMode) {
          return false
        }
        return val[key].active || val[key].name !== 'sell'
      }).map(key => {
        const hasValue = this.modelValue.find(item => item.name === val[key].name)
        const transformedValue = {
          ...val[key],
          name: val[key].name,
          active: val[key].active
        }
        if (hasValue && hasValue.id) {
          transformedValue.id = hasValue.id
        } else {
          transformedValue.id = null
        }
        return transformedValue
      })
    }
  }

}
</script>

<style scoped>

</style>