import { Model } from '@vuex-orm/core'
import User from "../../user/services/User.js";

export default class Bill extends Model {
  static entity = 'bill'

  static fields () {
    return {
      id: this.attr(null),
      numeroFactureComplet: this.attr(''),
      numeroFacture: this.attr(''),
      dateFacturation: this.attr(''),
      billedAt: this.attr(null),
    }
  }
}