<template>
  <section class="col-sm-10 owner-settings-page-section availability-section d-block" id="availability-section" section-title="Disponibilité">

    <form action="" method="POST">
      <h2 class="form-section-title" v-html="$t('owner-settings.list-group.item.1.location.title')"></h2>
      <p class="form-section-description mb-4" v-html="$t('owner-settings.list-group.item.1.location.description')">
      </p>

      <label for="use-personnal-adress" class="form-checkbox-label mb-3">
        <div class="custom-checkbox">
          <input type="checkbox" id="use-personnal-adress" name="usePersonnalAdress" v-model="edit.usePersonnalAdress">
          <span class="check"><i class="fas fa-check"></i></span>
        </div>
        <span v-html="$t('owner-settings.list-group.item.1.location.check-address')"></span>
      </label>

      <div>
        <div class="form-group">
          <label for="autocomplete" v-html="$t('owner-settings.list-group.item.1.location.second-address-form.label')">
          </label>
          <input-city v-model="edit.AdresseGeolocAnnonce" :disabled="edit.usePersonnalAdress"/>
  <!--        <input type="text" id="autocomplete" onfocus="initAutocomplete()" name="secondaryAdress" maxlength="255" class="form-control " placeholder="12 rue du réalisateur, 75012 Paris, France" value="{% if content.adresseGeoloc.certifie is defined and content.adresseGeoloc.certifie %}{{ content.adresseGeoloc.numero }} {{ content.adresseGeoloc.rue }}, {{ content.adresseGeoloc.codePostal }} {{ content.adresseGeoloc.ville }}{% elseif addresse.certifie is defined and addresse.certifie %}{{ addresse.codePostal }}{% endif %}">-->
          <small class="form-text text-muted" v-html="$t('owner-settings.list-group.item.1.location.second-address-form.text')">
          </small>
          <div class="invalid-feedback"></div>
        </div>

      </div>

      <hr class="mt-4 mb-4">

      <h2 class="form-section-title" v-html="$t('owner-settings.list-group.item.1.schedule.title')">
      </h2>
      <p class="form-section-description mb-4" v-html="$t('owner-settings.list-group.item.1.schedule.description')">
      </p>

      <div class="form-row">
        <div class="col">
          <label for="lundi" class="form-checkbox-label mb-3">
            <div class="custom-checkbox">
              <input type="checkbox" id="lundi" name="joursDispo[]" value="Lundi" v-model="joursDispo">
              <span class="check"><i class="fas fa-check"></i></span>
            </div>
            {{$t('Monday')}}
          </label>
        </div>
        <div class="col">
          <label for="mardi" class="form-checkbox-label mb-3">
            <div class="custom-checkbox">
              <input type="checkbox" id="mardi" name="joursDispo" value="Mardi" v-model="joursDispo">
              <span class="check"><i class="fas fa-check"></i></span>
            </div>
            {{$t('Tuesday')}}
          </label>
        </div>
        <div class="col">
          <label for="mercredi" class="form-checkbox-label mb-3">
            <div class="custom-checkbox">
              <input type="checkbox" id="mercredi" name="joursDispo[]" value="Mercredi" v-model="joursDispo">
              <span class="check"><i class="fas fa-check"></i></span>
            </div>
            {{$t('Wednesday')}}
          </label>
        </div>
        <div class="col">
          <label for="jeudi" class="form-checkbox-label mb-3">
            <div class="custom-checkbox">
              <input type="checkbox" id="jeudi" name="joursDispo[]" value="Jeudi" v-model="joursDispo">
              <span class="check"><i class="fas fa-check"></i></span>
            </div>
            {{$t('Thursday')}}
          </label>
        </div>
        <div class="col">
          <label for="vendredi" class="form-checkbox-label mb-3 ">
            <div class="custom-checkbox">
              <input type="checkbox" id="vendredi" name="joursDispo[]" value="Vendredi" v-model="joursDispo" >
              <span class="check"><i class="fas fa-check"></i></span>
            </div>
            {{$t('Friday')}}
          </label>
        </div>
        <div class="col">
          <label for="samedi" class="form-checkbox-label mb-3">
            <div class="custom-checkbox">
              <input type="checkbox" id="samedi" name="joursDispo[]" value="Samedi" v-model="joursDispo">
              <span class="check"><i class="fas fa-check"></i></span>
            </div>
            {{$t('Saturday')}}
          </label>
        </div>
        <div class="col">
          <label for="dimanche" class="form-checkbox-label mb-3">
            <div class="custom-checkbox">
              <input type="checkbox" id="dimanche" name="joursDispo" value="Dimanche" v-model="joursDispo">
              <span class="check"><i class="fas fa-check"></i></span>
            </div>
            {{$t('Sunday')}}
          </label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 pr-md-3 availability-select">
          <label for="aPartirDe" v-html="$t('owner-settings.list-group.item.1.schedule.availability.label.1')">
          </label>
          <select class=" form-control " id="aPartirDe" name="aPartirDe" v-model="edit.conditionsBoutique.aPartirDe">
            <option v-for="opt in ApartirDeOptions" :value="opt.value">
              {{opt.text}}
            </option>
          </select>
        </div>
        <div class="form-group col-md-6 pr-md-3 availability-select">
          <label for="jusquA" v-html="$t('owner-settings.list-group.item.1.schedule.availability.label.2')">
          </label>
          <select class=" form-control " id="jusquA" name="jusquA" v-model="edit.conditionsBoutique.jusquA">
            <option v-for="opt in JusquAHoursOptions" :value="opt.value">
              {{opt.text}}
            </option>
          </select>
        </div>
      </div>
      <hr class="mt-4 mb-4">

      <h2 class="form-section-title" v-html="$t('owner-settings.list-group.item.1.condition.title')">
      </h2>
      <p class="form-section-description mb-4" v-html="$t('owner-settings.list-group.item.1.condition.description')">
      </p>

      <div class="form-row flex-column mb-3 no-gutters">
        <p class="question col-12" v-html="$t('owner-settings.list-group.item.1.condition.durationMin.question')">
        </p>
        <div class="form-group col-md-4 availability-select">
          <select class=" form-control " id="time-notified" name="dureeLocationMin" v-model="edit.conditionsBoutique.dureeLocationMin">
            <option value="-1" disabled selected>
              {{$t('owner-settings.list-group.item.1.condition.durationMin.option.1')}}
            </option>
            <option value="0">
              {{$t('owner-settings.list-group.item.1.condition.durationMin.option.2')}}
            </option>
            <option value="1">
              {{$t('owner-settings.list-group.item.1.condition.durationMin.option.3')}}
            </option>
            <option value="2">
              {{$t('owner-settings.list-group.item.1.condition.durationMin.option.4')}}
            </option>
            <option value="3">
              {{$t('owner-settings.list-group.item.1.condition.durationMin.option.5')}}
            </option>
            <option value="7">
              {{$t('owner-settings.list-group.item.1.condition.durationMin.option.6')}}
            </option>
          </select>
<!--          {#                 <input type="number" class="form-control" id="time-notified"  placeholder="Choisir une durée" min="1" name="dureeLocationMin">#}-->
        </div>
      </div>
      <div class="form-row flex-column mb-0">
        <p class="question col-12" v-html="$t('owner-settings.list-group.item.1.condition.reservationMin.question')">
        </p>
        <div class="form-group col-md-4 availability-select">
          <select class=" form-control " id="time-request" name="delaisReservationMin" v-model="edit.conditionsBoutique.delaisReservationMin">
            <option value="-1" disabled selected v-html="$t('owner-settings.list-group.item.1.condition.reservationMin.option.1')">
            </option>
            <option value="0" v-html="$t('owner-settings.list-group.item.1.condition.reservationMin.option.2')">
            </option>
            <option value="1" v-html="$t('owner-settings.list-group.item.1.condition.reservationMin.option.3')">
            </option>
            <option value="2" v-html="$t('owner-settings.list-group.item.1.condition.reservationMin.option.4')">
            </option>
            <option value="3" v-html="$t('owner-settings.list-group.item.1.condition.reservationMin.option.5')">
            </option>
            <option value="7" v-html="$t('owner-settings.list-group.item.1.condition.reservationMin.option.6')">
            </option>
          </select>
        </div>
      </div>

      <hr class="mt-4 mb-4">

      <h2 class="form-section-title" v-html="$t('owner-settings.list-group.item.1.desactivation.title')">
      </h2>
      <p class="form-section-description mb-4" v-html="$t('owner-settings.list-group.item.1.desactivation.description')">
      </p>

      <label for="vacation-checkbox" class="form-checkbox-label mb-3">
        <div class="custom-checkbox">
          <input type="checkbox" id="vacation-checkbox" name="vacationMode" v-model="edit.vacationMode">
          <span class="check"><i class="fas fa-check"></i></span>
        </div>
        <span v-html="$t('owner-settings.list-group.item.1.desactivation.checkbox')"></span>
      </label>

      <div v-show="edit.vacationMode" id="datePickerForm">
        <div class="row">
          <div class="col-sm-1 ">
            <label style="line-height: 18px; margin-top:14px;" class="text-center" v-html="$t('owner-settings.list-group.item.1.desactivation.label.1')">
            </label>
          </div>
          <div class="col-sm-4 ">
            <el-date-picker id="vacation-start-date" class="start-date text-center datepicker_settings" size="large"
                   name='vacationStartDate' v-model="edit.vacationStart" value-format="DD/MM/YYYY" format="DD/MM/YYYY"/>
          </div>
          <div class="col-sm-1 ">
            <label style="line-height: 18px; margin-top:14px;" class="text-center" v-html="$t('owner-settings.list-group.item.1.desactivation.label.2')">
            </label>
          </div>
          <div class="col-sm-4 ">
            <el-date-picker id="vacation-end-date" class="end-date text-center datepicker_settings" size="large"
                   name='vacationEndDate' v-model="edit.vacationEnd" value-format="DD/MM/YYYY" format="DD/MM/YYYY"/>
          </div>
        </div>
      </div>
      <button class="btn btn-short btn-primary mt-4" :class="buttonClass" @click.prevent="sendForm" id="save-button-section-availability" v-html="$t('owner-settings.list-group.item.1.save')">
      </button>
    </form>
  </section>

</template>

<script>
import { loadPageContent, sendFormContent } from "../../services/parameters";
import inputCity from "./inputCity.vue";

function objectEquals(x, y) {
  'use strict';

  if (x === null || x === undefined || y === null || y === undefined) { return x === y; }
  // after this just checking type of one would be enough
  if (x.constructor !== y.constructor) { return false; }
  // if they are functions, they should exactly refer to same one (because of closures)
  if (x instanceof Function) { return x === y; }
  // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
  if (x instanceof RegExp) { return x === y; }
  if (x === y || x.valueOf() === y.valueOf()) { return true; }
  if (Array.isArray(x) && x.length !== y.length) { return false; }

  // if they are dates, they must had equal valueOf
  if (x instanceof Date) { return false; }

  // if they are strictly equal, they both need to be object at least
  if (!(x instanceof Object)) { return false; }
  if (!(y instanceof Object)) { return false; }

  // recursive object equality check
  var p = Object.keys(x);
  return Object.keys(y).every(function (i) { return p.indexOf(i) !== -1; }) &&
      p.every(function (i) { return objectEquals(x[i], y[i]); });
}

function zeroFill( number, width )
{
  width -= number.toString().length;
  if ( width > 0 )
  {
    return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
  }
  return number + ""; // retourne un string
}

function getHoursList () {
  const hours = []
  for(var i = 0; i < 24; i++)
  {
    hours.push(zeroFill(i, 2) + ':00')
    hours.push(zeroFill(i, 2) + ':30')
  }
  return hours
}

export default {
  name: "disponibilite",
  components: {
    inputCity
  },
  data () {
    return {
      personalAddressActive: false,
      vacationMode: false,
      contentLoaded: false,
      edit: {
        AdresseGeolocAnnonce: null,
        conditionsBoutique: {
          delaisReservationMin: 0,
          dureeLocationMin: 0,
          aPartirDe: '',
          jusquA: '',
          joursDispo: ''
        }
      },
      content: {
        AdresseGeolocAnnonce: null,
        conditionsBoutique: {
          delaisReservationMin: 0,
          dureeLocationMin: 0,
          joursDispo: ''
        }
      },
      vacationStart: null,
      vacationEnd: null
    }
  },
  watch: {
    content: {
      immediate: true,
      deep:true,
      handler (val) {
        // this.edit = val
        this.edit = {
          ...JSON.parse(JSON.stringify(val))
        }
      }
    },
    $route: {
      immediate: true,
      handler (val) {
        // if ()
        this.loadPage().catch(this.handleError)
      }
    }
  },
  computed: {
    symfony () {
      return this.$symfony
    },
    hasUpdate () {
      return !objectEquals(this.content, this.edit)
    },
    ApartirDeOptions () {
      let options = []
      options.push({
        'text': this.$t('owner-settings.list-group.item.1.schedule.availability.option'),
        'value': 'nimporteQuand'
      })
      const hours = getHoursList().map((v) => {
        return {
          text: v,
          value: v
        }
      })
      options = [...options, ...hours]
      return options

    },
    buttonClass () {
      return this.hasUpdate ? '' : 'disabled'
    },
    usePersonnalAdress: {
      get () {
        return this.personalAddressActive || this.content.AdresseGeolocAnnonce === null
      },
      set (val) {
        console.log(val)
        this.personalAddressActive = val
      }
    },
    JusquAHoursOptions () {
      let options = []
      options.push({
        'text': this.$t('owner-settings.list-group.item.1.schedule.availability.option'),
        'value': 'nimporteQuand'
      })
      const hours = getHoursList().map((v) => {
        return {
          text: v,
          value: v
        }
      })
      options = [...options, ...hours]
      return options
      // if ()
    },
    joursDispo: {
      get () {
        return this.edit.conditionsBoutique.joursDispo.split(',')
      },
      set (val) {
        const jours = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']
        const joursDispo = jours.reduce((acc, j) => {
          if (val.includes(j)) {
            acc.push(j)
          }
          return acc
        }, [])
        this.edit.conditionsBoutique.joursDispo = joursDispo.join(',')
      }
    }
  },
  methods: {
    async sendForm () {
      const formContent = {
        // user: {
          ...this.edit,
          ...this.edit.conditionsBoutique
        // },
      }
      const content = await sendFormContent(formContent)
      console.log(content)
      if (content.data && typeof content.data !== 'string') {
        this.content = {
          ...content.data
        }
      }
    },
    handleError (error) {
      console.error(error)
    },
    async loadPage () {
      const content = await loadPageContent()
      if (content.data) {
        this.content = {
          ...content.data
        }
      }
      console.log(content)
    }
  }
}
</script>

<style scoped>
.el-input__inner.datepicker_settings {
  box-shadow: none !important;
}
.el-input__inner.datepicker_settings:focus {
  box-shadow: none !important;
}
.el-input__inner.datepicker_settings:active {
  box-shadow: none !important;
}
</style>
