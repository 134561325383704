<template>
  <div class="boutique-header">
    <h3 class="parameters-title">{{ tabsTitle }}</h3>
    <h4 class="parameters-subtitle">{{ tabsDescription }}</h4>
  </div>
  <CustomTabs v-if="hasTabs" :tabs="allTabs"></CustomTabs>
</template>

<script>
import CustomTabs from "../../../components/CustomTabs.vue";
import LoadContent from "~/mixins/loadContent.js";
import Conflict from "~/modules/ad/services/Conflict.js";

export default {
  components: {
    CustomTabs
  },
  mixins: [LoadContent],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  data () {
    return {
      archivesCount: 0
    }
  },
  methods: {
    afterFetchSuccess (Product) {
      console.log('All Product')
      console.log(Product)
      if (Product && Product.response && Product.response.data && Product.response.data.archives_count) {
        this.archivesCount = Product.response.data.archives_count
      }
    }
  },
  computed: {
    tabs () {
      let tabs = [
        {
          label: 'Produits',
          to: { name: 'annonces'}
        },
        {
          label: 'Packs',
          to: { name: 'pack'}
        },
        // {
        //   label: 'Inventaires',
        //   to: { name: 'items'}
        // },
      ]
      if (this.conflicts.length > 0) {
        tabs.push({
          label: 'Conflits',
          to: { name: 'conflicts'}
        })
      }
      if (this.archivesCount > 0) {
        tabs.push({
          label: 'Archives',
          to: { name: 'products_archives'}
        })
      }
      return tabs
    },
    fetchUrl () {
      return '/user/produits/conflicts'
    },
    Model () {
      return Conflict
    },
    conflicts () {
      return Conflict.all()
    },
    hasTabs () {
      if ((this.$route.name === 'add-product' && !this.$route.params.id)
          || (this.$route.name === 'add-bundle' && !this.$route.params.id)
          || (this.$route.name === 'add-stock' && !this.$route.params.id)
      ) {
        return false
      } else {
        return true
      }
    },
    tabsDescription () {
      if (this.$route.name === 'add-product' && !this.$route.params.id) {
        return this.$t('shop.products.product-edit.description-new')
      }
      if (this.$route.name === 'add-bundle' && !this.$route.params.id) {
        return this.$t('shop.products.bundle-edit.description-new')
      }
      return this.$t('shop.products.description')
    },
    tabsTitle () {
      if (this.$route.name === 'add-product' && !this.$route.params.id) {
        return this.$t('shop.products.product-edit.title-new')
      }
      if (this.$route.name === 'add-bundle' && !this.$route.params.id) {
        return this.$t('shop.products.bundle-edit.title-new')
      }
      if (this.$route.name === 'add-stock' && !this.$route.params.id) {
        return this.$t('shop.products.stock.add-stock.title')
      }
      return this.$t('shop.products.title')
    },
    allTabs () {
      let routesTab = []
      if (this.$route.name === 'ad-edit') {
        routesTab = [
          {
              label: 'Modifier',
              to: { name: 'ad-edit', params: {id: this.$route.params.id}}
          }
        ]
      }
      return [
          ...routesTab,
          ...this.tabs
      ]
    }
  },
  name: "AdsTabs"
}
</script>

<style scoped>

</style>