<template>
  <el-alert v-for="error in HeaderErrors" :title="error" type="error" show-icon :closable="false" />
      <el-form v-loading="isLoading" :model="form" label-width="300px" :label-position="formAlignement" size="large" class="custom_form_lightyshare" >
        <div v-if="titleHeader" class="product-creation-form-header">
          <h3>Informations générales</h3>
          <p class="label-info">Ajouter un produit dans votre inventaire. Vous pourrez par la suite choisir de le proposer à la location à l’unité ou créer un pack en sélectionnant plusieurs produits.</p>
        </div>
        <el-form-item :error="FieldsErrors.titre">
          <template #label>
            <div>
              <h4 class="label-title">Nom du pack</h4>
              <p class="label-info">Ajouter le nom de votre pack qui sera affiché dans votre boutique</p>
            </div>
          </template>
          <el-input v-model="edit.titre" type="text" placeholder="Pack Sony Alpha 7S avec 2 objectifs"/>
        </el-form-item>
        <el-form-item :label="$t('steps-location-2.annonce.input-description.label')" :error="FieldsErrors.description">
          <template #label>
            <div>
              <h4 class="label-title">Description du pack</h4>
<!--              <p class="label-info">Indiquez par exemple les accessoires fournis avec ce produit.</p>-->
            </div>
          </template>
          <input-description v-if="hasDescription" v-model="edit.description" :limit="2000"></input-description>
<!--          <el-input v-model="edit.description" maxlength="2000" class="" show-word-limit type="textarea" :placeholder="$t('steps-location-2.annonce.input-description.placeholder')"/>-->
        </el-form-item>
        <el-form-item label="Photo">
          <template #label>
            <div>
              <h4 class="label-title">{{$t('steps-location-2.annonce.photos.main-photo')}}</h4>
              <p class="label-info">Ajouter jusqu’à 5 photos de votre produit.</p>
            </div>
          </template>
          <ProductMediasEdit
              v-model="edit.medias"
              :action="'/api/user/upload-media'"
              :annonce-id="edit.id"/>
        </el-form-item>
        <hr>
        <div class="product-creation-form-header">
          <h3>Produits</h3>
        </div>
        <div v-if="migrateFromDetails && edit.detailsannonce && edit.detailsannonce.length">
          <el-form-item>
            <template #label>
              <div>
                <h4 class="label-title">Liez les équipements en produit</h4>
                <p class="label-info">Pour valider la création du pack, chaque équipement doit être lié à un produit existant, un nouveau ou supprimer.</p>
              </div>
            </template>
            <el-card class="p-0 mrg-25" v-if="edit && edit.detailsannonce" style="width: 100%;" >
              <PackItemsTable :allow-to-add="false" :annonce-id="edit.id" v-model="edit.detailsannonce" :details="edit.detailsannonce" :migrate="true" @migrate="migrateDetails"/>
            </el-card>
          </el-form-item>
          <el-drawer
              :withHeader="false"
              v-model="addProduct"
              :size="'80vw'"
              :before-close="handleDrawerClose"
              @open="handleOpenDrawer"
          >
            <div style="padding: 32px 12px" v-if="selectDetail">
<!--              <el-form :label-position="'left'" label-width="300px" class="custom_form_lightyshare">-->
                <div class="product-creation-form-header" >
                  <h3>Gestion du détail : {{ selectDetail.nomEquipement}}</h3>
                  <p class="label-info">
                    Décriver comment ce détail doit être transformé
                  </p>
                </div>
                <el-form-item >
                  <template #label>
                    <div>
                      <h4 class="label-title">Résolution de la migration</h4>
                      <p class="label-info">Comment voulez-vous résoudre ce conflit</p>
                    </div>
                  </template>
                  <el-card shadow="never" class="bordered-card" style="width:100% " v-if="selectDetail">
                    <el-radio-group v-model="selectDetail.migrationType" class="block-radio">
                      <el-radio :label="1" class="label-title">Lier un nouveau produit</el-radio>
                      <p class="label-info text-radio-info" >Créer un nouveau produit pour le lié au pack en construction</p>
                      <el-radio :label="2" class="label-title">Lier un produit existant</el-radio>
                      <p class="label-info text-radio-info" >Lier ce détail à un produit existant de votre parc matériel</p>
                      <el-radio :label="0" class="label-title">Supprimer</el-radio>
                      <p class="label-info text-radio-info" >Supprimer ce détail annonce</p>
                    </el-radio-group>
                  </el-card>
                </el-form-item>
<!--              </el-form>-->
              <div v-show="selectDetail && selectDetail.migrationType === 1">
                <ProductEdit v-if="addProduct" ref="editProductPanel" :newEntity="true"  :formAlign="formAlignement"  :allowConflicts="true" :cancelAction="cancelProductAction" @afterSave="afterAddProduct" :redirectAfterSave="false"></ProductEdit>

              </div>
              <div v-show="selectDetail && selectDetail.migrationType === 2">
                <el-form :label-position="formAlignement" label-width="300px" class="custom_form_lightyshare" size="large">
                  <el-form-item>
                    <template #label>
                      <div>
                        <h4 class="label-title">Trouvez un produit</h4>
                        <p class="label-info">Trouver un produit pour le lier au detail</p>
                      </div>
                    </template>
                    <ItemSelect :result-filter="resultFilter" :load-on-focus="true" :placeholder="'Ajouter votre produit'" :newItem="false" :types="['annonce']" :ESOptions="{params: {p: 12, types: 'annonce', userIds: Auth.id}}" @update:modelValue="updateValue">
                    <template  #item="{element}" >
                      <a href="" class="clickable-item" v-html="getItemText(element)"></a>
                    </template>
                    </ItemSelect>
                  </el-form-item>
                  <AdTicket v-if="selectDetail.migrationAd" :Ad="selectDetail.migrationAd" style="margin: 12px"></AdTicket>

                  <el-button @click="linkProductToDetail(selectDetail.migrationAd)" :disabled="!selectDetail.migrationAd" :loading="linkProductToDetailLoading[selectDetail.id]" plain type="info" size="large" class="btn btn-save">Lier</el-button>
                </el-form>
              </div>
              <div v-show="selectDetail && selectDetail.migrationType === 0">
                      <el-button @click="deleteDetail(selectDetail)" :loading="deleteDetailLoading[selectDetail.id]" plain type="info" size="large" class="btn btn-save">Supprimer</el-button>

              </div>
            </div>
          </el-drawer>
          <hr>
        </div>
        <BundleItemEdit v-if="edit.bundle" v-model="edit.bundle.bundleItems" @newChange="BundleItemChange"/>
        <hr>
        <div class="product-creation-form-header">
          <h3>Tarification</h3>
        </div>
        <el-form-item>
          <template #label>
            <div>
              <h4 class="label-title">Valeur du pack</h4>
              <p class="label-info">Calcul automatique de la valeur par rapport aux produits contenu dans le pack</p>
            </div>
          </template>
          <el-input v-model="this.bundleItemValue" :disabled="true" >
            <template #append>€</template>
          </el-input>
        </el-form-item>
        <el-form-item v-if="!autoPriceComputation">
          <template #label>
            <div>
              <h4 class="label-title">Calcul automatique</h4>
              <p class="label-info">Calcul automatique du prix par rapport aux produits contenu dans le pack</p>
            </div>
          </template>
          <el-switch v-model="edit.autoPriceComputation"/>
        </el-form-item>
        <EditAdEconomics v-model="edit.computeds" :value-mode="false" :fields-errors="formErrors"/>
        <hr>
        <div class="product-creation-form-header">
          <h3>Visibilité</h3>
        </div>
        <el-form-item label="Publier">
          <template #label>
            <div>
              <h4 class="label-title">Afficher dans votre boutique</h4>
              <p class="label-info">Ce produit est visible dans votre boutique et peut être sélectionné par un locataire.</p>
            </div>
          </template>
          <el-switch v-model="publie"/>
        </el-form-item>
        <div v-if="edit.publie">
          <el-form-item label="Position dans le catalogue">
            <template #label>
              <div>
                <h4 class="label-title">Position</h4>
                <p class="label-info">Indiquez la position de ce produit dans votre boutique.</p>
              </div>
            </template>
            <el-input type="number" v-model="edit.position" />
          </el-form-item>
        </div>
        <hr>
        <el-button v-if="edit && !edit.id" @click="onCancel">Annuler</el-button>
        <el-button :loading="formLoading" plain type="info" size="large" class="btn btn-save" :disabled="!this.hasUpdate" @click.prevent="sendForm">{{ saveButtonText }}</el-button>
<!--        <button class="btn btn-short btn-primary mt-4" :class="buttonClass" id="save-button-section-pricing" @click.prevent="sendForm">-->
<!--          {{$t('owner-settings.list-group.item.2.save')}}-->
<!--        </button>-->
      </el-form>
</template>

<script>
import ItemSelect from "../components/ItemSelect.vue";
import formHandler from "../../../mixins/formHandler.js";
import Ad from '../services/Ads.js'
import EditModel from '../components/EditModel.vue'
import AdBundleEdit from '../components/AdBundleEdit.vue'
import PackItemsTable from "../components/PackItemsTable.vue";
import PackItemColumns from "../components/PackItemColumns.vue";
import MediasEdit from "../../media/components/mediasEdit.vue";
import draggable from 'vuedraggable'
import { ElTable } from 'element-plus'
import AnnonceCard from "../../../components/AnnonceCard.vue";
import EditAdEconomics from "~/modules/ad/components/EditAdEconomics.vue";
import { ElMessageBox } from 'element-plus'
import StockReferencesTable from "~/modules/ad/components/StockReferencesTable.vue";
import BundleItemEdit from "~/modules/ad/components/BundleItemEdit.vue";
import Ads from "../services/Ads.js";
import {sendFormContent, transformDataToFormContent} from "~/services/parameters.js";
import ProductMediasEdit from "~/modules/media/components/ProductMediasEdit.vue";
import screenBehavior from "~/mixins/screenBehavior.js";
import InputDescription from "~/modules/ad/components/InputDescription.vue";
import { ElNotification } from 'element-plus'
import ProductEdit from "~/modules/ad/pages/ProductEdit.vue";
import AdTicket from "~/modules/ad/components/AdTicket.vue";

export default {
  components: {
    AdTicket,
    ProductEdit,
    InputDescription,
    ProductMediasEdit,
    BundleItemEdit,
    StockReferencesTable,
    EditAdEconomics,
    AnnonceCard,
    PackItemColumns,
    AdBundleEdit,
    ElTable,
    draggable,
    EditModel,
    PackItemsTable,
    ItemSelect,
    MediasEdit
  },
  name: "BundleEdit",
  ApiUrl: 'user/bundle-edit',
  mixins: [formHandler, screenBehavior],
  emits: ['afterSave'],
  props: {
    migrateFromDetails: {
      type: Boolean,
      default: false
    },
    titleHeader: {
      type: Boolean,
      default: true
    },
    autoPriceComputation: {
      type: Boolean,
      default: true
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  async beforeRouteLeave (to, from, next) {
    if (this.hasUpdate) {
      try {
        if (this.id) {
          await ElMessageBox({
            message: 'Des modifications non sauvegarder sont présentes sur cette page. Êtes vous sur de vouloir quitter la page et perdre les informations ?',
            title: 'Attention',
            showCancelButton: true,
            confirmButtonText: 'Rester',
            cancelButtonText: 'Quitter',
            distinguishCancelAndClose: true,
            type: 'warning'
          })
          this.popupLeave = false
        } else {
          next(true)
          return
        }
        next(false); // Annuler la navigation
      } catch (e) {
        if (e === 'close') {
          this.popupLeave = false
          next(false); // Annuler la navigation
        } else if (e === 'cancel') {
          this.popupLeave = false
          next(true); // Continuer la navigation
        }
      }
    } else {
      next(true); // Continuer la navigation
    }
    // const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
    // if (!answer) return false
  },
  watch: {
    edit: {
      handler (val) {

      },
      deep: true,
      immediate: true
    },
    'edit.position': {
      handler (val) {
        if (typeof val === 'string') {
          this.edit.position = parseInt(val)
        }
      },
      immediate: true
    },
    'edit.aLaUneProfile': {
      handler (val) {
        if (typeof val === 'number') {
          this.edit.aLaUneProfile = val === 1
        }
      },
      immediate: true
    },
    'edit.autoPriceComputation': {
      handler (val) {
        if (val === true) {
          this.computeBundleItemsPrice()
        } else if (val === false) {
          this.edit.tarif.prixUnJour = this.content.tarif.prixUnJour
          let pricing = this.edit.computeds.find((i) => {
            return i.name = 'rent'
          })
          if (pricing) {
            pricing.value = this.content.tarif.prixUnJour
          }
        }
      }
    },
  },
  methods: {
    linkProductToDetail (product) {
      const editDetailsannonce = this.edit.detailsannonce[this.getDetailIdIndex(this.selectDetail.id)]
      if (!editDetailsannonce) {
        this.edit.detailsannonce.push({
          id: this.selectDetail.id,
          nomEquipement: product.title,
          migrationAd: product
        })
      } else {
        editDetailsannonce.nomEquipement = product.title
        editDetailsannonce.migrationAd = product
      }
      this.edit.bundle.bundleItems.push({
        annonceId: product.id,
        annonces: product.id,
        quantity: 1,
        discount: 0
      })
      this.selectDetail = null
      this.addProduct = false
    },
    deleteDetail (detail) {
      const detailIndex = this.getDetailIdIndex(detail.id)
      this.edit.detailsannonce.splice(detailIndex, 1)
      this.selectDetail = null
      this.addProduct = false
    },
    getItemText (item) {
      let text = ''
      let hasModele = item.modele && item.modele.nom && item.modele.marque && item.modele.marque.nom
      if (hasModele) {
        text += item.modele.marque.nom + ' ' + item.modele.nom
      }
      if (item.title) {
        return item.title
      }
      // if (hasModele && item.titre) {
      //   text += ' - '
      // }
      // if (item.titre) {
      //   text += item.titre
      // }
      return text
    },
    loadAds (items) {
      // foreach ads
      items.forEach((item, index) => {
        Ads.api().get('user/annonce-edit/' + item.migrationAdId)
      })
    },
    async updateValue (val) {
      this.selectDetail.migrationAdId = val.id
      const migrationAd = Ads.query().find(val.id)
      if (!migrationAd) {
        await this.loadAds([this.selectDetail])
      }
      this.selectDetail.migrationAd = Ads.query().find(val.id)

    },
    submitSelectProductMigration () {
      console.log('submitSelectProduct')
      console.log(this.selectProduct)
    },
    resultFilter (result) {
      return result.productType === 0
    },
    getDetailIdIndex (detailId) {
      return this.edit.detailsannonce.findIndex((d) => {
        return d.id === detailId
      })
    },
    afterAddProduct (product) {
      if (product && product.response && product.response.data && product.response.data.ads && product.response.data.ads.id) {
        this.edit.bundle.bundleItems.push({
          annonceId: product.response.data.ads.id,
          annonces: product.response.data.ads.id,
          quantity: 1,
          discount: 0
        })
        this.edit.detailsannonce[this.getDetailIdIndex(this.selectDetail.id)].migrationAd = product.response.data.ads
        this.edit.detailsannonce[this.getDetailIdIndex(this.selectDetail.id)].migrationAdId = product.response.data.ads.id

        this.selectDetail = null
        this.addProduct = false
      }
    },
    migrateDetails (detail) {
      this.addProduct = true
      this.selectDetail = detail
    },
    onCancel () {
      this.$router.push({
        name: 'pack'
      })
    },
    cancelProductAction () {
      this.selectDetail = null
      this.addProduct = false
    },
    async handleOpenDrawer () {
      await this.$refs.editProductPanel.beforeDataFetch()
    },
    handleDrawerClose () {
      this.selectDetail = null
      this.addProduct = false
    },
    async BundleItemChange () {
      for (let bundleItem of this.edit.bundle.bundleItems) {
        let ad = Ads.query().find(bundleItem.annonces)
        if (!ad) {
          await Ads.api().get('user/annonce-edit/' + bundleItem.annonces)
          ad = Ads.query().find(bundleItem.annonces)
        }
        if (ad) {
          if (ad.quantity && ad.quantity < bundleItem.quantity) {
            bundleItem.quantity = ad.quantity
          }
        }
        if (bundleItem.quantity <= 0) {
          bundleItem.quantity = 1
        }
        if (bundleItem.discount > 100) {
          bundleItem.discount = 100
        }
        if (bundleItem.discount < 0) {
          bundleItem.discount = 0
        }
      }
      if (this.edit.autoPriceComputation !== false) {
        this.computeBundleItemsPrice()
      }
    },
    computeBundleItemsPrice () {
      const newTotal = this.edit.bundle.bundleItems.reduce((total, item) => {

        const id = item.annonces ? item.annonces : item.annonceId
        let ad = Ads.query().find(id)
        let itemT = ad.tarif.prixUnJour * item.quantity
        if (item.discount) {
          itemT = itemT * (1-(item.discount/100))
        }
        return total += itemT
      }, 0)
      this.edit.tarif.prixUnJour = newTotal
      let pricing = this.edit.computeds.find((i) => {
        return i.name = 'rent'
      })
      if (pricing) {
        pricing.value = newTotal
      }
    },
    computeBundleItemsValue () {
      const newTotal = this.edit.bundle.bundleItems.reduce((total, item) => {

        const id = item.annonces ? item.annonces : item.annonceId
        let ad = Ads.query().find(id)
        if (ad) {
          let itemT = ad.valeurAchat * item.quantity
          return total += itemT
        }
        return total
      }, 0)
      console.log('newTotal')
      this.edit.valeurAchat = newTotal
      let pricing = this.edit.computeds.find((i) => {
        return i.name = 'cost'
      })
      if (pricing) {
        pricing.value = newTotal
      } else {
        this.edit.computeds.push({
          name: 'cost',
          value: newTotal
        })
      }
    },
    afterFormSend (response) {
      if (!this.id) {
        ElNotification({
          title: 'Pack ajouté',
          message: 'Votre pack a bien été ajouté à vos annonces',
          type: 'success'
        })
      } else {
        ElNotification({
          title: 'Pack sauvegardé',
          message: 'Les modification de votre pack a bien été sauvegardé',
          type: 'success'
        })
      }
      this.$emit('afterSave')
      if (!this.id && response.response.data[response.config.dataKey]['id']) {
        this.$router.push({name: 'pack'})
      }
    },
    transformHook (edit) {
      edit = JSON.parse(JSON.stringify(edit))
      if (edit.modele) {
        if (edit['modele']['categorie']) {
          edit['categorieAnnonce'] = edit['modele']['categorie']['id']
        }
        if (edit['modele']['sousCategorie']) {
          edit['souscategorieannonce'] = edit['modele']['sousCategorie']['id']
        }
        if (edit['modele']['marque']) {
          edit['marque'] = edit['modele']['marque']['id']
        }
        if(edit['modele']['id']) {
          edit['modele'] = edit['modele']['id']
        } else {
          edit['modele'] = edit['modele']
        }
        console.log(edit)
        edit['detailsannonce'] = edit['detailsannonce'].map((detail) => {
          if (detail['migrationAd'] && detail['migrationAd']['id']) {
            detail['migrationAd'] = detail['migrationAd']['id']
          }
          return {
            ...detail,
          }
        })
      }
      if (edit['stockType'] === 1) {
        edit['stocks'] = this.stocks
      }
      edit['productType'] = 1
      if (edit.bundle && Array.isArray(edit.bundle.bundleItems)) {
        const bundleItems = edit.bundle.bundleItems.map((i) => {
          return {
            ...i,
            annonces: i.annonceId
          }
        })
        edit.bundle.bundleItems = JSON.parse(JSON.stringify(bundleItems))
      }
      const rent = edit.computeds.find((computed) => computed.name === 'rent')
      if (rent) {
        edit.tarif.prixUnJour = rent.value
      }
      const cost = edit.computeds.find((computed) => computed.name === 'cost')
      if (cost) {
        edit.valeurAchat = cost.value
      }
      edit['computeds'].map((computed) => {
        if (computed['id'] === null) {
          delete computed['id'];
        }
        return computed
      })
      edit.publie = edit.publie ? 1 : 0
      return edit
    },
    modeleTransformer (s) {
      this.edit.categorieAnnonce = s.categorie;
      this.edit.marque = s.marque;
      this.edit.souscategorieannonce = s.souscategorie
      return {
        id: s.id,
        nom: s.term,
        sousCategorie: s.souscategorie,
        categorie: s.categorie,
        marque: s.marque
      }
    },
    preQuery (query) {
      query.with('bundle')
      // this.Model.
    },
    getComponentData () {
      return {
        data: this.equipements,
        stripe: true,
        style: "width: 100%;",
        'table-layout': "auto"
      }
    },
    cancelNewModele () {
      this.newModele = false
      this.edit.demandeAjoutModele = null
    },
    addNewModele () {
      // wait for 1 sec
      setTimeout(() => {
        this.$refs.newModelContainer.scrollIntoView({block: "start", inline: "nearest"})
      }, 100)
      // this.$refs.newModelContainer.scrollIntoView({block: "start", inline: "nearest"})
      // console.log('addNew modele')
      this.newModele = true
    }
  },
  computed: {
    bundleItemValue () {
      return this.edit && this.edit.bundle && this.edit.bundle.bundleItems ? this.edit.bundle.bundleItems.reduce((total, item) => {

        const id = item.annonces ? item.annonces : item.annonceId
        let ad = Ads.query().find(id)
        if (ad) {
          let itemT = ad.valeurAchat * item.quantity
          return total += itemT
        }
        return total
      }, 0) : 0
    },
    hasDescription () {
      return typeof this.edit.description !== 'undefined'
    },
    aLaUneProfile: {
      set (val) {
        this.edit.aLaUneProfile = val ? 1 : 0
      },
      get () {
        return !!this.edit.aLaUneProfile
      }
    },
    saveButtonText () {
      return this.id ? 'Sauvegarder' : 'Ajouter'
    },
    ignoreKeysUpdate () {
      return ['$id', 'imagesannonce', 'user_id', 'user', 'sku', 'computeds', 'oldTitle']
    },
    trackGroupType: {
      set (v) {
        if (v === 0) {
          this.edit.stockType = 0
        } else if (v === 1) {
          this.edit.stockType = 2
        } else if (v === 2) {
          this.edit.stockType = 4
        }
      },
      get () {
        if (this.edit.stockType === 0 || this.edit.stockType === 1) {
          return 0
        } else if (this.edit.stockType === 2 || this.edit.stockType === 3) {
          return 1
        } else if (this.edit.stockType === 4) {
          return 2
        }
      }
    },
    publie: {
      set (val) {
        this.edit.publie = val ? 1 : 0
      },
      get () {
        return !!this.edit.publie
      }
    },
    componentData () {
      return {
        data: this.edit.detailsannonce,
        stripe: true,
        style: "width: 100%;",
        'table-layout': "auto"
      }
    },
    productType: {
      get () {
        return this.productTypes.find((t) => {
          return t.value === this.edit.productType
        }) ? this.productTypes.find((t) => {
          return t.value === this.edit.productType
        }).label : ''+this.edit.productType
      },
      set (value) {
        this.edit.productType = value
      }
    },
    Model () {
      return Ad
    },
    category () {
      return this.edit && this.edit.modele && this.edit.modele.categorie && this.edit.modele.categorie.nom ? this.edit.modele.categorie.nom : '-'
    },
    sousCat () {
      return this.edit && this.edit.modele && this.edit.modele.sousCategorie && this.edit.modele.sousCategorie.nom ? this.edit.modele.sousCategorie.nom : '-'
    },
    marque () {
      return this.edit && this.edit.modele && this.edit.modele.marque && this.edit.modele.marque.nom ? this.edit.modele.marque.nom : '-'
    },
    modele () {
      return this.edit && this.edit.modele && this.edit.modele.nom ? this.edit.modele.nom : '-'
    },
    referenceGeneration () {
      return this.marque[0] + '-' + this.modele.replaceAll(/\s/g,'-')
    }
  },
  data () {
    return {
      test: 0,
      deleteDetailLoading: {},
      linkProductToDetailLoading: {},
      selectDetail: null,
      popupLeave: false,
      addProduct: false,
      referencable: false,
      newModele: false,
      adType: 'Quantité',
      trackType: 0,
      // productType: 'ad',
      productTypes: [
        {
          label: 'Produit',
          value: 0
        },
        {
          label: 'Pack',
          value: 1
        },
        {
          label: 'Service',
          value: 2
        }
      ],
      adTypes: [
        {
          label: 'Quantité',
          value: 0
        },
        {
          label: 'Références',
          value: 1
        },
        {
          label: 'Consomable',
          value: 2
        }
      ],
      content: {
        productType: 0,
      },
      edit: {
        productType: 0,
      },
      form: {}
    }
  },
}
</script>

<style scoped>

</style>
