<template>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div>
        <h3 class="subTitle">Annonces <span v-if="paginationQuery.count" class="countLabel">{{paginationQuery.count}} fichiers</span></h3>
      </div>
<!--      <CustomFilterTable :radio-filters="FiltersProps"/>-->
    </div>
<!--    <AdsTable :ads="modelList" @sort-change="sortChange"></AdsTable>-->
    <div class="pagination-container">
<!--      <el-pagination :current-page="currentPage" hide-on-single-page background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>-->
    </div>
  </el-card>
</template>

<script>
import LoadContent from "../../../mixins/loadContent.js";

export default {
  name: "ItemList",
  mixins: [LoadContent],
  computed: {

  }
}
</script>

<style scoped>

</style>