<template>
  <el-table :data="ads"
            style="width: 100%;"
            :table-layout="'auto'"
            :default-sort="defaultOrder"
            @cell-click="onCellClick"
            :row-class-name="tableRowClassName"
  >
    <template #empty>
      <slot name="empty"></slot>
    </template>
<!--    <el-table-column fixed prop="id" label="#" width="60" />-->
    <el-table-column prop="titre" :label="$t('owner-listing.tab-head.title')" sortable="custom" min-width="100">
      <template #default="{row}">
        <AdTicket :Ad="row"/>
      </template>
    </el-table-column>
    <el-table-column label="Type" min-width="150">
      <template #default="{row}">
        <el-tag
            :type="row.productType === 0 ? '' : 'danger'"
            class="mx-1 media-tag"
            effect="light"
            round
        >
          <span> {{ row.productType === 1 ? 'Pack' : 'Produit' }}</span>
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="quantity" label="Quantité" sortable="custom" min-width="250"/>
    <el-table-column prop="tarif.tarifInteger" label="Prix (HT)" sortable="custom" min-width="200" >
      <template #default="{row}">
        <span v-if="row.tarif" v-html="$currencyFormat.format(row.tarif.tarifInteger/100)"></span>
      </template>
    </el-table-column>
    <el-table-column prop="publie" label="Status" sortable="custom">
      <template #default="{row}">
        <el-tag
            :type="row.publie ? 'success' : 'danger'"
            class="mx-1 media-tag"
            effect="light"
            round
        >
          <span class="dot"></span>
          {{ row.publie ? 'Visible' : 'Hors ligne' }}
        </el-tag>
<!--        <el-switch-->
<!--            width="90px"-->
<!--            v-model="scope.row.publie"-->
<!--            inline-prompt-->
<!--            style="&#45;&#45;el-switch-on-color: #13ce66; &#45;&#45;el-switch-off-color: #ff4949"-->
<!--            active-text="En ligne"-->
<!--            inactive-text="Hors ligne"/>-->
      </template>
    </el-table-column>
    <el-table-column fixed="right" width="40px" prop="action">
      <template #default="scope">
        <div class="display-flex">
          <el-dropdown trigger="click" :ref="'dropdown' + scope.$index">
            <div class="dropdown">
              <button class="dropdown-edit-toogle" style="padding: 5px;">
                <span class="icon-kit">
                  &#xe9a5
                </span>
              </button>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item>
                  <a class="dropdown-item" href="#" @click.prevent="restoreProduct(scope)">
                    <el-icon v-show="restoreProductLoading[scope.$index]" class="is-loading"><loading/></el-icon>
                    Restaurer
                  </a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import AdTicket from "./AdTicket.vue"
import Ads from "~/modules/ad/services/Ads.js";
export default {
  components: {
    AdTicket
  },
  name: "AdsTable",
  emits: ['onArchivedProduct', 'sortChange'],
  props: {
    defaultOrder: {
      type: Object,
      default () { return {}}
    },
    ads: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      restoreProductLoading: {}
    }
  },
  methods: {
    async restoreProduct (scope) {
      const row = scope.row
      if (!this.restoreProductLoading[row.id]) {
        this.restoreProductLoading[row.id] = true
        if (this.$refs['dropdown' + scope.$index]) {
          await this.$refs['dropdown' + scope.$index].handleClose()
        }
        await Ads.api().get('user/produit/' + row.id + '/restaurer')
        this.$emit('onArchivedProduct')
        this.restoreProductLoading[row.id] = false
      }
    },
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    },
    console(data) {
      console.log(data)
    },
    onCellClick (row, column) {
      // if (column.property !== 'action') {
      //   let routeName = 'product-edition'
      //   console.log('on cell click ')
      //   console.log(row)
      //   if (row.stockType === 1) {
      //     routeName = 'product-inventory'
      //   }
      //   this.$router.push({
      //     name: routeName,
      //     params: {
      //       id: row.id
      //     }
      //   })
      // }
      // this.$router.push({
      //   name: 'product-inventory',
      //   params: {
      //     id: row.id
      //   }
      // })
      // console.log('row click')
      // console.log(row)
      // console.log(column)
    }
  }
}
</script>

<style scoped>

</style>