<template>
  <el-select
      :disabled="disabled"
      :placeholder="placeholder"
      v-model="searchAsk"
      @focusin="onFocus"
      remote
      filterable
      remote-show-suffix
      :remote-method="runThrottle"
      :loading="numberOfRequest > 0"
    >
    <template v-if="groupSections.length > 1">
      <el-option-group
          v-for="group in groups"
          :key="group.name"
          :label="group.name"
      >
        <el-option
            v-for="item in group.suggestions"
            :key="item.id"
            :label="item.nom"
            :value="item.id"
            :disabled="item.disabled"
            :highlight-first-item="true"
            @focus="focusedSuggestion = item"
            @click.native="onSuggestionClick(item)"
        >
          <template v-if="hasItemSlot" slot="item">
            <slot :item="item"/>
          </template>
        </el-option>
      </el-option-group>
    </template>
    <template v-else>
      <el-option
          v-for="item in suggestionListItems"
          :key="item.id"
          :label="item.nom"
          :value="item.id"
          :disabled="item.disabled"
          :highlight-first-item="true"
          @focus="focusedSuggestion = item"
      >
        <span v-if="item.highlight" v-html="item.highlight"></span>
<!--        <template v-if="hasItemSlot" slot="item">-->
<!--          <slot :item="item"/>-->
<!--        </template>-->
      </el-option>
    </template>
  </el-select>
</template>

<script>
import {throttle} from "../../../utils/TimeExecution.js";
import {autocomplete} from "../../../services/search.js";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default {
  name: "ItemElSelect",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    dataTransformer: {
      type: [Function, Boolean],
      default () {
        return false
      }
    },
    placeholder: {
      type: String,
      default () {
        return ''
      }
    },
    throttleOptions: {
      type: Object,
      default () {
        return {
          wait: 500,
          leading: true,
          trailing: true
        }
      }
    },
    url: {
      type: String,
      default: '/autocomplete-json'
    },
    ESOptions: {
      type: Object,
      default () {
        return {}
      }
    },
    types: {
      type: Array,
      default () {
        return []
      }
    },
    modelValue: {
      type: [Object]
    },
    groupSections: {
      type: [Array],
      default () {
        return []
      }
    },
    newItem: {
      type: [Boolean, String],
      default: false
    }
  },
  mounted() {
    // this.updateInputWidth();
    // window.addEventListener('resize', this.updateInputWidth);
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.updateInputWidth);
  },
  created () {
    this.runThrottle = throttle((data) => {
      data = {q: data}
      console.log(this.types)
      const types = this.types.length ? this.types : false
      let params = {
        ...data,
      }
      if (this.ESOptions.params) {
        params = {
          ...data,
          ...this.ESOptions.params
        }
      }

      if (types) {
        params.types = types.join(',')
      }
      console.log(params)
      this.numberOfRequest++
      this.autocomplete({
        params
      }).then(this.saveSuggestion)
          .catch(this.handleError)
          .finally(() => {
            this.isResultLoading = false
            this.numberOfRequest--
          })
    }, this.throttleOptions.wait, this.throttleOptions.leading, this.throttleOptions.trailing)
  },
  data () {
    return {
      runThrottle: null,
      numberOfRequest: 0,
      inputWidth: 0,
      isResultLoading: false,
      selectedSuggestion: null,
      focusedSuggestion: null,
      suggestionResponse: null,
      currentSelectedSuggestion: {},
      suggestionsLoaded: [],
      displaySuggestions: true,
      suggestionFocus: false,
      searchAsk: ''
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler (val) {
        if (this.modelValue && this.modelValue.marque && this.modelValue.marque.nom) {
          this.searchAsk = this.modelValue.marque.nom + ''
        }
        if (this.modelValue && this.modelValue.nom) {
          this.searchAsk = this.searchAsk + ' ' + this.modelValue.nom
        }
      }
    },
    searchAsk: {
      // handler (val) {
      //   this.runThrottle({q: val})
      //   // this.runAutocomplete({q: val})
      // }
    }
  },
  methods: {

    addNew (item) {
      this.$emit('new', item)
    },
    onFocus () {
      this.suggestionFocus = true
    },
    onSuggestionClick (s) {
      this.selectedSuggestion = s

      this.searchAsk = ''
      this.suggestionFocus = false
      // const data = this.dataTransformer(s)
      let data = {
        ...s,
        id: s.id,
        nom: s.term,
        marque: s.marque
      }
      if (typeof this.dataTransformer === 'function') {
        data = this.dataTransformer(s)
      }
      this.$emit('update:modelValue', data)
    },
    looseFocus() {
      this.suggestionFocus = false
    },
    handleError () {
    },
    saveSuggestion (response) {
      this.suggestionResponse = response.data
    },
    runAutocomplete (data) {
      this.autocompleteThrottle(data)
    },
    autocompleteThrottle: throttle(function (data) {
      autocomplete(data)
          .then(this.saveSuggestion)
          .catch(this.handleError)
          .finally(() => {
            this.isResultLoading = false
          })
    }, 500, true, true),
    async autocomplete (params) {
      let config = {
        headers: {'content-type': 'Application/json'},
        ...params
      }
      const response = await this.$axios.get(this.url, config)
      if (response.statusText === "OK" && response.data) {
        return response
      } else {
        throw {response : response}
      }
    }
  },
  computed: {
    listContainerStyle () {
      return {
        width: this.inputWidth + 'px'
      }
    },
    classGroup () {
      return this.displayGroupTitle ? '' : 'no-padding'
    },
    displayGroupTitle () {
      return this.groupSections.length > 1
    },
    getPlaceholder () {
      if (this.placeholder) {
        return this.placeholder
      }
      return this.$t('home.input.1')
    },
    hasItemSlot () {
      return Object.keys(this.$slots).includes('item')
    },
    selectedValue () {
      if (this.selectedSuggestion === null) {
        return ''
      }
      return this.selectedSuggestion.id
    },
    selectedName () {
      if (this.selectedSuggestion === null) {
        return ''
      }
      const Types = {
        modele: 'mo',
        marque: 'ma',
        categorie: 'sc'
      }
      return Types[this.selectedSuggestion.type]
    },
    showSuggestion ()
    {
      return this.suggestionList.length && this.suggestionFocus
    },
    suggestionList () {
      return this.suggestionResponse === null ? [] : this.suggestionResponse.hits.hits;
    },
    suggestionListItems () {
      return this.suggestionList.map((suggestion) => {
        let data = {}
        if (suggestion['_source']) {
          data = suggestion['_source']
          let nom = suggestion['_source'].nom
          if (suggestion['highlight'] && suggestion['highlight'].nom) {
            data['hightlight'] = suggestion['highlight'].nom.join('')
          }
          data = {
            ...data,
            nom
          }
        } else {
          data = suggestion
        }
        return data
      }).filter((suggestion) => {
        return suggestion.nom !== ''
      })
    },
    suggestionListGroups () {
      return this.suggestionList.reduce((group, suggestion) => {
        if (!group.includes(suggestion._type)) {
          group.push(suggestion._type)
        }
        return group
      }, [])
    },
    groups () {
      return this.suggestionListGroups.map((v) => {
        return {
          name: capitalizeFirstLetter(v),
          suggestions: this.suggestionList.filter((suggestion) => {
            return suggestion['_type'] === v
          }).map((suggestion) => {
            let nom = suggestion['highlight'] && suggestion['highlight'].nom ? suggestion['highlight'].nom.join('') : suggestion['_source'].nom
            if (suggestion['_type'] === 'modele') {
              nom = suggestion['_source'].marque.nom + ' - ' + nom
            }
            const add = {
              type: suggestion['_type'],
              term: suggestion['_source'].nom,
              nom
            }
            return {...suggestion['_source'], ...add}
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
