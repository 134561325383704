<template>
    <h3 class="multisteps-form__title">Modification</h3>
    <p>Le propriétaire vient de modifier les dates de location :</p>
    <LocationInfosDatesUpdate :auth="auth" :DL="DL"/>
    <div class="location-update">
        <div class="newPrix">
            <p><i class="fas fa-arrow-right"></i> Nouveau prix : {{prixTotalTTC}} € soit <b>{{leftToPay}} € TTC</b> complémentaire à régler</p>
        </div>
        <div class="choice-update">
            <form method='POST' :action="path('apply_update_location',{'id': dlId})">
                <div class="row col-sm-12">
                    <div class="button">
                        <button type="submit" class="btn btn-primary" id="update_accept" value="Accepter" name="accept" :href="path('rental_payment',{'id': DL.id})">
                            Accepter
                        </button>
                        <button type="submit" class="btn btn-outline-primary submit" id="update_refuse" value="Refuser" name="refuse">
                            Refuser
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import LocationInfosDatesUpdate from "./LocationInfosDatesUpdate.vue";

export default {
    name: 'LocationInfosCancel',
    components: {
        LocationInfosDatesUpdate
    },
    props: {
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
    },
    computed: {
        prixTotalTTC() {
            return this.DL.prix_total_ttc
        },
        leftToPay() {
            return this.DL.leftToPay
        },
        dlId() {
            return this.DL.id
        }
    }
}

</script>