<template>
 <h3>Location chart</h3>
  <Line
      :chart-options="chartOptions"
      :chart-data="chartData"
      chart-id="barchart"
      :height="200"
      dataset-id-key="label"
  />
</template>

<script>
import { Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  // Title,
  Tooltip,
  // Legend,
  Filler,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from 'chart.js'

ChartJS.register(
    // Title,
    Tooltip,
    // Legend,
    Filler,
    LinearScale,
    LineElement,
    PointElement,
    CategoryScale
)
const chartColors = {
  red: 'rgb(255, 237, 240)',
  blue: 'rgb(239, 246, 250)',
  realblue: 'rgb(0,34,255)',
  black: 'rgb(0,0,0)'
};
const getLastMonth = function (nth = 6) {
  const today = new Date();
  let lastSixMonths = []

  for (var i = nth; i > 0; i -= 1) {
    const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
    lastSixMonths.push(moment(date).format("MM YYYY"))
  }

  return lastSixMonths.reverse()
}

const customTooltips = function(tooltip) {
  console.log('customTooltips')
  console.log(tooltip)
  const chart = tooltip.chart
  tooltip = tooltip.tooltip
  // Tooltip Element
  var tooltipEl = document.getElementById('chartjs-tooltip');
  // console.log(tooltipEl)
  console.log(tooltip)

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '<table></table>';
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltip.yAlign) {
    tooltipEl.classList.add(tooltip.yAlign);
  } else {
    tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
    return bodyItem.lines;
  }

  // Set Text
  if (tooltip.body) {
    var titleLines = tooltip.title || [];
    var bodyLines = tooltip.body.map(getBody);

    var innerHtml = '<thead>';

    /*titleLines.forEach(function(title) {
      innerHtml += '<tr><th>' + title + '</th></tr>';
    });*/
    innerHtml += '</thead><tbody>';

    bodyLines.forEach(function(body, i) {
      var bbody = [];
      body.forEach(element => {
        bbody.push(element.split(":")[1] + " " + element.split(":")[0]);
      });
      var colors = tooltip.labelColors[i];
      var style = 'background: white' + colors.backgroundColor;
      style += '; border-color:' + colors.borderColor;
      style += '; border-width: 2px';
      var span = '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
      innerHtml += '<tr><td>' + span + bbody + '</td></tr>';

    });
    innerHtml += '</tbody>';

    var tableRoot = tooltipEl.querySelector('table');
    tableRoot.innerHTML = innerHtml;
  }

  var positionY = chart.canvas.offsetTop;
  var positionX = chart.canvas.offsetLeft;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY - 67 + 'px';
  tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
  tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
  tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
  tooltipEl.style.padding = tooltip.yPadding + 'px ' + tooltip.xPadding + 'px';
};

export default {
  name: "BarChart",
  components: {Line},
  props: {
    dataset: {
      default () {
        return [
            [0, 0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0, 0]
        ]
      }
    }
  },
  computed: {
    chartData () {
      return {
        labels: this.getLastMonth(),
        datasets: [
          {
            // pointStyle: 'line',
            // borderWidth: 0,
            label: 'réalisé',
            fill: {
              target: 'origin',
              above: chartColors.red,   // Area will be red above the origin
              below: chartColors.red    // And blue below the origin
            },
            backgroundColor: chartColors.red,
            borderColor: chartColors.red,
            cubicInterpolationMode: 'monotone',
            data: this.dataset[1]
          },
          {
            // pointStyle: 'line',
            // borderWidth: 0,
            label: 'location reçues',
            fill: {
              target: 'origin',
              above: chartColors.blue,   // Area will be red above the origin
              below: chartColors.blue   // And blue below the origin
            },
            backgroundColor: chartColors.blue,
            borderColor: chartColors.blue,
            cubicInterpolationMode: 'monotone',
            data: this.dataset[0]
          }
        ]
      }
    }
  },
  data() {
    return {
      plugins: [
        "Tooltip"
      ],
      chartOptions: {
        plugins: {
          tooltip: {
            // enabled: false,
            // callbacks: {
            //   label: function(context) {
            //     let label = context.dataset.label || '';
            //
            //     if (label) {
            //       label += ': ';
            //     }
            //     if (context.parsed.y !== null) {
            //       label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            //     }
            //     return label;
            //   }
            // },
            xAlign: 'center',
            yAlign: 'bottom',
            caretSize: 0,
            usePointStyle: true,
            displayColors: false,
            // external: customTooltips,
            intersect: false,
            interaction: {
              mode: 'nearest',
              axis: 'x',
            },
          }   // disable plugin 'p1' for this instance
        },
        elements: {
          point:{
            radius: 0,
          }
        },
        // tooltips: {
        //   enabled: true
        // },
        // tooltips: {
        //   axis: 'xy',
        //   mode: 'nearest',
        //   intersect: false
        // },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false
            },
            beginAtZero: true
          },
          x: {
            grid: {
              drawBorder: false,
              display: false
            }
          }
        }
      }
    }
  },
  methods: {
    getLastMonth (nth = 5) {
      const today = new Date();
      let lastSixMonths = []

      for (var i = nth; i >= 0; i -= 1) {
        const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
        lastSixMonths.push(this.$moment(date).format("MMM"))
      }

      return lastSixMonths
    }
  }
}
</script>

<style scoped>

</style>