<template>
  <div style="height:744px;">
    <AutoResizer>
      <template #default="{ height, width }">
        <el-table-v2
            :columns="defaultColumns"
            :data="billings"
            :width="width"
            :height="height"
            fixed
            @scroll="onScroll"
        />
      </template>
    </AutoResizer>

  </div>
<!--  <el-auto-resizer>-->
<!--    <el-table-v2 :data="billings" :columns="defaultColumns" :width="700">-->
<!--    </el-table-v2>-->
<!--  </el-auto-resizer>-->
</template>

<script lang="jsx">
import AutoResizer from "../../../components/AutoResizer.vue";
import UserCard from "../../../components/UserCard.vue";
import ButtonActions from "./ButtonActions.vue"
import {TableV2FixedDir} from "element-plus";
export default {
  components: {
    AutoResizer
  },
  name: "BillingsTable",
  props: {
    billings: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      dataTest: [
        {
          numeroLocation: 'coucou',
          depart: 'test'
        }
      ],
      defaultColumns: [
        {
          key: 'numeroLocation',
          dataKey: 'facture.numeroFactureComplet',
          title: '#',
          width: 100,
          fixed: TableV2FixedDir.LEFT,
          cellRenderer ({cellData}) {
            return cellData ? cellData : '-'
          }
        },
        {
          key: 'depart',
          width: 300,
          // dataKey: 'locataire',
          title: 'depart',
          cellRenderer: (cell) => {
            // console.log(cell)
            return (
                <div className="user_card">
                  <UserCard user={cell.rowData.locataire}/>
                </div>
            )
          },
        },
        {
          key: 'depart',
          dataKey: 'depart',
          width: 200,
          title: 'depart'
        },
        {
          key: 'actions',
          // dataKey: '',
          align: 'right',
          width: 20,
          cellRenderer: (cell) => {
            // console.log(cell)
            return (
                <ButtonActions/>
            )
          },
        },
      ]
    }
  },
  methods: {
    onScroll (event) {
      // console.log(event)
    },
    sortChange (payload) {
    }
  }
}
</script>

<style lang="scss">
.user_card {
  padding: 10px;
  display: flex;
  width: 100%;
  height: 100%;
  .img {
    margin: 0 9px 0 0;
    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
      border-radius: 100%;
    }
  }
  .text h4 {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #080025;
    margin: 0;
  }
  .text h5 {
    font-size: 14px;
    line-height: 19px;
    margin: 0;
    display: flex;
    align-items: center;
    color: #A3A3A3;
    font-weight: 600;
  }
}
.el-table__row:nth-child(2n+1) {
  //--el-table-tr-bg-color: #0e84b5;
  .td.el-table__cell {
    //--el-table-tr-bg-color: #0e84b5;
  }
  /*background-color: #0e84b5;*/
}
.el-table__header-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--left, .el-table__header-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--right, .el-table__header-wrapper tr:nth-child(2n+1) , .el-table__header-wrapper tr:nth-child(2n+1), .el-table__body-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--left, .el-table__body-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--right, .el-table__body-wrapper tr:nth-child(2n+1) , .el-table__body-wrapper tr:nth-child(2n+1), .el-table__footer-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--left, .el-table__footer-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--right, .el-table__footer-wrapper tr:nth-child(2n+1) , .el-table__footer-wrapper tr:nth-child(2n+1) {
  //--el-table-tr-bg-color: #0e84b5;
  //background: var(--el-table-tr-bg-color);
}
</style>