<script>
import ItemSelect from "../components/ItemSelect.vue";
import formHandler from "../../../mixins/formHandler.js";
import Ad from '../services/Ads.js'
import EditModel from '../components/EditModel.vue'
import AdBundleEdit from '../components/AdBundleEdit.vue'
import PackItemsTable from "../components/PackItemsTable.vue";
import PackItemColumns from "../components/PackItemColumns.vue";
import MediasEdit from "../../media/components/mediasEdit.vue";
import draggable from 'vuedraggable'
import { ElTable } from 'element-plus'
import AnnonceCard from "../../../components/AnnonceCard.vue";
import EditAdEconomics from "~/modules/ad/components/EditAdEconomics.vue";
import { ElMessageBox } from 'element-plus'
import StockReferencesTable from "~/modules/ad/components/StockReferencesTable.vue";
import ProductMediasEdit from "~/modules/media/components/ProductMediasEdit.vue";
import axios from "axios";
import screenBehavior from "~/mixins/screenBehavior.js";
import InputDescription from "~/modules/ad/components/InputDescription.vue";
import { ElNotification } from 'element-plus'

export default {
  emits: ['afterSave'],
  components: {
    InputDescription,
    ProductMediasEdit,
    StockReferencesTable,
    EditAdEconomics,
    AnnonceCard,
    PackItemColumns,
    AdBundleEdit,
    ElTable,
    draggable,
    EditModel,
    PackItemsTable,
    ItemSelect,
    MediasEdit
  },
  name: "ProductEdit",
  ApiUrl: 'user/annonce-edit',
  mixins: [formHandler, screenBehavior],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  props: {
    cancelAction: {
      type: Function,
      default () {
        return false
      }
    },
    allowConflicts: {
      type: Boolean,
      default: false
    },
    redirectAfterSave: {
      type: Boolean,
      default: true
    }
  },
  async beforeRouteLeave (to, from, next) {
    console.log('before leave')
    if (this.hasUpdate) {
      try {
        if (this.id) {
          await ElMessageBox({
            message: 'Des modifications non sauvegarder sont présentes sur cette page. Êtes vous sur de vouloir quitter la page et perdre les informations ?',
            title: 'Attention',
            showCancelButton: true,
            confirmButtonText: 'Rester',
            cancelButtonText: 'Quitter',
            distinguishCancelAndClose: true,
            type: 'warning'
          })
          this.popupLeave = false
        } else {
          next(true)
          return;
        }
        next(false); // Annuler la navigation
      } catch (e) {
        if (e === 'close') {
          this.popupLeave = false
          next(false); // Annuler la navigation
        } else if (e === 'cancel') {
          this.popupLeave = false
          next(true); // Continuer la navigation
        }
      }
    } else {
      console.log('continu navigation')
      next(true); // Continuer la navigation
    }
  },
  watch: {
    'edit.demandeAjoutModele' (val) {
      console.log('demandeAjoutModele change')

    },
    'edit.modele': {
      deep: true,
      // immediate: true,
      handler (val) {
          this.checkModeleDisponibility()
      }
    },
    edit: {
      handler (val) {
        if (val && val['modele'] && (!val['sku'] || val['sku'] === null) && val['stockType'] === 1) {
          val['sku'] = this.marque[0] + '-' + this.modele.replaceAll(/\s/g,'-') + '-'
          // this.content.sku = this.marque[0] + '-' + this.modele.replaceAll(/\s/g,'-') + '-'
        } else if (val['stockType'] !== 1) {
          val['sku'] = null
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    if (!this.cancelAction) {
      this.cancelAction = this.onCancelDefault;
    }
  },
  methods: {
    afterFetchSuccess (success) {
      if (success && success.response && success.response.data && success.response.data.ads && success.response.data.ads.oldTitle) {
        this.edit.deleteTitle = true
      }
    },
    modelResultFilter (result) {
      return result.term !== ''
    },
    onCancelAction () {
      if (this.cancelAction() === false) {
        this.$router.push({
          name: 'annonces'
        })
      }
    },
    goToAd () {
      let routeName = 'product-edition'
      if (this.modeleDisponibilityStockType === 1) {
        routeName = 'product-inventory'
      }
      if (!this.modeleDisponibilityId) {
        throw new Error('No modeleDisponibilityId when going to modèle #'+this.edit.modele.id+' found')
      } else {
        this.$router.push({
          name: routeName,
          params: {
            id: this.modeleDisponibilityId
          }
        })
      }
    },
    async checkModeleDisponibility () {
      if (this.edit && this.edit.modele && this.edit.modele.id && this.isNew) {
        const response = await axios.get('/api/user/check-modele-disponibility/' + this.edit.modele.id)
        if (response && response.data && response.status === 200) {

          this.modeleDisponibility = response.data.available
          this.modeleDisponibilityId = response.data.ad_id
          this.modeleDisponibilityStockType = response.data.ad_stock_type
        }
      }
    },
    afterFormSend (response) {
      if (!this.id) {
        ElNotification({
          title: 'Produit ajouté',
          message: 'Votre produit a bien été ajouté à votre parc matériel',
          type: 'success'
        })
      } else {
        ElNotification({
          title: 'Produit sauvegardé',
          message: 'Les modification de votre produit a bien été sauvegardé',
          type: 'success'
        })
      }
      console.log('redirect after save : ', this.redirectAfterSave)
      console.log('id : ', this.id)
      if (!this.id && this.redirectAfterSave) {
        this.$router.push({name: 'annonces'})
        console.log('redirect')
      }
      this.$emit('afterSave', response)
    },
    transformHook (edit) {
      edit = JSON.parse(JSON.stringify(edit))
      if (edit.modele) {
        if (edit['modele']['categorie']) {
          edit['categorieAnnonce'] = edit['modele']['categorie']['id']
        }
        if (edit['modele']['sousCategorie']) {
          edit['souscategorieannonce'] = edit['modele']['sousCategorie']['id']
        }
        if (edit['modele']['marque']) {
          edit['marque'] = edit['modele']['marque']['id']
        }
        if(edit['modele']['id']) {
          edit['modele'] = edit['modele']['id']
        } else {
          edit['modele'] = edit['modele']
        }
        // edit['detailannonce'] = edit['detailannonce'].map((detail) => {
        //   return {
        //     ...detail,
        //     annonce: edit.id
        //   }
        // })
      }
      if (typeof edit['categorieAnnonce'] === 'object' && edit.categorieAnnonce !== null) {
        edit['categorieAnnonce'] = edit['categorieAnnonce']['id']
      }
      if (typeof edit['souscategorieannonce'] === 'object' && edit['souscategorieannonce'] !== null) {
        edit['souscategorieannonce'] = edit['souscategorieannonce']['id']
      }
      if (edit['demandeAjoutModele'] !== null) {
        edit['modele'] = null
        edit['marque'] = null
      }
      if (edit['demandeAjoutModele'] && typeof edit['demandeAjoutModele']['sousCategorie'] === 'object' && edit['demandeAjoutModele']['sousCategorie'] !== null) {
        edit['demandeAjoutModele']['sousCategorie'] = edit['demandeAjoutModele']['sousCategorie']['id']
      }
      if (edit['stockType'] === 1) {
        edit['stocks'] = this.stocks
      }
      edit['productType'] = 0
      const rent = edit.computeds.find((computed) => computed.name === 'rent')
      if (rent) {
        if (!edit.tarif) {
          edit.tarif = {}
        }
        edit.tarif.prixUnJour = rent.value
      }
      const cost = edit.computeds.find((computed) => computed.name === 'cost')
      if (cost) {
        edit.valeurAchat = cost.value
      }
      edit['computeds'].map((computed) => {
        if (computed['id'] === null) {
          delete computed['id'];
        }
        return computed
      })
      edit.publie = edit.publie ? 1 : 0
      edit.aLaUneProfile = edit.aLaUneProfile ? 1 : 0
      return edit
    },
    modeleTransformer (s) {
      this.edit.categorieAnnonce = s.categorie;
      this.edit.marque = s.marque;
      this.edit.souscategorieannonce = s.souscategorie
      return {
        id: s.id,
        nom: s.term,
        sousCategorie: s.souscategorie,
        categorie: s.categorie,
        marque: s.marque
      }
    },
    preQuery (query) {
      query.with('bundle')
      // this.Model.
    },
    getComponentData () {
      return {
        data: this.equipements,
        stripe: true,
        style: "width: 100%;",
        'table-layout': "auto"
      }
    },
    cancelNewModele () {
      this.newModele = false
      this.edit.demandeAjoutModele = null
    },
    addNewModele () {
      // wait for 1 sec
      setTimeout(() => {
        if (this.$refs && this.$refs.newModelContainer) {
          this.$refs.newModelContainer.scrollIntoView({block: "start", inline: "nearest"})
        }
      }, 100)
      // this.$refs.newModelContainer.scrollIntoView({block: "start", inline: "nearest"})
      // console.log('addNew modele')
      this.newModele = true
    }
  },
  computed: {
    saveButtonText () {
      return this.id ? 'Sauvegarder' : 'Ajouter'
    },
    ignoreKeysUpdate () {
      return ['$id', 'imagesannonce', 'user_id', 'user', 'oldTitle']
    },
    formAlignement () {
      return this.screen.width > 800 ? 'left' : 'top'
    },
    stocks () {
      const nbItem = this.edit.quantity - this.edit.stocks.length
      const stock = this.edit.stocks.map((v, k) => {
        const num = k + 1
        return {
          ...v,
          name: this.edit.sku + num
        }
      })
      if (nbItem < 0) {
        // remove
        return stock.splice(0, this.edit.quantity)
      }
      const items = Array.apply(null, Array(nbItem)).map((val, idx) => idx)
      const costIndex = this.edit.computeds.findIndex(item => item.name === 'cost')
      const cost = this.edit.computeds[costIndex].value
      // for each nb item
      const toAdd = items.map((v, k) => {
        let num = v + this.edit.stocks.length + 1
        return {
          name: this.edit.sku + num,
          status: 1,
          value: cost
        }
      })
      return [...stock, ...toAdd]
    },
    trackGroupType: {
      set (v) {
        if (v === 0) {
          this.edit.stockType = 0
        } else if (v === 1) {
          this.edit.stockType = 2
        } else if (v === 2) {
          this.edit.stockType = 4
        }
      },
      get () {
        if (this.edit.stockType === 0 || this.edit.stockType === 1) {
          return 0
        } else if (this.edit.stockType === 2 || this.edit.stockType === 3) {
          return 1
        } else if (this.edit.stockType === 4) {
          return 2
        }
      }
    },
    publie: {
      set (val) {
        this.edit.publie = val ? 1 : 0
      },
      get () {
        return !!this.edit.publie
      }
    },
    aLaUneProfile: {
      set (val) {
        this.edit.aLaUneProfile = val ? 1 : 0
      },
      get () {
        return !!this.edit.aLaUneProfile
      }
    },
    componentData () {
      return {
        data: this.edit.detailsannonce,
        stripe: true,
        style: "width: 100%;",
        'table-layout': "auto"
      }
    },
    productType: {
      get () {
        return this.productTypes.find((t) => {
          return t.value === this.edit.productType
        }) ? this.productTypes.find((t) => {
          return t.value === this.edit.productType
        }).label : ''+this.edit.productType
      },
      set (value) {
        this.edit.productType = value
      }
    },
    Model () {
      return Ad
    },
    category () {
      return this.edit && this.edit.modele && this.edit.modele.categorie && this.edit.modele.categorie.nom ? this.edit.modele.categorie.nom : '-'
    },
    sousCat () {
      return this.edit && this.edit.modele && this.edit.modele.sousCategorie && this.edit.modele.sousCategorie.nom ? this.edit.modele.sousCategorie.nom : '-'
    },
    marque () {
      return this.edit && this.edit.modele && this.edit.modele.marque && this.edit.modele.marque.nom ? this.edit.modele.marque.nom : '-'
    },
    modele () {
      return this.edit && this.edit.modele && this.edit.modele.nom ? this.edit.modele.nom : '-'
    },
    referenceGeneration () {
      return this.marque[0] + '-' + this.modele.replaceAll(/\s/g,'-')
    }
  },
  data () {
    return {
      modeleDisponibility: true,
      modeleDisponibilityId: null,
      modeleDisponibilityStockType: null,
      test: 0,
      referencable: false,
      newModele: false,
      adType: 'Quantité',
      trackType: 0,
      // productType: 'ad',
      productTypes: [
        {
          label: 'Produit',
          value: 0
        },
        {
          label: 'Pack',
          value: 1
        },
        {
          label: 'Service',
          value: 2
        }
      ],
      adTypes: [
        {
          label: 'Quantité',
          value: 0
        },
        {
          label: 'Références',
          value: 1
        },
        {
          label: 'Consomable',
          value: 2
        }
      ],
      content: {
        productType: 0,
      },
      edit: {
        productType: 0,
      },
      form: {}
    }
  },
}
</script>

<template>
  <el-alert v-for="error in HeaderErrors" :title="error" type="error" show-icon :closable="false" />
      <el-form v-loading="isLoading" :model="form" label-width="300px" :label-position="formAlignement" size="large" class="custom_form_lightyshare" >
        <div class="product-creation-form-header">
          <h3>{{ $t('shop.products.product-edit.info-title') }}</h3>
          <p class="label-info">{{ $t('shop.products.product-edit.info-description') }}</p>
        </div>
        <el-form-item v-if="content.oldTitle && content.oldTitle.length">
          <template #label>
            <div>
              <h4 class="label-title">Ancien titre</h4>
              <p class="label-info">Le titre de votre annonce va prochainement être supprimé. Seul la référence (par exemple Sony A7S3) sera affiché.</p>
            </div>
          </template>
          <div style="float:right">
            <el-input v-model="edit.titre" :disabled="edit.deleteTitle"/>
            <el-switch
                v-model="edit.deleteTitle"
                style="margin: 12px 0px; float:right;"
                inline-prompt
                width="100px"
                inactive-text="Attendre"
                active-text="Supprimer"/>

          </div>
        </el-form-item>
        <el-form-item v-if="!newModele && !edit.demandeAjoutModele" :error="!edit.modele && FieldsErrors.modele">
          <template #label>
            <div>
              <h4 class="label-title">{{ $t('steps-location.product-section.input-title') }}</h4>
              <p class="label-info">{{ $t('shop.products.product-edit.brand-description')}}</p>
            </div>
          </template>
          <ItemSelect v-model="edit.modele" :types="['modele']" :ESOptions="{params: {p: 12}}" @new="addNewModele" :new-item="'adEdit.addNewModele'" :data-transformer="modeleTransformer" :result-filter="modelResultFilter" :load-on-focus="true"/>
        </el-form-item>
        <EditModel
            v-if="newModele || edit.demandeAjoutModele"
            v-model="edit.demandeAjoutModele"
            :isNew="isNew"
            @cancel="cancelNewModele"
        ></EditModel>
        <div v-show="!modeleDisponibility && isNew && !allowConflicts">
          <el-alert title="Ce produit existe déjà dans votre inventaire" type="error" show-icon style="line-height: 25px;" :closable="false"/>
          <button v-if="modeleDisponibilityId" class="btn btn-short btn-primary mt-4"  @click.prevent="goToAd">
            {{ $t('shop.products.product-edit.modify-product')}}
          </button>

        </div>
        <div v-show="modeleDisponibility || !isNew || allowConflicts">
          <el-form-item label="Photo">
            <template #label>
              <div>
                <h4 class="label-title">{{$t('steps-location-2.annonce.photos.main-photo')}}</h4>
                <p class="label-info">{{ $t('shop.products.product-edit.photo-description')}}</p>
              </div>
            </template>
            <ProductMediasEdit
                v-model="edit.medias"
                :action="'/api/user/upload-media'"
                :annonce-id="edit.id"/>
          </el-form-item>
          <hr>
          <el-form-item>
            <template #label>
              <div>
                <h4 class="label-title">{{ $t('shop.products.product-edit.tracking.title')}}</h4>
                <p class="label-info">{{ $t('shop.products.product-edit.tracking.description')}}</p>
              </div>
            </template>
            <el-radio-group v-model="trackGroupType" class="block-radio">
              <el-radio :label="0" class="label-title">{{ $t('shop.products.product-edit.tracking.product-label')}}</el-radio>
              <p class="label-info text-radio-info">{{ $t('shop.products.product-edit.tracking.product-description') }}</p>
            <el-card v-if="trackGroupType === 0" shadow="never" class="bordered-card" style="width:100%">
              <el-radio-group v-model="edit.stockType" class="block-radio">
                <el-radio :label="1" class="label-title">{{ $t('shop.products.product-edit.tracking.product-unit') }}</el-radio>
                <p class="label-info text-radio-info" >{{ $t('shop.products.product-edit.tracking.product-unit-description') }}</p>
                <el-radio :label="0" class="label-title">{{ $t('shop.products.product-edit.tracking.product-quantity') }}</el-radio>
                <p class="label-info text-radio-info" >{{ $t('shop.products.product-edit.tracking.product-quantity-description') }}</p>
              </el-radio-group>
            </el-card>
<!--            <el-radio :label="1" class="label-title">Consommable</el-radio>-->
<!--            <p class="label-info text-radio-info">Produit dont vous n’attendez pas le retour en fin de location</p>-->
            <el-card v-if="trackGroupType === 1" shadow="never" class="bordered-card" style="width:100%">
              <el-radio-group v-model="edit.stockType" class="block-radio">
                <el-radio :label="2" class="label-title">{{ $t('shop.products.product-edit.tracking.consumable-no-track') }}</el-radio>
                <p class="label-info text-radio-info" >{{  $t('shop.products.product-edit.tracking.consumable-no-track-description') }}</p>
                <el-radio :label="3" class="label-title">{{  $t('shop.products.product-edit.tracking.consumable-quantity') }}</el-radio>
                <p class="label-info text-radio-info" >{{  $t('shop.products.product-edit.tracking.consumable-quantity-description') }}</p>
              </el-radio-group>
            </el-card>
<!--            <el-radio :label="2" class="label-title">Service</el-radio>-->
<!--            <p class="label-info text-radio-info">Service que vous proposez comme de la formation ou la livraison. Les services n’ont pas de quantité ou de suivis. </p>-->
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="edit.stockType === 0 || edit.stockType === 1 || edit.stockType === 3" label="Quantité">
            <template #label>
              <div>
                <h4 class="label-title">{{  $t('shop.products.product-edit.quantity') }}</h4>
                <p class="label-info">{{  $t('shop.products.product-edit.quantity-description') }}</p>
              </div>
            </template>
            <el-input v-model="edit.quantity" size="large" type="number" />
          </el-form-item>
          <el-form-item v-if="edit.stockType === 1" label="SKU">
            <template #label>
              <div>
                <h4 class="label-title">{{  $t('shop.products.product-edit.sku') }}</h4>
                <p class="label-info">{{  $t('shop.products.product-edit.sku-description') }}</p>
              </div>
            </template>
            <el-input v-model="edit.sku" size="large" />
          </el-form-item>
          <hr>
          <div class="product-creation-form-header">
            <h3>Tarification</h3>
          </div>
          <EditAdEconomics v-model="edit.computeds" :fields-errors="FieldsErrors"/>
          <hr>
          <div class="product-creation-form-header">
            <h3>{{ $t('shop.products.product-edit.visibility') }}</h3>
          </div>
          <el-form-item label="Publier">
            <template #label>
              <div>
                <h4 class="label-title">{{ $t('shop.products.product-edit.publish-label') }}</h4>
                <p class="label-info">{{ $t('shop.products.product-edit.publish-description') }}</p>
              </div>
            </template>
            <el-switch v-model="publie"/>
          </el-form-item>
          <div v-if="edit.publie">
            <el-form-item :label="$t('steps-location-2.annonce.input-description.label')" :error="FieldsErrors.description">
              <template #label>
                <div>
                  <h4 class="label-title">{{ $t('shop.products.product-edit.description') }}</h4>
                  <p class="label-info">{{ $t('shop.products.product-edit.description-description') }}</p>
                </div>
              </template>
              <input-description v-model="edit.description" :limit="2000"></input-description>
<!--              <el-input v-model="edit.description" maxlength="2000" class="" show-word-limit type="textarea" :placeholder="$t('steps-location-2.annonce.input-description.placeholder')" />-->
            </el-form-item>
            <el-form-item label="Position dans le catalogue">
              <template #label>
                <div>
                  <h4 class="label-title">{{ $t('shop.products.product-edit.position') }}</h4>
                  <p class="label-info">{{ $t('shop.products.product-edit.position-description') }}</p>
                </div>
              </template>
              <el-input type="number" v-model="edit.position" />
            </el-form-item>
          </div>
          <hr>
          <div class="product-creation-form-header">
            <h3>Détails</h3>
            <p>{{$t('steps-location-3.top-section.description')}} </p>
          </div>
          <el-card class="p-0 mrg-25" v-if="edit && edit.detailsannonce" >
            <PackItemsTable :annonce-id="edit.id" v-model="edit.detailsannonce" :details="edit.detailsannonce"/>
          </el-card>
          <hr>
          <el-button v-if="edit && !edit.id" @click="onCancelAction">Annuler</el-button>
          <el-button :loading="formLoading" plain type="info" size="large" class="btn btn-save" :disabled="!this.hasUpdate" @click.prevent="sendForm">{{ saveButtonText }}</el-button>
<!--          <button class="btn btn-short btn-primary mt-4" :class="buttonClass" id="save-button-section-pricing" @click.prevent="sendForm">-->
<!--            {{$t('owner-settings.list-group.item.2.save')}}-->
<!--          </button>-->

        </div>
      </el-form>
</template>

<style scoped>

</style>
