<template>
  <el-upload
    v-model:file-list="listMedias"
    drag
    :show-file-list="true"
    :list-type="listType"
    :limit="uploadLimit"
    :action="mediaAction"
    :on-preview="handlePreview"
    :before-upload="handleBeforeUpload"
    :on-remove="handleRemove"
    :on-change="handleChange"
    :on-success="handleSuccess"
  >
    <template #file="{file}">
      <div>
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
        <span class="el-upload-list__item-actions" v-if="file.id">
          <span @click="handleRemove(file)" class="icon-kit" v-html="'&#xe9f1'"></span>
        </span>

      </div>
    </template>
  </el-upload>
</template>

<script>
import Media from "../services/Media.js";
import formHandler from "../../../mixins/formHandler.js";

export default {
  components: {
      // Download
  },
  name: "mediasEdit",
  mixins: [formHandler],
  ApiUrl: 'annonce-medias',
  props: {
    uploadLimit: {
      type: [Number, Boolean],
      default: 5
    },
    cover: {
      type: String,
      default: false
    },
    annonceId: {
      type: [String, Number],
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      upload: 0,
      medias: [
      ]
    }
  },
  computed: {
    listType () {
      if (this.cover) {
        return 'picture'
      }
      return 'picture-card'
    },
    limit () {
      if (this.cover) {
        return 1
      }
      return this.uploadLimit
    },
    nextPosition () {
      if (this.cover) {
        return '/1'
      }
      return ''
    },
    mediaAction () {
      return this.action + '/' + this.annonceId + this.nextPosition
    },
    listMedias: {
      get () {
        if (this.cover) {
          const cover = this.Medias.find((el) => {
            return el.position === 1
          })
          return cover ? [{
                  ...cover,
                  name: cover.id,
                  url: cover.path
          }] : []
        } else {
          return this.Medias.filter((el) => {
            return el.position !== 1
          }).map((media) => {
            return {
              // name: media.nom
              ...media,
              name: media.id,
              url: media.path
            }
          })
        }
      },
      set (val) {
        this.medias = val
      }
    },
    perPage () {
      return 30
    },
    Model () {
      return Media
    },
    CoverMedia () {
      return Media.query().with('annonce').where((media) => {
        return media.annonce_id === parseInt(this.annonceId) && media.position === 1
      }).get()
    },
    Medias () {
      return Media.query().with('annonce').where('annonce_id', parseInt(this.annonceId)).get()
    }
  },
  methods: {
    preQuery (query) {
      // query.with('annonce')
      query.where('annonce_id', this.annonceId)
    },
    deleteMedia (media) {
      let url = 'media-delete/' + media.id
      Media.api().delete(url, {
        delete: media.id
      });
    },
    handlePreview (file) {
      console.log(file)
    },
    handleRemove (file, fileList) {
      console.log(file.id)
      this.deleteMedia(file)
    },
    handleBeforeUpload (file) {
      this.upload++
    },
    handleSuccess (response, file, fileList) {
      //update last item of fileList
      fileList[fileList.length - 1] = {
        ...response.media,
        name: response.media.id,
        url: response.media.path
      }
    },
    handleChange (file, fileList) {
      console.log(file)
    }
  }
}
</script>

<style scoped>

</style>
