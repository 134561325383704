<template>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div>
        <h3 class="subTitle">Facture <span v-if="paginationQuery.count" class="countLabel">{{paginationQuery.count}} fichiers</span></h3>
      </div>
      <CustomFilterTable :radio-filters="FiltersProps" />
    </div>
    <BillingsTable :default-order="tableSortData" :billings="modelList" @sort-change="sortChange">
      <template #empty>
        <div class="empty-background">
          <div v-if="isLoadingContent" class="loading-content">
            <div class="cv-spinner">
              <span class="spinner"></span>
            </div>
            <div class="empty-background-content-title">
              {{ $t('shop.billings.billings-list.loading')}}
            </div>
          </div>
          <div v-else class="empty-content">
            <div>
              <div class="non-clickable-button icomoon">
                &#xe965
              </div>
              <div class="empty-background-content-title">
                {{ $t('shop.billings.billings-list.empty.title')}}
              </div>
              <div class="empty-background-content-description">
                {{ $t('shop.billings.billings-list.empty.description')}}
              </div>
            </div>
            <div class="empty-background-content-button">
              <el-button @click="openModal" size="large" type="primary"><span class="icomoon" style="margin-right: 8px">&#xe9d1</span>   {{ $t('shop.billings.billings-list.empty.button')}}</el-button>
            </div>
          </div>
        </div>
      </template>
    </BillingsTable>
    <Teleport to="body">
      <ModalLayout :show="openShareModal" @close="closeModal">
        <template #header>
          <h3><span class="icon-kit">&#xe9d1</span>Partager ma boutique</h3>
        </template>
        <template #body>
          <ShareCart @close="closeModal" :share-link="shareLinkShop">
          </ShareCart>
        </template>
      </ModalLayout>
    </Teleport>
    <div class="pagination-container" v-if="paginationQuery.countPage > 1">
      <el-pagination :current-page="currentPage" background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>
    </div>
  </el-card>
</template>

<script>
import BillingsTable from "../components/BillingsTable.vue";
import Billing from "../services/Billing.js"
import LoadContent from "../../../mixins/loadContent.js";
import {throttle} from "../../../utils/TimeExecution.js";
import BillingsTableV2 from "../components/BillingsTableV2.vue";
import seedrandom from 'seedrandom'
import CustomFilterTable from "../../../components/CustomFilterTable.vue";
import ShareCart from "~/modules/cart/components/ShareCart.vue";
import ModalLayout from "~/layouts/ModalLayout.vue";
import Auth from "~/modules/auth/services/Auth.js";

const modesValues = {
  "tous": 'Tous',
  "attente": "En attente",
  "facture": "Facturé",
}

export default {
  defaultOrderBy: {
    order: 'descending',
    prop: 'finUnix'
  },
  fetchUrl: 'billings',
  mixins: [LoadContent],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  loadingOnce: true,
  components: {
    ModalLayout, ShareCart,
    CustomFilterTable,
    BillingsTable,
    BillingsTableV2
  },
  name: "Billings-list-page",
  data () {
    return {
      openShareModal: false,
      FiltersProps: {
        "attente": "En attente",
        "facture": "Facturé",
      },
      currentLoadedData: [],
      loadingData: {},
      tableOptions: {
        mode: 'Tous',
        perPage: 10,
        currentLoadingPage: 1,
        currentPage: 1,
        countPage: 0,
        search: '',
      },
      // mode: 'Tous',
      countBills: null,
      // perPage: 10,
      currentLoadingPage: 1,
      // currentPage: 1,
      countPage: 0,
      // search: '',
      // searchQuery: '',
      billings: [

      ]
    }
  },
  watch: {
    tableOptions: {
      deep: true,
      immediate: true,
      handler (tableOptions, oldTableOption) {
        if (!oldTableOption) {
          // this.updateLoadingData(tableOptions)
        } else {
          // this.updateLoadingDataThrottle()
        }
      }
    },
    $route: {
      deep: true,
      immediate: true,
      handler (route) {
        if (route.query.p) {
          this.tableOptions.currentPage = parseInt(route.query.p)
        }
        // this.fetchDataThrottle()
      }
    },
    // search: {
    //   async handler (v) {
    //     //
    //     // this.updateSearch()
    //   }
    // }
  },
  computed: {
    shareLinkShop () {
      if (this.auth && this.auth.id) {
        let nomDeDomaine = window.location.hostname;
        return 'https://' + nomDeDomaine + '/public/' + this.auth.id + '/show'
      } else {
        return ''
      }
    },
    auth () {
      return Auth.query().first()
    },
    Model () {
      return Billing
    },
    // seedRandomInt () {
    //   const rng = seedrandom(this.loadingData.search)
    //   return Math.floor(rng()*3)
    // },
    // search: {
    //   set (val) {
    //     this.addQuery({
    //       q: val
    //     })
    //   },
    //   get () {
    //     return this.$route.query.q ? this.$route.query.q : ''
    //   }
    // },
    // mode: {
    //   set (val) {
    //     if (val === 'tous') {
    //       val = false
    //     }
    //     this.addQuery({f: val})
    //   },
    //   get () {
    //     return this.$route.query.f ? this.$route.query.f : 'tous'
    //   }
    // },
    // tableSortData () {
    //   const sort = {}
    //   if (this.sortData && this.sortData.length === 2) {
    //     sort['prop'] = this.sortData[0]
    //     sort['order'] = this.sortData[1] === 'desc' ? 'descending' : 'ascending'
    //   }
    //   // if (this.)
    //   return sort
    // },
    // sortData () {
    //   if (this.$route.query.s) {
    //     return this.$route.query.s.split('-')
    //   } else {
    //     return false
    //   }
    // },
    // countDataLoaded () {
    //   return this.billsQuery.get().length
    // },
    // allLoaded () {
    //   return this.billsQuery.get().length >= this.countBills
    // },
    // currentDataIds () {
    //   return this.currentLoadedData.map(i => i.id)
    // },
    billsQuery () {
      const query = Billing.query().with('locataire.ImageProfil')
      query.with('facture')
      if (this.mode !== 'Tous') {
        if (this.mode === 'facture') {
          query.where((bill) => {
            return bill.billedAt !== null
          })
        } else if (this.mode === 'attente') {
          query.where((bill) => {
            return bill.billedAt === null
          })
        }
      }

      return query
    },
    bills () {
      const query = Billing.query().with('locataire.ImageProfil')
      query.with('facture')
      console.log('search')
      console.log(this.search)
      console.log(this.loadingData.search)
      if (this.search.length > 0) {
        query.whereHas('locataire', (query) => {
          query.where((locataire) => {
            return locataire.fullName.toLowerCase().includes(this.search.toLowerCase())
          })
        }).orWhere((bill) => {
          let find = false
          return find || bill.numeroLocation.includes(this.search.toLowerCase())
        })

      }
      if (this.mode !== 'tous') {
        const modeValue = modesValues[this.mode]
        query.where('billStatus', modeValue)
      }
      if (this.currentLoadedData.length && !this.allLoaded) {
        query.whereIdIn(this.currentDataIds)
      } else {
        const offset = (this.loadingData.currentPage - 1) * this.perPage
        query.offset(offset).limit(this.perPage)
      }
      if (this.sortData) {
        query.orderBy(this.sortData[0], this.sortData[1])
      }
      return query.get()
    }
  },
  methods: {
    async openModal () {
      this.openShareModal = true
    },
    async closeModal () {
      this.openShareModal = false
    },
    applySearchQuery (query) {
      if (this.search.length > 0) {
        query.whereHas('locataire', (query) => {
          query.where((locataire) => {
            return locataire.fullName.toLowerCase().includes(this.search.toLowerCase())
          })
        }).orWhere((bill) => {
          let find = false
          return find || bill.numeroLocation.includes(this.search.toLowerCase()) || bill.id === parseInt(this.search)
        })
      }
    },
    preQuery (query) {
      query.with('locataire.ImageProfil')
      query.with('facture')
      if (this.mode !== 'tous') {
        const modeValue = modesValues[this.mode]
        if (this.mode === 'facture') {
          query.where((bill) => {
            return bill.billedAt !== null
          })
        } else if (this.mode === 'attente') {
          query.where((bill) => {
            return bill.billedAt === null
          })
        }
      }
    },
    updateSearch: throttle(function () {
      // console.log(this.search)
      this.addQuery({q: this.search + ''})
      this.searchQuery = this.search + ''
    }, 1000, true, true),
    addQuery (q) {
      const query = {
        ...this.$route.query,
        ...q
      }
      if (q.q === '') {
        delete query.q
      }
      if (q.s === false) {
        delete query.s
      }
      if (q.f === false) {
        delete query.f
      }
      this.$router.push({
        query
      })
    },
    changePage (page) {
      this.addQuery({
        p: page
      })
    },
    sortChange (payload) {
      if (payload.order) {
        const order = payload.order === 'ascending' ? 'asc' : 'desc'
        const sort = `${payload.prop}-${order}`
        this.addQuery({
          s: sort
        })
      } else {
        this.addQuery({
          s: false
        })
      }
    },
    // updateLoadingData (data) {
    //   this.loadingData = {
    //     ...data
    //   }
    // },
    // updateLoadingDataThrottle: throttle(function () {
    //   this.updateLoadingData(this.tableOptions)
    // }, 800, false, true),
    // fetchDataThrottle: throttle(function () {
    //   this.fetchData()
    // }, 1000, true, true),
    // async fetchData () {
    //   this.currentLoadedData = []
    //   let rep = await this.Model.api().get(null, {
    //     params: this.$route.query
    //   })
    //   if (rep.response.data[rep.config.dataKey]) {
    //     this.currentLoadedData = rep.response.data[rep.config.dataKey]
    //   }
    //   this.countBills = parseInt(rep.response.data.count)
    //   this.countPage = rep.response.data.countPage
    //   this.currentLoadingPage = rep.response.data.page
    // }
  }
}
</script>

<style scoped>

</style>