<template>
<h3 v-if="isOwner" class="multisteps-form__title">Dates de location</h3>

<h3 v-if="isTenant && stepOneIsLocationUpdating" class="multisteps-form__title">Votre demande (actuel) </h3>

<h3 v-if="isTenant && !stepOneIsLocationUpdating" class="multisteps-form__title">Votre demande </h3>

<div class="votre-demande">
    <div class="date-box depart-date">
        <div class="date">
            <h5 style="text-transform: capitalize;">{{ jourDebut }} <br> <span>{{ numeroJourDebut }}</span><br><span>{{ moisDebut }}</span></h5>
        </div>
        <h4>
        {{$t('infos.date.start-label')}} <br> <span>{{$t('infos.date.start')}}</span>
        </h4>
    </div>
    <div class="arrow">
        <i class="fas fa-chevron-right"></i>
    </div>
    <div class="date-box retour-date">
        <div class="date">
            <h5 style="text-transform: capitalize;">{{ jourFin }} <br> <span>{{ numeroJourFin }}</span><br><span>{{ moisFin }}</span></h5>
        </div>
        <h4>
            {{$t('infos.date.return-label')}}<br> <span>{{$t('infos.date.return')}}</span>
        </h4>
    </div>
</div>

</template>

<script>

export default {
    name: 'LocationInfosDates',
    props: {
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
        termined : {
            type: Boolean
        },
    },
    computed: {
        locataire () {
            return this.DL.locataire
        },
        proprietaire () {
            return this.DL.proprietaire
        },
        isTenant() {
            return this.auth.id == this.locataire.id ? true : false
        },
        isOwner() {
            return this.auth.id == this.proprietaire.id ? true : false
        },
        stepOneIsLocationUpdating () {
            return this.DL.updateStatusLocation == 1 ? true : false 
        },
        dateDebut() {
            return this.DL.departUnix
        },
        dateFin() {
            return this.DL.finUnix
        },
        jourDebut() {
            return this.$moment(this.dateDebut, 'X').format('dddd')
        },
        numeroJourDebut() {
            return this.$moment(this.dateDebut, 'X').format('DD')
        },
        moisDebut() {
            return this.$moment(this.dateDebut, 'X').format('MMM').toUpperCase()
        },
        jourFin() {
            return this.$moment(this.dateFin, 'X').format('dddd')
        },
        numeroJourFin() {
            return this.$moment(this.dateFin, 'X').format('DD')
        },
        moisFin() {
            return this.$moment(this.dateFin, 'X').format('MMM').toUpperCase()
        },
        jourOldP() {
            return this.$moment(this.oldPanier.dateRetour).format('dddd')
        },
        numeroJourOldP() {
            return this.$moment(this.oldPanier.dateRetour).format('d')
        },
        moisOldP() {
            return this.$moment(this.oldPanier.dateRetour).format('MMM').toUpperCase()
        }
    }
}
</script>