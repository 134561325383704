<script>
import InputDescription from "~/modules/ad/components/InputDescription.vue";

export default {
  name: "AdminBrandEdit",
  components: {InputDescription},
  props: {
    modelValue: {
      type: [Object, String]
    },
    name: String
  },
  data () {
    return {
      edit: {
        nom: '',
        pictureSrc: '',
        description: ''
      }
    }
  },
  mounted () {
    this.edit.nom = this.name + ''
  },
  watch: {
    edit: {
      deep: true,
      handler (val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>

<template>
  <el-form-item>
    <template #label>
      <div>
        <h4 class="label-title">Marque</h4>
        <p class="label-info">Nom de la marque "{{name}}"</p>
      </div>
    </template>
    <el-input v-model="edit.nom"></el-input>
  </el-form-item>
  <el-form-item>
    <template #label>
      <div>
        <h4 class="label-title">Image</h4>
        <p class="label-info">Chemin de l'image</p>
      </div>
    </template>
    <el-input v-model="edit.pictureSrc"></el-input>
  </el-form-item>
  <el-form-item>
    <template #label>
      <div>
        <h4 class="label-title">Description</h4>
        <p class="label-info">Texte pour référencer la marque</p>
      </div>
    </template>
    <input-description v-model="edit.description"></input-description>
  </el-form-item>
  <hr>
</template>

<style scoped>

</style>