<script>
// import AdminAddressInputDebug from "~/modules/user/components/AdminAddressInputDebug.vue";

export default {
  name: "AdminEditInfoSociete",
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object
    },
    fields: {
      type: [Array, String],
      default () {
        return 'all'
      }
    }
  },
  watch: {
    modelValue: {
      handler (val, oldVal) {
        console.log('modelValue', val);
        console.log('modelValue', oldVal);
        if (val) {
          this.edit = JSON.parse(JSON.stringify(val));
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    hasVat: {
      set (val) {
        this.edit.hasVat = val ? 1 : 0
      },
      get () {
        return Boolean(this.edit.hasVat)
      }
    },
    validVAT: {
      set (val) {
        this.edit.validVAT = val ? 1 : 0
      },
      get () {
        return Boolean(this.edit.validVAT)
      }
    }
  },
  methods: {
    hasField (field) {
      if (this.fields === 'all') {
        return true
      }
      return this.fields.includes(field)
    },
    updateValue (value) {
      // this.$emit('update:modelValue', value)
    }
  },
  data () {
    return {
      edit: {
      }
    }
  }
}
</script>

<template>
  <hr>
  <div class="product-creation-form-header">
    <h3>Société</h3>
  </div>
  <el-form-item v-if="hasField('raisonSociale')" :label="$t('signup.company.label-name')">
    <el-input v-model="edit.raisonSociale" :placeholder="$t('signup.company.label-name')" class="full-width-input" />
  </el-form-item>
  <el-form-item v-if="hasField('formJuridique')" :label="$t('signup.company.label-status')">
    <el-input v-model="edit.formeJuridique" :placeholder="$t('signup.company.placeholder-status')" class="full-width-input" />
  </el-form-item>
  <el-form-item v-if="hasField('siret')" :label="$t('signup.company.label-registration-number')">
    <el-input v-model="edit.siret" :placeholder="$t('signup.company.placeholder-registration-number')" class="full-width-input" />
  </el-form-item>
  <el-form-item v-if="hasField('numeroRcs')" label="Numero RCS">
    <el-input v-model="edit.numeroRcs" class="full-width-input" />
  </el-form-item>
  <el-form-item v-if="hasField('vatNumber')" label="Numéro TVA">
    <el-input v-model="edit.vatNumber" />
  </el-form-item>
  <el-form-item v-if="hasField('validVAT')" label="Numéro TVA vérifié">
    <el-switch v-model="validVAT"/>
  </el-form-item>
  <el-form-item v-if="hasField('validVAT')" label="Facture la TVA">
    <el-switch v-model="hasVat"/>
  </el-form-item>
  <el-form-item v-if="hasField('capital')" label="Capital">
    <el-input v-model="edit.capital"/>
  </el-form-item>
</template>

<style scoped>

</style>