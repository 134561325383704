<script>
import AuthNavMenu from "~/components/AuthNavMenu.vue";
import PublicMenu from "~/components/PublicMenu.vue";
import SearchForm from "~/components/SearchForm.vue";

export default {
  name: "TopBar",
  components: {SearchForm, PublicMenu, AuthNavMenu}
}
</script>

<template>
  <header class="topHeader">
    <nav class="navbar navbar-expand-xl navbar-light">
      <a class="navbar-brand" href="/">
        <img :src="asset('img/new/logo-dark.png')" alt="LightyShare" />
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="navbar-nav mr-auto">
          <div class="tabNav">
            <ul style="pointer-events: none;">
              <li class="active"><a>{{ $t('valid-cart.events.step.1') }}</a></li>
              <li><a>{{ $t('valid-cart.events.step.2') }}</a></li>
              <li><a>{{ $t('valid-cart.events.step.3') }}</a></li>
            </ul>
          </div>
        </div>
<!--        <script type="text/javascript">-->
<!--          let currentType ='rent'-->
<!--          $('.header-search-form .dropdown-item').click(function(e){-->
<!--            e.preventDefault()-->

<!--            if (currentType == 'rent') {-->
<!--              currentType = 'buy'-->
<!--              $(this).text('Louer')-->
<!--              $('.header-search-form .selected-type-toggle').text('Acheter')-->
<!--              $('.header-search-form #type').val('buy')-->
<!--            } else {-->
<!--              currentType = 'rent'-->
<!--              $(this).text('Acheter')-->
<!--              $('.header-search-form .selected-type-toggle').text('Louer')-->
<!--              $('.header-search-form #type').val('rent')-->
<!--            }-->
<!--          })-->
<!--        </script>-->
      </div>
    </nav>
  </header>
</template>

<style scoped>

</style>