<script>
export default {
  name: "MenuBarItem",
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
    isActive: {
      type: Function,
      default: null,
    },
  },
}
</script>

<template>
<!--  <button v-bind="$attrs" class="menu-item" :class="{ 'is-active': isActive ? isActive(): null }" @click="action" :title="title">-->
  <span
      v-bind="$attrs"
      class="item-tool"
      :class="{ 'is-active': isActive ? isActive(): null }"
      @click.prevent="action"
      :title="title"
      v-html="icon"
  >
  </span>
</template>

<style scoped>

</style>