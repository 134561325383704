<template>
  <el-table :data="computations" style="width: 100%; " :table-layout="'auto'" stripe :default-sort="defaultOrder" >
    <el-table-column fixed prop="id" label="#" width="60" >
      <template #default="{row}">
        <span v-html="row.id"></span>
      </template>
    </el-table-column>
    <el-table-column fixed prop="key" label="Clé" />
    <el-table-column prop="type" label="Type" sortable="custom" min-width="150">
      <template #default="{row}">
        <span v-html="getType(row.type)"></span>
      </template>
    </el-table-column >
    <el-table-column prop="billStatus" label="Status" sortable="custom" min-width="100" >
      <template #default="{row}">
        <el-tag
            :type="row.online ? 'success' : 'danger'"
            class="mx-1"
            effect="light"
            round
        >
          {{ row.online ? 'Connecté' : 'Sommeil' }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="value" label="Valeur" sortable="custom" min-width="130" />
  </el-table>
</template>

<script>
import UserTicketCard from "../../user/components/UserTicketCard.vue";

export default {
  name: "BillingsTable",
  components: {
    UserTicketCard
  },
  props: {
    defaultOrder: {
      type: Object,
      default () { return {}}
    },
    computations: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      defaultColumns: [
        {
          dataKey: 'id',
          title: '#'
        }
      ]
    }
  },
  computed: {
    types () {
      return ['Fixe', 'Formula', 'Rate', 'Sum', 'Sub']
    }
  },
  methods: {
    editComputation (row) {
      this.$router.push({name: 'admin-edit-economics', params: { id: row.id}})
    },
    getType (type) {
      const types = this.types
      return types[type]
    },
    sortChange (payload) {
    }
  }
}
</script>

<style lang="scss">
.el-table__row:nth-child(2n+1) {
  //--el-table-tr-bg-color: #0e84b5;
  .td.el-table__cell {
    //--el-table-tr-bg-color: #0e84b5;
  }
  /*background-color: #0e84b5;*/
}
.el-table__header-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--left, .el-table__header-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--right, .el-table__header-wrapper tr:nth-child(2n+1) , .el-table__header-wrapper tr:nth-child(2n+1), .el-table__body-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--left, .el-table__body-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--right, .el-table__body-wrapper tr:nth-child(2n+1) , .el-table__body-wrapper tr:nth-child(2n+1), .el-table__footer-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--left, .el-table__footer-wrapper tr:nth-child(2n+1) td.el-table-fixed-column--right, .el-table__footer-wrapper tr:nth-child(2n+1) , .el-table__footer-wrapper tr:nth-child(2n+1) {
  //--el-table-tr-bg-color: #0e84b5;
  //background: var(--el-table-tr-bg-color);
}
</style>