<template>
  <el-alert v-for="error in HeaderErrors" :title="error" type="error" show-icon :closable="false" />
      <el-form v-loading="isLoading" :model="form" label-width="50%" label-position="left" size="large">
        <div class="product-creation-form-header">
          <h3>{{$t('steps-location.product-section.title')}}</h3>
          <p>{{$t('steps-location.product-section.description')}} </p>
        </div>
        <el-form-item :label="$t('steps-location.top-section.ad-type.title')">
          <el-select v-model="edit.productType" style="width:100%">
            <el-option
                v-for="type in productTypes"
                :key="type.value"
                :label="type.label"
                :value="type.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="Publier">
          <el-switch v-model="publie"/>
        </el-form-item>
        <el-form-item :label="$t('steps-location-2.annonce.input-title.label')" :error="FieldsErrors.titre">
          <el-input v-model="edit.titre" maxlength="255" show-word-limit class="" :placeholder="$t('steps-location-2.annonce.input-title.placeholder')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('steps-location-2.annonce.input-description.label')" :error="FieldsErrors.description">
          <el-input
              v-model="edit.description"
              maxlength="2000"
              class=""
              show-word-limit
              type="textarea"
              :placeholder="$t('steps-location-2.annonce.input-description.placeholder')"
          />
        </el-form-item>
        <hr>
        <el-form-item label="Position dans le catalogue">
          <el-input-number v-model="edit.position" size="large" />
        </el-form-item>
        <hr>

        <!--  Edition produit  -->
        <div v-if="productType === 'Produit'">
          <span ref="newModelContainer"></span>
          <EditModel
            v-if="newModele"
            @cancel="cancelNewModele"
          ></EditModel>
          <el-form-item v-else :label="$t('steps-location.product-section.input-title')">
            <ItemSelect v-model="edit.modele" :types="['modele']" :ESOptions="{params: {p: 12}}" @new="addNewModele" :new-item="'adEdit.addNewModele'" :data-transformer="modeleTransformer"/>
          </el-form-item>
          <div class="row">
            <div class="col-md-6 product-form-field mrg-25">
              <label>{{$t('steps-location-2.top-section.category')}}</label>
              <div class="proCat">
                <span>{{ sousCat }}</span> {{ category }}
              </div>
            </div>
            <div class="col-md-6 product-form-field mrg-25">
              <label>{{$t('steps-location-2.top-section.model')}}</label>
              <div class="proCat">
                <span> {{marque}}</span> {{ modele }}
              </div>
            </div>
          </div>
          <hr>
          <div class="product-creation-form-header">
            <h3>{{ $t('steps-location-3.top-section.title') }}</h3>
            <p>{{$t('steps-location-3.top-section.description')}} </p>
          </div>
          <el-card class="p-0 mrg-25" v-if="edit && edit.detailsannonce" >
            <PackItemsTable :annonce-id="edit.id" v-model="edit.detailsannonce" :details="edit.detailsannonce"/>
          </el-card>
          <hr>
          <div class="product-creation-form-header">
            <h3>{{ $t('steps-location-2.annonce.photos.title') }}</h3>
            <p>{{$t('steps-location-2.annonce.photos.description')}} </p>
          </div>
          <div class="col-md-12 product-form-field mrg-25">
            <label for="">{{$t('steps-location-2.annonce.photos.main-photo')}}</label>
            <div class="" v-if="id">
              <MediasEdit
                  :uploadLimit="1"
                  :cover="true"
                  :action="'/api/upload-media'"
                  :annonce-id="edit.id"/>
              <MediasEdit
                  :action="'/api/upload-media'"
                  :annonce-id="edit.id + ''"/>
            </div>
          </div>
          <hr>
          <div class="product-creation-form-header">
            <h3>{{ $t('steps-location-3.tracking-section.title') }}</h3>
            <p>{{$t('steps-location-3.tracking-section.description')}} </p>
          </div>
          <el-form-item label="Type de suivi">
            <el-select v-model="edit.stockType" style="width:100%">
              <el-option
                  v-for="type in adTypes"
                  :key="type.value"
                  :label="type.label"
                  :value="type.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Quantité">
            <el-input-number v-if="edit.stockType !== 1" v-model="edit.quantity" size="large" />
            <span v-else> {{edit.stocks.length}} </span>
          </el-form-item>
          <StockReferencesTable v-if="edit.stockType === 1" v-model="edit.stocks" :reference="referenceGeneration"/>
        </div>

        <!--  Edition bundle  -->
        <div v-else>
          <div class="row">
            <div class="col-md-6 product-form-field mrg-25">
              <label>{{$t('steps-location-2.top-section.category')}}</label>
              <div class="proCat">
                <span>{{ sousCat }}</span> {{ category }}
              </div>
            </div>
            <div class="col-md-6 product-form-field mrg-25">
              <label>{{$t('steps-location-2.top-section.model')}}</label>
              <div class="proCat">
                <span> {{marque}}</span> {{ modele }}
              </div>
            </div>
          </div>
          <hr>
          <ad-bundle-edit v-if="id" :annonce-id="id"></ad-bundle-edit>
        </div>
        <hr>
        <EditAdEconomics v-model="edit.computeds" :fields-errors="FieldsErrors"/>
        <div>
          <div class="blue-notic">
            <p>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z" fill="#3A8BBB"></path>
              </svg>
              <span> {{$t('steps-location.value-section.info')}}</span>
            </p>
          </div>
        </div>
        <button class="btn btn-short btn-primary mt-4" :class="buttonClass" id="save-button-section-pricing" @click.prevent="sendForm">
          {{$t('owner-settings.list-group.item.2.save')}}
        </button>
      </el-form>
</template>

<script>
import ItemSelect from "../components/ItemSelect.vue";
import formHandler from "../../../mixins/formHandler.js";
import Ad from '../services/Ads.js'
import EditModel from '../components/EditModel.vue'
import AdBundleEdit from '../components/AdBundleEdit.vue'
import PackItemsTable from "../components/PackItemsTable.vue";
import PackItemColumns from "../components/PackItemColumns.vue";
import MediasEdit from "../../media/components/mediasEdit.vue";
import draggable from 'vuedraggable'
import { ElTable } from 'element-plus'
import AnnonceCard from "../../../components/AnnonceCard.vue";
import EditAdEconomics from "~/modules/ad/components/EditAdEconomics.vue";
import { ElMessageBox } from 'element-plus'
import StockReferencesTable from "~/modules/ad/components/StockReferencesTable.vue";

export default {
  components: {
    StockReferencesTable,
    EditAdEconomics,
    AnnonceCard,
    PackItemColumns,
    AdBundleEdit,
    ElTable,
    draggable,
    EditModel,
    PackItemsTable,
    ItemSelect,
    MediasEdit
  },
  name: "AdEdit",
  ApiUrl: 'user/annonce-edit',
  mixins: [formHandler],
  async beforeRouteLeave (to, from) {
    if (this.hasUpdate) {
      try {
        let confirmUpdate = await ElMessageBox({
          message: 'Votre annonce a subit des modifications, voulez vous les sauvegarder avant de quitter la page ?',
          title: 'Annonce modifiée',
          showCancelButton: true,
          confirmButtonText: 'Sauvegarder & continuer',
          cancelButtonText: 'Continuer',
          distinguishCancelAndClose: true,
          type: 'warning'
        })
        await this.sendForm()
        return true
      } catch (e) {
        if (e === 'close') {
          return false
        } else if (e === 'cancel') {
          return true
        }
      }
    }
    // const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
    // if (!answer) return false
  },
  methods: {
    transformHook (edit) {
      if (edit.modele) {
        if (edit['modele']['categorie']) {
          edit['categorieAnnonce'] = edit['modele']['categorie']['id']
        }
        if (edit['modele']['sousCategorie']) {
          edit['souscategorieannonce'] = edit['modele']['sousCategorie']['id']
        }
        if (edit['modele']['marque']) {
          edit['marque'] = edit['modele']['marque']['id']
        }
        if(edit['modele']['id']) {
          edit['modele'] = edit['modele']['id']
        } else {
          edit['modele'] = edit['modele']
        }
        // edit['detailannonce'] = edit['detailannonce'].map((detail) => {
        //   return {
        //     ...detail,
        //     annonce: edit.id
        //   }
        // })
      }
      const rent = edit.computeds.find((computed) => computed.name === 'rent')
      if (rent) {
        edit.tarif.prixUnJour = rent.value
      }
      const cost = edit.computeds.find((computed) => computed.name === 'cost')
      if (cost) {
        edit.valeurAchat = cost.value
      }
      edit['computeds'].map((computed) => {
        if (computed['id'] === null) {
          delete computed['id'];
        }
        return computed
      })
      edit.publie = edit.publie ? 1 : 0
      return edit
    },
    modeleTransformer (s) {
      this.edit.categorieAnnonce = s.categorie;
      this.edit.marque = s.marque;
      this.edit.souscategorieannonce = s.souscategorie
      return {
        id: s.id,
        nom: s.term,
        sousCategorie: s.souscategorie,
        categorie: s.categorie,
        marque: s.marque
      }
    },
    preQuery (query) {
      query.with('bundle')
      // this.Model.
    },
    getComponentData () {
      return {
        data: this.equipements,
        stripe: true,
        style: "width: 100%;",
        'table-layout': "auto"
      }
    },
    cancelNewModele () {
      this.newModele = false
    },
    addNewModele () {
      // wait for 1 sec
      setTimeout(() => {
        this.$refs.newModelContainer.scrollIntoView({block: "start", inline: "nearest"})
      }, 100)
      // this.$refs.newModelContainer.scrollIntoView({block: "start", inline: "nearest"})
      // console.log('addNew modele')
      this.newModele = true
    }
  },
  computed: {
    publie: {
      set (val) {
        this.edit.publie = val ? 1 : 0
      },
      get () {
        return !!this.edit.publie
      }
    },
    componentData () {
      return {
        data: this.edit.detailsannonce,
        stripe: true,
        style: "width: 100%;",
        'table-layout': "auto"
      }
    },
    productType: {
      get () {
        return this.productTypes.find((t) => {
          return t.value === this.edit.productType
        }) ? this.productTypes.find((t) => {
          return t.value === this.edit.productType
        }).label : ''+this.edit.productType
      },
      set (value) {
        this.edit.productType = value
      }
    },
    Model () {
      return Ad
    },
    category () {
      return this.edit && this.edit.modele && this.edit.modele.categorie && this.edit.modele.categorie.nom ? this.edit.modele.categorie.nom : '-'
    },
    sousCat () {
      return this.edit && this.edit.modele && this.edit.modele.sousCategorie && this.edit.modele.sousCategorie.nom ? this.edit.modele.sousCategorie.nom : '-'
    },
    marque () {
      return this.edit && this.edit.modele && this.edit.modele.marque && this.edit.modele.marque.nom ? this.edit.modele.marque.nom : '-'
    },
    modele () {
      return this.edit && this.edit.modele && this.edit.modele.nom ? this.edit.modele.nom : '-'
    },
    referenceGeneration () {
      return this.marque[0] + '-' + this.modele.replaceAll(/\s/g,'-')
    }
  },
  data () {
    return {
      test: 0,
      referencable: false,
      newModele: false,
      adType: 'Quantité',
      // productType: 'ad',
      productTypes: [
        {
          label: 'Produit',
          value: 0
        },
        {
          label: 'Pack',
          value: 1
        },
        {
          label: 'Service',
          value: 2
        }
      ],
      adTypes: [
        {
          label: 'Quantité',
          value: 0
        },
        {
          label: 'Références',
          value: 1
        },
        {
          label: 'Consomable',
          value: 2
        }
      ],
      content: {
        productType: 0,
      },
      edit: {
        productType: 0,
      },
      form: {}
    }
  },
}
</script>

<style scoped>

</style>
