<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "OwnerAskStatus",
  props: {
    ask: {
      type: Object,
      default () {
        return null
      }
    }
  },
  computed: {
    dayLeft () {
      let now = new Date();
      now.setHours(0,0,0,0);
      let daysLeftDeparture = Math.ceil((this.ask.departUnix - now.getTime() / 1000) / (60 * 60 * 24))
      if (daysLeftDeparture > 0) {
        return daysLeftDeparture
      } else {
        return Math.ceil((this.ask.finUnix - now.getTime() / 1000) / (60 * 60 * 24))
      }
    },
    askStatus () {
      if (this.ask) {
        let now = new Date();
        now.setHours(0,0,0,0);
        if (this.ask.canceled) {
          return 'canceled'
        } else if (this.ask.statutProprietaire === 1) {
          return 'to-confirm'
        } else if (this.ask.statutProprietaire === 2) {
          return 'waiting-payment'
        } else if (this.ask.statutLocataire >= 3 && this.ask.statutProprietaire >= 3) {
          let daysLeftDeparture = Math.ceil((this.ask.departUnix - now.getTime() / 1000) / (60 * 60 * 24))
          let daysLeftArrival = Math.ceil((this.ask.finUnix - now.getTime() / 1000) / (60 * 60 * 24))
          if (daysLeftDeparture === 0) {
            return 'today'
          } else if (daysLeftDeparture === 1) {
            return 'tomorrow'
          } else if (daysLeftDeparture > 1) {
            return 'day-left-departure'
          } else if (daysLeftArrival === 0) {
            return 'today-arrival'
          } else if (daysLeftArrival === 1) {
            return 'tomorrow-arrival'
          } else if (daysLeftArrival > 1) {
            return 'day-left-arrival'
          } else {
            return 'finish'
          }
        }
      }
      return 'finish'
    },
    elTagType () {
      if (this.askStatus === 'canceled') {
        return 'danger'
      } else if (this.askStatus === 'to-confirm') {
        return 'warning'
      } else if (this.askStatus === 'waiting-payment') {
        return 'info'
      } else if (this.askStatus === 'today') {
        return 'success'
      } else if (this.askStatus === 'tomorrow') {
        return 'warning'
      } else if (this.askStatus === 'day-left-departure') {
        return ''
      } else if (this.askStatus === 'today-arrival') {
        return 'success'
      } else if (this.askStatus === 'tomorrow-arrival') {
        return 'warning'
      } else if (this.askStatus === 'day-left-arrival') {
        return ''
      } else if (this.askStatus === 'finish') {
        return 'success'
      }
      return ''
    }
  }
})
</script>

<template>
  <el-tag
      :type="elTagType"
      class="mx-1"
      effect="light"
      round
  >
    {{ $t('owner-rental.status.' + askStatus, {days: dayLeft}) }}
  </el-tag>
</template>

<style scoped>

</style>