<template>
  <el-table-column prop="nomEquipement" label="Equipement" align="left" :min-width="300">
    <template #default="scope">
      <el-input v-model="scope.row.nomEquipement"/>
    </template>
  </el-table-column>
  <el-table-column prop="quantity" label="Quantité" :min-width="20">
    <template #default="scope">
      <el-input-number v-model="scope.row.quantity"/>
    </template>
  </el-table-column>
  <el-table-column prop="quantity" label="" width="100" align="right" fixed="right">
    <template #default="scope">
      <el-button v-if="scope.row.isNew" @click="pushItem(scope.row)" >Ajouter</el-button>
      <el-button v-else >Migrez en produit</el-button>

    </template>
  </el-table-column>
</template>

<script>

export default {
  name: "PackItemColumns",
  props: {
  }
}
</script>

<style scoped>

</style>
