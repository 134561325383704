<script>
import { ElMessage } from 'element-plus'
export default {
  emits: ['close'],
  name: "ShareCart",
  props: {
    shareLink: {
      type: String,
      default: ''
    },
  },
  computed: {
    currentEncodeUrl () {
      if (this.shareLink.length) return encodeURIComponent(this.shareLink)
      return encodeURIComponent(window.location.href)
    },
    currentUrl () {
      if (this.shareLink.length) return this.shareLink
      return window.location.href
    }
  },
  methods: {
    async copyPageUrl (url) {
      try {
        await navigator.clipboard.writeText(url);
        ElMessage({
          message: this.$t('ad.share.copy-link-button') + ' 👋',
          type: 'success',
        })
        this.$emit('close')
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
    shareFacebook () {
      window.open('https://www.facebook.com/sharer/sharer.php?u=' + this.currentEncodeUrl, 'popup','width=600,height=600,scrollbars=no,resizable=no')
      this.$emit('close')
    },
    shareTwitter () {
      window.open('https://twitter.com/intent/tweet?url=' + this.currentEncodeUrl, 'popup','width=600,height=600,scrollbars=no,resizable=no')
      this.$emit('close')
    },
    shareMessenger () {
      window.open('fb-messenger://share?link=' + this.currentEncodeUrl, 'popup','width=600,height=600,scrollbars=no,resizable=no')
      this.$emit('close')
    }

  }
}
</script>

<template>
  <ul class="share-link-list">
    <li @click.prevent="copyPageUrl(currentUrl)">
      <a href="#" >
        <span class="icon-kit">&#xe947</span>Copier le lien
      </a>
    </li>
    <li @click.prevent="shareFacebook">
      <a :href="'https://www.facebook.com/sharer/sharer.php?u='+this.currentEncodeUrl" target="_blank"><span class="icon-kit">&#xe961</span>Facebook
      </a>
    </li>
    <li @click.prevent="shareTwitter">
      <a :href="'https://twitter.com/intent/tweet?url=' + this.currentEncodeUrl" target="_blank"><span class="icon-kit">&#xe9f9</span>Twitter
      </a>
    </li>
    <li @click.prevent="shareMessenger">
      <a :href="'https://twitter.com/intent/tweet?url=' + this.currentEncodeUrl" target="_blank"><i class="fab fa-facebook-messenger"></i>  Messenger
      </a>
    </li>
  </ul>
</template>

<style scoped>

</style>