<script>
export default {
  name: "BankValidationNotifications",
  props: {
    document: {
      type: Object,
      default () {
        return null
      }
    },
    detailsErrors: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    detailsErrorsArray () {
      return Object.values(this.detailsErrors)
    },
    alertClass () {
      if (this.document && this.document.Status === 'VALIDATION_ASKED') {
        return 'alert-info'
      } else if (this.document && this.document.Status === 'VALIDATED') {
        return 'alert-success'
      } else if (this.document && this.document.Status === 'OUT_OF_DATE') {
        return 'alert-warning'
      } else if (this.document && this.document.Status === 'REFUSED') {
        return 'alert-danger'
      }
      return ''
    },
    alertFaIcon () {
      if (this.document && this.document.Status === 'VALIDATION_ASKED') {
        return 'fa-clock'
      } else if (this.document && this.document.Status === 'VALIDATED') {
        return 'fa-check-circle'
      } else if (this.document && this.document.Status === 'OUT_OF_DATE') {
        return 'fa-refresh'
      } else if (this.document && this.document.Status === 'REFUSED') {
        return 'fa-exclamation-circle'
      }
      return ''
    },

  },
  methods: {
    parseInt (int) {
      return parseInt(int)
    }
  }
}
</script>

<template>
  <div v-if="document && document.Status !== 'CREATED'" class="alert-cart-errors" >
    <div class="alert alert-info mb-4" :class="alertClass" role="alert">
      <div class="alert-icon">
        <span class="fa" :class="alertFaIcon"></span>
      </div>
      <span v-html="document.Status === 'REFUSED' && document.RefusedReasonType && document.Type ? $t('mangopay.LegalDocuments.RefusedReasonType.'+ document.Type +'.' + document.RefusedReasonType) : $t('shop.settings.bank-validation.notifications.' + document.Status)"></span>
    </div>
    <div v-for="flag in document.Flags" class="alert alert-warning mb-4" role="alert">
      <div class="alert-icon">
        <span class="fa fa-thumbs-down" ></span>
      </div>
      <span v-html="$t('mangopay.LegalDocuments.IdentityProof.Flags.' + parseInt(flag))"></span>
    </div>
  </div>
  <div v-for="Error in detailsErrorsArray" class="alert alert-warning mb-4" role="alert">
    <div class="alert-icon">
      <span class="fa fa-thumbs-down" ></span>
    </div>
    <span v-html="$t(Error)"></span>
  </div>
</template>

<style scoped>

</style>