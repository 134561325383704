<script>

import signupHandler from "~/modules/signup/mixins/signupHandler.js";

export default {
  name: "RegisterIdentity",
  mixins: [signupHandler],
  data () {
    return {
      edit: {
        prenom: '',
        nom: '',
        dateNaissance: '',
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setDefaultData()
      let allreadyLogged = vm.loggedUser
      if (allreadyLogged) {
        if (vm.nextStep) {
          vm.$router.push({name: vm.nextStep})
        }
      }
    })
  },

  watch: {
    'edit.dateNaissance'(newVal, oldVal) {
      if (newVal.length < oldVal.length) {
        return;
      }
      if (newVal.slice(-1) === '/') {
        let parts = newVal.split('/');
        for (let i = 0; i < parts.length - 1; i++) {
          if (parts[i].length === 1) {
            parts[i] = '0' + parts[i];
          }
        }
        newVal = parts.join('/');
      } else if (newVal.length === 2 || newVal.length === 5) {
        newVal += '/';
      }
      this.edit.dateNaissance = newVal;
    },
    edit: {
      handler: function (val, oldVal) {
        if (val) {
          this.UpdateSignupUser({
            id: 1,
            prenom: val.prenom,
            nom: val.nom,
            dateNaissance: val.dateNaissance
          })
        }
      },
      deep: true
    }
  },
  methods: {
    addZeroIfNeeded(datePart) {
      return datePart.length < 2 ? '0' + datePart : datePart;
    },
    setDefaultData () {
      if (this.signupUser) {
        this.edit.prenom = this.signupUser.nom
        this.edit.nom = this.signupUser.prenom
        this.edit.dateNaissance = this.signupUser.dateNaissance
      }
    },
    // continu () {
    //   this.$emit('next', this.edit)
    // }
  },
  computed: {
    hasChanged () {
      const birthdate = this.$moment(this.edit.dateNaissance, 'DD/MM/YYYY');
      const dateNow = new Date();
      const age = (dateNow - birthdate) / 31536000000;
      return this.edit.prenom.length > 0 && this.edit.nom.length > 0 && age >= 18 && age <= 150;
    },
    dateNaissanceError () {
      if (this.formErrors && this.formErrors.dateNaissance && Array.isArray(this.formErrors.dateNaissance)) {
        return this.formErrors.dateNaissance[0]
      }
      if (this.edit.dateNaissance.length === 10) {
        const birthdate = this.$moment(this.edit.dateNaissance, 'DD/MM/YYYY');
        const dateNow = new Date();
        const age = (dateNow - birthdate) / 31536000000;
        if (this.edit.dateNaissance.length > 0 && (age < 18 || age > 150)) {
          return this.$t('signup.step-2.birthDateError.minor');
        }
      }
      return ''
    }
  }
}
</script>
<template>
  <div>
    <el-alert v-if="generalError" :title="generalError.title" :description="generalError.description" type="error" show-icon style="margin-bottom: 12px;" :closable="false"/>
    <h2 class="signup-form-title">{{$t('signup.step-2.title')}}</h2>
    <el-form-item :label="$t('signup.step-2.surname')" :error="formErrors && formErrors.prenom && formErrors.prenom[0]">
      <el-input v-model="edit.prenom" :placeholder="$t('signup.step-2.surname-placeholder')" class="full-width-input" required />
    </el-form-item>
    <el-form-item :label="$t('signup.step-2.name')" :error="formErrors && formErrors.nom && formErrors.nom[0]">
      <el-input v-model="edit.nom" :placeholder="$t('signup.step-2.name-placeholder')" class="full-width-input" required />
    </el-form-item>
    <el-form-item :label="$t('signup.step-2.birthday')" :error="dateNaissanceError">
      <el-input v-model="edit.dateNaissance" :placeholder="$t('signup.step-2.birthday-placeholder')" class="full-width-input" required />
    </el-form-item>
    <div class="form-group mt-4">
      <el-button class="signup-primary" :class="hasChanged ? '' : 'disabled'" @click="continu" :loading="isLoading">
        {{$t('signup.continue')}}
      </el-button>
    </div>
  </div>
</template>

<style scoped>

</style>