<template>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div>
        <h3 class="subTitle"> Utilisateurs <span class="countLabel"> {{$t('owner-rental.count.'+ status, {count: paginationQuery.count}) }}</span></h3>
        <span class="card-description">
          {{ $t('owner-rental.card-description')}}
        </span>
      </div>
      <CustomFilterTable :placeholderSearch="'Recherche un utilisateur'"/>
    </div>
    <UsersTable :default-order="tableSortData" :users="modelList" @sort-change="sortChange">
      <template #empty>
        <div class="empty-background">
          <div v-if="isLoadingContent" class="loading-content">
            <div class="cv-spinner">
              <span class="spinner"></span>
            </div>
            <div class="empty-background-content-title">
              {{ $t('owner-rental.asks-list.loading')}}
            </div>
          </div>
          <div v-else class="empty-content">
            <div>
              <div class="non-clickable-button icomoon">
                &#xea04
              </div>
              <div class="empty-background-content-title">
                {{ $t('owner-rental.asks-list.empty.title')}}
              </div>
              <div class="empty-background-content-description">
                {{ $t('owner-rental.asks-list.empty.description')}}
              </div>
            </div>
            <div class="empty-background-content-button">
              <el-button size="large" type="primary" @click="openModal"><span class="icomoon" style="margin-right: 8px">&#xea03</span>   {{ $t('owner-rental.asks-list.empty.button')}}</el-button>
            </div>
          </div>
        </div>
      </template>
    </UsersTable>
    <Teleport to="body">
      <ModalLayout :show="openShareModal" @close="closeModal">
        <template #header>
          <h3><span class="icon-kit">&#xe9d1</span>Partager ma boutique</h3>
        </template>
        <template #body>
          <ShareCart @close="closeModal" :share-link="shareLinkShop">
          </ShareCart>
        </template>
      </ModalLayout>
    </Teleport>
    <div class="pagination-container" v-if="paginationQuery.countPage > 1">
      <el-pagination :current-page="currentPage" background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>
    </div>
  </el-card>
</template>

<script>
import CustomFilterTable from "~/components/CustomFilterTable.vue";
import LoadContent from "~/mixins/loadContent.js";
import ShareCart from "~/modules/cart/components/ShareCart.vue";
import ModalLayout from "~/layouts/ModalLayout.vue";
import Auth from "~/modules/auth/services/Auth.js";
import UsersTable from "../components/UsersTable.vue";
import User from "../services/User";

function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export default {
  name: "UsersList",
  mixins: [LoadContent],
  components: {ModalLayout, ShareCart, UsersTable, CustomFilterTable},
  props: {
    status: {
      default: 'all'
    }
  },
  data () {
    return {
      openShareModal: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  computed: {
    type () {
      return this.$route.params && this.$route.params.type
    },
    shareLinkShop () {
      if (this.auth && this.auth.id) {
        let nomDeDomaine = window.location.hostname;
        return 'https://' + nomDeDomaine + '/public/' + this.auth.id + '/show'
      } else {
        return ''
      }
    },
    auth () {
      return Auth.query().first()
    },
    Model () {
      return User
    },
    fetchUrl () {
      return 'admin/users-list/' + this.type
    }
  },
  methods: {
    async openModal () {
      this.openShareModal = true
    },
    async closeModal () {
      this.openShareModal = false
    },
    applySearchQuery (query) {
      if (this.search.length > 0) {
        query.where((user) => {
          let nom = user.nom ? user.nom : ''
          let prenom = user.prenom ? user.prenom : ''
          let fullname = removeAccents(prenom + ' ' + nom)
          return (fullname.toLowerCase().includes(removeAccents(this.search.toLowerCase())))
        }).orWhere((user) => {
          let find = false
          return find || (user.email && user.email.includes(this.search)) || user.id === parseInt(this.search)
        })
      }
    },
    preQuery (query) {
      query.with(['ImageProfil', 'infoSociete'])
      let now = new Date();
      now.setHours(0,0,0,0);
      if (this.type === 'active') {
        query.where((ask) => {
          let departDate = new Date(ask.departUnix * 1000);
          departDate.setHours(0,0,0,0);
          let finDate = new Date(ask.finUnix * 1000);
          finDate.setHours(0,0,0,0);
          return (departDate.getTime() === now.getTime() || finDate.getTime() === now.getTime()) && !ask.canceled
        })
      } else if (this.type === 'unverified') {
        query.where((user) => {
          return user.lightyShareCertifed === false
        })
      } else if (this.type === 'tenant') {
        query.where((user) => {
          return true
        })
      } else if (this.type === 'owner') {
        query.where((user) => {
          return user.boutiqueActive === true
        })
      } else if (this.type === 'anonymous') {
        query.where((user) => {
          return user.isDeleted === 1
        })

      }
    },
  }
}
</script>

<style scoped>

</style>
