import User from "../../user/services/User.js";
import Media from "../../media/services/Media.js"
import { Model } from "@vuex-orm/core"
import Bundle from "./Bundle.js";
import Inventory from "~/modules/ad/services/Inventory.js";
import AdUnavailability from "~/modules/ad/services/AdUnavalability.js";

export default class Conflict extends Model {
  static entity = 'conflicts'

  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api',
    dataKey: 'conflicts',
  }
  static fields () {
    return {
      id: this.attr(null),
      count_conflicts: this.attr(null),
      count_resolved_conflicts: this.attr(null),
      name: this.attr(null),
      annonce_ids: this.attr(null),
    }
  }
}
