<script>
import CustomFilterTable from "~/components/CustomFilterTable.vue";
import LoadContent from "~/mixins/loadContent.js";
import Ads from "~/modules/ad/services/Ads.js";
import AdUnavailability from "~/modules/ad/services/AdUnavalability.js";
import EditInventory from "~/modules/ad/components/EditInventory.vue";
import UnavailabilityTable from "~/modules/ad/components/UnavailabilityTable.vue";
import EditUnavailability from "~/modules/ad/components/EditUnavailability.vue";
import StockCalendar from "~/modules/ad/components/StockCalendar.vue";
import ShareCart from "~/modules/cart/components/ShareCart.vue";
import ModalLayout from "~/layouts/ModalLayout.vue";

export default {
  name: "UnavailabilityList",
  components: {
    ModalLayout,
    ShareCart,
    StockCalendar, EditUnavailability, UnavailabilityTable, CustomFilterTable, EditInventory},
  fetchUrl: 'inventory',
  mixins: [LoadContent],
  data () {
    return {
      openShareModal: false,
      selectedItem: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      console.log('beforeRouteEnter')
      const rep = await vm.beforeDataFetch()
      // rep[]
      console.log('rep')
      console.log(rep)
    })
  },
  computed: {
    shareLinkShop () {
      let nomDeDomaine = window.location.hostname;
      return 'https://' + nomDeDomaine + '/annonce/show/' + this.id
    },
    unavailabilityProduct () {
      return this.response && this.response.UnavailabilityQuantities ? this.response.UnavailabilityQuantities : {}
    },
    unavailabilityQuantities () {
      return this.response && this.response.UnavailabilityQuantities
    },
    quantityMax () {
      return this.response && this.response.quantityMax
    },
    fetchUrl () {
      return 'user/produit/' + this.id + '/indisponibilite'
    },
    Model () {
      return AdUnavailability
    },
    id () {
      return this.$route.params.id
    },
    ad () {
      return Ads.find(this.id)
    },
    title () {
      return this.ad ? this.ad.title : ''
    },
    addButton () {
      if (this.selectedItem && !this.selectedItem.id) {
        return false
      } else {
        return 'Ajouter une plage d\'indisponibilité'
      }
    }
  },
  methods: {
    async openModal () {
      this.openShareModal = true
    },
    async closeModal () {
      this.openShareModal = false
    },
    async removeUnavailability (id) {
      let url = 'user/indisponibilite/' + id
      let removedUnavailability = await this.Model.api().delete(url, {
        delete: id
      })
      console.log('removedUnavailability')
      console.log(removedUnavailability)
      if (removedUnavailability && removedUnavailability.response && removedUnavailability.response.data && removedUnavailability.response.data.unavailabilityIds && Array.isArray(removedUnavailability.response.data.unavailabilityIds) && removedUnavailability.response.data.unavailabilityIds.length) {
        removedUnavailability.response.data.unavailabilityIds.foreach((id) => {
          this.Model.remove(id)
        })
      }
      if (removedUnavailability && removedUnavailability.response && removedUnavailability.response.data) {
        console.log('removedUnavailability.response.data')
        this.response = removedUnavailability.response.data
      }
    },
    handleCurrentChange (val) {
      console.log('handleCurrentChange')
      if (val) {
        val['_token'] = this.token_csrf
      }
      this.selectedItem = val
    },
    handleNewClick () {
      this.selectedItem = {
        ad_id: this.id,
        quantity: 1,
        status: 2,
        unavailableRange: {
          startAt: null,
          endAt: null
        },
        _token: this.token_csrf
      }
    },
    preQuery (query) {
      query.where('ad_id', parseInt(this.id))
    },
    afterSubmit (response) {
      this.selectedItem = null
      if (response && response.response && response.response.data) {
        this.response = response.response.data
      }
    }
  }
}
</script>

<template>
  <EditUnavailability v-if="selectedItem" :unavailability="selectedItem" @afterSubmit="afterSubmit" :unavailabilityQuantities="unavailabilityQuantities" :quantityMax="quantityMax"/>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div>
        <h3 class="subTitle">{{ $t('shop.products.unavailability.title') }} <span class="countLabel">{{ $t('shop.products.unavailability.count-units', {count: paginationQuery.count }) }}</span></h3>
      </div>
      <CustomFilterTable :new-button="addButton"  @new-click="handleNewClick"/>
    </div>
    <UnavailabilityTable :unavailability="modelList" @sort-change="sortChange" @handleCurrentChange="handleCurrentChange" @remove="removeUnavailability">
      <template #empty>
        <div class="empty-background">
          <div v-if="isLoadingContent" class="loading-content">
            <div class="cv-spinner">
              <span class="spinner"></span>
            </div>
            <div class="empty-background-content-title">
              {{ $t('shop.products.unavailability.loading')}}
            </div>
          </div>
          <div v-else class="empty-content">
            <div>
              <div class="non-clickable-button icomoon">
                &#xe927
              </div>
              <div class="empty-background-content-title">
                {{ $t('shop.products.unavailability.empty.title')}}
              </div>
              <div class="empty-background-content-description">
                {{ $t('shop.products.unavailability.empty.description')}}
              </div>
            </div>
            <div class="empty-background-content-button">
              <el-button @click="openModal" size="large" type="primary"><span class="icomoon" style="margin-right: 8px">&#xe9d1</span>   {{ $t('shop.products.unavailability.empty.button')}}</el-button>
            </div>
          </div>
        </div>
      </template>
    </UnavailabilityTable>
    <Teleport to="body">
      <ModalLayout :show="openShareModal" @close="closeModal">
        <template #header>
          <h3><span class="icon-kit">&#xe9d1</span>Partager mon annonce</h3>
        </template>
        <template #body>
          <ShareCart @close="closeModal" :share-link="shareLinkShop">
          </ShareCart>
        </template>
      </ModalLayout>
    </Teleport>
<!--    <div class="pagination-container">-->
<!--      <el-pagination :current-page="currentPage" hide-on-single-page background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>-->
<!--    </div>-->
    <stock-calendar v-if="false" :adUnavailability="modelList" :product="ad" :unavailabilityProduct="unavailabilityProduct"></stock-calendar>
  </el-card>
</template>

<style scoped>

</style>