<template>
  <el-form-item>
    <template #label>
      <div>
        <h4 class="label-title">Ajouter un produit</h4>
        <p class="label-info">Sélectionner les produits de votre inventaire que vous souhaitez mettre dans votre pack.</p>
      </div>
    </template>
    <ItemSelect :result-filter="resultFilter" :load-on-focus="true" :placeholder="'Ajouter votre produit'" :newItem="false" :types="['annonce']" :ESOptions="{params: {p: 12, types: 'annonce', userIds: Auth.id}}" @update:modelValue="updateValue">
      <template  #item="{element}" >
        <a href="" class="clickable-item" v-html="getItemText(element)"></a>
      </template>
    </ItemSelect>
    <el-card class="p-0 bordered-card small-shadow" style="width: 100%; margin-top: 12px">
      <el-table class="bundle-item-table"  :data="bundleItems" :table-layout="'auto'">
        <el-table-column prop="nomEquipement" label="Produit" align="left" :min-width="300">
          <template #default="scope">
            <AdTicket v-if="scope.row.annonces" :Ad="scope.row.annonces" :bundle-item="true"/>
          </template>
        </el-table-column>
        <el-table-column prop="quantity" label="Quantité" width="150">
          <template #default="scope" >
            <el-input type="number" v-model="edit[scope.$index].quantity" @input="onUpdate">
              <template v-if="scope.row && scope.row.annonces" #append>/ {{ scope.row.annonces.quantity }}</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column prop="discount" label="Réduction" width="180">
          <template #default="scope">
            <el-input type="number" v-model="edit[scope.$index].discount" @input="onUpdate" :min="0" :max="100">
              <template #append>%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column prop="quantity" label="" width="60" align="right" fixed="right">
          <template #default="scope">
<!--            <el-button class="dropdown-edit-toogle" style=padding: 5px;">-->
<!--              <span class="icon-kit" @click="goToAdEdit(scope.row.annonceId)">-->
<!--                &#xe95c-->
<!--              </span>-->
<!--            </el-button>-->
            <el-button class="dropdown-edit-toogle" style="padding: 5px;" @click="removeItem(scope.$index)">
              <span class="icon-kit">
                &#xe9f1
              </span>
            </el-button>
            <!--        <el-button v-if="scope.row.isNew" @click="pushItem(scope.row)" >Ajouter</el-button>-->
            <!--        <el-button v-else >Migrez en produit</el-button>-->

          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </el-form-item>
</template>

<script>
import ItemSelect from "~/modules/ad/components/ItemSelect.vue";
import Auth from "~/modules/auth/services/Auth.js";
import Ads from "~/modules/ad/services/Ads.js";
import AdTicket from "~/modules/ad/components/AdTicket.vue";

export default {
  name: "BundleItemEdit",
  components: {AdTicket, ItemSelect},
  props: {
    modelValue: {
      type: Array,
      default () {
        return []
      }
    },
  },
  data () {
    return {
      edit: [],
    }
  },
  watch: {
    modelValue: {
      handler (val) {
        this.edit = JSON.parse(JSON.stringify(val))
      },
      immediate: true,
      deep: true
    },
    edit: {
      handler (val) {
        let adsToLoad = val.filter(item => Ads.query().find(item.annonces) === null)
        this.loadAds(adsToLoad)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    Auth () {
      return Auth.query().get().length ? Auth.query().get()[0] : false
    },
    items () {
      return []
    },
    itemsAdsIds () {
      return this.bundleItems.map((i) => {
        return i.annonceId
      })
    },
    bundleItems () {
      return this.edit.map((item, index) => {
        let annonceId = null
        if (item.annonces) {
          annonceId = item.annonces
        } else if (item.annonceId) {
          annonceId = item.annonceId
        }
        return {
          ...item,
          annonces: Ads.query().with('medias').find(annonceId)
        }
      })
    }
  },
  methods: {
    resultFilter (result) {
      console.log(result)
      // return true
      return result.productType === 0
    },
    goToAdEdit (id) {
      this.$router.push({name: 'product-edition', params: {id: id}})
    },
    getItemText (item) {
      let text = ''
      let hasModele = item.modele && item.modele.nom && item.modele.marque && item.modele.marque.nom
      if (hasModele) {
        text += item.modele.marque.nom + ' ' + item.modele.nom
      }
      if (item.title) {
        return item.title
      }
      // if (hasModele && item.titre) {
      //   text += ' - '
      // }
      // if (item.titre) {
      //   text += item.titre
      // }
      return text
    },
    onUpdate (event) {
      if (!!event) {
        this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.edit.map((item) => {
          return {
            ...item,
            annonces: item.annonceId
          }
        }))));
        this.$emit('newChange')
      }
    },
    removeItem (index) {
      const items = [...this.edit]
      const newValue = items.splice(index, 1)
      this.$emit('update:modelValue', JSON.parse(JSON.stringify(items.map((item) => {
        return {
          ...item,
          annonces: item.annonceId
        }
      }))));
      this.$emit('newChange')
    },
    updateValue(value) {
      if (!this.itemsAdsIds.includes(value.id)) {
        this.edit.push({
          annonceId: value.id,
          quantity: 1,
          discount: 5
        })
        this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.edit.map((item) => {
          return {
            ...item,
            annonces: item.annonceId
          }
        }))));
        this.$emit('newChange')
      }
      // this.$emit('update:modelValue', value)
    },
    loadAds (ads) {
      // foreach ads
      ads.forEach((item, index) => {
        Ads.api().get('user/annonce-edit/' + item.annonceId)
      })
    }
  }
}
</script>

<style scoped>

</style>