<template>
  <el-alert v-for="error in HeaderErrors" :title="error" type="error" show-icon :closable="false" />
  <el-form :model="form" label-width="30%" label-position="left" size="large">
    <div class="product-creation-form-header">
      <h3>Modèle de calcul</h3>
      <p>{{$t('steps-location-3.tracking-section.description')}} </p>
    </div>
    <el-form-item :label="'Clé'" :error="FieldsErrors.key">
      <el-input v-model="edit.key" maxlength="255" class="" :placeholder="'TVA FR'"></el-input>
    </el-form-item>
    <el-form-item label="Type de calcul">
      <el-select v-model="adType" style="width:100%">
        <el-option
            v-for="type in adTypes"
            :key="type.value"
            :label="type.label"
            :value="type.value"
        />
      </el-select>
    </el-form-item>
    <el-form-item label="Valeur">
      <el-input v-model="edit.value" maxlength="255" class="" :placeholder="'quantity x time x location'"></el-input>
    </el-form-item>
    <el-form-item label="Pourcentage">
      <el-input-number v-model="edit.quantity" size="large" />
    </el-form-item>
    <div v-if="adType === 'pack'">
      <el-form-item label="Variants">
          <el-select v-model="adType" style="width:100%">
            <el-option
              v-for="type in adTypes"
              :key="type.value"
              :label="type.label"
              :value="type.value"
            />
          </el-select>
      </el-form-item>
    </div>
    <el-form-item :label="'Paramètre (JSON)'" :error="FieldsErrors.settings">
      <el-input
          v-model="edit.settings"
          maxlength="2000"
          class=""
          show-word-limit
          type="textarea"
          :placeholder="$t('steps-location-2.annonce.input-description.placeholder')"
      />
    </el-form-item>
    <hr>
    <el-form-item label="Activer">
      <el-switch v-model="edit.online"/>
    </el-form-item>
    <button class="btn btn-short btn-primary mt-4" :class="buttonClass" id="save-button-section-pricing" @click.prevent="sendForm">
      {{$t('owner-settings.list-group.item.2.save')}}
    </button>
  </el-form>
</template>

<script>
import formHandler from "../../../mixins/formHandler.js";
import Computed from '../services/Computed.js'

export default {
  components: {
  },
  name: "ComputationEdit",
  ApiUrl: 'user/annonce-edit',
  mixins: [formHandler],
  methods: {
  },
  computed: {
    id () {

    },
    Model () {
      return Computed
    },
    category () {
    }
  },
  data () {
    return {
      newModele: false,
      adType: 'Fixe',
      adTypes: [
        {
          label: 'Fixe',
          value: 0
        },
        {
          label: 'Formula',
          value: 1
        },
        {
          label: 'Rate',
          value: 2
        },
        {
          label: 'Sum',
          value: 3
        },
        {
          label: 'Sub',
          value: 4
        }
      ],
      content: {
      },
      edit: {},
      form: {}
    }
  },
}
</script>

<style scoped>

</style>
