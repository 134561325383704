<template>
  <div class="">
    <div class="">
      <div class="reservation-header">
        <h3>Revenus</h3>
<!--        <h4>{{ app.user.email }} <i class="fas fa-circle"></i> <a href="{{ path('my_user_show', {'id' : app.user.id}) }}" class="profile-link ml-2"><span>Voir ma boutique</span></a></h4>-->
      </div>
    </div>
  </div>
</template>

<script>
import formHandler from "../../mixins/formHandler";
export default {
  name: "revenus",
  mixins: [formHandler]
}
</script>

<style scoped>

</style>
